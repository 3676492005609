import defaultMetricGroup from '../../utils/defaultMetricGroup';

export default function updateMetrics({ target, source }) {
  const metrics = target ? target : defaultMetricGroup();

  metrics.activities += source.activities;
  metrics.groups += source.groups;
  metrics.managers += source.managers;
  metrics.roles += source.roles;
  metrics.hours += source.hours;

  if (Number.isFinite(metrics.fte) && Number.isFinite(source.fte)) {
    metrics.fte += source.fte;
  }
  if (Number.isFinite(metrics.budget) && Number.isFinite(source.budget)) {
    metrics.budget += source.budget;
  }

  // Spans don't exist on all source metrics so restrict when they are
  // calculated.
  if (Number.isFinite(source.span)) {
    metrics.span = Number.isFinite(metrics.span)
      ? metrics.span + source.span
      : source.span;
  }

  return metrics;
}
