import config from '@/lib/config';
import { DesignEntity } from '@/lib/enums';
import defaultMetricGroup from '../utils/defaultMetricGroup';
import deriveEntityTag from './shared/deriveEntityTag';
import updateMetrics from './shared/updateMetrics';

/**
 * Calculates activiy tags for every entity it's directly associated with.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function deriveActivityTags({
  activity,
  role,
  group,
  metrics,
  tagMap,
}) {
  if (!activity?.tags?.length) {
    return;
  }

  const isVisible = activity.__visibility === config.VISIBILITY.FULL;

  // Capture activity metrics that apply to all entities.
  const activityMetrics = {
    ...defaultMetricGroup(false),
    activities: 1,
    hours: activity.hours,
  };

  // Loop through each activity tag and update the metrics for every entity
  // it's directly related to.
  activity.tags.forEach((tagId) => {
    const totalActivityTagMetrics =
      activity.__metrics.self.total.tags[DesignEntity.ACTIVITY]?.[tagId] ??
      defaultMetricGroup(false);
    const visibleActivityTagMetrics =
      activity.__metrics.self.visible.tags[DesignEntity.ACTIVITY]?.[tagId] ??
      defaultMetricGroup(false);

    // Directly update the metrics on the activity.
    const total = {
      ...activityMetrics,
      roles: role ? 1 : 0,
      groups: group ? 1 : 0,
    };
    activity.__metrics.self.total.tags[DesignEntity.ACTIVITY][tagId] =
      updateMetrics({
        target: { ...totalActivityTagMetrics },
        source: { ...total },
      });
    const visible = isVisible ? { ...total } : defaultMetricGroup(false);
    activity.__metrics.self.visible.tags[DesignEntity.ACTIVITY][tagId] =
      updateMetrics({
        target: { ...visibleActivityTagMetrics },
        source: { ...visible },
      });

    // Derive tag metrics for the role which are stored in the metrics object.
    if (role && metrics.has(role.uuid)) {
      deriveEntityTag({
        activity,
        entityId: role.uuid,
        group,
        metrics,
        role,
        tagId,
        tagType: DesignEntity.ACTIVITY,
        tagMap,
      });
    }

    // Derive tag metrics for the group which are stored in the metrics object.
    if (group && metrics.has(group.uuid)) {
      deriveEntityTag({
        activity,
        entityId: group.uuid,
        group,
        metrics,
        role,
        tagId,
        tagType: DesignEntity.ACTIVITY,
        tagMap,
      });
    }

    // Derive root tag metrics which are stored in the metrics object.
    if (metrics.has('*')) {
      deriveEntityTag({
        activity,
        entityId: '*',
        group,
        metrics,
        role,
        tagId,
        tagType: DesignEntity.ACTIVITY,
        tagMap,
      });
    }
  });
}
