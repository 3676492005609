import { tool } from 'ai';
import { z } from 'zod';

const activityTagging = tool({
  description:
    'Add or remove tags from activities in the organizational design',
  parameters: z.object({
    payload: z
      .object({
        ids: z
          .array(z.string())
          .describe('Array of activity UUIDs to modify tags for'),
        change: z
          .object({
            add: z
              .array(z.string())
              .describe('Array of tag UUIDs to add to the activities'),
            remove: z
              .array(z.string())
              .describe('Array of tag UUIDs to remove from the activities'),
          })
          .describe('Changes to make to the activity tags'),
      })
      .describe('The payload for tagging activities'),
    explanation: z
      .string()
      .describe(
        'An explanation of what tags are about to be modified. This step will be asked to be confirmed. Phrase it accordingly.'
      ),
  }),
  execute: async ({ payload, explanation }) => ({
    payload,
    explanation,
  }),
});

export default activityTagging;
