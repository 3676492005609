import { tool } from 'ai';
import { z } from 'zod';

const assignLead = tool({
  description:
    'Assign a role as the leader for one or more groups in the organizational structure. This updates the leadership relationship between the specified groups and the designated role.',
  parameters: z.object({
    payload: z
      .object({
        ids: z
          .array(z.string())
          .describe(
            'Array of 9-digit group UUIDs that will have their leader changed. You can specify one or multiple groups to update simultaneously.'
          ),

        leadId: z
          .string()
          .nullable()
          .describe(
            'The 9-digit UUID of the role that will be assigned as the leader for the specified groups. Use null to remove the current leader without assigning a new one.'
          ),
      })
      .describe(
        'The payload containing the groups to update and the role to assign as their leader'
      ),
    explanation: z
      .string()
      .describe(
        'A clear explanation of the leadership change being made. For example: "Assign Jane Doe (Product Manager) as the leader of the Marketing and Sales groups." This will be shown to the user for confirmation.'
      ),
  }),
  execute: async ({ payload, explanation }) => ({
    payload,
    explanation,
  }),
});

export default assignLead;
