import { Size, Variant } from '@/atoms/enums';
import { Colors } from '@/atoms/inputs';
import useInput from '@/atoms/inputs/hooks/useInput';
import {
  flatInputSizeProps,
  outlineInputSizeProps,
} from '@/atoms/inputs/utils';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import excludeProps from '@/lib/theme/excludeProps';

const StyledOutlinedInput = styled(OutlinedInput, {
  shouldForwardProp: excludeProps('color', 'textProps', 'variant', 'width'),
})(({ color, textProps, variant, width }) => ({
  '& .MuiOutlinedInput-input': {
    ...textProps,
    color: Colors.Input[color].normal.color,
    backgroundColor: 'transparent',
    padding: variant === Variant.TEXT ? 0 : '4px',
    width: width ?? 'inherit',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    boxShadow:
      variant === Variant.OUTLINE
        ? `inset 0 0 0 1px ${Colors.Input[color].normal.borderColor}`
        : 'none',
    transition: 'box-shadow 200ms',
  },
  '&:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      boxShadow:
        variant === Variant.OUTLINE
          ? `inset 0 0 0 2px ${Colors.Input[color].hover.borderColor}`
          : 'none',
    },
  },
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      boxShadow:
        variant === Variant.OUTLINE
          ? `0 0 0 2px ${Colors.Input[color].focus.borderColor}`
          : 'none',
    },
  },
  // Hide number arrows from input fields.
  '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
}));

const TextInput = ({
  autoFocus = false,
  color = 'primary',
  disabled = false,
  debounce = 200,
  endAdornment,
  fullWidth,
  initialValue = '',
  inputProps,
  textAlign,
  maxLength,
  name,
  onBlur,
  onFocus,
  onChange,
  onKeyDown,
  placeholder,
  size = Size.MEDIUM,
  variant = 'outline',
  width,
}) => {
  const { inputBlur, inputChange, inputFocus, value, inputKeyDown } = useInput({
    debounce,
    maxLength,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    initialValue,
  });

  const textProps =
    variant === 'outline'
      ? { ...outlineInputSizeProps(size) }
      : { ...flatInputSizeProps(size) };

  textProps.textAlign = textAlign;

  return (
    <StyledOutlinedInput
      autoComplete="off"
      autoFocus={autoFocus}
      color={color}
      disabled={disabled}
      endAdornment={endAdornment}
      fullWidth={fullWidth}
      textProps={textProps}
      id={`outlined-input-${name}`}
      inputProps={inputProps}
      textAlign={textAlign}
      name={name}
      onBlur={inputBlur}
      onChange={inputChange}
      onFocus={inputFocus}
      onKeyDown={inputKeyDown}
      placeholder={placeholder}
      value={value}
      variant={variant}
      width={width}
    />
  );
};

export default TextInput;
