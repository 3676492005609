import defaultMetricGroup from './defaultMetricGroup';

export default function defaultEntityMetrics() {
  return {
    managed: {
      total: defaultMetricGroup(),
      visible: defaultMetricGroup(),
    },
    self: {
      total: defaultMetricGroup(),
      visible: defaultMetricGroup(),
    },
    span: {
      total: defaultMetricGroup(),
      visible: defaultMetricGroup(),
    },
  };
}
