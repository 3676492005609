import { tool } from 'ai';
import { z } from 'zod';

const activityUpdate = tool({
  description: 'Update an existing activity in the organizational design',
  parameters: z.object({
    payload: z
      .object({
        uuid: z.string().describe('The UUID of the activity to update'),
        hours: z
          .number()
          .describe('The number of hours allocated to this activity'),
        tags: z
          .array(z.string())
          .describe('Array of tag UUIDs associated with this activity'),
      })
      .describe('The update payload for the activity'),
    explanation: z
      .string()
      .describe('An explanation of what is about to be updated. '),
  }),
  execute: async ({ payload, explanation }) => ({
    payload: {
      ...payload,
      updated_at: Date.now() / 1000,
    },
    explanation,
  }),
});

export default activityUpdate;
