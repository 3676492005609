/**
 * Restore original UUIDs in a data structure by replacing 9-digit strings
 * with their corresponding UUIDs from the provided mapping
 *
 * @param {Object|Array} obj - The object or array to traverse
 * @param {Object} uuidMap - Mapping of 9-digit strings to original UUIDs
 * @returns {Object|Array} - A new object/array with replaced UUIDs
 */
export function restoreUUIDs(obj, uuidMap) {
  if (!obj || !uuidMap) return obj;

  function traverse(current) {
    if (current === null || current === undefined) {
      return current;
    }

    // Handle string values - check if it's a 9-digit string
    if (typeof current === 'string' && /^\d{9}$/.test(current)) {
      // Look up the original UUID in the map
      const originalUUID = uuidMap[current];
      if (originalUUID) {
        console.log(`Replacing ${current} with original UUID ${originalUUID}`);
        return originalUUID;
      }
    }

    // Handle arrays
    if (Array.isArray(current)) {
      return current.map((item) => traverse(item));
    }

    // Handle objects
    if (typeof current === 'object') {
      const result = {};
      for (const key in current) {
        if (Object.prototype.hasOwnProperty.call(current, key)) {
          result[key] = traverse(current[key]);
        }
      }
      return result;
    }

    // Return primitive values as is
    return current;
  }

  return traverse(obj);
}
