import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';

export default function assignedRoleAction({
  comparisonScenario,
  originalRole,
  role,
}) {
  const action = {
    type: ActionType.ASSIGNED_ROLES,
    [EntityType.ROLE]: new Map(),
    metrics: defaultMetrics({ comparisonScenario }),
  };

  const roleProperties = {};

  if (role?.user_uuid !== originalRole?.user_uuid) {
    roleProperties.assigned = {
      from: originalRole?.user_uuid,
      to: role?.user_uuid,
    };
  }

  action[EntityType.ROLE].set(role.uuid, { props: roleProperties });

  action.metrics[1].assigned += 1;

  return action;
}
