import getSummarised from '@pkg/entities/tags/get/summarised';
import { Colors, Num } from '@pkg/utils';
import config from '@/lib/config';
import { DesignEntity } from '@/lib/enums';

/**
 * Normalizes default activity properties.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function normalizeActivity({ activity, library, snapshot }) {
  if (!activity || !snapshot || !library) {
    return;
  }

  const ownerPlural = DesignEntity.toPlural(activity.owner_type);
  const owner = snapshot.entities[ownerPlural][activity.owner_uuid];
  const isAllocated = activity.owner_type === DesignEntity.ROLE;

  activity.order = Number(activity.order || 0);
  activity.properties = activity.properties ?? [];
  activity.tags = activity.tags ?? [];

  activity.__color = Colors.seededOld(activity.uuid);
  activity.__description =
    library.activities.mapped.get(activity.library_uuid)?.description ??
    activity.__description ??
    'DELETED';

  activity.__group = owner?.[isAllocated ? 'group_uuid' : 'uuid'] ?? null;

  // Tags.
  const tagsSummarised = getSummarised(library.tags.tags, activity.tags);
  activity.__tags_set = new Set(activity.tags);
  activity.__tags_summarised = tagsSummarised;

  // Properties.
  const properties = activity.properties ?? [];
  activity.__properties_map = new Map(
    properties.map(({ key, value }) => [key, value])
  );

  activity.__type = DesignEntity.ACTIVITY;
  activity.__visibility = activity.__visibility ?? config.VISIBILITY.FULL;
  const isVisible = activity.__visibility === config.VISIBILITY.FULL;

  // normalize hours
  activity.hours = Num.roundFloat(activity.hours);
  activity.hours = Number.isFinite(activity.hours) ? activity.hours : null;

  return activity;
}
