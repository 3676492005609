import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

export default function movedGroupsAction({
  actionMap,
  comparisonScenario,
  originalGroup,
  group,
  scenario,
}) {
  const metrics = {
    ...group.__metrics.self.visible,
  };

  const existingAction = actionMap?.get(ActionType.MOVE_GROUPS);

  const action = existingAction ?? {
    entity: EntityType.GROUP,
    metrics: defaultMetrics({ comparisonScenario }),
    type: ActionType.MOVE_GROUPS,
    [EntityType.GROUP]: new Map(),
    [EntityType.MANAGER]: new Set(),
  };

  const groupProperties = {};

  // If moved between managers.
  if (group.__manager !== originalGroup.__manager) {
    if (group.__manager) {
      action[EntityType.MANAGER].add(group.__manager);
    }
    if (originalGroup.__manager) {
      action[EntityType.MANAGER].add(originalGroup.__manager);
    }
  }

  // If moved between parents.
  if (group.__parent?.uuid !== originalGroup.__parent?.uuid) {
    groupProperties.moved = {
      ...groupProperties.moved,
      parent: {
        from: originalGroup.__parent,
        to: group.__parent,
      },
    };
  }

  action.metrics[1].moved += 1;

  // Aggregate the individual group action metrics.
  const actionGroup = action[EntityType.GROUP].get(group.uuid) ?? {
    metrics: defaultMetrics({ comparisonScenario, group }),
  };
  actionGroup.metrics[1].moved += 1;
  actionGroup.props = { ...groupProperties };
  action[EntityType.GROUP].set(group.uuid, actionGroup);

  const groupCount = action[EntityType.GROUP].size;
  const managerCount = action[EntityType.MANAGER].size;

  const groupLabel = entityLabel(EntityType.GROUP, groupCount !== 1);
  const managerLabel = entityLabel(EntityType.MANAGER, managerCount !== 1);

  const verb = groupCount === 1 ? 'has' : 'have';

  action.title = `${groupCount} ${groupLabel} ${verb} been moved`;

  if (managerCount > 0) {
    action.title = `${action.title}, impacting ${managerCount} ${managerLabel}`;
  }

  return action;
}
