import { useEffect, useState } from 'react';
import { Button } from '@/atoms/buttons';
import * as Colors from '@/atoms/colors';
import {
  ActivityDescriptions,
  ActivityHours,
  ActivityTags,
  Completed,
  ReviewRole,
  Welcome,
} from '@/organisms/roles/Onboarding';
import Wizard, { WizardStep } from '@/organisms/wizard';
import { FlowStatus } from '@/shared/enums';
import { useUpdateFlow } from '@/shared/hooks';
import {
  useFlowContext,
  useScenarioContext,
  useTagContext,
} from '@/shared/providers';
import ExitToAppIcon from '@mui/icons-material/ExitToAppRounded';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as Auth from '@pkg/auth';
import { DesignEntity } from '@/lib/enums';

const StyledBox = styled(Box)(({ theme }) => ({
  color: Colors.Base.font.primary,
  backgroundColor: Colors.Base.background.tertiary,
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  scrollBehavior: 'smooth',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    overflow: 'hidden',
  },
}));

const RoleWizardLayout = ({ coreActivitySet }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { flow } = useFlowContext();
  const { scenario, canPublish, publish } = useScenarioContext();
  const person = scenario?.entity?.__person;
  const { entityTagMap } = useTagContext();
  const tagOptions = entityTagMap?.get(DesignEntity.ACTIVITY);
  const [tagSubSteps, setTagSubSteps] = useState();
  const { logout } = Auth.useLogout();
  const updateFlow = useUpdateFlow();
  const [loading, setLoading] = useState(false);
  const [allowedTagSet, setAllowedTagSet] = useState();
  const [categoryTagMap, setCategoryTagMap] = useState();
  const categoryTags = flow.showTags ? [...flow.showTags] : [];
  const showDaysPerMonth = flow.showDaysPerMonth;

  const handleSignout = () => {
    logout(false);
  };

  const handlePublish = async () => {
    const { details, entity } = scenario;

    if (!details || !entity) {
      return;
    }

    if (canPublish) {
      setLoading(true);
      await updateFlow({ uuid: flow?.uuid, status: FlowStatus.COMPLETE });
      await publish({
        title: entity?.title,
        uuid: entity.uuid,
        message: 'Finalising your role',
      });
      setLoading(false);
    }
  };

  // Updates the flow updated_at so we know it was opened
  useEffect(() => {
    const update = async () => {
      await updateFlow({ uuid: flow?.uuid, status: FlowStatus.INCOMPLETE });
    };
    update();
  }, []);

  // Sets the beamible and primary tags as sub-steps in the wizard.
  useEffect(() => {
    if (!tagOptions?.length || !flow.tags.size) {
      return;
    }

    const tags = [];
    const categoryTagMap = new Map();

    tagOptions.forEach((category) => {
      category.options.forEach((option) => {
        if (flow.showTags.has(option.id)) {
          categoryTagMap.set(option.id, option);
        }

        if (!flow.tags.has(option.id) && !flow.showTags.has(option.id)) {
          return;
        }

        tags.push({
          title: option?.properties?.name,
          ...option,
        });
      });
    });

    setTagSubSteps(tags.filter(({ id }) => !categoryTagMap.has(id)));
    setAllowedTagSet(new Set(tags.map((tag) => tag.id)));
    setCategoryTagMap(categoryTagMap);
  }, [tagOptions]);

  return (
    scenario && (
      <StyledBox>
        <Wizard
          navigationComponent={
            <Button
              color="white"
              label="Sign out"
              dataTestid="logout"
              onClick={handleSignout}
              startIcon={<ExitToAppIcon />}
              variant="naked"
            />
          }
        >
          <WizardStep
            showNavigation={false}
            stepLabel={`Welcome`}
            title={`Hi ${person.first_name}`}
          >
            <Welcome
              description={flow.description}
              disclaimer={flow.disclaimer}
            />
          </WizardStep>
          <WizardStep
            stepLabel={`Break down your role`}
            title={`Break down your role`}
          >
            <ActivityDescriptions
              coreActivitySet={coreActivitySet}
              categoryTagMap={categoryTagMap}
              categoryTags={categoryTags}
            />
          </WizardStep>
          <WizardStep stepLabel={`Edit your hours`} title={`Edit your hours`}>
            <ActivityHours
              coreActivitySet={coreActivitySet}
              categoryTagMap={categoryTagMap}
              categoryTags={categoryTags}
              showDaysPerMonth={showDaysPerMonth}
            />
          </WizardStep>
          {isLargeScreen && tagSubSteps?.length && (
            <WizardStep
              stepLabel={`Share your perspective`}
              title={`Share your perspective`}
              subSteps={tagSubSteps}
            >
              <ActivityTags
                coreActivitySet={coreActivitySet}
                categoryTagMap={categoryTagMap}
                categoryTags={categoryTags}
              />
            </WizardStep>
          )}
          {!isLargeScreen &&
            tagSubSteps?.map((tag) => (
              <WizardStep stepLabel={tag.title} title={tag.title} key={tag.id}>
                <ActivityTags
                  id={tag.id}
                  properties={tag.properties}
                  title={tag.title}
                  coreActivitySet={coreActivitySet}
                  categoryTagMap={categoryTagMap}
                  categoryTags={categoryTags}
                />
              </WizardStep>
            ))}
          <WizardStep
            stepLabel={`Review your role`}
            nextLabel="Finish"
            isLoadingNext={loading}
            title={`Almost there!`}
            onNext={handlePublish}
          >
            <ReviewRole
              allowedTagSet={allowedTagSet}
              categoryTagMap={categoryTagMap}
              categoryTags={categoryTags}
            />
          </WizardStep>
          <WizardStep
            showNavigation={false}
            stepLabel={`Done!`}
            title={`Nice work, ${person.first_name}!`}
          >
            <Completed allowedTagSet={allowedTagSet} />
          </WizardStep>
        </Wizard>
      </StyledBox>
    )
  );
};

export default RoleWizardLayout;
