import { useRouter } from 'next/router';
import { useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { Modal } from '@/molecules/modals';
import { AddScenarioForm } from '@/organisms/forms';
import { ViewMode } from '@/shared/enums';
import { useViewModeContext } from '@/shared/providers';
import AddIcon from '@mui/icons-material/AddCircleOutlineRounded';
import * as Auth from '@pkg/auth';
import { Routes } from '@pkg/utils';
import { DesignLevel } from '@/lib/enums';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';

const AddScenario = ({
  color = 'light-blue',
  buttonSize = Size.MEDIUM,
  disabled = false,
  boldButtonText,
  view = ViewMode.DIAGRAM,
  variant = 'contained',
  onScenarioCreated,
}) => {
  const me = Auth.useStore((state) => state.me);
  const level = useDesignStore((state) => state.level);
  const router = useRouter();
  const { selectViewMode } = useViewModeContext();

  const [addScenarioOpen, setAddScenarioOpen] = useState(false);
  const canCreateScenario =
    me?.is_role_only && level?.type !== DesignLevel.ROLE ? false : true;

  const handleOpenAddScenario = () => {
    setAddScenarioOpen(true);
  };

  /** Redirects to the new scenario or closes the modal.
   *
   * @param {Object} event
   * @param {Object} scenario
   */
  const handleCloseAddScenario = async (event, scenario) => {
    if (!scenario) {
      setAddScenarioOpen(false);
      return;
    }

    const { entity, scenarioId, scope } = scenario;

    // Call onScenarioCreated if provided
    if (onScenarioCreated) {
      onScenarioCreated(scenario);
      setAddScenarioOpen(false);
      return;
    }

    const path = Routes.build.designParts({
      designId: scenarioId,
      level: {
        type: scope,
        uuid: entity?.uuid ?? entity?.__uuid,
      },
      params: {
        view,
      },
    });

    if (path) {
      // We need to trigger the view mode update after the route change to
      // ensure the redirect occurs.
      await router.push(path);
      selectViewMode(view);
    }
  };

  return (
    canCreateScenario && (
      <>
        <Button
          boldText={boldButtonText}
          color={color}
          disabled={disabled || !canCreateScenario}
          label="Create scenario"
          size={buttonSize}
          startIcon={<AddIcon />}
          onClick={handleOpenAddScenario}
          variant={variant}
        />
        <Modal
          open={addScenarioOpen}
          onClose={handleCloseAddScenario}
          maxWidth="calc(40vw)"
          padding="0 32px 48px"
        >
          {addScenarioOpen && (
            <AddScenarioForm onUpdate={handleCloseAddScenario} />
          )}
        </Modal>
      </>
    )
  );
};

export default AddScenario;
