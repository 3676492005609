import { tool } from 'ai';
import { z } from 'zod';

const groupArchive = tool({
  description: 'Archive one or more groups from the organisational design',
  parameters: z.object({
    payload: z
      .object({
        ids: z
          .array(z.string().describe('9-digit UUIDs of the groups to archive'))
          .describe('Array of group UUIDs to archive'),
        inclusive: z
          .boolean()
          .describe(
            'If true, also archives all roles and activities contained within these groups'
          ),
      })
      .describe('The payload for archiving groups'),
    explanation: z
      .string()
      .describe(
        'An explanation of what will be archived and the impact on the organization'
      ),
  }),
  execute: async ({ payload, explanation }) => ({
    payload,
    explanation,
  }),
});

export default groupArchive;
