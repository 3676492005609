import { ActionType, EntityType } from '@/shared/enums';

export default function skipManagerUpdate({
  action,
  role,
  activity,
  managerMap,
  showBudget,
}) {
  if (!role?.is_manager) {
    return true;
  }

  const existingAction = managerMap
    .get(role?.title)
    ?.[EntityType.ROLE]?.get(role.uuid)
    ?.actions?.get(action.type);

  // Skip previously added role.
  if (
    action.type === ActionType.ADD_MANAGERS &&
    existingAction?.type === ActionType.ADD_MANAGERS
  ) {
    return true;
  }

  // Skip previously removed role.
  if (
    action.type === ActionType.REMOVE_MANAGERS &&
    existingAction?.type === ActionType.REMOVE_MANAGERS
  ) {
    return true;
  }

  return false;
}
