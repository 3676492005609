import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

export default function removedManagersAction({
  actionMap,
  comparisonScenario,
  originalGroup,
  originalRole,
  role,
  scenario,
}) {
  const metrics = {
    ...originalRole.__metrics.self.visible,
    groups: originalGroup ? 1 : 0,
  };

  const existingAction = actionMap?.get(ActionType.REMOVE_MANAGERS);

  const action = existingAction ?? {
    entity: EntityType.MANAGER,
    metrics: defaultMetrics({ comparisonScenario }),
    type: ActionType.REMOVE_MANAGERS,
    [EntityType.MANAGER]: new Map(),
  };

  action.metrics[1].managers -= 1;

  // Aggregate the individual manager action metrics.
  const actionManager = action[EntityType.MANAGER].get(originalRole.uuid) ?? {
    metrics: defaultMetrics({ comparisonScenario, role: originalRole }),
  };

  const managerProperties = {};

  // Add the parent manager.
  if (originalRole.__manager) {
    managerProperties.moved = {
      manager: {
        from: originalRole.__manager,
      },
    };
  }

  // Add the parent group.
  if (originalRole.group_uuid) {
    managerProperties.moved = {
      ...managerProperties.moved,
      group: {
        from: originalRole.group_uuid,
      },
    };
  }

  actionManager.props = { ...managerProperties };

  actionManager.metrics[1].managers -= 1;
  action[EntityType.MANAGER].set(originalRole.uuid, actionManager);

  const managerCount = action[EntityType.MANAGER].size;

  const managerLabel = entityLabel(EntityType.MANAGER, managerCount !== 1);
  const verb = managerCount === 1 ? 'was' : 'were';

  action.title = `${managerCount} ${managerLabel} ${verb} removed`;

  return action;
}
