import { tool } from 'ai';
import { z } from 'zod';

const generateReport = tool({
  description: 'Asks for confirmation to generate a report',
  parameters: z.object({
    question: z
      .string()
      .describe(
        'Reformulate the user\'s question into a detailed analysis scope. Include: 1) The specific data points and metrics to be analyzed, 2) The time period or scope of analysis, 3) The organizational context (teams, departments, projects affected), 4) The type of insights being sought (e.g., performance analysis, trend analysis, comparative study), and 5) The expected business impact. Format as: "I will generate a report analyzing [specific data/metrics] from [time period/scope] for [context], focusing on [type of insights] to help determine [business impact]. Would you like me to proceed?"'
      ),
    keywords: z
      .array(z.string())
      .describe(
        'A list of keywords to generate a report on e.g. automation, ai, layers, business'
      ),
  }),
  execute: async ({ question, keywords }) => ({
    question,
    keywords,
  }),
});

export default generateReport;
