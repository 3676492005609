import { cloneElement, useEffect, useState } from 'react';
import { Chip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { SurveyHeading, TagHeading } from '@/molecules/headings';
import { retrieveFirstTagId, useActivityList } from '@/organisms/activities';
import Grid from '@mui/material/Grid';

const CategorisedActivityList = ({
  categoryTagMap,
  categoryTags,
  Component,
  coreActivitySet,
  settings,
}) => {
  const { activityList } = useActivityList({
    coreActivitySet,
    config: settings,
    includesTags: categoryTags,
  });

  const [firstTagId, setFirstTagId] = useState();

  useEffect(() => {
    if (!activityList.length || !settings.showDaysPerMonth) {
      return;
    }

    const firstTagId = retrieveFirstTagId({ activityList, tags: categoryTags });

    if (firstTagId) {
      setFirstTagId(firstTagId);
    }
  }, [activityList?.length, settings.showDaysPerMonth]);

  return (
    <Grid container direction="row">
      {categoryTags.map((id, index) => (
        <Grid item xs={12} key={id}>
          <div style={{ marginLeft: '16px' }}>
            {cloneElement(Component, {
              excludesTags: categoryTags.slice(0, index),
              includesTags: [id],
              TitleComponent: (
                <SurveyHeading
                  settings={{
                    ...settings,
                    showDaysPerMonth:
                      settings.showDaysPerMonth && id === firstTagId,
                  }}
                >
                  <TagHeading tag={categoryTagMap?.get(id)} />
                </SurveyHeading>
              ),
              settings: {
                ...settings,
                showTitle: false,
                canAddNew: false,
              },
            })}
          </div>
        </Grid>
      ))}
      <Grid item xs={12} sx={{ mt: 1 }}>
        <div style={{ marginLeft: '16px' }}>
          {cloneElement(Component, {
            excludesTags: categoryTags,
            settings: {
              ...settings,
              showTitle: false,
            },
            TitleComponent: (
              <SurveyHeading
                settings={{
                  ...settings,
                  showDaysPerMonth: settings.showDaysPerMonth && !firstTagId,
                }}
              >
                <div style={{ marginBottom: '12px' }}>
                  <Chip
                    id="other-activities"
                    color="tertiary"
                    name="Other work activities"
                    size={Size.LARGE}
                  />
                </div>
              </SurveyHeading>
            ),
          })}
        </div>
      </Grid>
    </Grid>
  );
};

export default CategorisedActivityList;
