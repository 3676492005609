import {
  cumulateMetrics,
  cumulateTagMetrics,
  defaultMetrics,
} from '@/organisms/plans/utils';
import { ActionType, EntityType } from '@/shared/enums';

export default function updateRoleType({
  action,
  role,
  roleMap,
  comparisonScenario,
}) {
  const roleType = roleMap.get(role.title) ?? {
    [EntityType.ROLE]: new Map(),
    metrics: defaultMetrics({ comparisonScenario }),
    props: {
      title: role.title,
    },
  };

  if (action.type === ActionType.UPDATED_TAGS) {
    const tags = cumulateTagMetrics({
      entityTags: action.metrics[1].tags,
      originalEntityTags: action.metrics[0].tags,
      existingMetrics: roleType.metrics,
    });

    roleType.metrics[0].tags = tags[0];
    roleType.metrics[1].tags = tags[1];
  }
  if (action.type !== ActionType.UPDATED_TAGS) {
    roleType.metrics[1] = {
      ...cumulateMetrics(action, roleType.metrics[1]),
      tags: roleType.metrics[1].tags,
    };
  }

  return roleType;
}
