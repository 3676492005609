import { Utils } from '@/atoms/chips';
import { metricColor } from '@/shared/utils';
import isTagActionType from './isTagActionType';

export default function planEntityColors({
  actionType,
  actionProperties,
  properties,
  entityType,
  metric,
  tagMap,
}) {
  const isTagAction = isTagActionType(actionType);

  if (!isTagAction) {
    return metricColor(metric, entityType);
  }

  const tag = isTagAction && tagMap ? tagMap?.get(actionProperties?.id) : null;

  const tagColors = tag ? Utils.tagColor({ color: tag.color }) : null;

  if (!tagColors) {
    return metricColor(metric, entityType);
  }

  return {
    primary: tagColors.normal.backgroundColor,
    secondary: tagColors.hover.backgroundColor,
  };
}
