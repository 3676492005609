/**
 * @param {Object[]} collection
 * @param {String} key
 * @param {any} values
 *
 * @return {Object}
 */
export function whereIn(collection, key, values) {
  const comparator = Array.isArray(values) ? 'includes' : 'has';
  return collection.filter((item) => values[comparator](item[key]));
}

/**
 * @param {Object[]} collection
 * @param {String[]} ids
 *
 * @return {Object}
 */
export function whereInIds(collection, ids) {
  const comparator = Array.isArray(ids) ? 'includes' : 'has';
  return collection.filter((item) => ids[comparator](item.uuid));
}
