import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { color } from '@/lib/theme/tokens';

const ConfirmBar = styled('div')({
  background: color.core.white,
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 1,
});

export default memo(ConfirmBar);
