import { EntityType } from '@/shared/enums';
import updateEntityTagsAction from '../updateEntityTagsAction';
import addedRoleAction from './addedRoleAction';
import assignedRoleAction from './assignedRoleAction';
import removedRoleAction from './removedRoleAction';
import updatedBudgetAction from './updatedBudgetAction';
import updatedFTEAction from './updatedFTEAction';
import updatedManagerAction from './updatedManagerAction';

export default function addActionByRole({
  comparisonScenario,
  role,
  scenario,
  showBudget,
  roleMap,
}) {
  const scenarioRole = scenario.relationships
    .get(EntityType.ROLE)
    .get(role.uuid);
  const comparisonRole = comparisonScenario.relationships
    .get(EntityType.ROLE)
    ?.get(role.uuid);

  const isNewRole = scenarioRole && !comparisonRole;
  const isRemovedRole = comparisonRole && !scenarioRole;

  const actions = [];

  // Changed role assignee.
  if (scenarioRole && scenarioRole?.user_uuid !== comparisonRole?.user_uuid) {
    actions.push(
      assignedRoleAction({
        comparisonScenario,
        originalRole: comparisonRole,
        role: scenarioRole,
      })
    );
  }

  // If it's a new role.
  if (isNewRole) {
    actions.push(addedRoleAction({ role, comparisonScenario }));
    return actions;
  }

  // If the role has been removed.
  if (isRemovedRole) {
    actions.push(
      removedRoleAction({
        role: comparisonRole,
        comparisonScenario,
      })
    );

    return actions;
  }

  // Budget changes.
  if (showBudget && scenarioRole.budget !== comparisonRole.budget) {
    actions.push(
      updatedBudgetAction({
        role: scenarioRole,
        originalRole: comparisonRole,
        comparisonScenario,
      })
    );
  }

  // FTE changes.
  if (scenarioRole.fte !== comparisonRole.fte) {
    actions.push(
      updatedFTEAction({
        role: scenarioRole,
        originalRole: comparisonRole,
        comparisonScenario,
      })
    );
  }

  const tagChangeAction = updateEntityTagsAction({
    entity: scenarioRole,
    tagTypes: [EntityType.ACTIVITY, EntityType.ROLE],
    originalEntity: comparisonRole,
    comparisonScenario,
  });

  if (tagChangeAction) {
    actions.push(tagChangeAction);
  }

  // Moved between managers.
  if (
    scenarioRole &&
    comparisonRole &&
    (scenarioRole.__manager !== comparisonRole.__manager ||
      scenarioRole.group_uuid !== comparisonRole.group_uuid)
  ) {
    actions.push(
      updatedManagerAction({
        comparisonScenario,
        originalRole: comparisonRole,
        role: scenarioRole,
      })
    );
  }

  return actions;
}
