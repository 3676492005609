import {
  getThresholdBucket,
  getThresholdScore,
} from '@pkg/entities/snapshots/lenses/threshold';
import { Num } from '@pkg/utils';
import { BeamibleTag, Completeness, DesignEntity } from '@/lib/enums';

const METRICS = [
  {
    tag: BeamibleTag.STRATEGIC_IMPORTANCE,
    name: 'Strategic Importance',
  },
  {
    tag: BeamibleTag.ADMINISTRATIVE,
    name: 'Administrative',
  },
  {
    tag: BeamibleTag.INTERNAL_MEETINGS,
    name: 'Internal Meetings',
  },
];

/**
 * @param {Object} entity
 * @return {Object}
 */
export function entityProductivity(entity, breakdown) {
  const entityMetrics = entity.__metrics?.self?.total;
  const entityHours = entityMetrics?.hours ?? 0;
  const entityTagMetrics = entityMetrics?.tags?.[DesignEntity.ACTIVITY];

  let containsAnyTag = false;

  METRICS.forEach((metric) => {
    const { tag } = metric;
    let tagHours = 0;

    if (entityTagMetrics?.[tag]) {
      tagHours = entityTagMetrics[tag].hours;
    }

    if (Object.hasOwn(entityTagMetrics, tag)) {
      containsAnyTag = true;
    }

    const tagTotalPercentage =
      tagHours > 0 ? (tagHours / breakdown.totalActivityHours) * 100 : 0;

    const tagEntityPercentage =
      tagHours > 0 ? (tagHours / entityHours) * 100 : 0;

    const relativeEntityPercent = entity.__is_complete
      ? getThresholdScore({
          thresholdRangeId: tag,
          value: tagEntityPercentage,
        }) / breakdown.entityCount
      : 0;

    if (breakdown.tags[tag]) {
      breakdown.tags[tag].hoursPercentage += tagTotalPercentage;
      breakdown.tags[tag].value += relativeEntityPercent;
    } else {
      breakdown.tags[tag] = {
        name: metric.name,
        hoursPercentage: tagTotalPercentage,
        value: relativeEntityPercent,
      };
    }
  });

  if (!containsAnyTag && breakdown.__completeness === Completeness.FULL) {
    breakdown.__completeness = Completeness.PARTIAL;
  }

  return breakdown;
}

/**
 * @param {Array} entities
 * @return {Object}
 */
export default function productivity(entities) {
  let breakdown = {
    tags: {},
    score: 0,
    entityCount: 0,
    totalActivityHours: 0,
    __completeness: Completeness.FULL,
  };

  if (!entities?.length) {
    METRICS.forEach(({ tag, name }) => {
      breakdown.tags[tag] = {
        name,
        hoursPercentage: 0,
        value: 0,
      };
    });

    return breakdown;
  }

  entities.forEach(({ __total_metrics, __is_complete }) => {
    if (__is_complete) {
      breakdown.entityCount += 1;
    }
    breakdown.totalActivityHours += __total_metrics.hours;
  });

  entities.forEach((entity) => {
    breakdown = entityProductivity(entity, breakdown);
  });

  if (breakdown.entityCount < entities.length) {
    breakdown.__completeness = Completeness.PARTIAL;
  }

  if (breakdown.entityCount === 0) {
    breakdown.__completeness = Completeness.NONE;
  }

  let productivityScore = 0;

  Object.entries(breakdown.tags).forEach(([uuid]) => {
    productivityScore +=
      breakdown.tags[uuid].value > 0
        ? breakdown.tags[uuid].value / METRICS.length
        : 0;
  });

  breakdown.score = Num.roundFloat(productivityScore);
  breakdown.threshold = getThresholdBucket({ value: breakdown.score });

  return breakdown;
}
