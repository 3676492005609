import env from '@beam-australia/react-env';
import { clearAllSubscribers } from '@pkg/broadcasting/useObservable';
import { useDatabase } from '@pkg/database';
import Sdk from '@pkg/sdk';
import { useAccessToken } from './AccessTokenProvider';
import useLogoutEvent from './useLogoutEvent';
import useStore from './useStore';

export default function useLogout() {
  const database = useDatabase();
  const { me, set } = useStore();
  const { setAccessToken } = useAccessToken();
  const triggerLogout = useLogoutEvent(me?.uuid);

  const fronteggLogout = `${env('FRONTEGG_BASE_URL')}/oauth/account/logout`;

  const logout = async () => {
    triggerLogout();
    completeLogout();
  };

  const completeLogout = async () => {
    // clear guest tokens
    Sdk.clearTokens();
    // set isAuthenticated to false in Auth store
    set({ isAuthenticated: false });
    // clear Bearer token
    setAccessToken(null);
    // remove all listeners
    clearAllSubscribers();
    // clear IndexedDB
    await database?.reset();
    // redirect to logout page
    window.location = fronteggLogout;

    return null;
  };

  return { logout, completeLogout };
}
