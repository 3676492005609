import config from '@/lib/config';
import { DesignEntity } from '@/lib/enums';
import deriveEntityTag from './shared/deriveEntityTag';

/**
 * Calculates group tags for every entity it's directly associated with.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function deriveGroupTags({
  activity,
  role,
  group,
  metrics,
  tagMap,
}) {
  if (!group?.tags?.length) {
    return;
  }

  const isVisible = group.__visibility === config.VISIBILITY.FULL;

  group.tags.forEach((tagId) => {
    // Derive tag metrics for the group which are stored in the metrics object.
    if (metrics.has(group.uuid)) {
      deriveEntityTag({
        activity,
        entityId: group.uuid,
        role,
        group,
        metrics,
        tagId,
        tagType: DesignEntity.GROUP,
        tagMap,
      });
    }

    // Derive root tag metrics which are stored in the metrics object.
    if (metrics.has('*')) {
      deriveEntityTag({
        activity,
        entityId: '*',
        group,
        metrics,
        role,
        tagId,
        tagType: DesignEntity.GROUP,
        tagMap,
      });
    }
  });
}
