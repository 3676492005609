import {
  getThresholdBucket,
  getThresholdScore,
} from '@pkg/entities/snapshots/lenses/threshold';
import { Num } from '@pkg/utils';
import AppConfig from '@/lib/config';
import { BeamibleTag, Completeness, DesignEntity, Lens } from '@/lib/enums';

const METRICS = [
  {
    tag: BeamibleTag.ENERGISING,
    name: 'Energising',
  },
  {
    tag: Lens.WORKLOAD,
    name: 'Workload',
  },
  {
    tag: Lens.OVER_CAPACITY,
    name: 'Over Capacity',
  },
];

/**
 * @param {object} entity
 * @return {number}
 */
export function entityWorkHealth(entity, breakdown, totalEntities) {
  const capacityThreshold = AppConfig.INSIGHTS.CAPACITY_THRESHOLD;
  const entityMetrics = entity.__metrics?.self?.total;
  const entityHours = entityMetrics?.hours ?? 0;

  const containsAnyTag = entityHours > 0 && entity.__is_complete;

  METRICS.forEach((metric) => {
    const { tag } = metric;
    const entityTagMetrics = entityMetrics?.tags?.[DesignEntity.ACTIVITY];

    let tagTotalPercentage = 0;
    let tagEntityPercentage = 0;

    if (tag === BeamibleTag.ENERGISING) {
      const tagHours = entityTagMetrics?.[tag]?.hours ?? 0;

      tagTotalPercentage =
        tagHours > 0 ? (tagHours / breakdown.totalActivityHours) * 100 : 0;

      tagEntityPercentage = tagHours > 0 ? (tagHours / entityHours) * 100 : 0;
    }

    const workLoadHours = Num.fteToHours(entity.fte ?? entityMetrics?.fte ?? 0);

    if (tag === 'WORKLOAD') {
      tagEntityPercentage =
        entityHours > 0 ? (entityHours / workLoadHours) * 100 : 0;
      tagTotalPercentage =
        tagEntityPercentage > 0 ? tagEntityPercentage / totalEntities : 0;
    }

    if (tag === 'OVER_CAPACITY') {
      const overCapacity = entityHours / workLoadHours > capacityThreshold;
      if (entityHours > 0) {
        tagEntityPercentage = overCapacity ? 100 : 0;
        tagTotalPercentage =
          tagEntityPercentage > 0 ? tagEntityPercentage / totalEntities : 0;
      } else {
        tagEntityPercentage = 0;
        tagTotalPercentage = 0;
      }
    }

    const relativeEntityPercent = entity.__is_complete
      ? getThresholdScore({
          thresholdRangeId: tag,
          value: tagEntityPercentage,
        }) / breakdown.entityCount
      : 0;

    if (breakdown.tags[tag]) {
      breakdown.tags[tag].hoursPercentage += tagTotalPercentage;
      breakdown.tags[tag].value += relativeEntityPercent;
    } else {
      breakdown.tags[tag] = {
        name: metric.name,
        hoursPercentage: tagTotalPercentage,
        value: relativeEntityPercent,
      };
    }
  });

  if (!containsAnyTag && breakdown.__completeness === Completeness.FULL) {
    breakdown.__completeness = Completeness.PARTIAL;
  }

  return breakdown;
}

export default function workHealth(entities) {
  let breakdown = {
    tags: {},
    score: 0,
    entityCount: 0,
    totalActivityHours: 0,
    __completeness: Completeness.FULL,
  };

  if (!entities?.length) {
    METRICS.forEach(({ tag, name }) => {
      breakdown.tags[tag] = {
        name,
        hoursPercentage: 0,
        value: 0,
      };
    });

    return breakdown;
  }

  entities.forEach(({ __metrics, __is_complete }) => {
    if (__is_complete) {
      breakdown.entityCount += 1;
    }
    breakdown.totalActivityHours += __metrics.self?.total?.hours ?? 0;
  });

  entities.forEach((entity) => {
    breakdown = entityWorkHealth(entity, breakdown, entities.length);
  });

  if (breakdown.entityCount < entities.length) {
    breakdown.__completeness = Completeness.PARTIAL;
  }

  if (breakdown.entityCount === 0) {
    breakdown.__completeness = Completeness.NONE;
  }

  let workHealthScore = 0;

  Object.entries(breakdown.tags).forEach(([uuid]) => {
    workHealthScore +=
      breakdown.tags[uuid].value > 0
        ? breakdown.tags[uuid].value / METRICS.length
        : 0;
  });

  breakdown.score = Num.roundFloat(workHealthScore);
  breakdown.threshold = getThresholdBucket({ value: breakdown.score });

  return breakdown;
}
