import { tool } from 'ai';
import { z } from 'zod';

const roleMove = tool({
  description:
    'Move roles within the organizational design, including their hierarchy and group assignments',
  parameters: z.object({
    payload: z
      .object({
        ids: z.array(z.string()).describe('Array of role UUIDs to move'),
        groupId: z
          .union([z.string(), z.null()])
          .describe(
            'The UUID of the target group to move roles to. Can be a group UUID or null to remove from group'
          ),
        parentId: z
          .union([z.string(), z.null()])
          .describe(
            'The UUID of the new parent role. Can be a role UUID or null. If not provided, will use group lead'
          ),
      })
      .describe('The input parameters for moving roles'),
    explanation: z
      .string()
      .describe(
        'REQUIRED: Explain what roles are being moved and why. Must include: 1) Which roles are moving 2) Where they are moving to 3) Why they are being moved. Example: "Moving the Engineering Manager role under the CTO and into the Tech Leadership group to better align reporting structure"'
      ),
  }),
  execute: async ({ payload, explanation }) => ({
    payload,
    explanation,
  }),
});

export default roleMove;
