import { EntityType } from '@/shared/enums';
import defaultMetricGroup from '@pkg/entities/snapshots/utils/defaultMetricGroup';
import tagMetricChange from './tagMetricChange';

const TAG_TYPES = [EntityType.ACTIVITY, EntityType.ROLE, EntityType.GROUP];

export default function summaryTagMetricList({
  comparisonMetrics,
  scenarioMetrics,
  tagMap,
}) {
  if (!comparisonMetrics?.tags || !scenarioMetrics?.tags) {
    return [];
  }

  const tagMetricList = [];

  TAG_TYPES.forEach((tagType) => {
    const comparisonTags = comparisonMetrics?.tags?.[tagType];
    const scenarioTags = scenarioMetrics?.tags?.[tagType];

    const scenarioTagList = scenarioTags ? Object.keys(scenarioTags) : [];
    const comparisonTagList = comparisonTags ? Object.keys(comparisonTags) : [];

    const tagList = new Set([...scenarioTagList, ...comparisonTagList]);

    [...tagList].forEach((tagId) => {
      const tag = tagMap.get(tagId);
      const metrics = scenarioTags?.[tagId];
      const comparisonMetrics = comparisonTags?.[tagId];

      const tagMetric = tagMetricChange({
        comparisonMetrics: {
          ...defaultMetricGroup(false),
          ...comparisonMetrics,
        },
        scenarioMetrics: {
          ...defaultMetricGroup(false),
          ...metrics,
        },
        tag,
        tagType,
      });

      if (tagMetric) {
        tagMetricList.push(tagMetric);
      }
    });
  });

  return tagMetricList;
}
