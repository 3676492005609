import { memo } from 'react';
import Close from '@mui/icons-material/Close';
import Home from '@mui/icons-material/HomeOutlined';
import Back from '@mui/icons-material/NorthWestOutlined';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Colors } from '@pkg/utils';
import excludeProps from '@/lib/theme/excludeProps';
import HeaderButton from './HeaderButton';
import Timestamp from './Timestamp';

const GridContainer = styled(Grid, {
  shouldForwardProp: excludeProps('backgroundColor'),
})(({ backgroundColor }) => ({
  backgroundColor,
  borderRadius: '4px',
  color: '#FFF',
  margin: '8px 8px 0',
  padding: '0 8px',
  width: 'auto',
}));

const Heading = styled('h2')({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: 2.5,
  margin: 0,
  textAlign: 'center',
});

const Header = ({
  created,
  updated,
  color,
  title,
  level,
  onHome,
  onReturn,
  onClose,
}) => {
  const palette = color ?? Colors.entity(level);

  return (
    <div>
      <GridContainer
        alignItems="center"
        backgroundColor={palette.main}
        columns={16}
        container
        direction="row"
      >
        <Grid item xs={2} sx={{ textAlign: 'left' }}>
          {Boolean(onReturn) && (
            <HeaderButton
              aria-label="return"
              color={palette}
              onClick={onReturn}
            >
              <Back />
            </HeaderButton>
          )}
          {Boolean(onClose) && (
            <HeaderButton aria-label="close" color={palette} onClick={onClose}>
              <Close />
            </HeaderButton>
          )}
        </Grid>
        <Grid item xs={12}>
          <Heading>{title}</Heading>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: 'right' }}>
          {Boolean(onHome) && (
            <HeaderButton aria-label="return" color={palette} onClick={onHome}>
              <Home />
            </HeaderButton>
          )}
        </Grid>
      </GridContainer>
      <Timestamp created={created} updated={updated} sx={{ ml: 2, mt: 1 }} />
    </div>
  );
};

export default memo(Header);
