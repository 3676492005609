import { ActionType, EntityType } from '@/shared/enums';

export default function skipRoleUpdate({
  action,
  role,
  activity,
  roleMap,
  showBudget,
}) {
  if (!role) {
    return true;
  }
  const existingAction = roleMap
    .get(role?.title)
    ?.[EntityType.ROLE]?.get(role.uuid)
    ?.actions?.get(action.type);

  if (
    action.type === ActionType.UPDATED_FTE &&
    existingAction?.type === ActionType.UPDATED_FTE
  ) {
    return true;
  }
  if (
    action.type === ActionType.UPDATED_BUDGET &&
    (existingAction?.type === ActionType.UPDATED_BUDGET || !showBudget)
  ) {
    return true;
  }
  if (
    action.type === ActionType.UPDATED_MANAGER &&
    existingAction?.type === ActionType.UPDATED_MANAGER
  ) {
    return true;
  }

  if (existingAction?.type === ActionType.REMOVED_ROLE) {
    if (activity) {
      const existing = roleMap
        .get(role.title)
        ?.[EntityType.ROLE].get(role.uuid);

      existing[EntityType.ACTIVITY]?.add(activity.uuid);
    }
    return true;
  }

  if (existingAction?.type === ActionType.ADDED_ROLE) {
    if (activity) {
      const existing = roleMap
        .get(role.title)
        ?.[EntityType.ROLE].get(role.uuid);

      existing[EntityType.ACTIVITY]?.add(activity.uuid);
    }
    return true;
  }

  return false;
}
