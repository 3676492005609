import { AddFieldButton } from '@/molecules/formElements';
import { ActivityHeading } from '@/molecules/headings';
import {
  ActivityConfig,
  calculateAddButtonPadding,
  useActivityList,
} from '@/organisms/activities';
import { useDefaultActivity } from '@/shared/hooks';
import { DispatchProvider } from '@/shared/providers';
import Box from '@mui/material/Box';
import { Property } from '@/lib/enums';
import ActivityTagCheckbox from './ActivityTagCheckbox';

const CheckboxActivityList = ({
  allowedTagSet,
  coreActivitySet,
  excludesTags,
  id,
  includesTags,
  tagProperties,
  settings,
  TitleComponent = <ActivityHeading />,
}) => {
  const { canAddNew, isEditable, showDragDrop, showTotalActivityHours } = {
    ...ActivityConfig,
    ...settings,
  };

  const { activityCount, activityList, entity, metrics } = useActivityList({
    coreActivitySet,
    config: settings,
    excludesTags,
    includesTags,
  });

  const defaultActivity = useDefaultActivity();

  const canAddActivity = isEditable && canAddNew;

  const buttonPadding = calculateAddButtonPadding({
    ...ActivityConfig,
    ...settings,
  });

  return (
    activityList.length > 0 && (
      <>
        <Box position="relative" mt={1}>
          {TitleComponent}
          {activityList?.map((activity, index) => (
            <Box
              key={`${activity.uuid}:${id}`}
              sx={{
                mb: 1,
                position: 'relative',
                zIndex: activityList.length - index,
              }}
            >
              <DispatchProvider
                uuid={activity.uuid}
                action="activities.update"
                isEditable={true}
                name={Property.TAGS}
              >
                <ActivityTagCheckbox
                  key={`${activity.uuid}:${id}`}
                  allowedTagSet={allowedTagSet}
                  activity={activity}
                  coreActivitySet={coreActivitySet}
                  metrics={metrics}
                  name={Property.TAGS}
                  tag={{ ...tagProperties, id }}
                  settings={settings}
                />
              </DispatchProvider>
            </Box>
          ))}
        </Box>
        {defaultActivity && entity && canAddActivity && (
          <Box
            sx={{
              ml: 5,
              pt: 1,
              pr: `${buttonPadding}px`,
            }}
          >
            <DispatchProvider
              action="activities.add"
              uuid={entity?.uuid}
              isEditable={isEditable}
            >
              <AddFieldButton
                label="Add activity"
                entity={entity}
                fields={[
                  {
                    library_uuid: defaultActivity.uuid,
                    owner_uuid: entity.uuid,
                    owner_type: entity.__type,
                    order: activityCount + 1,
                  },
                ]}
              />
            </DispatchProvider>
          </Box>
        )}
      </>
    )
  );
};

export default CheckboxActivityList;
