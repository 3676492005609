import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';

export default function removedRoleAction({ role, comparisonScenario }) {
  const metrics = role.__metrics.self.visible;

  const roleProperties = {};

  // Add the parent manager.
  if (role.__manager) {
    roleProperties.moved = {
      manager: {
        from: role.__manager,
      },
    };
  }

  // Add the parent group.
  if (role.group_uuid) {
    roleProperties.moved = {
      ...roleProperties.moved,
      group: {
        from: role.group_uuid,
      },
    };
  }

  const action = {
    type: ActionType.REMOVED_ROLE,
    [EntityType.ROLE]: new Map([
      [
        role.uuid,
        {
          metrics,
          props: { ...roleProperties },
        },
      ],
    ]),
    metrics: defaultMetrics({ comparisonScenario, role }),
  };

  action.metrics[1].budget -= metrics.budget;
  action.metrics[1].fte -= metrics.fte;

  return action;
}
