import { DimensionType } from '@/molecules/tableElements';
import { compare } from '@pkg/entities/library/skills';
import config from '@/lib/config';
import { DesignEntity, PropertyType, Visibility } from '@/lib/enums';

/**
 * Formats the row's design data.
 *
 * @param {Object} design
 *
 * @return {Object}
 */
export const formatDesignData = (design, hours) => {
  if (!design) {
    return;
  }

  return {
    design: {
      id: design.uuid,
      name: design.latest?.name,
      scope: design.scope,
      hours,
      type: design.is_scenario ? DesignEntity.SCENARIO.toLowerCase() : 'live',
      created_at: new Date(design.created_at),
      updated_at: new Date(design.updated_at),
    },
  };
};

/**
 * Formats the row's group data.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export const formatGroupData = ({ group, groupLead, propertyMap, tagMap }) => {
  if (!group) {
    return;
  }

  const properties = getProperties(group.properties, propertyMap);
  const tags = getTags(group.tags, tagMap);

  const { hours } = group.__visible_metrics;

  return {
    team: {
      id: group.uuid ?? '_',
      created_at: new Date(group.created_at),
      hours,
      lead: groupLead ? `${groupLead.__name}` : 'Unassigned',
      name: group.name,
      tags,
      properties,
      updated_at: new Date(group.updated_at),
    },
  };
};

/**
 * Formats the row's role data.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export const formatRoleData = ({
  showBudget,
  person,
  role,
  propertyMap,
  skillMap,
  tagMap,
}) => {
  if (!role) {
    return;
  }

  const tags = getTags(role.tags, tagMap);
  const properties = getProperties(role.properties, propertyMap);
  const skills = role.skills
    .filter((skill) => typeof skill === 'object')
    .map(({ uuid, level }) => {
      const skill = skillMap?.get(uuid);

      return {
        id: uuid,
        name: skill.name,
        level: level,
      };
    });

  const { fte } = role.__visible_metrics;

  const roleData = {
    id: role.uuid ?? '_',
    activityHours: role.__total_metrics?.hours,
    assignee: person ? `${person.__name}` : 'Unassigned',
    created_at: new Date(role.created_at),
    childRoles: role.__children.size,
    managedRoles: role.__metrics?.managed?.total?.roles ?? 0,
    managedFte: role.__metrics?.managed?.total?.fte ?? 0,
    depth: role.__depth,
    fte: fte ?? 0,
    hours: fte * config.FTE_HOURS_PER_WEEK,
    isManager: role.is_manager ? 'Manager' : 'Role',
    layers: role.__layers,
    layer: role.__layer,
    properties,
    skills,
    span: role.__metrics?.span?.total?.roles ?? 0,
    tags,
    title: role.title,
    updated_at: new Date(role.updated_at),
  };

  if (showBudget) {
    roleData.budget = role.budget;
  }

  return {
    role: roleData,
  };
};

/**
 * Formats the row's person data.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export const formatPersonData = ({ person, personSkills, skillMap }) => {
  if (!person) {
    return;
  }

  const personData = {
    id: person.uuid,
    name: person.__name,
  };

  if (personSkills) {
    const skills = [...personSkills].map(([uuid, level]) => {
      const skill = skillMap?.get(uuid);

      return {
        id: uuid,
        name: skill.name,
        level: level,
      };
    });

    personData.skills = skills;
  }

  return {
    person: personData,
  };
};

/**
 * Formats the row's skill data.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export const formatSkillData = ({
  dimension,
  personSkills,
  roleSkills,
  skill,
}) => {
  const skillData = {
    id: skill?.uuid ?? null,
    name: skill?.name ?? null,
  };

  if (dimension === DimensionType.PEOPLE_SKILLS) {
    const roleSkill = roleSkills?.get(skill?.uuid);
    const personSkill = personSkills?.get(skill?.uuid);

    // If the person has a higher skill level return a partial utilisation.
    skillData.utilisation = 'Not utilised';
    skillData.level = personSkill;

    if (!roleSkill) {
      return { skill: skillData };
    }

    // If the person has a higher skill level return a partial utilisation.
    const isHigher = compare.isHigher(personSkill, roleSkill);
    if (isHigher) {
      skillData.utilisation = 'Partial';
    }

    if (!isHigher && compare.meets(personSkill, roleSkill)) {
      skillData.utilisation = 'Utilised';
    }
  }

  if (dimension === DimensionType.ROLE_SKILLS) {
    const roleSkill = roleSkills?.get(skill?.uuid);
    const personSkill = personSkills?.get(skill?.uuid);
    skillData.level = roleSkill;

    skillData.gap = 'Gap';

    if (!personSkill) {
      return { skill: skillData };
    }

    // If the person has a higher skill level return a partial utilisation.
    const isHigher = compare.isHigher(roleSkill, personSkill);
    if (isHigher) {
      skillData.gap = 'Partial';
    }

    if (!isHigher && compare.meets(roleSkill, personSkill)) {
      skillData.gap = 'No gap';
    }
  }

  return {
    skill: skillData,
  };
};

/**
 * Formats the row's activity data.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export const formatActivityData = ({ activity, propertyMap, role, tagMap }) => {
  if (!activity || activity?.__visibility === Visibility.NONE) {
    return;
  }

  const fte =
    role?.fte && activity.hours > 0
      ? (activity.hours / role.__total_metrics?.hours) * role.fte
      : 0;

  const tags = getTags(activity.tags, tagMap);
  const properties = getProperties(activity.properties, propertyMap);

  return {
    activity: {
      id: activity.uuid ?? '_',
      library_uuid: activity.library_uuid,
      hours: activity.hours,
      fte,
      description: activity.__description,
      properties,
      tags,
      created_at: new Date(activity.created_at),
      updated_at: new Date(activity.updated_at),
    },
  };
};

/**
 * Returns a property object based on the provided list of values.
 *
 * @param {Array} propertyList
 * @param {Map} propertyMap
 *
 * @return {Object}
 */
const getProperties = (propertyList, propertyMap) => {
  const properties = {};

  propertyList.forEach(({ key, value }) => {
    const property = propertyMap?.get(key);

    if (!property) {
      return;
    }

    const propertyValue =
      property.type === PropertyType.TEXT
        ? value
        : property.__options?.get(value)?.name;

    properties[key] = propertyValue;
  });

  return properties;
};

const getTags = (tagList, tagMap) => {
  return tagList
    ?.filter((id) => tagMap.has(id))
    .map((id) => {
      const { uuid, name, color } = tagMap.get(id);

      return { id: uuid, name, color };
    });
};

/**
 * Returns a relationship of entities based on a provided activity or role.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export const getEntities = ({ activity, role, group, scenarioMap }) => {
  if (!scenarioMap) {
    return;
  }

  const activityOwner = activity?.owner_type;

  const roleId = role?.uuid ?? activity?.owner_uuid;

  const roleData = role ?? scenarioMap.get(DesignEntity.ROLE).get(roleId);
  const person = scenarioMap
    ?.get(DesignEntity.PERSON)
    ?.get(roleData?.user_uuid);

  const groupId =
    activityOwner === DesignEntity.GROUP
      ? activity?.owner_uuid
      : (roleData?.group_uuid ?? group?.uuid);

  const groupData = scenarioMap.get(DesignEntity.GROUP).get(groupId);
  const groupLead = scenarioMap
    ?.get(DesignEntity.PERSON)
    ?.get(groupData?.lead_uuid);

  const design = scenarioMap?.get(DesignEntity.ORGANISATION);

  return {
    design,
    group: groupData,
    person,
    role: roleData,
    groupLead,
  };
};
