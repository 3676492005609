import { Utils } from '@/atoms/chips';
import { EntityName, EntityType } from '@/shared/enums';

const sortOrder = [
  EntityType.GROUP,
  EntityType.ROLE,
  EntityType.ACTIVITY,
  EntityType.TAG,
];

function defaultAction(action) {
  const chipColor = Utils.entityColor(action.entity);

  return {
    entityType: action.entity,
    label: `${EntityName[action.entity]} ACTIONS`,
    colorProps: chipColor,
    actions: [action],
  };
}

/**
 * Groups the actions by entity type.
 *
 * @param {Array} actions
 *
 * @return {Object}
 */
export default function groupActions(actions) {
  const actionGroups = {};

  const sortedActions = [...actions].sort((a, b) => {
    return sortOrder.indexOf(a.entity) - sortOrder.indexOf(b.entity);
  });

  sortedActions.forEach((action) => {
    if (action.actionMap) {
      [...action.actionMap.values()].forEach((groupActionMap) => {
        [...groupActionMap.values()].forEach((actionItem) => {
          if (actionGroups[actionItem.entity]) {
            actionGroups[actionItem.entity].actions.push(actionItem);
          } else {
            actionGroups[actionItem.entity] = defaultAction(actionItem);
          }
        });
      });
      return;
    }

    if (actionGroups[action.entity]) {
      actionGroups[action.entity].actions.push(action);
    } else {
      actionGroups[action.entity] = defaultAction(action);
    }
  });

  return actionGroups;
}
