import { Properties } from '@pkg/entities/library';

/**
 * @param {Object} property
 * @param {Object} data
 * @return {Object}
 */
export default function createInput(property, data) {
  const input = { uuid: property?.uuid, ...data };
  if (data.options) {
    input.options = Properties.options.mutate(property, data.options);
  }

  return input;
}
