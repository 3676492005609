import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';
import createInput from './createInput';

const UPDATE_PROPERTY = /* GraphQL */ `
  mutation UpdateLibraryProperty($input: UpdateLibraryPropertyInput!) {
    updateLibraryProperty(input: $input) {
      uuid
      name
    }
  }
`;

/**
 * @return {Function}
 */
export default function useUpdate() {
  const database = useDatabase();
  const { mutateAsync } = useMutation({
    mutation: UPDATE_PROPERTY,
  });

  /**
   * @param {Object} property
   * @param {Object} input
   * @param {String} input.name
   * @param {Array} input.options
   * @returns {Promise}
   */
  function updateProperty(property, { name, options }) {
    const payload = {};
    if (name) {
      payload.name = name;
    }

    if (options) {
      payload.options = options;
    }

    database.properties.update(property.uuid, payload); // @todo rollback
    const input = createInput(property, payload);
    return mutateAsync(input);
  }

  return useCallback(updateProperty, [mutateAsync]);
}
