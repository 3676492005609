import {
  cumulateMetrics,
  cumulateTagMetrics,
  defaultMetrics,
  updateEntityAction,
} from '@/organisms/plans/utils';
import { ActionType, EntityType } from '@/shared/enums';

export default function updateRole({
  action,
  activity,
  role,
  roleType,
  comparisonScenario,
}) {
  const updateRole = roleType[EntityType.ROLE].get(role.uuid) ?? {
    [EntityType.ACTIVITY]: new Set(),
    actions: new Map(),
    metrics: defaultMetrics({ comparisonScenario, role }),
  };

  updateRole.actions.set(
    action.type,
    updateEntityAction({
      action,
      actions: updateRole.actions,
      role,
      comparisonScenario,
    })
  );

  if (activity) {
    updateRole[EntityType.ACTIVITY].add(activity.uuid);
  }

  if (action.type === ActionType.UPDATED_TAGS) {
    const tags = cumulateTagMetrics({
      entityTags: action.metrics[1].tags,
      originalEntityTags: action.metrics[0].tags,
      existingMetrics: updateRole.metrics,
    });
    updateRole.metrics[0].tags = tags[0];
    updateRole.metrics[1].tags = tags[1];
  } else {
    updateRole.metrics[1] = {
      ...cumulateMetrics(action, updateRole.metrics[1]),
      tags: updateRole.metrics[1].tags,
    };
  }

  return updateRole;
}
