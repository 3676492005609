import { tool } from 'ai';
import { z } from 'zod';

const roleTagging = tool({
  description: 'Add or remove tags from roles in the organizational design',
  parameters: z.object({
    payload: z
      .object({
        ids: z
          .array(z.string())
          .describe('Array of 9-digit role UUIDs to modify tags for'),
        change: z
          .object({
            add: z
              .array(z.string())
              .describe('Array of 9-digit tag UUIDs to add to the roles'),
            remove: z
              .array(z.string())
              .describe('Array of 9-digit tag UUIDs to remove from the roles'),
          })
          .describe('Changes to make to the role tags'),
      })
      .describe('The payload for tagging roles'),
    explanation: z
      .string()
      .describe(
        'An explanation of why these tag changes are being made and how they benefit the organization'
      ),
  }),
  execute: async ({ payload, explanation }) => ({
    payload,
    explanation,
  }),
});

export default roleTagging;
