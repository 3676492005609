import clsx from 'clsx';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useSearch } from '@pkg/entities/people';
import { useDebounce } from '@pkg/hooks';
import Close from '@pkg/icons/Close';
import Plus from '@pkg/icons/Plus';
import excludeProps from '@/lib/theme/excludeProps';
import { color } from '@/lib/theme/tokens';
import Button from '@/components/Button';
import StaticAvatar from '@/components/UserAvatar/Static';

const useStyles = makeStyles({
  root: {
    width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto'),
  },
  person: {
    cursor: 'pointer',
    display: 'flex',
    padding: 6,
    '&:hover': {
      backgroundColor: color.primary.grey[100],
    },
  },
  avatar: {
    height: 24,
    marginLeft: 2,
    width: 24,
  },
  name: {
    color: color.primary.brand[800],
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    margin: 0,
    marginLeft: 8,
  },
  nocta: {
    color: color.primary.grey[400],
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    margin: 0,
    marginLeft: 8,
  },
  clear: {
    position: 'absolute',
    right: 0,
    top: -2,
  },
});

const Selected = styled('div', {
  shouldForwardProp: excludeProps('readOnly', 'disableAction'),
})(({ readOnly, disableAction }) => ({
  backgroundColor: disableAction ? undefined : color.primary.grey[100],
  border: `2px solid ${color.primary.brand[200]}`,
  borderRadius: 4,
  cursor: readOnly ? 'default' : 'pointer',
  display: 'flex',
  padding: 4,
  position: 'relative',
  '&:hover': {
    borderColor: color.system.interactive[300],
  },
}));

const PersonInput = ({
  className,
  cta = 'Assign a person',
  disableAction = false,
  disabled = false,
  exclude = [],
  filters = {},
  fullWidth = false,
  label,
  name,
  onChange,
  onClickAvatar,
  placeholder = 'Assign a person',
  readOnly = false,
  value,
}) => {
  const classes = useStyles({ fullWidth });
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [search, setSearch] = useState('');
  const debounceSearch = useDebounce(setSearch, 200);

  const { results, isLoading } = useSearch(search, {
    exclude,
    filters,
    enabled: editing,
  });

  const handleBlur = () => {
    setEditing(false);
    setInputValue('');
  };

  const handleChange = (event, value, reason) => {
    if (reason === 'selectOption') {
      setEditing(false);
      onChange?.(event, value);
    }
  };

  const handleClear = (event) => {
    event.stopPropagation();
    handleChange(event, null, 'selectOption');
  };

  const handleFocus = () => {
    setEditing(true);
  };

  const handleSearch = (_, value) => {
    setInputValue(value);
  };

  useEffect(() => {
    if (inputValue !== search) {
      debounceSearch(inputValue);
    }
  }, [inputValue]);

  return (
    <FormControl className={clsx(classes.root, className)}>
      {!editing &&
        (Boolean(value) || disableAction ? (
          <>
            {label && <FormLabel>{label}</FormLabel>}
            <Selected
              disableAction={disableAction}
              readOnly={readOnly}
              onClick={disabled || readOnly ? null : () => setEditing(true)}
            >
              {value ? (
                <>
                  <StaticAvatar
                    className={classes.avatar}
                    onClick={onClickAvatar}
                    user={value}
                  />
                  <p className={classes.name}>
                    {value.first_name} {value.last_name}
                  </p>
                  {!readOnly && (
                    <IconButton
                      className={classes.clear}
                      onClick={handleClear}
                      size="medium"
                      variant="tertiary"
                      disabled={disabled}
                    >
                      <Close />
                    </IconButton>
                  )}
                </>
              ) : (
                <p className={classes.nocta}>{placeholder}</p>
              )}
            </Selected>
          </>
        ) : (
          !readOnly && (
            <div>
              <Button
                disabled={disabled}
                onClick={handleFocus}
                startIcon={<Plus />}
                size="small"
                variant="secondary"
              >
                {cta}
              </Button>
            </div>
          )
        ))}
      {editing && (
        <Autocomplete
          autoComplete
          autoHighlight
          clearOnBlur
          clearOnEscape
          disableClearable
          disabled={disabled}
          filterSelectedOptions
          filterOptions={(option) => option}
          freeSolo
          fullWidth
          getOptionLabel={({ first_name, last_name }) =>
            first_name ? first_name + ' ' + last_name : ''
          }
          handleHomeEndKeys
          inputValue={inputValue}
          isOptionEqualToValue={(a, b) => a.uuid === b.uuid}
          loading={isLoading}
          loadingText="Searching&hellip;"
          noOptionsText="No results"
          onChange={handleChange}
          onInputChange={handleSearch}
          open={editing}
          options={results}
          readOnly={false}
          renderOption={(props, person) => (
            <li {...props} key={person.uuid} className={classes.person}>
              <StaticAvatar className={classes.avatar} user={person} />
              <p className={classes.name}>
                {person.first_name} {person.last_name}
              </p>
            </li>
          )}
          renderInput={({ InputLabelProps, InputProps, ...params }) => {
            delete InputProps.className;
            delete params.inputProps.className;

            return (
              <>
                <FormLabel {...InputLabelProps}>{label}</FormLabel>
                <OutlinedInput
                  {...InputProps}
                  {...params}
                  autoFocus
                  name={name}
                  onBlur={handleBlur}
                  value={inputValue}
                  placeholder="Search people"
                />
              </>
            );
          }}
          value={value}
        />
      )}
    </FormControl>
  );
};

PersonInput.propTypes = {
  className: PropTypes.string,
  disableAction: PropTypes.bool,
  disabled: PropTypes.bool,
  exclude: PropTypes.array,
  filters: PropTypes.object,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClickAvatar: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
};

export default memo(PersonInput);
