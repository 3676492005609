import { DesignEntity } from '@/lib/enums';
import derived from './derived';
import perform from './perform';
import { getThresholdBucket } from './threshold';

export const calculate = (tag) => {
  return (entities, property = 'hours') => {
    const [entity] = entities;
    let tagValue = 0;
    const entityMetrics = entity.__metrics?.self?.total;

    const propertyValue = entityMetrics?.[property];
    const entityTagMetrics = entityMetrics?.tags?.[DesignEntity.ACTIVITY];

    if (entityTagMetrics && entityTagMetrics[tag.uuid]) {
      tagValue = entityTagMetrics[tag.uuid][property];
    }

    let value = 0;

    if (tagValue !== 0 && propertyValue !== 0) {
      value = Math.round((tagValue / propertyValue) * 100);
    }

    const threshold = getThresholdBucket({
      value,
    });

    return {
      score: value,
      threshold,
      palette: tag.color,
    };
  };
};

/**
 * @param {Object} params.snapshot
 * @param {Object} params.lens
 * @param {Map} params.tags
 * @return {number}
 */
export default function tag({ snapshot, lens, tags }) {
  const tag = tags.map.get(lens.uuid);
  const derivedFn = derived(calculate(tag), lens.type);
  return perform(derivedFn)({ snapshot, lens, tags });
}
