import { LicenseManager } from 'ag-grid-enterprise';
import { enableMapSet } from 'immer';
import Head from 'next/head';
import TagManager from 'react-gtm-module';
import { useEffectOnce } from 'react-use';
import AppLayout from '@/layouts/App/AppLayout';
import env from '@beam-australia/react-env';
import { LicenseInfo } from '@mui/x-license';
import Analytics from '@pkg/analytics/Analytics';
import config from '@/lib/config';
import Track from '@/lib/trackjs';
import AppProviders from '@/components/AppProviders';

const MUI_LICENSE_KEY =
  'b1578e2b153a06d5fb14ae3bf97e3e55Tz05NzQ5MCxFPTE3NTcwMzgzNDUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const AG_GRID_LICENSE_KEY =
  'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-076567}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Beamible}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Beamible}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Beamible}_need_to_be_licensed___{Beamible}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{5_April_2026}____[v3]_[0102]_MTc3NTM0MzYwMDAwMA==dfb73fb7d35bf4e11374ffac25d29c83';
LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

enableMapSet();

const App = ({ Component, pageProps }) => {
  useEffectOnce(() => {
    if (env('GOOGLE_TAG_MANAGER_AUTH')) {
      window.Analytics = Analytics;
      TagManager.initialize({
        gtmId: 'GTM-TVJ95H3',
        auth: env('GOOGLE_TAG_MANAGER_AUTH'),
        preview: env('GOOGLE_TAG_MANAGER_PREVIEW'),
      });
    }
  });

  return (
    <>
      <Head>
        <title>Beamible</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="icon" href={`${config.CDN}/statics/favicon.ico`} />
      </Head>
      <AppProviders>
        <AppLayout>
          <Component {...pageProps} />
        </AppLayout>
      </AppProviders>
    </>
  );
};

App.componentDidCatch = (error) => {
  Track.track(error);
  this.setState({ error });
};

export default App;
