import { forwardRef } from 'react';
import { Colors } from '@/atoms/buttons';
import { ButtonSize } from '@/atoms/buttons/utils';
import { Size } from '@/atoms/enums';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { Analytics } from '@pkg/analytics';
import excludeProps from '@/lib/theme/excludeProps';

const StyledButton = styled(LoadingButton, {
  shouldForwardProp: excludeProps('textProps', 'color'),
})(({ textProps, color }) => ({
  ...textProps,
  ...color.normal,
  '&: hover': {
    ...color.hover,
  },
  '&: focus-visible': {
    ...color.focus,
  },
  '&: focus': {
    ...color.normal,
  },
  '& .MuiButton-startIcon': {
    marginRight: '4px',
  },
  '& .MuiButton-endIcon': {
    marginLeft: '4px',
  },
  '&.Mui-disabled': {
    opacity: 0.75,
    backgroundColor: color.normal.backgroundColor,
  },
  '& .MuiLoadingButton-loadingIndicator': {
    color: color.normal.color,
  },
}));

const Button = forwardRef(function Button(
  {
    boldText,
    color = 'primary',
    colorProps,
    dataTestid,
    disabled,
    endIcon,
    fullWidth,
    label,
    loading,
    loadingIndicator,
    onClick,
    size = Size.MEDIUM,
    startIcon,
    track,
    variant = 'contained',
    onMouseDown,
    onMouseUp,
  },
  ref
) {
  const textProps = { ...ButtonSize[size] };
  const colors = colorProps ?? Colors.button[color][variant];

  if (boldText) {
    textProps.fontWeight = 500;
  }

  const handleClick = (event) => {
    if (track) {
      const { name, properties } = track;
      Analytics.track(name, properties);
    }

    onClick?.(event);
  };

  return (
    <StyledButton
      color={colors}
      data-testid={dataTestid}
      disableElevation
      disabled={disabled}
      endIcon={endIcon}
      loading={loading}
      loadingIndicator={loadingIndicator}
      fullWidth={fullWidth}
      onClick={handleClick}
      ref={ref}
      startIcon={startIcon}
      textProps={textProps}
      variant={variant}
    >
      {label}
    </StyledButton>
  );
});

export default Button;
