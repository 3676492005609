import { EntityName, EntityType } from '@/shared/enums';

function getEntityByType({ scenario, entityType, id }) {
  const type = entityType === entityType.manager ? EntityType.ROLE : entityType;
  return scenario?.relationships?.get(type)?.get(id);
}

export default function getMovementEntities({
  comparisonScenario,
  entityType,
  movementType,
  properties,
  scenario,
}) {
  if (!properties) {
    return;
  }

  let entityName = EntityName[entityType];

  if (entityType === EntityType.MANAGER) {
    entityName = `${entityName} role`;
  }

  const movement = {};

  switch (movementType) {
    case 'assigned':
      const assigned = properties.assigned;
      if (!assigned) {
        return;
      }

      movement.title = `${entityName} assigned`;

      if (Object.hasOwn(assigned, 'to')) {
        movement.to = {
          entityType: EntityType.PERSON,
          entity: assigned.to
            ? getEntityByType({
                scenario,
                entityType: EntityType.PERSON,
                id: assigned.to,
              })
            : null,
        };
      }
      if (Object.hasOwn(assigned, 'from')) {
        movement.from = {
          entityType: EntityType.PERSON,
          entity: assigned.from
            ? getEntityByType({
                scenario: comparisonScenario,
                entityType: EntityType.PERSON,
                id: assigned.from,
              })
            : null,
        };
      }
      return movement;
    case 'moved':
      const moved = properties.moved;

      if (!moved) {
        return;
      }

      if (moved.parent?.to) {
        movement.to = {
          entityType: moved.parent.to.type,
          entity: getEntityByType({
            scenario,
            entityType: moved.parent.to.type,
            id: moved.parent.to.uuid,
          }),
        };
      }
      if (moved.parent?.from) {
        movement.from = {
          entityType: moved.parent.from.type,
          entity: getEntityByType({
            scenario,
            entityType: moved.parent.from.type,
            id: moved.parent.from.uuid,
          }),
        };
      }

      if (moved?.manager?.to) {
        movement.to = {
          entityType: EntityType.ROLE,
          entity: getEntityByType({
            scenario,
            entityType: EntityType.ROLE,
            id: moved.manager.to,
          }),
        };
      }
      if (!moved.manager?.to && moved?.group?.to) {
        movement.to = {
          entityType: EntityType.GROUP,
          entity: getEntityByType({
            scenario,
            entityType: EntityType.GROUP,
            id: moved.group.to,
          }),
        };
      }

      if (moved.manager?.from) {
        movement.from = {
          entityType: EntityType.ROLE,
          entity: getEntityByType({
            scenario: comparisonScenario,
            entityType: EntityType.ROLE,
            id: moved.manager.from,
          }),
        };
      }
      if (!moved.manager?.from && moved?.group?.from) {
        movement.from = {
          entityType: EntityType.GROUP,
          entity: getEntityByType({
            scenario: comparisonScenario,
            entityType: EntityType.GROUP,
            id: moved.group.from,
          }),
        };
      }

      if (movement.to && movement.from) {
        movement.title = `${entityName} moved`;
      }
      if (movement.to && !movement.from) {
        movement.title = `${entityName} added`;
      }
      if (!movement.to && movement.from) {
        movement.title = `${entityName} removed`;
      }
      return movement;
    default:
      return;
  }
}
