import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

const MAX_RECENT_MESSAGES = 20;

const MessagesContext = createContext(null);

export const MessagesProvider = ({ children }) => {
  const [messages, setMessages] = useState(() => {
    const storedMessages = localStorage.getItem('messages');
    const parsedMessages = storedMessages ? JSON.parse(storedMessages) : [];
    // Return only the most recent messages
    return parsedMessages.slice(-MAX_RECENT_MESSAGES);
  });

  const [status, setStatus] = useState(null);

  useEffect(() => {
    localStorage.setItem('messages', JSON.stringify(messages));
  }, [messages]);

  const getMessages = useCallback(() => {
    const storedMessages = localStorage.getItem('messages');
    const parsedMessages = storedMessages ? JSON.parse(storedMessages) : [];
    // Return only the most recent messages
    return parsedMessages.slice(-MAX_RECENT_MESSAGES);
  }, []);

  const addMessage = useCallback(
    ({ text, sender = 'User', action, parameters, type }) => {
      if (type === 'clear') {
        setMessages([]);
        localStorage.removeItem('messages');
        return;
      }

      const messageId = `msg-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
      const actions = [];

      if (action) {
        const actionId = `act-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
        actions.push({
          id: actionId,
          type: action,
          applied: false,
          parameters,
        });
      }

      setMessages((prevMessages) => {
        const newMessages = [
          ...prevMessages,
          { id: messageId, message: text, sender, actions },
        ];
        localStorage.setItem('messages', JSON.stringify(newMessages));
        return newMessages;
      });
    },
    []
  );

  const clearStatus = useCallback(() => {
    setStatus(null);
  }, []);

  const addStream = useCallback(({ action, stream, sender = 'Bot' }) => {
    console.debug({ stream, action, sender });

    const messageId = `msg-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    const actions = [];

    if (action) {
      const actionId = `act-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
      actions.push({
        id: actionId,
        type: action,
        applied: false,
      });
    }

    setMessages((prevMessages) => {
      const newMessages = [
        ...prevMessages,
        { id: messageId, message: '', sender, actions },
      ];
      localStorage.setItem('messages', JSON.stringify(newMessages));
      return newMessages;
    });

    let fullText = '';
    const updateStream = async () => {
      try {
        for await (const textPart of stream.textStream) {
          fullText += textPart;
          setMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            const lastIndex = newMessages.length - 1;
            newMessages[lastIndex] = {
              ...newMessages[lastIndex],
              message: fullText,
            };
            localStorage.setItem('messages', JSON.stringify(newMessages));
            return newMessages;
          });
        }
      } catch (error) {
        console.error('Error in stream processing:', error);
      }
    };

    updateStream();
  }, []);

  const updateMessageAction = useCallback((messageId, actionId, updates) => {
    console.debug('Updating message action:', { messageId, actionId, updates });

    setMessages((prevMessages) => {
      const updatedMessages = prevMessages.map((msg) => {
        if (msg.id === messageId) {
          return {
            ...msg,
            actions:
              msg.actions?.map((act) =>
                act.id === actionId ? { ...act, ...updates } : act
              ) || [],
          };
        }
        return msg;
      });

      // Debug the update
      const updatedAction = updatedMessages
        .flatMap((msg) => msg.actions || [])
        .find((act) => act && act.id === actionId);
      console.debug('Action update result:', {
        messageId,
        actionId,
        updatedAction,
      });

      // Force immediate localStorage update
      localStorage.setItem('messages', JSON.stringify(updatedMessages));

      return updatedMessages;
    });
  }, []);

  const value = useMemo(
    () => ({
      messages,
      getMessages,
      addMessage,
      addStream,
      updateMessageAction,
      setStatus,
      clearStatus,
      status,
    }),
    [
      messages,
      getMessages,
      addMessage,
      addStream,
      updateMessageAction,
      setStatus,
      clearStatus,
      status,
    ]
  );

  return (
    <MessagesContext.Provider value={value}>
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessages = () => {
  const context = useContext(MessagesContext);
  if (!context) {
    throw new Error('useMessages must be used within a MessagesProvider');
  }
  return context;
};
