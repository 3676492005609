import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';

export default function updatedSpansAction({
  originalRole,
  role,
  comparisonScenario,
}) {
  const primaryRole = role || originalRole;

  const roleMetrics = primaryRole?.__metrics.span.visible;

  const action = {
    type: ActionType.UPDATED_SPANS,
    [EntityType.ROLE]: new Map([
      [
        primaryRole?.uuid,
        {
          metrics: {
            span: role?.__metrics?.span.visible.roles || 0,
          },
        },
      ],
    ]),
    metrics: defaultMetrics({ comparisonScenario, includeSpans: true }),
  };

  const originalRoleSpan = originalRole?.__metrics?.span?.visible.roles || 0;
  const roleSpans = role?.__metrics?.span?.visible.roles || 0;
  const spanChange = roleSpans - originalRoleSpan;

  action.metrics[1].span += spanChange;

  return action;
}
