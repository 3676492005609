import { useState } from 'react';
import { AddFilterButton } from '@/molecules/filterElements';
import Stack from '@mui/material/Stack';
import FilterCondition from './FilterCondition';
import { DefaultCondition, filterShouldUpdate } from './utils';

const FilterGroup = ({
  groupIndex,
  initialConditions,
  onChange,
  readOnly = false,
}) => {
  const [conditions, setConditions] = useState(
    initialConditions?.length ? initialConditions : [DefaultCondition]
  );

  const handleChange = (conditionIndex, condition) => {
    const existingCondition = new Map(conditions?.[conditionIndex]);

    const updatedConditions = [...conditions];
    updatedConditions[conditionIndex] = condition;
    setConditions(updatedConditions);

    // Only call the change event when the values have actually changed.
    if (filterShouldUpdate(existingCondition, condition)) {
      onChange?.(groupIndex, updatedConditions);
    }
  };

  const addCondition = () => {
    const updatedConditions = [...conditions, DefaultCondition];
    setConditions([...conditions, DefaultCondition]);
    onChange?.(groupIndex, updatedConditions);
  };

  const handleRemoveCondition = (index) => {
    const updatedConditions = conditions.filter((item, itemIndex) => {
      return itemIndex !== index;
    });

    // If we're clearing the first condition we want to make sure we still
    // keep a default condition.
    if (!updatedConditions?.length) {
      updatedConditions.push(new Map(DefaultCondition));
    }

    setConditions(updatedConditions);
    onChange?.(groupIndex, updatedConditions);
  };

  return (
    <Stack spacing={0.5}>
      {conditions.map((condition, conditionIndex) => (
        <FilterCondition
          key={`group-${groupIndex}-condition-${conditionIndex}-${condition.get(
            'entity'
          )}`}
          canRemove={conditions?.length > 1 || condition?.get('entity')}
          conditionIndex={conditionIndex}
          condition={condition}
          onChange={handleChange}
          onRemoveCondition={handleRemoveCondition}
          readOnly={readOnly}
        />
      ))}
      <div>
        {!readOnly && <AddFilterButton label="Add" onClick={addCondition} />}
      </div>
    </Stack>
  );
};

export default FilterGroup;
