import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Colors, Str } from '@pkg/utils';
import config from '@/lib/config';
import excludeProps from '@/lib/theme/excludeProps';
import { palettes } from '@/lib/theme/tokens';
import Tooltip from '@/components/Tooltip';

const Container = styled('div', {
  shouldForwardProp: excludeProps('expanded'),
})(({ expanded }) => ({
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: expanded ? 16 : 12,
  lineHeight: 1,
}));

const TagChip = styled('div', {
  shouldForwardProp: excludeProps('color', 'active'),
})(({ active, color }) => ({
  backgroundColor: active ? color.fill : 'transparent',
  borderRadius: 4,
  boxShadow: `inset 0 0 0 2px ${
    active ? color.fill : palettes.grey.colors[100]
  }`,
  display: 'inline-flex',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 0,
  transition: 'background-color 0.2s, box-shadow 0.2s',
  '&:hover': {
    backgroundColor: active ? color.iconFill : 'transparent',
    boxShadow: `inset 0 0 0 2px ${
      active ? color.iconFill : palettes.grey.colors[200]
    }`,
  },
}));

const Label = styled('div', {
  shouldForwardProp: excludeProps('color', 'active'),
})(({ color, active }) => ({
  color: active ? color.text : palettes.grey.colors[500],
  display: 'block',
  fontSize: 12,
  fontWeight: 600,
  padding: '4px 8px',
  zIndex: 1,
  transition: 'color 0.2s',
  '&:hover': {
    color: active ? color.text : palettes.grey.colors[700],
  },
}));

const Nugget = styled('span')({
  height: 12,
  width: 16,
});

const Tag = ({ active = false, expanded = false, onClick, tag, ...props }) => {
  if (!tag) {
    return null;
  }

  const color = Colors.tag({ color: tag.color });
  const truncated = Str.truncate(tag.name, config.VALUE_LENGTH.DISPLAY.TAG);

  const handleClick = (event) => {
    onClick?.(event, tag);
    return;
  };

  return (
    <Container {...props} expanded={expanded} data-testid="tag">
      <Tooltip title={tag?.description ?? ''} arrow disableInteractive>
        <TagChip color={color.base} active={active} onClick={handleClick}>
          {expanded ? (
            <Label color={color.base} active={active}>
              {truncated}
            </Label>
          ) : (
            <Nugget />
          )}
        </TagChip>
      </Tooltip>
    </Container>
  );
};

Tag.propTypes = {
  active: PropTypes.bool,
  expanded: PropTypes.bool,
  tag: PropTypes.object,
};

export default Tag;
