import { isTagActionType } from '@/organisms/plans';
import { ActionType } from '@/shared/enums';
import { DesignEntity } from '@/lib/enums';

function activityActionType(action) {
  let actionType = ActionType.UPDATE_HOURS;

  const comparisonMetrics = action.metrics[0];
  const metrics = action.metrics[1];

  if (comparisonMetrics.activities > metrics.activities) {
    actionType = ActionType.REMOVE_TAGS;
  }
  if (metrics.activities > comparisonMetrics.activities) {
    actionType = ActionType.ADD_TAGS;
  }

  return actionType;
}

function entityActionType(action) {
  const comparisonMetrics = action.metrics[0];
  const metrics = action.metrics[1];
  const entityType = action.entity;
  const plural = DesignEntity.toPlural(entityType);

  const count = action.metrics[1][plural];
  const comparisonCount = action.metrics[0][plural];

  if (comparisonCount > count) {
    return ActionType.REMOVE_TAGS;
  }

  if (count > comparisonCount) {
    return ActionType.ADD_TAGS;
  }

  if (comparisonMetrics.fte !== metrics.fte) {
    return ActionType.UPDATE_FTE;
  }

  if (comparisonMetrics.budget !== metrics.budget) {
    return ActionType.UPDATE_BUDGETS;
  }

  return ActionType.UPDATED_TAGS;
}

function determineActionType(action) {
  if (action.entity === DesignEntity.ACTIVITY) {
    return activityActionType(action);
  }

  return entityActionType(action);
}

export default function groupTagActions(actionGroup) {
  const actionCategories = [];

  // We only need to group tag actions with
  if (!isTagActionType(actionGroup.type)) {
    actionCategories.push({
      type: actionGroup.type,
      actions: [...actionGroup[actionGroup.entity].entries()].map(
        ([id, action]) => {
          return {
            ...action,
            id,
          };
        }
      ),
    });

    return actionCategories;
  }

  const categories = new Map();

  [...actionGroup[actionGroup.entity].entries()].forEach(([id, action]) => {
    const actionType = determineActionType(action);

    const category = categories.get(actionType) ?? {
      title: actionType,
      type: actionType,
      actions: [],
    };

    category.actions.push({
      ...action,
      id,
    });

    categories.set(actionType, category);
  });

  return [...categories.values()];
}
