import ArrowIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import AccordionSummary from '@mui/material/AccordionSummary';
import { brand } from '@/lib/theme/tokens/palettes';

const AccordionTitle = ({ children }) => {
  return (
    <AccordionSummary
      expandIcon={
        <ArrowIcon
          sx={{
            fontSize: '2rem',
            color: brand.grey.tints[7],
          }}
        />
      }
      sx={{
        '& .MuiAccordionSummary-content': {
          my: 2,
        },
      }}
    >
      {children}
    </AccordionSummary>
  );
};

export default AccordionTitle;
