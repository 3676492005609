import { tool } from 'ai';
import { z } from 'zod';

const roleArchive = tool({
  description: 'Archive one or more roles from the organisational design',
  parameters: z.object({
    payload: z
      .object({
        ids: z
          .array(z.string().describe('9-digit UUIDs of the roles to archive'))
          .describe('Array of role UUIDs to archive'),
        inclusive: z
          .boolean()
          .describe(
            'If true, also archives all managed entities (roles, groups, activities)'
          ),
      })
      .describe('The payload for archiving roles'),
    explanation: z
      .string()
      .describe(
        'An explanation of what will be archived and the impact on the organization'
      ),
  }),
  execute: async ({ payload, explanation }) => ({
    payload,
    explanation,
  }),
});

export default roleArchive;
