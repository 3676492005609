import { useEffect, useState } from 'react';
import { Chip } from '@/atoms/chips';
import { Paragraph } from '@/atoms/typography';
import {
  Accordion,
  AccordionTitle,
  AccordionContent,
} from '@/molecules/accordions';
import { PlanActionCard } from '@/organisms/cards';
import {
  EmptyPlanList,
  mapAggregateActions,
  PlanListSkeleton,
} from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import {
  usePlanContext,
  useScenarioContext,
  useViewModeContext,
} from '@/shared/providers';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { groupActions } from './utils';

const ACTIONS_ORDER = [EntityType.GROUP, EntityType.ROLE, EntityType.ACTIVITY];

const PlanActionsTypeList = ({ onSelect }) => {
  const { comparisonScenario, scenario } = useScenarioContext();
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(true);
  const { showBudget } = useViewModeContext();
  const { selectedSummaryItem, selectSummaryItem } = usePlanContext();

  const handleSummarySelect = (event, action) => {
    event.preventDefault();
    event.stopPropagation();

    selectSummaryItem({
      ...action,
    });
  };

  const handleActionSelect = (event, action) => {
    event.preventDefault();
    event.stopPropagation();

    if (action && selectSummaryItem?.id !== action.id) {
      selectSummaryItem({
        ...action,
      });
    }

    onSelect(action);
  };

  useEffect(() => {
    if (!comparisonScenario || !scenario) {
      return;
    }

    const actions = mapAggregateActions({
      comparisonScenario,
      scenario,
      showBudget,
    });

    if (!actions) {
      return;
    }

    if (!actions.size) {
      setActions([]);
      setLoading(false);
      setIsEmpty(true);
      return;
    }

    const groups = groupActions(
      [...actions.entries()].map(([id, action]) => {
        return {
          actionType: id,
          ...action,
          id,
        };
      })
    );

    const actionGroups = Object.entries(groups)
      .map(([id, action]) => {
        return {
          actionType: id,
          ...action,
          id,
        };
      })
      .sort((a, b) => {
        return (
          ACTIONS_ORDER.indexOf(a.actionType) -
          ACTIONS_ORDER.indexOf(b.actionType)
        );
      });

    setActions(actionGroups);

    setLoading(false);
    setIsEmpty(false);
  }, [
    JSON.stringify(scenario?.entity?.__metrics?.visible),
    JSON.stringify(comparisonScenario?.entity?.__metrics?.visible),
    scenario?.details?.hash,
    comparisonScenario?.details?.hash,
    showBudget,
  ]);

  return loading ? (
    <PlanListSkeleton />
  ) : (
    <>
      {isEmpty ? (
        <EmptyPlanList
          message={`No changes have been found in this scenario.`}
        />
      ) : (
        <>
          <Box px={2} mt={2}>
            <Paragraph>
              Below is a summary of actions applied to your scenario. You can
              expand the sections to explore the impact of each action type.
              Click the <strong>View</strong> button on the cards to see
              individual changes for that action type.
            </Paragraph>
          </Box>
          {actions.map((group, index) => (
            <Accordion key={group.actionType} showBorder={index > 0}>
              <AccordionTitle key={group.actionType} id={group.actionType}>
                <Box>
                  <Chip
                    fontWeight={700}
                    name={group.label}
                    colorProps={group.colorProps}
                    expanded={true}
                  />
                </Box>
              </AccordionTitle>
              <AccordionContent>
                <Grid container key={group.actionType} spacing={2}>
                  {group.actions.map((action) => (
                    <Grid
                      item
                      xs={12}
                      key={`${action.id}-${action.title}`}
                      onClick={(event) => handleSummarySelect(event, action)}
                    >
                      <PlanActionCard
                        actionType={action.type}
                        entityType={action.entity}
                        isSelected={action.id === selectedSummaryItem?.id}
                        metric={action.metric}
                        metrics={action.metrics}
                        onEdit={(event) => handleActionSelect(event, action)}
                        properties={action.props}
                        title={action.title}
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionContent>
            </Accordion>
          ))}
        </>
      )}
    </>
  );
};

export default PlanActionsTypeList;
