import { Snapshots } from '@pkg/entities';
import shortuuid from '@pkg/uuid';
import config from '@/lib/config';
import { DesignEntity } from '@/lib/enums';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {Map<String,DesignEntity>} input.ids
 * @param {String} [input.uuid]
 * @param {String} [input.name]
 * @param {String} [input.objective]
 * @returns {Object}
 */
export default function add(snapshot, { ids = new Map(), ...input }) {
  const keyed = Snapshots.deriveProps({
    snapshot,
    includeMetrics: false,
    flatten: false,
  });

  const now = Date.now();

  // get real ids
  const realIds = new Map();
  ids.forEach((type, id) => {
    const plural = DesignEntity.toPlural(type);
    if (Object.hasOwn(keyed.entities[plural], id)) {
      realIds.set(id, type);
    }
  });

  // get outer group and parent
  const [sampleId, sampleType] = Array.from(realIds)[0] ?? [];
  const samplePlural = DesignEntity.toPlural(sampleType);
  const sample = keyed.entities[samplePlural]?.[sampleId] ?? null;

  const leadKey = `${sampleType === DesignEntity.ROLE ? 'parent' : 'lead'}_uuid`;
  const outerGroupId = sample?.group_uuid ?? null;
  const groupLeadId = sample?.[leadKey] ?? null;

  // create group and mutation
  const group = {
    uuid: shortuuid.generate(),
    group_uuid: outerGroupId,
    lead_uuid: groupLeadId,
    name: config.DEFAULT_GROUP_NAME,
    objective: '',
    properties: [],
    tags: [],
    created_at: now,
    updated_at: now,
    ...input,
  };

  const mutation = {
    created_at: now,
    entities: {
      groups: {
        create: [group],
        update: [],
      },
      roles: {
        update: [],
      },
    },
  };

  // iterate to update hierarchy
  const iterateHierarchy = (type, id) => {
    const update = { uuid: id, group_uuid: group.uuid, updated_at: now };
    const plural = DesignEntity.toPlural(type);
    const entity = keyed.entities[plural][id];

    // short circuit for groups, nested children don't change
    if (type === DesignEntity.GROUP) {
      return mutation.entities.groups.update.push(update);
    }

    // update role and iterate children
    mutation.entities.roles.update.push(update);
    entity.__children.forEach(iterateHierarchy);
  };

  realIds.forEach(iterateHierarchy);

  // trim unused mutations
  Object.keys(mutation.entities).forEach((plural) => {
    Object.keys(mutation.entities[plural]).forEach((action) => {
      if (!mutation.entities[plural][action].length) {
        delete mutation.entities[plural][action];
      }
    });

    if (!Object.keys(mutation.entities[plural]).length) {
      delete mutation.entities[plural];
    }
  });

  if (!Object.keys(mutation.entities).length) {
    return null;
  }

  // stuff changed, return mutation
  return mutation;
}
