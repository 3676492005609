import { Entity as EntityColors } from '@/atoms/colors';
import { EntityType } from '@/shared/enums';
import { EntityMetric } from '@/lib/enums';

export default function metricColor(metric, entityType) {
  switch (metric) {
    case EntityMetric.BUDGET:
    case EntityMetric.FTE:
      return EntityColors[entityType];
    case EntityMetric.ACTIVITIES:
    case EntityMetric.HOURS:
      return EntityColors[EntityType.ACTIVITY];
    case EntityMetric.ROLES:
    case EntityType.ROLE:
      return EntityColors[EntityType.ROLE];
    case EntityMetric.MANAGERS:
    case EntityMetric.AVERAGE_SPAN:
    case EntityMetric.SPAN:
    case EntityType.MANAGER:
      return EntityColors[EntityType.MANAGER];
    case EntityMetric.GROUPS:
    case EntityType.GROUP:
      return EntityColors[EntityType.GROUP];
    default:
      return EntityColors[entityType];
  }
}
