import {
  cumulateMetrics,
  cumulateTagMetrics,
  defaultMetrics,
  updateEntityAction,
} from '@/organisms/plans/utils';
import { ActionType, EntityType } from '@/shared/enums';

export default function updateActivity({
  action,
  activity,
  role,
  activityType,
  comparisonScenario,
}) {
  const updateActivity = activityType[EntityType.ACTIVITY].get(
    activity.uuid
  ) ?? {
    [EntityType.ROLE]: new Set(role ? [role.uuid] : []),
    actions: new Map(),
    metrics: defaultMetrics({
      activity,
      action,
      comparisonScenario,
      role,
      includeTags: action.type === ActionType.UPDATED_TAGS,
    }),
  };

  updateActivity.actions.set(
    action.type,
    updateEntityAction({
      action,
      actions: updateActivity.actions,
      role,
      comparisonScenario,
    })
  );

  if (action.type === ActionType.UPDATED_TAGS) {
    const tags = cumulateTagMetrics({
      entityTags: action.metrics[1].tags,
      originalEntityTags: action.metrics[0].tags,
      existingMetrics: updateActivity.metrics,
    });
    updateActivity.metrics[0].tags = tags[0];
    updateActivity.metrics[1].tags = tags[1];
  } else {
    updateActivity.metrics[1] = {
      ...cumulateMetrics(action, updateActivity.metrics[0]),
      tags: updateActivity.metrics[1].tags,
    };
  }

  return updateActivity;
}
