import { useState, useEffect } from 'react';
import { Designs, Library, Revisions } from '@pkg/entities';
import mapDesign from '../utils/mapDesign';

export const derive = (library, underivedDesign) => {
  const { latest: underivedLatest, ...design } = underivedDesign;
  const derived = Revisions.deriveProps({
    library,
    revision: underivedLatest,
    includeMetrics: false,
    includeTags: false,
  });
  const { snapshot, ...latest } = derived;

  return {
    design: {
      ...design,
      latest,
    },
    snapshot,
  };
};

const useDerivedDesigns = (designIds) => {
  const { hydrated, isHydrating } = Designs.useHydrated(designIds);
  const library = Library.useLibrary();

  const [designs, setDesigns] = useState(null);

  useEffect(() => {
    if (isHydrating) {
      return;
    }

    const mapped = new Map(designs);
    let updated = false;

    const main = designs?.get(hydrated.main?.uuid);

    if (main?.design?.latest?.uuid !== hydrated.main?.latest?.uuid) {
      const { snapshot, design } = derive(library, hydrated.main);
      const snapshotEntityMap = mapDesign(design, snapshot);

      mapped.set(design.uuid, {
        design,
        snapshotEntityMap,
        snapshot,
      });
      updated = true;
    }

    hydrated.scenarios.list.forEach((item) => {
      const scenario = designs?.get(item.uuid);

      const collaborators = JSON.stringify(scenario?.design?.collaborators);
      const hydratedCollaborators = JSON.stringify(item?.collaborators);

      if (
        scenario?.design?.latest?.uuid !== item?.latest?.uuid ||
        collaborators !== hydratedCollaborators
      ) {
        const { snapshot, design } = derive(library, item);
        const snapshotEntityMap = mapDesign(design, snapshot);

        mapped.set(design.uuid, {
          design,
          snapshotEntityMap,
          snapshot,
        });

        updated = true;
      }
    });

    if (updated) {
      setDesigns(mapped);
    }
  }, [hydrated]);

  const isLoading = !designs?.size;

  return { designs, isLoading };
};

export default useDerivedDesigns;
