import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';
import useStore from '../useStore';

const UPDATE_FLOW_TEMPLATE = /* GraphQL */ `
  mutation UpdateFlowTemplate($input: UpdateFlowTemplateInput!) {
    updateFlowTemplate(input: $input) {
      uuid
      name
      description
      disclaimer
      is_onboarding
      is_token_authenticated
      settings {
        show_days_per_month
        show_tags
        tags
      }
    }
  }
`;

/**
 * @return {Function}
 */
export default function useUpdate() {
  const { mutateAsync } = useMutation({
    mutation: UPDATE_FLOW_TEMPLATE,
  });

  const database = useDatabase();
  const { update } = useStore();

  /**
   * Optimistically update flow template in storage
   * @todo handle failure
   */
  async function optimisticUpdate(flowTemplateId, input) {
    update(flowTemplateId, input);
    await database.flowTemplates.update(flowTemplateId, input);
  }

  /**
   * @param {String} flowTemplateId
   * @param {Object} input
   * @param {String} input.name
   * @returns {Promise}
   */
  async function updateFlowTemplate(flowTemplateId, input) {
    await optimisticUpdate(flowTemplateId, input);
    return mutateAsync({ uuid: flowTemplateId, ...input });
  }

  return useCallback(updateFlowTemplate, [mutateAsync, database, update]);
}
