import config from '@/lib/config';
import { DesignEntity } from '@/lib/enums';
import defaultMetricGroup from '../../utils/defaultMetricGroup';
import updateMetrics from './updateMetrics';

/**
 * Finds the tag map based on the provided id or creates a new one.
 *
 * @param {Object}
 *
 * @return {Object}
 */
function getTagMap({ id, tagId, tagMap, tagType }) {
  const tags = tagMap.get(tagType);

  // If this is a new tag create a new entry in the map.
  if (!tags.has(tagId)) {
    tags.set(
      tagId,
      new Map([
        [
          id,
          new Map([
            [DesignEntity.ACTIVITY, new Set()],
            [DesignEntity.ROLE, new Set()],
            [DesignEntity.GROUP, new Set()],
          ]),
        ],
      ])
    );
  }

  // If this is a new entity, add the entity to the tag map.
  if (!tags.get(tagId)?.has(id)) {
    tags.get(tagId).set(
      id,
      new Map([
        [DesignEntity.ACTIVITY, new Set()],
        [DesignEntity.ROLE, new Set()],
        [DesignEntity.GROUP, new Set()],
      ])
    );
  }

  return tags.get(tagId).get(id);
}

/**
 * Derives the tag metrics based on the entity and the tag type.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function deriveEntityTag({
  activity,
  entityId,
  group,
  metrics,
  role,
  tagId,
  tagType,
  tagMap,
}) {
  if (!metrics.has(entityId)) {
    return;
  }

  const entityTagMap = getTagMap({
    id: entityId,
    tagId,
    tagMap,
    tagType,
  });

  const isRoot = entityId === '*';
  const isActivityTag = tagType === DesignEntity.ACTIVITY;
  const isVisibleAcivity = activity?.__visibility === config.VISIBILITY.FULL;
  const isVisibleRole = role?.__visibility === config.VISIBILITY.FULL;
  const isVisibleGroup = group?.__visibility === config.VISIBILITY.FULL;

  const isNewTagActivity = Boolean(
    activity && !entityTagMap.get(DesignEntity.ACTIVITY).has(activity?.uuid)
  );
  const isNewTagRole = Boolean(
    role && !entityTagMap.get(DesignEntity.ROLE).has(role.uuid)
  );
  const isNewTagGroup = Boolean(
    group && !entityTagMap.get(DesignEntity.GROUP).has(group?.uuid)
  );

  const entityMetrics = isRoot
    ? metrics.get(entityId)
    : metrics.get(entityId)?.self;

  const totalMetrics =
    entityMetrics.total.tags[tagType]?.[tagId] ?? defaultMetricGroup(false);
  const visibleMetrics =
    entityMetrics.visible.tags[tagType]?.[tagId] ?? defaultMetricGroup(false);

  // Exit early if we don't have new data.
  if (!isNewTagActivity && !isNewTagRole && !isNewTagGroup) {
    return { total: { ...totalMetrics }, visible: { ...visibleMetrics } };
  }

  const baseMetrics = {
    ...defaultMetricGroup(false),
    activities: isNewTagActivity ? 1 : 0,
    budget: !isActivityTag && isNewTagRole ? role.budget : 0,
    fte: !isActivityTag && isNewTagRole ? role.fte : 0,
    hours: isNewTagActivity ? activity.hours : 0,
    roles: isNewTagRole ? 1 : 0,
    groups: isNewTagGroup ? 1 : 0,
  };

  const total = updateMetrics({
    target: { ...totalMetrics },
    source: { ...baseMetrics },
  });

  const visible = updateMetrics({
    target: { ...visibleMetrics },
    source: {
      activities: isVisibleAcivity ? baseMetrics.activities : 0,
      budget: isVisibleRole ? baseMetrics.budget : 0,
      fte: isVisibleRole ? baseMetrics.fte : 0,
      hours: isVisibleAcivity ? baseMetrics.hours : 0,
      managers: isVisibleRole ? baseMetrics.managers : 0,
      roles: isVisibleRole ? baseMetrics.roles : 0,
      groups: isVisibleGroup ? baseMetrics.groups : 0,
    },
  });

  entityMetrics.total.tags[tagType] = {
    ...entityMetrics.total.tags[tagType],
    [tagId]: { ...total },
  };

  entityMetrics.visible.tags[tagType] = {
    ...entityMetrics.visible.tags[tagType],
    [tagId]: { ...visible },
  };

  if (isNewTagActivity) {
    entityTagMap.get(DesignEntity.ACTIVITY).add(activity.uuid);
  }
  if (isNewTagRole) {
    entityTagMap.get(DesignEntity.ROLE).add(role.uuid);
  }
  if (isNewTagGroup) {
    entityTagMap.get(DesignEntity.GROUP).add(group.uuid);
  }

  return { total, visible };
}
