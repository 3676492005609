import { tool } from 'ai';
import { z } from 'zod';

const roleUpdate = tool({
  description: 'Update an existing role in the organizational design',
  parameters: z.object({
    payload: z
      .object({
        uuid: z.string().describe('The UUID of the role to update'),
        title: z.string().describe('The title of the role'),
        fte: z
          .number()
          .describe('The Full Time Equivalent (FTE) allocation for this role'),
        budget: z.number().describe('The budget allocated to this role'),
        tags: z
          .array(z.string())
          .describe('Array of tag UUIDs associated with this role'),
        is_manager: z
          .boolean()
          .describe('Flag indicating if this role is a manager position'),
      })
      .describe('The update payload for the role'),
    explanation: z
      .string()
      .describe('An explanation of what is about to be updated.'),
  }),
  execute: async ({ uuid, payload, explanation }) => ({
    uuid,
    payload: {
      ...payload,
      updated_at: Date.now() / 1000, // Current epoch time in seconds
    },
    explanation,
  }),
});

export default roleUpdate;
