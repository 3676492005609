import { formatFlow } from '@/shared/utils/flows';
import { useQuery } from '@pkg/graphql';

const FLOW_QUERY = /* GraphQL */ `
  query Flow($uuid: UUID!) {
    flow(uuid: $uuid) {
      uuid
      status
      campaign {
        flowTemplate {
          description
          disclaimer
          is_onboarding
          is_token_authenticated
          settings {
            tags
            show_days_per_month
            show_tags
          }
          type
        }
      }
      payload {
        design_uuid
        entity {
          uuid
          name
        }
      }
    }
  }
`;

export default function useFlow({ uuid }, enabled) {
  const query = [FLOW_QUERY, { uuid }];

  const { data, refetch, isLoading, isFetching, remove } = useQuery({
    query,
    enabled,
  });

  const flow = formatFlow(data?.flow);

  return {
    flow,
    refetch,
    isLoading,
    isFetching,
    remove,
  };
}
