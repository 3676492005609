import { ActionType, EntityType } from '@/shared/enums';
import { Visibility } from '@/lib/enums';
import addActionByActivity from '../activities/addActionByActivity';
import addActionByRole from './addActionByRole';
import skipRoleUpdate from './skipRoleUpdate';
import updateMap from './updateMap';

/**
 * Creates a map of changed roles.
 *
 * @param {Object}
 *
 * @return {Map}
 */
export default function mapAggregateRoles({
  scenario,
  showBudget,
  comparisonScenario,
}) {
  if (!comparisonScenario) {
    return;
  }

  const roleMap = new Map();

  // Keep a record of the original entities.
  const originalActivityList = new Set(
    comparisonScenario.relationships?.get(EntityType.ACTIVITY)?.keys()
  );
  const originalRoleList = new Set(
    comparisonScenario.relationships?.get(EntityType.ROLE)?.keys()
  );
  const originalManagerList = new Set(
    [...originalRoleList].filter(
      (id) =>
        comparisonScenario.relationships?.get(EntityType.ROLE)?.get(id)
          .is_manager
    )
  );
  const originalGroupList = new Set(
    comparisonScenario.relationships?.get(EntityType.GROUP)?.keys()
  );

  // Keep a list of the scenario roles and groups to identify ones which don't
  // have activities associated with them.
  const scenarioRoleList = new Set(
    scenario.relationships?.get(EntityType.ROLE)?.keys()
  );
  const scenarioGroupList = new Set(
    scenario.relationships?.get(EntityType.GROUP)?.keys()
  );

  // Loop through the activities in the scenario to create the role map.
  [...scenario.relationships.get(EntityType.ACTIVITY).values()].forEach(
    (activity) => {
      const originalActivity = comparisonScenario.relationships
        ?.get(EntityType.ACTIVITY)
        ?.get(activity.uuid);

      const role =
        activity.owner_type === EntityType.ROLE
          ? scenario.relationships
              .get(EntityType.ROLE)
              ?.get(activity.owner_uuid)
          : null;

      const originalRole =
        originalActivity?.owner_type === EntityType.ROLE
          ? comparisonScenario.relationships
              .get(EntityType.ROLE)
              ?.get(originalActivity.owner_uuid)
          : null;

      // @ToDo: Handle unallocated activities.
      if (activity.__visibility !== Visibility.FULL) {
        return;
      }

      const actions = [];

      if (scenarioRoleList.has(role?.uuid)) {
        // Add role actions.
        actions.push(
          ...addActionByRole({
            comparisonScenario,
            role,
            scenario,
            showBudget,
          })
        );
      }

      // Add activity actions.
      actions.push(
        ...addActionByActivity({
          activity,
          comparisonScenario,
          ignoreMoved: true,
          ignoreTags: true,
          originalActivity,
          scenario,
          showBudget,
        })
      );

      for (let i = 0; i < actions?.length; i++) {
        const action = actions[i];

        const actionRole =
          action.type === ActionType.REMOVED_ACTIVITY ? originalRole : role;

        if (skipRoleUpdate({ action, role: actionRole, roleMap, showBudget })) {
          continue;
        }

        // Updates the role map with the role metrics and action.
        updateMap({ action, comparisonScenario, role: actionRole, roleMap });
      }

      originalActivityList.delete(originalActivity?.uuid);
      originalRoleList.delete(originalRole?.uuid);
      originalGroupList.delete(originalRole?.group_uuid);
      scenarioRoleList.delete(role?.uuid);
      scenarioGroupList.delete(role?.group_uuid);
    }
  );

  // Loop through the scenario roles that didn't have any activities.
  [...scenarioRoleList].forEach((id) => {
    const role = scenario.relationships.get(EntityType.ROLE)?.get(id);
    const originalRole = comparisonScenario.relationships
      .get(EntityType.ROLE)
      ?.get(id);

    scenarioRoleList.delete(role?.uuid);

    if (role?.__visibility !== Visibility.FULL) {
      return;
    }

    const actions = [];

    // Add role actions for roles without activities.
    actions.push(
      ...addActionByRole({
        comparisonScenario,
        role,
        scenario,
        showBudget,
      })
    );

    for (let i = 0; i < actions?.length; i++) {
      const action = actions[i];

      const actionRole =
        action.type === ActionType.REMOVED_ACTIVITY ? originalRole : role;

      if (skipRoleUpdate({ action, role: actionRole, roleMap, showBudget })) {
        continue;
      }

      // Updates the role map with the role metrics and action.
      updateMap({ action, comparisonScenario, role, roleMap });

      originalRoleList.delete(originalRole?.uuid);
      scenarioRoleList.delete(role?.uuid);
    }
  });

  // Loop through the activities that were removed from the scenario.
  [...originalActivityList].forEach((id) => {
    const activity = comparisonScenario.relationships
      .get(EntityType.ACTIVITY)
      ?.get(id);

    if (activity?.__visibility !== Visibility.FULL) {
      return;
    }

    const role =
      activity.owner_type === EntityType.ROLE
        ? comparisonScenario.relationships
            .get(EntityType.ROLE)
            ?.get(activity.owner_uuid)
        : null;

    // For now let's ignore unallocated activities.
    if (!role) {
      return;
    }

    const actions = [];

    if (originalRoleList.has(role.uuid)) {
      actions.push(
        ...addActionByRole({
          comparisonScenario,
          role,
          scenario,
          showBudget,
        })
      );
    }

    originalActivityList.delete(activity?.uuid);
    originalRoleList.delete(role?.uuid);
    originalGroupList.delete(role?.group_uuid);

    actions.push(
      ...addActionByActivity({
        comparisonScenario,
        ignoreMoved: true,
        ignoreTags: true,
        originalActivity: activity,
        scenario,
        showBudget,
      })
    );

    for (let i = 0; i < actions?.length; i++) {
      const action = actions[i];

      // If this is adding a new role or removing an existing role we want to
      // make sure we only increment the metrics once.
      if (skipRoleUpdate({ action, role, roleMap, showBudget })) {
        continue;
      }

      // Updates the role map with the role metrics and action.
      updateMap({ action, activity, comparisonScenario, role, roleMap });
    }
  });

  // Loop through the roles that were removed from the scenario and didn't have
  // any activities.
  [...originalRoleList].forEach((id) => {
    const role = comparisonScenario.relationships.get(EntityType.ROLE)?.get(id);

    originalRoleList.delete(role?.uuid);

    if (role?.__visibility !== Visibility.FULL) {
      return;
    }

    const actions = [];

    // Add role actions for roles without activities.
    actions.push(
      ...addActionByRole({
        comparisonScenario,
        role,
        scenario,
        showBudget,
      })
    );

    for (let i = 0; i < actions?.length; i++) {
      const action = actions[i];

      if (skipRoleUpdate({ action, role, roleMap, showBudget })) {
        continue;
      }

      // Updates the role map with the role metrics and action.
      updateMap({ action, comparisonScenario, role, roleMap });

      originalRoleList.delete(role.uuid);
    }
  });

  return roleMap;
}
