import { Base as BaseColor } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { GroupTitle } from '@/molecules/groups';
import { SummaryHeading } from '@/molecules/headings';
import { PersonTitle } from '@/molecules/people';
import { RoleTitle } from '@/molecules/roles';
import { EntityType } from '@/shared/enums';
import { useScenarioContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import { getMovementEntities } from './utils';

const EntityMovement = ({ entityType, properties, movementType }) => {
  const { comparisonScenario, scenario } = useScenarioContext();

  const entities = getMovementEntities({
    comparisonScenario,
    entityType,
    movementType,
    properties,
    scenario,
  });

  const showTo = Boolean(
    entities?.to &&
      (entities.to.type === EntityType.PERSON || entities.to.entity)
  );
  const showFrom = Boolean(
    entities?.from &&
      (entities.from.type === EntityType.PERSON || entities.from.entity)
  );

  return (
    (showTo || showFrom) &&
    Boolean(entities) && (
      <>
        <SummaryHeading title={entities.title} />
        {Boolean(showTo) && (
          <Box px={2} mt={1}>
            <Paragraph size={Size.XX_SMALL} overrideStyles={{ mb: 0.5 }}>
              TO
            </Paragraph>
            <Box
              p={1}
              sx={{
                borderRadius: '2px',
                boxShadow: `0 0 0 1px ${BaseColor.border.primary}`,
              }}
            >
              {entities.to.entityType === EntityType.PERSON && (
                <PersonTitle person={entities.to.entity} />
              )}
              {entities.to.entityType === EntityType.ROLE && (
                <RoleTitle role={entities.to.entity} />
              )}
              {entities.to.entityType === EntityType.GROUP && (
                <GroupTitle group={entities.to.entity} />
              )}
            </Box>
          </Box>
        )}
        {Boolean(showFrom) && (
          <Box px={2} mt={1}>
            <Paragraph size={Size.XX_SMALL} overrideStyles={{ mb: 0.5 }}>
              FROM
            </Paragraph>
            <Box
              p={1}
              sx={{
                borderRadius: '2px',
                boxShadow: `0 0 0 1px ${BaseColor.border.primary}`,
              }}
            >
              {entities.from.entityType === EntityType.PERSON && (
                <PersonTitle person={entities.from.entity} />
              )}
              {entities.from.entityType === EntityType.ROLE && (
                <RoleTitle role={entities.from.entity} />
              )}
              {entities.from.entityType === EntityType.GROUP && (
                <GroupTitle group={entities.from.entity} />
              )}
            </Box>
          </Box>
        )}
      </>
    )
  );
};

export default EntityMovement;
