import getSummarised from '@pkg/entities/tags/get/summarised';
import { DesignEntity, Visibility } from '@/lib/enums';

/**
 * Normalizes default group properties.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function normalizeGroup({ group, library, snapshot }) {
  if (!group || !library || !snapshot) {
    return;
  }

  const manager = snapshot.entities.roles[group.__manager] ?? null;
  group.__person = snapshot.entities.people[manager?.user_uuid] ?? null;

  // Tags.
  group.tags = group.tags ?? [];
  group.__tags_set = new Set(group.tags);
  group.__tags_summarised = getSummarised(library.tags.tags, group.tags);
  group.__type = DesignEntity.GROUP;

  // Properties.
  group.properties = group.properties ?? [];
  group.__properties_map = new Map(
    group.properties.map(({ key, value }) => [key, value])
  );

  group.__visibility = group.__visibility ?? Visibility.FULL;

  return group;
}
