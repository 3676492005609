import { tool } from 'ai';
import { z } from 'zod';

const groupMove = tool({
  description:
    'Move groups within the organizational structure, updating their group and leadership relationships',
  parameters: z.object({
    payload: z
      .object({
        ids: z.array(z.string()).describe('Array of group UUIDs to move'),
        groupId: z
          .string()
          .nullable()
          .describe(
            'The UUID of the destination group to move into, or null for top-level'
          ),
        leadId: z
          .string()
          .nullable()
          .describe(
            'The UUID of the role that will lead these groups, or null for no direct leader'
          ),
      })
      .describe('The move payload for the groups'),
    explanation: z
      .string()
      .describe(
        'An explanation of what is about to be moved. This step will be asked to be confirmed. Phrase it accordingly.'
      ),
  }),
  execute: async ({ payload, explanation }) => ({
    payload,
    explanation,
  }),
});

export default groupMove;
