import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import TagDescription from '@/molecules/tags/TagDescription';
import { CategorisedActivityList } from '@/organisms/activities';
import { CheckboxActivityCardList, OutlineCard } from '@/organisms/cards';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const ActivityTags = ({
  allowedTagSet,
  coreActivitySet,
  id,
  categoryTagMap,
  categoryTags,
  properties,
  title,
}) => {
  const settings = {
    isEditable: true,
    isTagsEditable: false,
    showComparison: false,
    showDragDrop: false,
    showHours: true,
    showMenu: false,
    showPercentage: true,
    showRemove: true,
    showTags: false,
    showTitle: !categoryTagMap?.size,
    showTotalActivityHours: false,
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <OutlineCard padding={0} margin={0}>
          <Box sx={{ overflow: 'hidden', borderRadius: '4px' }}>
            <TagDescription tag={properties} />
          </Box>
        </OutlineCard>
      </Grid>
      <Grid item xs={12} md={8} sx={{ mt: 1 }}>
        <Paragraph size={Size.MEDIUM}>
          Using the checkboxes, select all activities which you consider{' '}
          <span style={{ fontWeight: 600 }}>{title}</span>.
        </Paragraph>
      </Grid>
      <Grid item xs={12} md={8}>
        {categoryTags?.length ? (
          <CategorisedActivityList
            categoryTags={categoryTags}
            categoryTagMap={categoryTagMap}
            Component={
              <CheckboxActivityCardList
                allowedTagSet={allowedTagSet}
                coreActivitySet={coreActivitySet}
                id={id}
                tagProperties={properties}
              />
            }
            settings={settings}
          />
        ) : (
          <CheckboxActivityCardList
            allowedTagSet={allowedTagSet}
            coreActivitySet={coreActivitySet}
            id={id}
            tagProperties={properties}
            settings={settings}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ActivityTags;
