import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';

export default function removedManagerAction({ role, comparisonScenario }) {
  const metrics = role.__metrics.self.total;

  const managerProperties = {};

  // Add the parent manager.
  if (role.__manager) {
    managerProperties.moved = {
      manager: {
        from: role.__manager,
      },
    };
  }

  // Add the parent group.
  if (role.group_uuid) {
    managerProperties.moved = {
      ...managerProperties.moved,
      group: {
        from: role.group_uuid,
      },
    };
  }

  const action = {
    type: ActionType.REMOVE_MANAGERS,
    [EntityType.MANAGER]: new Map([
      [
        role.uuid,
        {
          metrics,
          props: { ...managerProperties },
        },
      ],
    ]),
    metrics: defaultMetrics({ comparisonScenario }),
  };

  action.metrics[1].managers -= 1;

  return action;
}
