import { memo } from 'react';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { ChangeMetric } from '@/molecules/metrics';
import { OutlineCard } from '@/organisms/cards';
import { PlanChip, planEntityColors } from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { useTagContext, useViewModeContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { determineChartType, determineMetric } from './utils';

const PlanActionCard = ({
  title,
  entityType = EntityType.ROLE,
  actionType,
  isSelected,
  metrics,
  properties,
  onEdit,
  onRemove,
}) => {
  const { tagMap } = useTagContext();
  const { showBudget } = useViewModeContext();
  const cardMetric = determineMetric({
    actionType,
    entityType,
    metrics,
    showBudget,
  });

  const actionProperties = { ...properties };

  if (cardMetric.tagId) {
    actionProperties.props = {
      ...actionProperties.props,
      id: cardMetric.tagId,
    };
  }

  const chartType = determineChartType(cardMetric.metricType);

  const colors = planEntityColors({
    actionProperties,
    actionType,
    entityType,
    metric: cardMetric.metricType,
    tagMap,
  });

  return (
    <Stack direction="row" alignItems="center" width="100%">
      <Box width="136px" mr={1}>
        <ChangeMetric
          chartType={chartType}
          colors={colors}
          entityType={entityType}
          metric={cardMetric.metricType}
          metrics={cardMetric.metrics}
        />
      </Box>
      <Box flexGrow={1}>
        <OutlineCard padding={0} margin={0}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            p={1.5}
            spacing={1}
            sx={{
              boxShadow: isSelected
                ? `inset 0 0 0 1px ${colors.primary}, 0 0 0 1px ${colors.primary}`
                : 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              transition: 'box-shadow 200ms',
              '&: hover': !isSelected && {
                boxShadow: `inset 0 0 0 1px ${colors.secondary}, 0 0 0 1px ${colors.secondary}`,
              },
            }}
          >
            <Box>
              <PlanChip
                actionType={actionType}
                entityType={entityType}
                properties={properties}
              />
              <Heading
                variant="h4"
                overrideStyles={{ fontSize: '1.025rem', mb: 0, mt: 0.5 }}
              >
                {title}
              </Heading>
            </Box>
            <Button
              label="View"
              color="light-blue"
              size={Size.SMALL}
              onClick={onEdit}
            />
          </Stack>
        </OutlineCard>
      </Box>
    </Stack>
  );
};

export default memo(PlanActionCard, (previous, next) => {
  return previous?.isSelected === next?.isSelected;
});
