import { Paragraph, Heading } from '@/atoms/typography';
import { InformationTip } from '@/molecules/modals';
import Stack from '@mui/material/Stack';

const titleWidth = ({ showDaysPerMonth, showMenu, showRemove }) => {
  let pixels = 0;

  if (showDaysPerMonth) {
    pixels += 148;
  }

  if (showMenu) {
    pixels += 32;
  }

  if (showRemove) {
    pixels += 28;
  }

  return `calc(100% - ${pixels}px)`;
};

const SurveyHeading = ({ TagComponent, settings, children }) => {
  const { showDaysPerMonth, showDragDrop, showRemove, showMenu } = settings;
  const width = titleWidth({ showDaysPerMonth, showRemove, showMenu });

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="flex-start"
    >
      <Stack direction="row" width={width}>
        {children}
      </Stack>
      {showDaysPerMonth && (
        <Stack direction="row" alignItems="center">
          <Heading
            variant="h6"
            overrideStyles={{
              fontWeight: 600,
              mb: 0,
              ml: showDragDrop ? 4 : 0,
              textTransform: 'uppercase',
            }}
          >
            Equivalent to
          </Heading>
          <InformationTip>
            <Paragraph overrideStyles={{ mb: 0 }}>
              This number reflects the equivalent days per month for each
              activity’s hours
            </Paragraph>
          </InformationTip>
        </Stack>
      )}
    </Stack>
  );
};

export default SurveyHeading;
