import FlexSearch from 'flexsearch';
import { produce } from 'immer';
import { create } from 'zustand';
import { Colors, Obj, Sort } from '@pkg/utils';

const ScopedMap = Object.freeze({ list: [], mapped: new Map() });
const InitialIndex = new FlexSearch.Index({
  tokenize: 'forward',
  context: true,
});

/**
 * @param {Index} index
 * @param {Map} mapped
 * @returns {Index}
 */
const updateIndex = (index, mapped) => {
  if (!Obj.isEmpty(index.register)) {
    for (const key in index.register) {
      if (!mapped.has(key)) {
        index.remove(key);
      }
    }
  }

  mapped.forEach(({ name }, key) => {
    if (!index.register?.[key]) {
      index.add(key, name);
    }
  });

  return index;
};

/**
 * @param {Object[]} list
 * @param {Index} index
 * @returns {Object}
 */
export const storeProperties = (list, index = InitialIndex) => {
  const mapped = new Map();
  const scoped = new Map();
  const names = {
    default: new Map(),
    lower: new Map(),
  };

  const sorted = structuredClone(list).sort(Sort.alpha('name'));
  sorted.forEach((item) => {
    const id = item.uuid;
    const name = item.name;

    item.__options = new Map(item.__options);
    item.options.sort(Sort.order());
    item.options.forEach(({ uuid, value }) => {
      item.__options.set(uuid, { uuid, name: value, value });
    });

    const color = Colors.seeded(id);
    item.__color = Colors.tag({ color, state: null });

    mapped.set(id, item);
    names.default.set(name, id);
    names.lower.set(name.toLowerCase(), id);

    if (!scoped.has(item.scope)) {
      scoped.set(item.scope, structuredClone(ScopedMap));
    }

    scoped.get(item.scope).list.push(item);
    scoped.get(item.scope).mapped.set(id, item);
  });

  return Object.freeze({
    index: updateIndex(index, mapped),
    list: sorted,
    mapped,
    names,
    scoped,
  });
};

/**
 * @type {Function}
 * @param {Function} [getter]
 * @returns {any}
 */
const useStore = create((set, get) => ({
  index: InitialIndex,
  isLoading: true,
  list: [],
  mapped: new Map(),
  mutations: {},
  names: {
    lower: new Map(),
    default: new Map(),
  },
  setLoading: (isLoading) => {
    console.debug('LibraryPropertiesStore.set', { isLoading });
    return set(() => ({ isLoading }));
  },
  setMutations: (mutations) => {
    console.debug('LibraryPropertiesStore.set', { mutations });
    return set(() => ({ mutations }));
  },
  setProperties: (list) => {
    console.debug('LibraryPropertiesStore.store', { list });
    return set(({ index }) => storeProperties(list, index));
  },

  /**
   * @param {Object} input
   * @param {String} input.uuid
   * @param {String} input.name
   * @param {String} input.type
   * @param {String} input.scope
   * @param {String} input.options
   * @returns {void}
   */
  add: (input) => {
    console.debug('LibraryPropertiesStore.add', { input });
    return set(
      produce((state) => {
        // setup new property
        const property = structuredClone(input);
        const name = property.name;
        const id = property.uuid;

        property.__options = new Map();
        property.options = property.options ?? [];
        property.options.sort(Sort.order());
        property.options.forEach(({ uuid, value }) => {
          property.__options.set(uuid, { uuid, name: value });
        });

        const color = Colors.seeded(id);
        property.__color = Colors.tag({ color, state: null });

        // update map and indexes
        const frozen = Object.freeze(property);
        state.names.default.set(id, name);
        state.names.lower.set(id, name.toLowerCase());
        state.index.add(id, name);
        state.mapped.set(id, frozen);

        // append list
        state.list.push(frozen);
        state.list.sort(Sort.alpha('name'));

        // add to scoped
        const scoped = new Map(state.scoped);
        const scope = scoped.get(property.scope) ?? structuredClone(ScopedMap);
        scope.list.push(frozen);
        scope.mapped.set(id, frozen);
        state.scoped.set(property.scope, scope);
      })
    );
  },

  /**
   * @param {String} name
   * @param {Boolean} strict
   * @returns {String|null}
   */
  getExisting: (name, strict = false) => {
    const map = get().names[strict ? 'default' : 'lower'];
    const value = strict ? name : name.toLowerCase();
    return map.get(value.trim()) ?? null;
  },
}));

export default useStore;
