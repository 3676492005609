import { tool } from 'ai';
import { z } from 'zod';

const activityArchive = tool({
  description: 'Archive one or more activities in the organizational design',
  parameters: z.object({
    payload: z
      .object({
        ids: z
          .array(
            z.string().describe('9-digit UUIDs of the activities to archive')
          )
          .describe('Array of activity UUIDs to archive'),
      })
      .describe('The payload for archiving activities'),
    explanation: z
      .string()
      .describe(
        'An explanation of what activities are being archived and their impact on roles'
      ),
  }),
  execute: async ({ payload, explanation }) => ({
    payload,
    explanation,
  }),
});

export default activityArchive;
