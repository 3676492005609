import { ActionType, EntityType } from '@/shared/enums';
import defaultMetricGroup from '@pkg/entities/snapshots/utils/defaultMetricGroup';

export default function defaultMetrics({
  activity,
  action,
  comparisonScenario,
  role,
  tag,
  includeTags = false,
  includeSpans = false,
}) {
  let metricGroup = defaultMetricGroup(includeTags);
  const isOrganisationScenario =
    comparisonScenario?.entity?.__type === EntityType.ORGANISATION;

  if (includeSpans) {
    metricGroup.span = 0;
  }

  // If we don't have any entities provided, we want the root level metrics.
  if (!activity && !role && !tag) {
    const metrics = isOrganisationScenario
      ? comparisonScenario?.entity?.__metrics?.visible
      : comparisonScenario?.entity?.__metrics?.self?.visible;

    if (includeSpans && !isOrganisationScenario) {
      metrics.span =
        comparisonScenario?.entity?.__metrics?.span?.visible?.roles;
    }

    if (metrics) {
      metricGroup = {
        ...metrics,
      };
    }
  }

  // If a tag object has been provided we want tag metrics.
  if (tag) {
    let metrics;

    // When we aren't specifically wanting metrics for an entity, we simply
    // return the tag metrics for the comparison entity.
    if (!role && !activity) {
      metrics =
        comparisonScenario?.entity?.__metrics?.visible?.tags?.[tag.type]?.[
          tag.id
        ];
    }

    // Capture activity tag metrics if an activity has been provided.
    if (activity) {
      const comparisonActivity = comparisonScenario.relationships
        ?.get(EntityType.ACTIVITY)
        ?.get(activity?.uuid);

      metrics =
        comparisonActivity?.__metrics?.self?.visible?.tags?.[tag.type]?.[
          tag.id
        ];
    }

    // Capture role tag metrics if a role has been provided.
    if (!activity && role) {
      const comparisonRole = comparisonScenario.relationships
        ?.get(EntityType.ROLE)
        ?.get(role?.uuid);

      metrics =
        comparisonRole?.__metrics?.self?.visible?.tags?.[tag.type]?.[tag.id];
    }

    if (metrics) {
      metricGroup = {
        ...metrics,
      };
    }

    return [
      {
        ...metricGroup,
        assigned: 0,
        moved: 0,
        affectedRoles: 0,
      },
      {
        ...metricGroup,
        assigned: 0,
        moved: 0,
        affectedRoles: 0,
      },
    ];
  }

  // If we have provided an activity, capture comparison activity metrics.
  if (activity) {
    const comparisonActivity = comparisonScenario.relationships
      ?.get(EntityType.ACTIVITY)
      ?.get(activity?.uuid);

    metricGroup.activities = comparisonActivity ? 1 : 0;
    metricGroup.hours = comparisonActivity?.hours || 0;

    // We want to increment the activity count if this action is removing
    // an activity.
    if (
      action?.type === ActionType.REMOVED_ACTIVITY ||
      action?.type === ActionType.REMOVE_ACTIVITIES
    ) {
      metricGroup.activities = 1;
    }

    if (includeTags) {
      metricGroup.tags = comparisonActivity?.__metrics?.self?.visible?.tags;
    }
  }

  // If we have provided a role, capture comparison role metrics.
  if (!activity && role) {
    const comparisonRole = comparisonScenario.relationships
      ?.get(EntityType.ROLE)
      ?.get(role?.uuid);

    const metrics = comparisonRole?.__metrics?.self?.visible;

    metricGroup = {
      ...metrics,
    };

    if (includeSpans) {
      metricGroup.span = comparisonRole?.__metrics?.span?.visible.roles || 0;
    }
  }

  return [
    {
      ...defaultMetricGroup(includeTags),
      assigned: 0,
      moved: 0,
      affectedRoles: 0,
      ...metricGroup,
    },
    {
      ...defaultMetricGroup(includeTags),
      assigned: 0,
      moved: 0,
      affectedRoles: 0,
      ...metricGroup,
    },
  ];
}
