import { useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Base as BaseColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import { SelectField, TextField } from '@/molecules/formElements';
import { ConfirmationDialog } from '@/molecules/modals';
import { BulkEditList, BulkEditChanges } from '@/organisms/bulkEdits';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import SaveIcon from '@mui/icons-material/SaveRounded';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Library } from '@pkg/entities';
import deriveSnapshot from '@pkg/entities/snapshots/derive/snapshot';
import localMutate from '@pkg/entities/snapshots/mutate';
import { bulk as bulkActivities } from '@pkg/entities/snapshots/reducers/activities';
import { bulk as bulkRoles } from '@pkg/entities/snapshots/reducers/roles';
import { Collections } from '@pkg/utils';
import { BulkOperators, DesignEntity, DesignLevel } from '@/lib/enums';
import { brand } from '@/lib/theme/tokens/palettes';
import useDesignStore from '../DesignContainer/hooks/useDesignStore';

const entityTypes = [
  { id: 'ROLE', label: 'Role', value: 'ROLE' },
  { id: 'ACTIVITY', label: 'Activity', value: 'ACTIVITY' },
];

const entityTypeProperties = {
  ROLE: [
    { id: 'FTE', value: 'fte', label: 'FTE' },
    { id: 'Budget', value: 'budget', label: 'Budget' },
  ],
  ACTIVITY: [{ id: 'Hours', value: 'hours', label: 'Hours' }],
};

const operators = [
  {
    id: BulkOperators.SET,
    label: 'Set Value',
    value: BulkOperators.SET,
  },
  {
    id: BulkOperators.INCREASE,
    label: 'Increase',
    value: BulkOperators.INCREASE,
  },
  {
    id: BulkOperators.INCREASE_PERCENTAGE,
    label: 'Increase Percentage',
    value: BulkOperators.INCREASE_PERCENTAGE,
  },
  {
    id: BulkOperators.DECREASE,
    label: 'Decrease',
    value: BulkOperators.DECREASE,
  },
  {
    id: BulkOperators.DECREASE_PERCENTAGE,
    label: 'Decrease Percentage',
    value: BulkOperators.DECREASE_PERCENTAGE,
  },
];

const entityLabels = {
  ACTIVITY: 'activities',
  ROLE: 'roles',
};

const BulkEditModal = ({ opened, onClose }) => {
  const [entityType, setEntityType] = useState(entityTypes[1]);
  const [property, setProperty] = useState(
    entityTypeProperties[DesignEntity.ACTIVITY][0]
  );
  const [operator, setOperator] = useState(operators[0]);
  const [value, setValue] = useState(0);

  const { scenario } = useScenarioContext();
  const snapshot = useDesignStore((state) => state.snapshot);
  const actions = useDesignStore((state) => state.actions);
  const level = useDesignStore((state) => state.level);
  const library = Library.useLibrary();

  // dropdown options
  const entityTypesOptions = entityTypes.map((entity) => ({
    id: entity.id,
    label: entity.label,
    value: entity.value,
  }));

  const operatorOptions = operators.map((operator) => ({
    id: operator.value,
    label: operator.label,
    value: operator.value,
  }));

  const handleUpdateEntity = (selected) => {
    setEntityType(selected);
    setProperty(entityTypeProperties[selected.id][0]);
  };

  const handleUpdateProperty = (selected) => {
    setProperty(selected);
  };

  const handleUpdateOperator = (selected) => {
    setOperator(selected);
  };

  const handleUpdateValue = (event, value) => {
    setValue(value);
  };

  const entityTypePropertiesOptions = entityTypeProperties[entityType.id];

  // uuids
  const entities = scenario?.relationships.get(entityType.value);
  const uuids = Array.from(entities?.values() ?? []).map((v) => v.uuid);

  // handle bulk update
  const onBulkEdit = () => {
    const realEntityType = DesignEntity.toPlural(entityType.value);
    actions.dispatch(`${realEntityType}.bulk`, {
      uuids,
      operator: operator.value,
      property: property.value,
      value: parseFloat(value),
    });
    onClose();
  };

  // derive what the bulk mutation will do
  let derivedUpdatedSnapshot = null;
  let originalPropertyValue = null;
  let updatedPropertyValue = null;

  if (snapshot) {
    // calculate the bulk mutation locally
    const bulkMutation =
      entityType.value === DesignEntity.ROLE ? bulkRoles : bulkActivities;
    const mutation = bulkMutation(snapshot, {
      uuids,
      property: property.value,
      operator: operator.value,
      value: parseFloat(value),
    });

    const updatedSnapshot = localMutate(snapshot, mutation);
    derivedUpdatedSnapshot = deriveSnapshot({
      library,
      snapshot: updatedSnapshot,
      includeHierarchy: false,
    });

    // set the total difference of concerned property after local mutate
  }

  let entitiesArray = Array.from(entities?.values() ?? []);

  if (snapshot && derivedUpdatedSnapshot) {
    let entity = snapshot;
    let updatedEntity = derivedUpdatedSnapshot;

    if (level.type === DesignLevel.GROUP) {
      entity = Collections.findById(snapshot.entities.groups, level.uuid);
      updatedEntity = Collections.findById(
        derivedUpdatedSnapshot.entities.groups,
        level.uuid
      );
    }

    if (level.type === DesignLevel.ROLE) {
      entity = Collections.findById(snapshot.entities.roles, level.uuid);
      updatedEntity = Collections.findById(
        derivedUpdatedSnapshot.entities.roles,
        level.uuid
      );
    }

    originalPropertyValue = entity.__total_metrics[property.value];
    updatedPropertyValue = updatedEntity.__total_metrics[property.value];

    if (derivedUpdatedSnapshot) {
      //map over entities array and add new updated property value for the entity type
      entitiesArray = entitiesArray.map((entity) => {
        const updatedEntity = derivedUpdatedSnapshot.entities[
          DesignEntity.toPlural(entityType.value)
        ].find((e) => e.uuid === entity.uuid);
        return {
          id: entity.uuid,
          description: entity.__description || entity.title,
          oldValue: entity[property.value],
          newValue: updatedEntity[property.value],
        };
      });
    }
  }

  return (
    <>
      <div
        style={{
          display: 'block',
          position: 'fixed',
          height: 'calc(100vh - 93px)',
          top: '93px',
          left: 0,
          width: '100%',
          background: '#fff',
          visibility: opened ? 'visible' : 'hidden',
          color: BaseColors.font.tertiary,
          zIndex: 1,
        }}
      >
        <Box>
          <Grid container sx={{ mt: 0 }}>
            <Grid item xs={8} sx={{ pt: '24px !important', px: 4 }}>
              <Stack justifyContent="space-between" direction="row">
                <Heading
                  variant="h1"
                  overrideStyles={{ fontWeight: 600, mb: 2 }}
                >
                  Make bulk changes
                </Heading>
                <Button
                  label="Close"
                  color="light-blue"
                  sx={{ mt: 2 }}
                  onClick={onClose}
                />
              </Stack>

              <Paragraph overrideStyles={{ mb: 3 }}>
                The following{' '}
                <span style={{ fontWeight: 600 }}>
                  {uuids ? uuids.size : 0}
                </span>{' '}
                {entityLabels[entityType.value]} have been included for bulk{' '}
                editing. If you wish to change which{' '}
                {entityLabels[entityType.value]} are included, you can do so{' '}
                using the <span style={{ fontWeight: 600 }}>Filters</span>{' '}
                button above.
              </Paragraph>
              <Stack direction="row" alignItems="end" spacing={1} mb={3}>
                <SelectField
                  label="Edit"
                  initialValue={entityType}
                  options={entityTypesOptions}
                  optionLabel="label"
                  onChange={handleUpdateEntity}
                  width={120}
                  size={Size.LARGE}
                />
                <SelectField
                  label="Property"
                  initialValue={property}
                  options={entityTypePropertiesOptions}
                  optionLabel="label"
                  onChange={handleUpdateProperty}
                  width={180}
                  size={Size.LARGE}
                />
                <SelectField
                  label="Operator"
                  initialValue={operator}
                  options={operatorOptions}
                  optionLabel="label"
                  onChange={handleUpdateOperator}
                  width={180}
                  size={Size.LARGE}
                />
                <TextField
                  label="Value"
                  debounce={300}
                  initialValue={0}
                  width={80}
                  name="value"
                  inputProps={{ type: 'number' }}
                  onChange={handleUpdateValue}
                  size={Size.LARGE}
                />
                <ConfirmationDialog
                  Component={
                    <Button
                      boldText
                      color="secondary"
                      label="Save changes"
                      onClick={onBulkEdit}
                      size={Size.LARGE}
                      startIcon={<SaveIcon />}
                    />
                  }
                  title="Are you sure you want to apply these changes?"
                  content={`You will be changing these activities permanantely.`}
                  onConfirm={onBulkEdit}
                  confirmLabel="Apply changes"
                />
              </Stack>
              <BulkEditList
                entities={entitiesArray}
                entityType={entityLabels[entityType.value]}
                propertyType={property.value}
                height={360}
              />
            </Grid>
            <Grid item xs={4}>
              <Stack
                height="calc(100vh - 80px)"
                sx={{
                  boxShadow: `-2px 0 0 ${brand.lightBlue.tints[4]}`,
                }}
                px={2}
                pt={4}
              >
                <BulkEditChanges
                  originalValue={originalPropertyValue}
                  updatedValue={updatedPropertyValue}
                  property={property}
                  barChartWidth={260}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default BulkEditModal;
