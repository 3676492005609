import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

export default function removedGroupsAction({
  actionMap,
  comparisonScenario,
  group,
}) {
  const metrics = {
    ...group.__metrics.self.visible,
  };

  const existingAction = actionMap?.get(ActionType.REMOVE_GROUPS);

  const action = existingAction ?? {
    entity: EntityType.GROUP,
    metrics: defaultMetrics({ comparisonScenario }),
    type: ActionType.REMOVE_GROUPS,
    [EntityType.GROUP]: new Map(),
  };

  action.metrics[1].groups -= 1;
  action.metrics[1].affectedRoles += metrics.roles;

  // Aggregate the individual group action metrics.
  const actionGroup = action[EntityType.GROUP].get(group.uuid) ?? {
    metrics: defaultMetrics({ comparisonScenario }),
  };

  const groupProperties = {};

  // If moved between parents.
  if (group.__parent?.uuid) {
    groupProperties.moved = {
      ...groupProperties.moved,
      parent: {
        from: group.__parent,
      },
    };
  }

  actionGroup.props = { ...groupProperties };
  actionGroup.metrics[1].groups -= 1;
  actionGroup.metrics[1].affectedRoles += metrics.roles;
  action[EntityType.GROUP].set(group.uuid, actionGroup);

  const groupCount = action[EntityType.GROUP].size;
  const roleCount = action.metrics[1].affectedRoles;
  const roleLabel = entityLabel(EntityType.ROLE, roleCount !== 1);
  const groupLabel = entityLabel(EntityType.GROUP, groupCount !== 1);
  const verb = groupCount === 1 ? 'was' : 'were';

  action.title = `${groupCount} ${groupLabel} ${verb} removed affecting ${roleCount} roles`;

  return action;
}
