import { percent } from '@pkg/utils/numbers';
import config from '@/lib/config';
import defaultMetricGroup from '../utils/defaultMetricGroup';
import updateMetrics from './shared/updateMetrics';
import updateRootMetrics from './shared/updateRootMetrics';

function calculateVisibleMetric({ activity, role, factor, metric }) {
  const isVisibleRole = role?.__visibility === config.VISIBILITY.FULL;

  if (!isVisibleRole) {
    return 0;
  }

  if (!activity) {
    return role[metric];
  }

  const isVisibleActivity = activity?.__visibility === config.VISIBILITY.FULL;

  if (!isVisibleActivity) {
    return 0;
  }

  const activityPercentage = activity
    ? !activity.hours
      ? 0
      : percent(activity.hours, role.__hours, 8) / 100
    : 100;

  return role[metric] * factor;
}

/**
 * Returns the incremental metrics to be added to the visible metrics.
 *
 * @param {Object}
 *
 * @return {Object}
 */
function getVisibleIncrementMetrics({
  activity,
  group,
  groupMetrics,
  isVisibleActivity,
  isVisibleGroup,
  isVisibleRole,
  role,
}) {
  const factor = activity
    ? !activity.hours || (isVisibleRole && !role.__hours)
      ? 0
      : percent(activity.hours, role?.__hours || activity.hours, 8) / 100
    : 1;

  const visibleBudget = calculateVisibleMetric({
    activity,
    role,
    factor,
    metric: 'budget',
  });

  const visibleFTE = calculateVisibleMetric({
    activity,
    role,
    factor,
    metric: 'fte',
  });

  const visibleMetrics = group.__metrics.self.visible;

  const visibleIncrement = {
    ...defaultMetricGroup(),
    budget: visibleBudget,
    fte: visibleFTE,
    roles: isVisibleRole ? groupMetrics.roles : 0,
    activities: isVisibleActivity ? groupMetrics.activities : 0,
    hours: isVisibleActivity ? groupMetrics.hours : 0,
  };

  if (isVisibleActivity || isVisibleRole || isVisibleGroup) {
    visibleIncrement.groups = visibleMetrics.groups === 0 ? 1 : 0;
  }

  return visibleIncrement;
}

/**
 * Derives metrics for the group.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function groupMetrics({
  activity,
  role,
  roles,
  group,
  groups,
  metrics,
}) {
  const isNewGroup = groups?.has(group.uuid);
  const isVisibleGroup = group.__visibility === config.VISIBILITY.FULL;
  const isNewRole = role && roles?.has(role?.uuid);
  const isVisibleRole = role?.__visibility === config.VISIBILITY.FULL;
  const isActivityDisabled = Boolean(activity?.disabled_at);
  const isVisibleActivity = activity?.__visibility === config.VISIBILITY.FULL;

  // Capture metrics for this group.
  const groupMetrics = {
    ...defaultMetricGroup(false),
    budget: isNewRole ? role.budget : 0,
    fte: isNewRole ? role.fte : 0,
    managers: isNewRole && role.is_manager ? 1 : 0,
    roles: isNewRole ? 1 : 0,
    groups: isNewGroup ? 1 : 0,
    activities: !activity || isActivityDisabled ? 0 : 1,
    hours: !activity || isActivityDisabled ? 0 : activity.hours,
  };

  // Aggregate this group's metrics with the total metrics.
  const total = updateMetrics({
    target: isNewGroup
      ? defaultMetricGroup()
      : { ...group.__metrics.self.total },
    source: {
      ...groupMetrics,
    },
  });

  const visibleIncrementMetrics = getVisibleIncrementMetrics({
    activity,
    group,
    groupMetrics,
    isVisibleActivity,
    isVisibleRole,
    isVisibleGroup,
    role,
  });

  // Aggregate this group's metrics with the visible metrics.
  const visible = isVisibleGroup
    ? updateMetrics({
        target: isNewGroup
          ? defaultMetricGroup()
          : { ...group.__metrics.self.visible },
        source: {
          ...visibleIncrementMetrics,
        },
      })
    : isNewGroup
      ? { ...defaultMetricGroup() }
      : { ...group.__metrics.self.visible };

  // If this is a new group, aggregate the root metrics with this group data.
  if (isNewGroup) {
    updateRootMetrics({
      entityMetrics: {
        ...defaultMetricGroup(),
        groups: isNewGroup ? 1 : 0,
      },
      visibleMetrics: {
        ...defaultMetricGroup(),
        groups: visibleIncrementMetrics.groups,
      },
      metrics,
    });
  }

  group.__metrics.self.total = total;
  group.__metrics.self.visible = visible;

  return { total, visible };
}
