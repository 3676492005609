import { Fragment, useEffect, useRef, useState } from 'react';
import { Chip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import {
  PlanActivityCard,
  PlanGroupCard,
  PlanManagerCard,
  PlanRoleCard,
} from '@/organisms/cards';
import { isTagActionType } from '@/organisms/plans';
import { planEntityColors } from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { useOutsideClick } from '@/shared/hooks';
import { usePlanContext, useTagContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';
import { core } from '@/lib/theme/tokens/palettes';
import groupTagActions from './utils/groupTagActions';

const entityTypeFromProperties = (properties) => {
  if (properties.activity) {
    return EntityType.ACTIVITY;
  }
  if (properties.group) {
    return EntityType.GROUP;
  }
  if (properties.role?.is_manager) {
    return EntityType.MANAGER;
  }
  return EntityType.ROLE;
};

const PlanActionType = ({ onSelect, action }) => {
  const { tagMap } = useTagContext();
  const [actionCategories, setActionCategories] = useState();
  const entityType = action.entity;
  const { selectedSummaryItem, selectSummaryItem } = usePlanContext();
  const actions = new Map([[action.type, {}]]);
  const ref = useRef();

  const colors = planEntityColors({
    entityType: action.entity,
  });

  const tag = isTagActionType(selectedSummaryItem?.actionType)
    ? tagMap.get(selectedSummaryItem?.props?.id)
    : null;
  const tagProperties = tag ? { id: tag.uuid, name: tag.name } : null;

  /**
   * Handles the default selection of the action type screen.
   */
  const handleSelectDefault = (event) => {
    event?.preventDefault();
    event?.stopPropagation();

    // Don't trigger an update if we're already on the default view.
    if (selectedSummaryItem?.id === action.id) {
      return;
    }

    selectSummaryItem({
      ...action,
      actions,
    });
  };

  const handleSelect = (selectedAction) => {
    if (!selectedAction || selectedAction.id === selectedSummaryItem?.id) {
      handleSelectDefault();
      return;
    }

    selectSummaryItem({
      ...selectedAction,
      actionType: action.actionType,
      actions,
      entityType: entityTypeFromProperties(selectedAction.props),
    });
  };

  useEffect(() => {
    if (!action?.[action?.entity]?.size) {
      return;
    }

    setActionCategories(groupTagActions(action));
  }, [action?.[action?.entity]?.size]);

  // Handles the click events triggered outside of the action type cards which
  // should default the view back to the action type screen.
  useOutsideClick({
    ref,
    onClick: handleSelectDefault,
    updateProperty: selectedSummaryItem?.id,
  });

  return (
    actionCategories && (
      <Grid container spacing={2} p={2} onClick={handleSelectDefault} ref={ref}>
        {actionCategories.map((category, index) => (
          <Fragment key={`${category?.type}-${index}`}>
            {category.title && (
              <Grid item xs={12}>
                <div style={{ marginLeft: '146px' }}>
                  <Chip
                    id={category.title}
                    name={category.title.toUpperCase()}
                    colorProps={{
                      normal: {
                        backgroundColor: colors.primary,
                        color: core.white,
                      },
                    }}
                    size={Size.X_SMALL}
                  />
                </div>
              </Grid>
            )}
            {category.actions.map((entity, index) => (
              <Grid item xs={12} key={entity.id}>
                {entityType === EntityType.GROUP && (
                  <PlanGroupCard
                    actionType={action.actionType}
                    groupProperties={tagProperties || entity.props}
                    id={entity.id}
                    key={entity.id}
                    metrics={entity.metrics}
                    onSelect={handleSelect}
                    isSelected={entity.id === selectedSummaryItem?.id}
                  />
                )}
                {entityType === EntityType.MANAGER && (
                  <PlanManagerCard
                    actionType={action.actionType}
                    id={entity.id}
                    key={entity.id}
                    metrics={entity.metrics}
                    onSelect={handleSelect}
                    roleProperties={tagProperties || entity.props}
                    isSelected={entity.id === selectedSummaryItem?.id}
                  />
                )}
                {entityType === EntityType.ROLE && (
                  <PlanRoleCard
                    actionType={action.actionType}
                    id={entity.id}
                    key={entity.id}
                    metrics={entity.metrics}
                    onSelect={handleSelect}
                    roleProperties={tagProperties || entity.props}
                    isSelected={entity.id === selectedSummaryItem?.id}
                  />
                )}
                {entityType === EntityType.ACTIVITY && (
                  <PlanActivityCard
                    actionType={action.actionType}
                    activityProperties={tagProperties || entity.props}
                    id={entity.id}
                    metrics={entity.metrics}
                    onSelect={handleSelect}
                    isSelected={entity.id === selectedSummaryItem?.id}
                  />
                )}
              </Grid>
            ))}
          </Fragment>
        ))}
      </Grid>
    )
  );
};

export default PlanActionType;
