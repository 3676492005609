const metricDifference = (metric, action) => {
  if (typeof action.metrics[1][metric] === 'undefined') {
    return 0;
  }
  return action.metrics[1][metric] - action.metrics[0][metric];
};

export default function cumulateMetrics(action, metrics, ignoreUnchanged) {
  let total = 0;

  const activities = metricDifference('activities', action);
  total += activities;

  const assigned = metricDifference('assigned', action);
  total += assigned;

  const budget = metricDifference('budget', action);
  total += budget;

  const fte = metricDifference('fte', action);
  total += fte;

  const managers = metricDifference('managers', action);
  total += managers;

  const groups = metricDifference('groups', action);
  total += groups;

  const hours = metricDifference('hours', action);
  total += hours;

  const roles = metricDifference('roles', action);
  total += roles;

  const moved = metricDifference('moved', action);
  total += moved;

  const span = metricDifference('span', action);
  total += span;

  if (ignoreUnchanged && total === 0) {
    return;
  }

  const updatedMetrics = {
    activities: metrics.activities + activities,
    assigned: metrics.assigned + assigned,
    budget: metrics.budget + budget,
    fte: metrics.fte + fte,
    managers: metrics.managers + managers,
    groups: metrics.groups + groups,
    hours: metrics.hours + hours,
    roles: metrics.roles + roles,
    moved: metrics.moved + moved,
  };

  if (typeof metrics.span === 'number') {
    updatedMetrics.span = metrics.span + span;
  }

  return updatedMetrics;
}
