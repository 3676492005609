import getSummarised from '@pkg/entities/tags/get/summarised';
import { Num } from '@pkg/utils';
import { DesignEntity, Visibility } from '@/lib/enums';

/**
 * Normalizes default role properties.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function normalizeRole({ library, person, role, snapshot }) {
  if (!role || !library || !snapshot) {
    return;
  }

  role.properties = role.properties ?? [];
  role.skills = role.skills ?? [];
  role.tags = role.tags ?? [];

  role.budget = Number.parseInt(role.budget);
  role.budget = Number.isFinite(role.budget) ? role.budget : null;
  const hasBudget = Number.isFinite(role.budget);

  role.fte = Num.roundFloat(role.fte);
  role.fte = Number.isFinite(role.fte) ? role.fte : null;
  const hasFte = Number.isFinite(role.fte);

  role.__depth = role.__above ? role.__above[DesignEntity.ROLE].size + 1 : 1;

  // Role assignee.
  role.__person = person
    ? {
        ...person,
        __assigned: {
          [DesignEntity.ROLE]: new Set([role.uuid]),
          [DesignEntity.MANAGER]: new Set(role.is_manager ? [role.uuid] : []),
        },
      }
    : null;

  // Skills.
  role.__skill_map = new Map();
  role.skills.forEach(({ uuid, level }) => role.__skill_map.set(uuid, level));

  // Tags.
  role.__tags_set = new Set(role.tags);
  role.__tags_summarised = getSummarised(library.tags.tags, role.tags);

  // Properties.
  const properties = role.properties ?? [];
  role.__properties_map = new Map(
    properties.map(({ key, value }) => [key, value])
  );

  role.__type = DesignEntity.ROLE;
  role.__visibility = role.__visibility ?? Visibility.FULL;

  return role;
}
