import { Num } from '@pkg/utils';
import { DesignEntity, Visibility } from '@/lib/enums';
import applyFilter from '../filters/apply';
import make from '../make';
import defaultSnapshotMetrics from '../utils/defaultSnapshotMetrics';
import deriveHierarchy from '../utils/deriveHierarchy';
import getFlattened from '../utils/getFlattened';
import getKeyed from '../utils/getKeyed';
import deriveEntities from './deriveEntities';
import roundFloatMetrics from './shared/roundFloatMetrics';
import spanMetrics from './spanMetrics';

function filterConditionsExist(filterConditions) {
  if (!filterConditions) {
    return;
  }

  const emptyConditions = Object.values(filterConditions).every(
    (entities) => entities.length === 0
  );

  return !emptyConditions;
}

/**
 * @param {Object} snapshot
 * @param {Object} library
 * @return {Object}
 */
export default function deriveSnapshot({
  filterConditions,
  library,
  skillMap,
  snapshot,
  includeMake = true,
  includeKeyed = true,
  includeHierarchy = true,
  includeMetrics = true,
  includeTagMetrics = true,
  flatten = true,
}) {
  // setup
  console.groupCollapsed('Snapshots.deriveProps');
  console.time('Snapshots.deriveProps');

  let derived = snapshot;
  const isFiltered = filterConditionsExist(filterConditions);

  if (includeMake) {
    console.time('Snapshots.deriveProps.make');
    derived = make(snapshot);
    console.timeEnd('Snapshots.deriveProps.make');
  }

  if (includeKeyed) {
    console.time('Snapshots.deriveProps.utils.getKeyed');
    derived = getKeyed(derived, false);
    console.timeEnd('Snapshots.deriveProps.utils.getKeyed');
  }

  if (includeHierarchy) {
    console.time('Snapshots.deriveProps.utils.getHierarchy');
    derived = deriveHierarchy({ snapshot: derived });
    console.timeEnd('Snapshots.deriveProps.utils.getHierarchy');
  }

  // If conditions have been provided we set a visibility property on each
  // entity to be used when calculating metrics.
  if (filterConditions) {
    console.time('Snapshots.deriveProps.filter');
    derived = applyFilter(derived, filterConditions, skillMap);
    console.timeEnd('Snapshots.deriveProps.filter');
  }

  if (includeMetrics && library) {
    const metrics = new Map();
    metrics.set('*', defaultSnapshotMetrics());

    console.time('Snapshots.deriveProps.entities.derive.metrics');
    derived = deriveEntities({
      includeTagMetrics,
      isFiltered,
      metrics,
      library,
      snapshot: derived,
    });
    console.timeEnd('Snapshots.deriveProps.entities.derive.metrics');

    console.time('Snapshots.deriveProps.entities.derive.spans');
    derived = spanMetrics(derived);
    console.timeEnd('Snapshots.deriveProps.entities.derive.spans');

    // finalize
    console.time('Snapshots.deriveProps.totals');
    const root = roundFloatMetrics(metrics.get('*'), false);
    derived.__metrics = root;
    derived.__percentage = null;
    derived.__type = DesignEntity.ORGANISATION;
    derived.__visibility = derived.__visibility ?? Visibility.FULL;

    if (Number.isFinite(root.total.hours)) {
      derived.__percentage = Num.percent(root.visible.hours, root.total.hours);
    }

    /** @deprecated */
    derived.__total_metrics = root.total;
    derived.__visible_metrics = root.visible;
    console.timeEnd('Snapshots.deriveProps.totals');
  }

  console.timeEnd('Snapshots.deriveProps');
  console.groupEnd('Snapshots.deriveProps');
  return flatten ? getFlattened(derived) : Object.freeze(derived);
}
