import createFilters from '../tools/createFilters/createFilters';
// import createMutation from '../tools/createMutation/createMutation';
import generateReport from '../tools/generateReport/generateReport';
import navigateTo from '../tools/navigateTo/navigateTo';
import activityArchive from '../tools/reducers/activities/archive';
import activityTagging from '../tools/reducers/activities/tagging';
import activityUpdate from '../tools/reducers/activities/update';
import groupAdd from '../tools/reducers/groups/add';
import groupArchive from '../tools/reducers/groups/archive';
import groupAssignLead from '../tools/reducers/groups/assignLead';
import groupMove from '../tools/reducers/groups/move';
import groupUpdate from '../tools/reducers/groups/update';
import roleAdd from '../tools/reducers/roles/add';
import roleArchive from '../tools/reducers/roles/archive';
import roleMove from '../tools/reducers/roles/move';
import roleTagging from '../tools/reducers/roles/tagging';
import roleUpdate from '../tools/reducers/roles/update';
import lastMessagesSlice from './slices/lastMessagesSlice';
import orgSlice from './slices/orgSlice';
import systemSlice from './slices/systemSlice';
import { getToolsListing } from './toolsListing';

export const UUID_FORMAT = '000000000-999999999';

const formatIntents = (intents) => {
  return intents
    .map((intentText, index) => `Intent ${index + 1}: ${intentText}`)
    .join('\n');
};

const formatOriginalIntents = (originalIntents) => {
  return originalIntents
    .map((intentObj, index) => `Intent ${index + 1}: ${intentObj.intent}`)
    .join('\n');
};

const formatValidation = (validation) => {
  const parts = [];

  if (validation.intendedAction) {
    parts.push(`User Intent: ${validation.intendedAction}`);
  }
  if (validation.explanation) {
    parts.push(`User Explanation: ${validation.explanation}`);
  }

  parts.push(`Validation Status: ${validation.isValid ? 'Valid' : 'Invalid'}`);

  if (!validation.isValid && validation.missingInfo.length > 0) {
    parts.push(`Missing Information: ${validation.missingInfo.join(', ')}`);
  }

  return parts.join('\n');
};

const getIntentSection = (intent) => {
  if (!intent || (!intent.intents?.length && !intent.originalIntents?.length)) {
    return 'No intent';
  }

  const intentInfo = intent.intents?.length
    ? formatIntents(intent.intents)
    : intent.originalIntents?.length
      ? formatOriginalIntents(intent.originalIntents)
      : 'No intent information available';

  return `
    Intent Information:
    ${intentInfo}
    
    ${formatValidation(intent.validation)}
  `;
};

const mainPrompt = async ({
  userText,
  lastMessages,
  snapshot,
  intentSnapshot,
  processedIntentSnapshot,
  tags,
  stream,
  selection,
  intent,
}) => {
  const prompt = `

${systemSlice()}

---

### Organizational Data
${orgSlice({ snapshot, tags, intentSnapshot })}

---

Users can select entities from the diagram.
Act on them if they ask
Selection: ${JSON.stringify(selection, undefined, 4)}

---

**Last Messages**:
${lastMessagesSlice({ lastMessages })}

Ignore irrelevant previous messages.

---

### Tools and Usage Rules
${getToolsListing()}


---

### Human Expert Input
These are notes provided by a human to help also guide you. Take this advice very seriously, it will vary depending on the situation. It should generally overide previous sentiments but use your best judgement.

===PROMPT_LAYER===

---

### Final Notes
- Use tools only for specific actions or data operations.
- Do not output UUIDs in the response.
    
---

The Users Intent
${
  intent &&
  ((intent.intents && intent.intents.length > 0) ||
    (intent.originalIntents && intent.originalIntents.length > 0))
    ? getIntentSection(intent)
    : ''
}

---
  
User's request: ${userText}

`;

  return {
    modelName: 'gpt-4o-mini',
    tools: {
      generateReport,
      createFilters,
      navigateTo,
      roleAdd,
      roleUpdate,
      roleMove,
      activityUpdate,
      groupUpdate,
      groupAdd,
      groupMove,
      groupAssignLead,
      activityTagging,
      roleTagging,
      groupArchive,
      roleArchive,
      activityArchive,
    },
    promptLayer: 'main',
    prompt,
  };
};

export default mainPrompt;
