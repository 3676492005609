import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { ActivityHeading, SurveyHeading } from '@/molecules/headings';
import { CategorisedActivityList } from '@/organisms/activities';
import { ActivityCardList } from '@/organisms/cards';
import { EventName, EventProperty } from '@/shared/enums';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ReviewRole = ({
  allowedTagSet,
  isLoadingNext,
  categoryTagMap,
  categoryTags,
  onNext,
  stepCount,
  stepIndex,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const settings = {
    isEditable: false,
    showComparison: false,
    showDragDrop: false,
    showHours: true,
    showMenu: false,
    showPercentage: true,
    showRemove: false,
    showTags: true,
    showTitle: !categoryTagMap?.size,
    showTotalActivityHours: !categoryTagMap?.size,
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Paragraph
          size={isLargeScreen ? Size.LARGE : Size.MEDIUM}
          overrideStyles={{ mb: 3 }}
        >
          Please take a moment to review your role summary. This is what you've
          told us so far, but if something doesn't look quite right, you can
          always go back and adjust it in the previous steps.
        </Paragraph>

        <Button
          dataTestid="onboard-next"
          label="Finish"
          loading={isLoadingNext}
          onClick={onNext}
          color="secondary"
          size="large"
          track={{
            name: EventName.SURVEY_COMPLETED,
            properties: {
              [EventProperty.ENTRY]: 'Survey',
              [EventProperty.STEP]: stepIndex,
              [EventProperty.STEP_COUNT]: stepCount - 1,
              [EventProperty.STEP_NAME]: 'Review your role',
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          transform: 'translate(0, 0)',
          transition: 'transform 300ms',
          mt: 1,
        }}
      >
        {categoryTags?.length ? (
          <CategorisedActivityList
            categoryTags={categoryTags}
            categoryTagMap={categoryTagMap}
            Component={<ActivityCardList allowedTagSet={allowedTagSet} />}
            settings={settings}
          />
        ) : (
          <>
            <SurveyHeading settings={settings}>
              <ActivityHeading
                showDragDrop={settings.showDragDrop}
                showHours={settings.showHours}
              />
            </SurveyHeading>
            <ActivityCardList
              allowedTagSet={allowedTagSet}
              settings={settings}
              showHeading={false}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ReviewRole;
