import deriveSnapshot from '../derive/snapshot';
import oldApply from './apply';

export default { apply, metrics };

/**
 * @deprecated re-implement this function
 *
 * @param {Object} library
 * @param {Object} snapshot
 * @param {Object} [condition]
 * @return {Object}
 */
function apply(...args) {
  return oldApply(...args);
}

/**
 * @deprecated can just feed this through the derive - needs perf
 *
 * @param {Object} library
 * @param {Object} snapshot
 * @return {Object}
 */
function metrics(library, snapshot) {
  const derived = deriveSnapshot({
    library,
    snapshot,
    includeHierarchy: false,
  });

  return derived;
}
