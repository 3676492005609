import { Avatar } from '@/atoms/avatars';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const PersonTitle = ({ person }) => {
  const personName = person?.__name || 'Unassigned';

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Avatar src={person?.avatar} name={personName} size={Size.SMALL} />
      <Box>
        <Stack alignItems="center" direction="row" spacing={0.5} mt={0.25}>
          <Heading variant="h4">{personName}</Heading>
        </Stack>
      </Box>
    </Stack>
  );
};

export default PersonTitle;
