import { memo } from 'react';
import { styled } from '@mui/material/styles';
import excludeProps from '@/lib/theme/excludeProps';

const List = styled('ol', {
  shouldForwardProp: excludeProps('mt'),
})(({ mt }) => ({
  margin: '16px 0',
  marginTop: mt * 8,
  padding: 0,
}));

const Item = styled('li')({
  display: 'block',
  '& + &': {
    marginTop: 4,
  },
});

const Menu = ({ children, mt = 2 }) => {
  const items = Array.isArray(children) ? children : [children];
  return (
    <List mt={mt}>
      {items.map((child, key) => (
        <Item key={key}>{child}</Item>
      ))}
    </List>
  );
};

export default memo(Menu);
