import { DesignEntity } from '@/lib/enums';

const DEFAULT_OPTIONS = {
  includeTags: true,
  includeSpan: false,
};

const defaultMetricGroup = (metricOptions) => {
  const options = {
    ...DEFAULT_OPTIONS,
    ...metricOptions,
  };

  const metrics = {
    groups: 0,
    roles: 0,
    managers: 0,
    activities: 0,
    hours: 0,
    fte: 0,
    budget: 0,
  };

  if (options.includeSpan) {
    metrics.span = 0;
  }

  if (options.includeTags) {
    metrics.tags = {
      [DesignEntity.ACTIVITY]: {},
      [DesignEntity.GROUP]: {},
      [DesignEntity.ROLE]: {},
    };
  }

  return metrics;
};

export default defaultMetricGroup;
