import { createContext, useContext, useMemo } from 'react';
import { useSummaryMetrics } from './hooks';

export const PlanContext = createContext();
export const usePlanContext = () => useContext(PlanContext);

const PlanProvider = ({ children }) => {
  const {
    comparisonMetrics,
    metricList,
    tagMetricList,
    scenarioMetrics,
    selectedSummaryItem,
    selectSummaryItem,
  } = useSummaryMetrics();

  const context = useMemo(() => {
    return {
      comparisonMetrics,
      metricList,
      scenarioMetrics,
      selectedSummaryItem,
      selectSummaryItem,
      tagMetricList,
    };
  }, [
    comparisonMetrics,
    scenarioMetrics,
    selectedSummaryItem,
    metricList,
    tagMetricList,
  ]);

  return (
    <PlanContext.Provider value={context}>{children}</PlanContext.Provider>
  );
};

export default PlanProvider;
