import { EntityType } from '@/shared/enums';
import { Visibility } from '@/lib/enums';
import addActionByManager from './addActionByManager';
import skipManagerUpdate from './skipManagerUpdate';
import updateMap from './updateMap';

/**
 * Creates a map of changed managers.
 *
 * @param {Object}
 *
 * @return {Map}
 */
export default function mapAggregateManagers({
  scenario,
  showBudget,
  comparisonScenario,
}) {
  if (!comparisonScenario) {
    return;
  }

  const managerMap = new Map();

  // Keep a record of the original entities.
  const originalActivityList = new Set(
    comparisonScenario.relationships?.get(EntityType.ACTIVITY)?.keys()
  );
  const originalRoleList = new Set(
    comparisonScenario.relationships?.get(EntityType.ROLE)?.keys()
  );
  const originalManagerList = new Set(
    [...originalRoleList].filter(
      (id) =>
        comparisonScenario.relationships?.get(EntityType.ROLE)?.get(id)
          .is_manager
    )
  );
  const originalGroupList = new Set(
    comparisonScenario.relationships?.get(EntityType.GROUP)?.keys()
  );

  // Keep a list of the scenario roles and groups to identify ones which don't
  // have activities associated with them.
  const scenarioRoleList = new Set(
    scenario.relationships?.get(EntityType.ROLE)?.keys()
  );
  const scenarioGroupList = new Set(
    scenario.relationships?.get(EntityType.GROUP)?.keys()
  );

  // Loop through the scenario roles that didn't have any activities.
  [...scenarioRoleList].forEach((id) => {
    const role = scenario.relationships.get(EntityType.ROLE)?.get(id);
    const originalRole = comparisonScenario.relationships
      .get(EntityType.ROLE)
      ?.get(id);

    scenarioRoleList.delete(role?.uuid);

    if (role?.__visibility !== Visibility.FULL) {
      return;
    }

    const actions = [];

    // Add role actions for roles without activities.
    actions.push(
      ...addActionByManager({
        comparisonScenario,
        originalRole,
        role,
        scenario,
        showBudget,
      })
    );

    for (let i = 0; i < actions?.length; i++) {
      const action = actions[i];

      // Updates the role map with the role metrics and action.
      updateMap({ action, comparisonScenario, role, managerMap });
    }

    originalRoleList.delete(originalRole?.uuid);
  });

  // Loop through the roles that were removed from the scenario and didn't have
  // any activities.
  [...originalRoleList].forEach((id) => {
    const role = comparisonScenario.relationships.get(EntityType.ROLE)?.get(id);

    originalRoleList.delete(role?.uuid);

    if (role?.__visibility !== Visibility.FULL) {
      return;
    }

    const actions = [];

    // Add role actions for roles without activities.
    actions.push(
      ...addActionByManager({
        comparisonScenario,
        originalRole: role,
        scenario,
        showBudget,
      })
    );

    for (let i = 0; i < actions?.length; i++) {
      const action = actions[i];

      if (skipManagerUpdate({ action, role, managerMap, showBudget })) {
        continue;
      }

      // Updates the role map with the role metrics and action.
      updateMap({ action, comparisonScenario, role, managerMap });

      originalRoleList.delete(role.uuid);
    }
  });

  return managerMap;
}
