import { cumulateMetrics, defaultMetrics } from '@/organisms/plans/utils';
import { EntityType } from '@/shared/enums';

export default function updateAction({
  action,
  actions,
  activity,
  role,
  comparisonScenario,
}) {
  const updateAction = actions.get(action.type) ?? {
    [EntityType.ACTIVITY]: new Map(),
    [EntityType.MANAGER]: new Map(),
    [EntityType.ROLE]: new Map(),
    metrics: defaultMetrics({ comparisonScenario }),
    type: action.type,
  };

  if (action[EntityType.MANAGER]) {
    updateAction[EntityType.MANAGER] = new Map([
      ...updateAction[EntityType.MANAGER],
      ...action[EntityType.MANAGER],
    ]);
  }

  if (action[EntityType.ROLE]) {
    updateAction[EntityType.ROLE] = new Map([
      ...updateAction[EntityType.ROLE],
      ...action[EntityType.ROLE],
    ]);
  }
  if (action[EntityType.ACTIVITY]) {
    updateAction[EntityType.ACTIVITY] = new Map([
      ...updateAction[EntityType.ACTIVITY],
      ...action[EntityType.ACTIVITY],
    ]);
  }

  updateAction.metrics[1] = cumulateMetrics(action, updateAction.metrics[1]);

  return updateAction;
}
