import { useEffect, useState } from 'react';
import { StoreType } from '@/shared/enums';
import { useDesignsContext } from '@/shared/providers/DesignsProvider';
import * as Utils from '@/shared/utils';
import mapDesign from '@/shared/utils/mapDesign';
import { useAccess } from '@pkg/access/designs';
import { Library, Snapshots } from '@pkg/entities';
import { isEqual } from '@pkg/utils/objects';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';

/**
 * Returns a design from the store and falls back to a hydrated design.
 * @param {Boolean} useStored
 * @param {Boolean} useComparison
 *
 * @return {Object}
 */
const useStoredDesign = ({
  designId,
  level,
  storeType = StoreType.HYDRATED,
  includeNested = true,
}) => {
  const library = Library.useLibrary();
  const hydratedDesigns = useDesignsContext();

  const storedDesign = useDesignStore((state) => state.design);
  const storedSnapshot = useDesignStore((state) => state.snapshot);

  const [design, setDesign] = useState(null);
  const [snapshot, setSnapshot] = useState(null);
  const [snapshotEntityMap, setSnapshotEntityMap] = useState(null);

  // For now we're only concerned with the latest revision and we're using
  // this instead of the stored access because this might be a comparison
  // or hyrdated design we need access permissions for.
  const access = useAccess(design, snapshot, false);
  const [hash, setHash] = useState(null);
  const [previousNested, setNested] = useState(null);

  // This effect is for loading a hyrdated design.
  useEffect(() => {
    if (!hydratedDesigns?.designs?.size || storeType !== StoreType.HYDRATED) {
      return;
    }

    if (designId === design?.uuid) {
      return;
    }

    const { designs } = hydratedDesigns;

    if (!designs.has(designId)) {
      setDesign(null);
      setSnapshot(null);
      setSnapshotEntityMap(null);
      setHash(null);
      return;
    }

    const hydrated = designs.get(designId);
    hydrated.snapshot = Snapshots.deriveProps({
      library,
      snapshot: hydrated.snapshot,
      includeHierarchy: false,
    });
    hydrated.snapshot = Snapshots.deriveCompleteness(hydrated.snapshot);
    hydrated.snapshotEntityMap = mapDesign(hydrated.design, hydrated.snapshot);

    setDesign(hydrated.design);
    setSnapshot(hydrated.snapshot);
    setSnapshotEntityMap(hydrated.snapshotEntityMap);
    setHash(hydrated.snapshot.__hash);
  }, [hydratedDesigns?.designs, designId, storeType]);

  // This effect is for loading a stored design.
  useEffect(() => {
    if (storeType !== StoreType.DESIGN || !designId || !storedSnapshot) {
      return;
    }

    const storedCollaborators = JSON.stringify(storedDesign?.collaborators);
    const designCollaborators = JSON.stringify(design?.collaborators);
    const filtersUnchanged = isEqual(
      snapshot?.__metrics?.visible,
      storedSnapshot?.__metrics?.visible
    );

    if (
      storedSnapshot?.__hash === hash &&
      (designId === design?.uuid || design?.uuid === storedDesign?.uuid) &&
      storedCollaborators === designCollaborators &&
      filtersUnchanged &&
      includeNested === previousNested
    ) {
      return;
    }

    // If the stored design is the same as the design id we can use that
    // design but we must derive the entity map.
    setNested(includeNested);
    setDesign(storedDesign);
    setSnapshot(storedSnapshot);
    setSnapshotEntityMap(
      Utils.mapDesign(storedDesign, storedSnapshot, level, includeNested)
    );
    setHash(storedSnapshot?.__hash);
  }, [
    storeType,
    designId,
    design?.uuid,
    includeNested,
    JSON.stringify(storedDesign?.collaborators),
    storedDesign?.uuid,
    storedSnapshot?.__hash,
    JSON.stringify(storedSnapshot?.__metrics?.visible),
  ]);

  return {
    access,
    design,
    snapshot,
    snapshotEntityMap,
  };
};

export default useStoredDesign;
