import lastMessagesSlice from './slices/lastMessagesSlice';
import orgSlice from './slices/orgSlice';
import systemSlice from './slices/systemSlice';

/**
 * Generates a structured and optimized prompt for generating organizational reports.
 * @param {Object} params - The parameters for the prompt generation.
 * @param {string} params.userText - The user's input or question.
 * @param {Array<string>} params.tags - Tags relevant to the organizational context.
 * @param {Object} params.snapshot - A snapshot of the current organizational state.
 * @param {Array<Object>} params.lastMessages - Recent messages for context.
 * @returns {Object} - The generated prompt configuration.
 */
const reportsPrompt = async ({
  userText,
  tags,
  snapshot,
  intentSnapshot,
  lastMessages,
}) => {
  // Generate the system description.
  const systemDescription = systemSlice();

  // Construct the organizational slice based on the snapshot and tags.
  const organizationalContext = orgSlice({ snapshot, tags, intentSnapshot });

  // Generate context from recent messages.
  const recentMessagesContext = lastMessagesSlice({ lastMessages });

  // Build the final prompt string.
  const promptString = `

${systemDescription}

# Report Generation

Generate a report based on the following information:


---

### Organizational Data
${organizationalContext}

---

### Human Expert Input
These are notes provided by a human to help also guide you. Take this advice very seriously, it will vary depending on the situation. It should generally overide previous sentiments but use your best judgement.

===PROMPT_LAYER===

---

**Last Messages**:
${recentMessagesContext}

User Request: ${userText}

`;

  // Return the prompt configuration.
  return {
    stream: true,
    modelName: 'o3-mini',
    tools: {},
    prompt: promptString,
    promptLayer: 'reports',
  };
};

export default reportsPrompt;
