import {
  cumulateMetrics,
  cumulateTagMetrics,
  defaultMetrics,
} from '@/organisms/plans/utils';
import { ActionType, EntityType } from '@/shared/enums';

export default function updateActivityType({
  action,
  activity,
  activityMap,
  comparisonScenario,
}) {
  const activityType = activityMap.get(activity.library_uuid) ?? {
    [EntityType.ACTIVITY]: new Map(),
    metrics: defaultMetrics({ comparisonScenario }),
    props: {
      title: activity.__description,
    },
  };

  if (action.type === ActionType.UPDATED_TAGS) {
    const tags = cumulateTagMetrics({
      entityTags: action.metrics[1].tags,
      originalEntityTags: action.metrics[0].tags,
      existingMetrics: activityType.metrics,
    });
    activityType.metrics[0].tags = tags[0];
    activityType.metrics[1].tags = tags[1];
  }
  if (action.type !== ActionType.UPDATED_TAGS) {
    activityType.metrics[1] = {
      ...cumulateMetrics(action, activityType.metrics[1]),
      tags: activityType.metrics[1].tags,
    };
  }

  return activityType;
}
