import { ActionType } from '@/shared/enums';

export default function isTagActionType(actionType) {
  switch (actionType) {
    case ActionType.UPDATED_ACTIVITY_TAGS:
    case ActionType.UPDATED_ROLE_TAGS:
    case ActionType.UPDATED_GROUP_TAGS:
    case ActionType.UPDATED_TAGS:
      return true;
    default:
      return false;
  }
}
