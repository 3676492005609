import config from '@/lib/config';
import defaultMetricGroup from '../utils/defaultMetricGroup';
import updateMetrics from './shared/updateMetrics';

/**
 * Derives metrics for a person.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function personMetrics({
  activity,
  people,
  person,
  role,
  roles,
  group,
  metrics,
}) {
  const isNewPerson = people.has(person.uuid);
  const isNewRole = roles.has(role.uuid);
  const isVisibleRole = role.__visibility === config.VISIBILITY.FULL;
  const isActivityDisabled = activity?.disabled_at;
  const isVisibleAcivity = activity?.__visibility === config.VISIBILITY.FULL;

  const personMetrics = {
    ...defaultMetricGroup(false),
    budget: isNewPerson ? role.budget : 0,
    fte: isNewPerson ? role.fte : 0,
    roles: isNewRole ? 1 : 0,
    groups: isNewRole && group ? 1 : 0,
    hours: !activity || isActivityDisabled ? 0 : activity.hours,
    activities: !activity || isActivityDisabled ? 0 : 1,
  };

  // Aggregate this person's metrics with the total metrics.
  const total = updateMetrics({
    target: isNewPerson
      ? defaultMetricGroup()
      : { ...metrics.get(person.uuid)?.self?.total },
    source: {
      ...personMetrics,
    },
  });

  // Aggregate this person's metrics with the visible metrics.
  const visible = isVisibleRole
    ? updateMetrics({
        target: isNewPerson
          ? defaultMetricGroup()
          : { ...metrics.get(person.uuid)?.self?.visible },
        source: {
          ...personMetrics,
          activities: isVisibleAcivity ? personMetrics.activities : 0,
          hours: isVisibleAcivity ? personMetrics.hours : 0,
        },
      })
    : isNewPerson
      ? { ...defaultMetricGroup() }
      : { ...metrics.get(person.uuid)?.self?.visible };

  person.__metrics.self.total = total;
  person.__metrics.self.visible = visible;

  return { total, visible };
}
