import { EntityType } from '@/shared/enums';
import updateManager from './updateManager';
import updateManagerType from './updateManagerType';

export default function updateMap({
  managerMap,
  action,
  activity,
  role,
  comparisonScenario,
}) {
  // Update the manager type metrics
  managerMap.set(
    role.title,
    updateManagerType({
      action,
      role,
      managerMap,
      comparisonScenario,
    })
  );

  // Get the manager type.
  const managerType = managerMap.get(role.title);

  // Update the manager.
  managerType[EntityType.MANAGER].set(
    role.uuid,
    updateManager({
      action,
      activity,
      role,
      managerType,
      comparisonScenario,
    })
  );

  return managerMap;
}
