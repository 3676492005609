import { Divider } from '@/atoms/dividers';
import MuiAccordion from '@mui/material/Accordion';
import { brand } from '@/lib/theme/tokens/palettes';

const Accordion = ({ children, defaultExpanded, showBorder = false }) => {
  return (
    <>
      <MuiAccordion
        defaultExpanded={defaultExpanded}
        disableGutters
        elevation={0}
        square
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          border: 'none',
          '&::before': {
            display: 'none',
          },
        }}
      >
        {children}
      </MuiAccordion>
      <Divider
        colorProps={{
          borderColor: brand.lightBlue.main,
        }}
        overrideStyles={{
          mx: 2,
          textAlign: 'center',
        }}
      />
    </>
  );
};

export default Accordion;
