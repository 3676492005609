import { styled } from '@mui/styles';
import excludeProps from '@/lib/theme/excludeProps';

const BarBackground = styled('div', {
  shouldForwardProp: excludeProps('color', 'interactive', 'height'),
})(({ color, height, interactive }) => {
  const cursor = interactive ? 'pointer' : 'inherit';
  return {
    width: '100%',
    height: height,
    backgroundColor: color.background,
    borderRadius: '3px',
    position: 'relative',
    overflow: 'hidden',
    transition: 'background-color 300ms, box-shadow 300ms',
    '&:hover': {
      cursor: cursor,
      backgroundColor:
        cursor === 'pointer' ? color.highlight?.background : color.background,
      boxShadow:
        cursor === 'pointer' ? `0 0 0 2px ${color.highlight?.border}` : 'none',
    },
    '&:hover > div': {
      backgroundColor:
        cursor === 'pointer' ? color.highlight?.foreground : color.foreground,
    },
  };
});

const BarForeground = styled('div', {
  shouldForwardProp: excludeProps('color', 'percentage'),
})(({ color, percentage }) => ({
  display: 'block',
  width: percentage > 100 ? '100%' : `${percentage}%`,
  height: '100%',
  backgroundColor: color.foreground,
  borderTopLeftRadius: '3px',
  borderBottomLeftRadius: '3px',
  position: 'absolute',
  zIndex: 1,
  transition: 'background-color 300ms',
}));

const ChartBar = ({
  children,
  color,
  height,
  interactive,
  percentage = 100,
}) => {
  return (
    <BarBackground color={color} height={height} interactive={interactive}>
      <BarForeground color={color} percentage={percentage} />
      {children}
    </BarBackground>
  );
};

export default ChartBar;
