import PlanTab from '../enums/PlanTab';

const PlanTabOptions = [
  {
    id: PlanTab.ACTIONS,
    label: 'Actions',
  },
  {
    id: PlanTab.MANAGERS,
    label: 'Managers',
  },
  {
    id: PlanTab.ROLES,
    label: 'Roles',
  },
  {
    id: PlanTab.ACTIVITIES,
    label: 'Activities',
  },
];

export default PlanTabOptions;
