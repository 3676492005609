import activityArchiveReducer from '@pkg/entities/snapshots/reducers/activities/archive';
import activityTaggingReducer from '@pkg/entities/snapshots/reducers/activities/tagging';
import activityUpdateReducer from '@pkg/entities/snapshots/reducers/activities/update';
import groupAddReducer from '@pkg/entities/snapshots/reducers/groups/add';
import groupArchiveReducer from '@pkg/entities/snapshots/reducers/groups/archive';
import groupMoveReducer from '@pkg/entities/snapshots/reducers/groups/move';
import groupUpdateReducer from '@pkg/entities/snapshots/reducers/groups/update';
import roleAddReducer from '@pkg/entities/snapshots/reducers/roles/add';
import roleArchiveReducer from '@pkg/entities/snapshots/reducers/roles/archive';
import roleMoveReducer from '@pkg/entities/snapshots/reducers/roles/move';
import roleTaggingReducer from '@pkg/entities/snapshots/reducers/roles/tagging';
import roleUpdateReducer from '@pkg/entities/snapshots/reducers/roles/update';
import createFilterTool from './filters/createFilter';
import navigateTo from './navigate/navigateTo';
import activityArchiveTool from './reducers/activities/archive';
import activityTaggingTool from './reducers/activities/tagging';
import activityUpdateTool from './reducers/activities/update';
import groupAddTool from './reducers/groups/add';
import groupArchiveTool from './reducers/groups/archive';
import assignLeadTool from './reducers/groups/assignLead';
import groupMoveTool from './reducers/groups/move';
import groupUpdateTool from './reducers/groups/update';
import roleAddTool from './reducers/roles/add';
import roleArchiveTool from './reducers/roles/archive';
import roleMoveTool from './reducers/roles/move';
import roleTaggingTool from './reducers/roles/tagging';
import roleUpdateTool from './reducers/roles/update';

export const toolsConfig = {
  activityUpdate: {
    title: 'Update Activities',
    description:
      'Update the description or hours allocation of one or more existing activities',
    category: 'Activities',
    tool: activityUpdateTool,
    reducer: activityUpdateReducer,
    prompt: `
      Rules for updating activities:
      1. UUID Requirements (CRITICAL):
         - All UUIDs must be exactly 9 digits (000000000-999999999)
         - Never use strings like "CEO" or "Engineering" as UUIDs
         Example:
           * CORRECT: ids: ["123456789"]
           * INCORRECT: ids: ["CEO"] or ids: ["550e8400"]
      2. Hours should be updated based on realistic time allocations
      3. Tags must be valid 9-digit UUIDs from the available tags
      4. Maintain consistency with existing activity descriptions
      5. Make sure to listen when people say tags, and call this tool to update activities when they do
    `,
  },
  activityArchive: {
    title: 'Archive Activities',
    description:
      'Archive one or more activities from the organisational design',
    category: 'Activities',
    tool: activityArchiveTool,
    reducer: activityArchiveReducer,
    prompt: `
      Rules for Archiving Activities:
      1. UUID Requirements (CRITICAL):
         - All UUIDs must be exactly 9 digits (000000000-999999999)
         - Never use descriptive strings as UUIDs
         Example:
           * CORRECT: ids: ["123456789", "234567890"]
           * INCORRECT: ids: ["Meeting"] or ids: ["550e8400"]

      2. Required Parameters:
         - ids: Must provide at least one valid 9-digit UUID in the array
         - Never leave ids parameter empty or undefined
         - Never use activity descriptions instead of UUIDs

      Example Valid Archive:
      {
        payload: { ids: ["123456789", "234567890"] },
        explanation: "Archiving redundant activities after process streamlining"
      }
    `,
  },
  activityTagging: {
    title: 'Tag Activities',
    description: 'Add or remove tags from multiple activities at once',
    category: 'Activities',
    tool: activityTaggingTool,
    reducer: activityTaggingReducer,
    prompt: `
      Required Payload Structure:
      {
        payload: {
          ids: string[],      // Array of 9-digit activity UUIDs to modify
          change: {           // Required object containing tag changes
            add: string[],    // Array of 9-digit tag UUIDs to add
            remove: string[]  // Array of 9-digit tag UUIDs to remove
          }
        },
        explanation: string   // Required explanation for the tag changes
      }

      UUID Requirements (CRITICAL):
      1. All UUIDs must be exactly 9 digits (000000000-999999999)
      2. Never use descriptive strings as UUIDs
      Example:
         * CORRECT: "123456789"
         * INCORRECT: "fun" or "550e8400"

      Validation Rules:
      1. The payload MUST include:
         - change: Object with 'add' and 'remove' arrays of 9-digit UUIDs
         - explanation: Clear reason for the tag changes

      2. Tag Matching Rules:
         - When user requests a tag by name (e.g., "fun"), find the closest matching existing tag UUID
         - If no similar tag exists in the available tags list, inform the user
         - Never create new tags - only use existing 9-digit UUIDs from the available list

      3. Tag Modification Rules:
         - Only use valid 9-digit UUIDs from the available tags list
         - Tags in 'add' array must be relevant to activities
         - Tags in 'remove' array must have clear removal reason
         - Both 'add' and 'remove' arrays must be present (can be empty)

      Example Valid Payload:
      {
        payload: {
          ids: ["123456789"],
          change: {
            add: ["234567890"],
            remove: []
          }
        },
        explanation: "Adding project management tag to better categorize planning activities"
      }
    `,
  },
  roleAdd: {
    title: 'Add Roles',
    description: 'Create new roles with associated activities',
    category: 'Roles',
    tool: roleAddTool,
    reducer: roleAddReducer,
    prompt: `
      Rules for Creating Organizational Structures:

      1. UUID Requirements (CRITICAL):
         - All UUIDs must be exactly 9 digits (000000000-999999999)
         - Never use descriptive strings as UUIDs
         Example:
           * CORRECT: id: "123456789", parent_id: "234567890"
           * INCORRECT: id: "CEO" or id: "Engineering"

      2. Core Structure Rules:
         - Follow the user's requested organizational design
         - Support any hierarchy type (flat, matrix, nested, etc.)
         - No circular reporting relationships allowed
         - Roles can exist without parent_id or group_id
         - Groups can exist without parent_group_id or lead_id

      3. Role Properties:
         - Required: id (9-digit UUID), title
         - Optional: parent_id, group_id, budget, fte
         - Default: fte = 1.0
         - Example:
           {
             id: "123456789",
             title: "Software Engineer",
             budget: 100000,  // optional
             fte: 1.0,       // optional
             parent_id: null, // optional
             group_id: null   // optional
           }

      4. Group Properties:
         - Required: id (9-digit UUID), name
         - Optional: lead_id, parent_group_id, tags
         - Example:
           {
             id: "123456789",
             name: "Engineering",
             lead_id: null,           // optional
             parent_group_id: null,    // optional
             tags: []                  // optional
           }

      5. Activity Properties:
         - Required: description
         - Optional: hours, tags
         - Description should be clear and action-oriented
         - Example:
           {
             description: "Lead weekly team meetings",
             hours: 5,     // optional
             tags: []      // optional
           }

      Note: 
      - Adapt to user's requested structure - no enforced hierarchy
      - Support any organizational design pattern:
        * Flat hierarchies
        * Traditional hierarchies
        * Matrix organizations
        * Project-based teams
        * Geographic divisions
        * Cross-functional teams
        * Any other structure requested by user
    `,
  },
  roleMove: {
    title: 'Move Roles',
    category: 'Roles',
    description: `
      Move roles within the organizational design, updating their direct reporting relationships.
      Note: To change which role leads a group, use the 'Move Groups' tool instead.
    `,
    tool: roleMoveTool,
    reducer: roleMoveReducer,
    prompt: `
      Rules for Moving Roles:
      1. UUID Requirements (CRITICAL):
         - All UUIDs must be exactly 9 digits (000000000-999999999)
         - Never use descriptive strings as UUIDs
         Example:
           * CORRECT: parentId: "345678901"
           * INCORRECT: parentId: "CEO" or parentId: "550e8400"
           * CORRECT: ids: ["456789012", "567890123"]
           * INCORRECT: ids: ["CEO", "CTO"]

      2. Reporting Structure:
         - Always provide clear explanation for why roles are being moved
         - Check that target roles exist before moving
         - When moving multiple roles, consider their relationships and hierarchy
         - If no parentId is provided, roles will report to the group lead
         - Moving a role will also move its children unless specified otherwise

      3. Group Assignment:
         - Set groupId to null to remove a role from its current group
         - Verify that the move won't create circular dependencies
         - Remember: To change which role leads a group (e.g., making CTO lead Engineering), 
           use the 'Move Groups' tool with leadId parameter instead

      4. Required Parameters:
         - ids: Must provide at least one valid 9-digit UUID in the array
         - Never leave ids parameter empty or undefined
         - Never use role titles or descriptions instead of UUIDs
    `,
  },
  roleArchive: {
    title: 'Archive Roles',
    description:
      'Archive one or more roles and optionally their managed entities',
    category: 'Roles',
    tool: roleArchiveTool,
    reducer: roleArchiveReducer,
    prompt: `
      Rules for Archiving Roles:
      1. UUID Requirements (CRITICAL):
         - All UUIDs must be exactly 9 digits (000000000-999999999)
         - Never use descriptive strings as UUIDs
         Example:
           * CORRECT: ids: ["123456789", "234567890"]
           * INCORRECT: ids: ["CEO"] or ids: ["550e8400"]

      2. Archiving Rules:
         - Provide clear explanation for why roles are being archived
         - Consider impact on reporting structure
         - Roles reporting to archived roles will be reassigned to next valid parent
         - Groups led by archived roles will need new leadership

      3. Inclusive Archive:
         - When inclusive=true, also archives:
           * All roles managed by archived roles
           * All groups managed by archived roles
           * All activities owned by archived roles
         - Use with caution as this can affect many entities

      4. Required Parameters:
         - ids: Must provide at least one valid 9-digit UUID in the array
         - Never leave ids parameter empty or undefined
         - Never use role titles or descriptions instead of UUIDs

      Example Valid Archive:
      {
        ids: ["123456789", "234567890"],
        inclusive: false,
        explanation: "Archiving redundant roles after department restructure"
      }
    `,
  },
  roleTagging: {
    title: 'Tag Roles',
    description: 'Add or remove tags from multiple roles at once',
    category: 'Roles',
    tool: roleTaggingTool,
    reducer: roleTaggingReducer,
    prompt: `
      Required Payload Structure:
      {
        payload: {
          ids: string[],      // Array of 9-digit role UUIDs to modify
          change: {           // Required object containing tag changes
            add: string[],    // Array of 9-digit tag UUIDs to add
            remove: string[]  // Array of 9-digit tag UUIDs to remove
          }
        },
        explanation: string   // Required explanation for the tag changes
      }

      UUID Requirements (CRITICAL):
      1. All UUIDs must be exactly 9 digits (000000000-999999999)
      2. Never use descriptive strings as UUIDs
      Example:
         * CORRECT: "123456789"
         * INCORRECT: "Manager" or "550e8400"

      Validation Rules:
      1. The payload MUST include:
         - change: Object with 'add' and 'remove' arrays of 9-digit UUIDs
         - explanation: Clear reason for the tag changes

      2. Tag Matching Rules:
         - When user requests a tag by name (e.g., "leadership"), find the closest matching existing tag UUID
         - If no similar tag exists in the available tags list, inform the user
         - Never create new tags - only use existing 9-digit UUIDs from the available list

      3. Tag Modification Rules:
         - Only use valid 9-digit UUIDs from the available tags list
         - Tags in 'add' array must be relevant to role functions and responsibilities
         - Tags in 'remove' array must have clear removal reason
         - Both 'add' and 'remove' arrays must be present (can be empty)

      Example Valid Payload:
      {
        payload: {
          ids: ["123456789", "234567890"],
          change: {
            add: ["345678901", "456789012"],
            remove: ["567890123"]
          }
        },
        explanation: "Adding leadership and technical tags to management roles while removing outdated classification"
      }
    `,
  },
  roleUpdate: {
    title: 'Update Roles',
    description:
      'Modify existing roles including their title, FTE, budget, and relationships',
    category: 'Roles',
    tool: roleUpdateTool,
    reducer: roleUpdateReducer,
    prompt: `
      Rules for updating roles:
      1. UUID Requirements (CRITICAL):
         - All UUIDs must be exactly 9 digits (000000000-999999999)
         - Never use descriptive strings as UUIDs
         Example:
           * CORRECT: id: "123456789"
           * INCORRECT: id: "CEO" or id: "550e8400"
      2. Title changes should maintain professional terminology
      3. FTE updates should be between 0.0 and 2.0
      4. Budget updates should be realistic for the role level
    `,
  },
  groupUpdate: {
    title: 'Update Groups',
    description:
      'Modify existing groups including their name, tags, and relationships.',
    category: 'Groups',
    tool: groupUpdateTool,
    reducer: groupUpdateReducer,
    prompt: `
      Rules for updating groups:
      1. UUID Requirements (CRITICAL):
         - All UUIDs must be exactly 9 digits (000000000-999999999)
         - Never use descriptive strings as UUIDs
         Example:
           * CORRECT: id: "123456789"
           * INCORRECT: id: "Engineering" or id: "550e8400"
      2. Name changes should be clear and descriptive
      3. Tags must be valid 9-digit UUIDs from the available tags
    `,
  },
  groupMove: {
    title: 'Move Groups',
    description:
      'Move groups within the organizational structure, updating their group membership.',
    category: 'Groups',
    tool: groupMoveTool,
    reducer: groupMoveReducer,
    prompt: `
      Rules for Moving Groups:
      1. UUID Requirements (CRITICAL):
         - All UUIDs must be exactly 9 digits (000000000-999999999)
         - Never use descriptive strings as UUIDs
         Example:
           * CORRECT: 
             ids: ["123456789"] (group UUID)
             groupId: "234567890" (group UUID)
           * INCORRECT: 
             ids: ["Engineering"]
             groupId: "550e8400"

      2. Group Movement Rules:
         - When moving multiple groups, consider their relationships and hierarchy
         - If no groupId is provided, groups will maintain their reporting structure
         - Set groupId to null to make it a top-level group
         - Verify that the move won't create circular dependencies
         - You can move many group ids at once under a new parent group_id
    `,
  },
  groupAssignLead: {
    title: 'Assign Group Leadership',
    description:
      'Assign a role as the leader for one or more groups in the organizational structure. This updates the leadership relationship between the specified groups and the designated role.',
    category: 'Groups',
    tool: assignLeadTool,
    reducer: (snapshot, { leadId, ids, groupId }) => {
      console.log('======= CALLED THE ASSIGN');
      console.log('======= CALLED THE ASSIGN');
      console.log('======= CALLED THE ASSIGN');
      console.log('======= CALLED THE ASSIGN');
      console.log('======= CALLED THE ASSIGN');
      console.log('======= CALLED THE ASSIGN', { leadId, ids, groupId });
      return groupMoveReducer(snapshot, { leadId, ids, groupId });
    },
    prompt: `
      Rules for Assigning Group Leadership:
      1. UUID Requirements (CRITICAL):
         - All UUIDs must be exactly 9 digits (000000000-999999999)
         - Never use descriptive strings as UUIDs
         Example:
           * CORRECT: leadId: "123456789"
           * INCORRECT: leadId: "CTO" or leadId: "550e8400"

      2. Leadership Assignment Rules:
         - Always use leadId parameter (role's UUID) to set the group's leader
         - Do NOT try to change group leadership with any other tool
         - Set leadId to null to remove current leadership
         - You can set multiple group ids to change their leader to the same person

      Example Valid Assignment:
      {
        leadId: "123456789",
        ids: ["234567890", "345678901"]
      }
    `,
  },
  groupAdd: {
    title: 'Add Groups',
    description:
      'Add new groups to the organisational design with appropriate leadership',
    category: 'Groups',
    tool: groupAddTool,
    reducer: groupAddReducer,
    prompt: `
      Rules for Adding Groups:
      1. UUID Requirements (CRITICAL):
         - All UUIDs must be exactly 9 digits (000000000-999999999)
         - Never use descriptive strings as UUIDs
         Example:
           * CORRECT: group_uuid: "123456789"
           * INCORRECT: group_uuid: "Engineering" or group_uuid: "550e8400"

      2. Group Properties:
         - Name must be clear and descriptive of the group's function
         - Consider how the group fits into the existing structure

      3. Hierarchical Relationships:
         - group_uuid determines group-to-group reporting:
           * Must be a valid 9-digit UUID if specified
           * Set to null for top-level groups
         - lead_uuid determines role-based reporting:
           * Must be a valid 9-digit UUID if specified
           * Set to null if no direct leader is assigned
         - When moving entities (roles/groups) via ids:
           * All IDs must be valid 9-digit UUIDs
           * Verify entity types match their actual types (ROLE or GROUP)

      4. Metadata:
         - Tags must be valid 9-digit UUIDs from the available tag set
         - Tags should reflect the group's function and domain

      Example Valid Group Creation:
      {
        name: "Engineering",
        group_uuid: "123456789",
        lead_uuid: "234567890",
        tags: ["345678901", "456789012"]
      }
    `,
  },
  groupArchive: {
    title: 'Archive Groups',
    description:
      'Archive one or more groups and optionally their contained entities',
    category: 'Groups',
    tool: groupArchiveTool,
    reducer: groupArchiveReducer,
    prompt: `
      Rules for Archiving Groups:
      1. UUID Requirements (CRITICAL):
         - All UUIDs must be exactly 9 digits (000000000-999999999)
         - Never use descriptive strings as UUIDs
         Example:
           * CORRECT: ids: ["123456789", "234567890"]
           * INCORRECT: ids: ["Engineering"] or ids: ["550e8400"]

      2. Archiving Rules:
         - Provide clear explanation for why groups are being archived
         - Consider impact on organizational structure
         - Child groups of archived groups will be moved to the next valid parent
         - Roles within archived groups will need reassignment

      3. Inclusive Archive:
         - When inclusive=true, also archives:
           * All roles contained within these groups
           * All activities owned by those roles
         - Use with caution as this can affect many entities

      4. Required Parameters:
         - ids: Must provide at least one valid 9-digit UUID in the array
         - Never leave ids parameter empty or undefined
         - Never use group names or descriptions instead of UUIDs

      Example Valid Archive:
      {
        ids: ["123456789", "234567890"],
        inclusive: false,
        explanation: "Archiving legacy groups after department consolidation"
      }
    `,
  },
  createFilter: {
    title: 'Create Filters',
    description:
      'Create filter configurations to find specific data in the organization',
    category: 'Filters',
    tool: createFilterTool,
    reducer: null,
    prompt: `
      Rules for creating filters:
      1. When comparing titles use CONTAINS over EQUALS
      2. When comparing tags use HAS_ITEM_ANY
      3. When comparing hours use GREATER_THAN or LESS_THAN
      4. When comparing dates use AFTER or BEFORE
      5. For budget/hours/FTE on roles and teams, set metricProperty to '__total_metrics'
      6. For activity nature, use library_uuid with ENTITY_IS_ANY
      7. Time conversions: 30 minutes = 0.5, 1 day = 24 hours
      8. Return numbers as numbers, not strings
    `,
  },
  navigateTo: {
    title: 'Navigate To',
    category: 'Navigation',
    description: 'Navigate to specific urls in the application',
    prompt: `
      Rules for navigation:
      1. Navigate to any URL within the JavaScript application
      2. Common destinations include:
         - View types: TABLE, INSIGHTS, DESIGN, PLAN
         - Specific groups or roles (requires valid UUID)
         - Dashboard views and reports
         - Configuration pages
      3. Always provide a clear explanation of where the URL will navigate to
      4. URLs should be properly formatted and encoded if necessary
      5. Ensure the destination exists within the application
    `,
    tool: navigateTo,
    reducer: null,
  },
};

// Helper to get all tools
export const getTools = () => {
  const tools = {};
  Object.entries(toolsConfig).forEach(([key, config]) => {
    tools[key] = config.tool;
  });
  return tools;
};

// Helper to get all reducers
export const getReducers = () => {
  const reducers = {};
  Object.entries(toolsConfig).forEach(([key, config]) => {
    reducers[key] = config.reducer;
  });
  return reducers;
};

export default toolsConfig;
