import { DesignEntity, Visibility } from '@/lib/enums';
import formatByActivity from './formatByActivity';
import formatByRole from './formatByRole';

const filterEntities = (map) => {
  const filtered = [...map.entries()].filter(([, entity]) => {
    const hasHours =
      entity.__type !== DesignEntity.ACTIVITY || entity.hours !== null;
    const isVisible = entity?.__visibility !== Visibility.NONE;

    return hasHours && isVisible;
  });

  return new Map(filtered);
};

export default function formatScenario({
  design,
  snapshot,
  snapshotEntityMap,
  entity,
  includeNested,
  isHistorical,
  comparisonColor,
}) {
  const { collaborators, is_scenario, name, owner, scope, uuid } = design;
  const goal = design.goal ?? design.latest?.snapshot?.objective;
  const entityType = entity.type;
  const shouldIncludeNested =
    includeNested &&
    (entityType === DesignEntity.GROUP || entityType === DesignEntity.MANAGER);
  let selectedEntity;

  switch (entity.type) {
    case DesignEntity.ORGANISATION:
      const selectedDesign = snapshotEntityMap.get(entity.type);
      selectedEntity = {
        ...snapshot,
        updated_at: selectedDesign.created_at,
        __uuid: selectedDesign.uuid,
      };
      delete selectedEntity.entities;
      break;
    default:
      selectedEntity = snapshotEntityMap.get(entity.type).get(entity.uuid);
  }

  const scenario = {
    details: {
      collaborators,
      designId: uuid,
      goal,
      isScenario: is_scenario,
      isHistorical,
      name,
      owner,
      scope,
      hash: snapshot?.__hash,
    },
    organisation: snapshotEntityMap.get(DesignEntity.ORGANISATION),
    entity: selectedEntity,
    comparisonColor,
    metrics: {
      ...snapshot.__metrics,
    },
    relationships: new Map([
      [DesignEntity.PERSON, new Map()],
      [DesignEntity.GROUP, new Map()],
      [DesignEntity.ROLE, new Map()],
      [DesignEntity.MANAGER, new Map()],
      [DesignEntity.ACTIVITY, new Map()],
    ]),
  };

  // short-circuit organisation levels
  if (entityType === DesignEntity.ORGANISATION) {
    const people = filterEntities(snapshotEntityMap.get(DesignEntity.PERSON));
    const groups = filterEntities(snapshotEntityMap.get(DesignEntity.GROUP));
    const roles = filterEntities(snapshotEntityMap.get(DesignEntity.ROLE));
    const activities = filterEntities(
      snapshotEntityMap.get(DesignEntity.ACTIVITY)
    );

    scenario.relationships.set(DesignEntity.PERSON, people);
    scenario.relationships.set(DesignEntity.GROUP, groups);
    scenario.relationships.set(DesignEntity.ROLE, roles);
    scenario.relationships.set(DesignEntity.MANAGER, roles); // @todo
    scenario.relationships.set(DesignEntity.ACTIVITY, activities);

    return scenario;
  }

  const hasActivities = snapshotEntityMap.get(DesignEntity.ACTIVITY)?.size > 0;

  // setup data
  const entityId = entity.uuid;

  const formatFn = hasActivities ? formatByActivity : formatByRole;

  const { activityMap, groupMap, personMap, roleMap } = formatFn({
    entityId,
    entityType,
    shouldIncludeNested,
    snapshotEntityMap,
  });

  scenario.relationships.set(DesignEntity.ROLE, roleMap);
  scenario.relationships.set(DesignEntity.ACTIVITY, activityMap);
  scenario.relationships.set(DesignEntity.PERSON, personMap);

  // only return surrounding group for role level
  if (
    selectedEntity &&
    !selectedEntity?.is_manager &&
    entity.type === DesignEntity.ROLE
  ) {
    const groupId = selectedEntity.group_uuid;
    const group = snapshotEntityMap.get(DesignEntity.GROUP).get(groupId);
    scenario.relationships.set(
      DesignEntity.GROUP,
      group ? new Map([[group.uuid, group]]) : new Map()
    );
    return scenario;
  }

  scenario.relationships.set(DesignEntity.GROUP, groupMap);

  return scenario;
}
