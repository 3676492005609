import {
  cumulateMetrics,
  cumulateTagMetrics,
  defaultMetrics,
} from '@/organisms/plans/utils';
import { ActionType, EntityType } from '@/shared/enums';

export default function updateManagerType({
  action,
  role,
  managerMap,
  comparisonScenario,
}) {
  const managerType = managerMap.get(role.title) ?? {
    [EntityType.MANAGER]: new Map(),
    metrics: defaultMetrics({ comparisonScenario, includeSpans: true }),
    props: {
      title: role.title,
    },
  };

  if (action.type === ActionType.UPDATED_TAGS) {
    const tags = cumulateTagMetrics({
      entityTags: action.metrics[1].tags,
      originalEntityTags: action.metrics[0].tags,
      existingMetrics: managerType.metrics,
    });

    managerType.metrics[0].tags = tags[0];
    managerType.metrics[1].tags = tags[1];
  }
  if (action.type !== ActionType.UPDATED_TAGS) {
    managerType.metrics[1] = {
      ...cumulateMetrics(action, managerType.metrics[1]),
      tags: managerType.metrics[1].tags,
    };
  }

  return managerType;
}
