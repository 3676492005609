import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';

export default function addedManagerAction({ role, comparisonScenario }) {
  const metrics = role.__metrics.self.total;

  const managerProperties = {};

  // Add the parent manager.
  if (role.__manager) {
    managerProperties.moved = {
      manager: {
        to: role.__manager,
      },
    };
  }

  // Add the parent group.
  if (role.group_uuid) {
    managerProperties.moved = {
      ...managerProperties.moved,
      group: {
        to: role.group_uuid,
      },
    };
  }

  // Add the assignee.
  managerProperties.assigned = {
    to: role.user_uuid || null,
  };

  const action = {
    type: ActionType.ADD_MANAGERS,
    [EntityType.MANAGER]: new Map([
      [
        role.uuid,
        {
          metrics,
          props: { ...managerProperties },
        },
      ],
    ]),
    metrics: defaultMetrics({ comparisonScenario }),
  };

  action.metrics[1].managers += 1;

  return action;
}
