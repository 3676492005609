import config from '@/lib/config';
import { DesignEntity } from '@/lib/enums';
import deriveEntityTag from './shared/deriveEntityTag';

/**
 * Calculates role tags for every entity it's directly associated with.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function deriveRoleTags({
  activity,
  role,
  group,
  metrics,
  tagMap,
}) {
  if (!role?.tags?.length) {
    return;
  }

  const isVisible = role.__visibility === config.VISIBILITY.FULL;

  role.tags.forEach((tagId) => {
    // Derive tag metrics for the role which are stored in the metrics object.
    if (metrics.has(role.uuid)) {
      deriveEntityTag({
        activity,
        entityId: role.uuid,
        group,
        metrics,
        role,
        tagId,
        tagType: DesignEntity.ROLE,
        tagMap,
      });
    }

    // Derive tag metrics for the group which are stored in the metrics object.
    if (group && metrics.has(group.uuid)) {
      deriveEntityTag({
        activity,
        entityId: group.uuid,
        group,
        metrics,
        role,
        tagId,
        tagType: DesignEntity.ROLE,
        tagMap,
      });
    }

    // Derive root tag metrics which are stored in the metrics object.
    if (metrics.has('*')) {
      deriveEntityTag({
        activity,
        entityId: '*',
        group,
        metrics,
        role,
        tagId,
        tagType: DesignEntity.ROLE,
        tagMap,
      });
    }
  });
}
