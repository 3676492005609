const ComparisonOperator = Object.freeze({
  AFTER: 'AFTER',
  BEFORE: 'BEFORE',
  CONTAINS: 'CONTAINS',
  NOT_CONTAINS: 'NOT_CONTAINS',
  CONTAINS_ANY_OF: 'CONTAINS_ANY_OF',
  EQUALS: 'EQUALS',
  EXCLUDE_ANY: 'EXCLUDE_ANY',
  EXCLUDE_ANY_OBJECT: 'EXCLUDE_ANY_OBJECT',
  EXCLUDE_ALL: 'EXCLUDE_ALL',
  EXCLUDE_ALL_OBJECT: 'EXCLUDE_ALL_OBJECT',
  GREATER_THAN: 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
  HAS_ITEM_ANY: 'HAS_ITEM_ANY',
  HAS_ITEM_ALL: 'HAS_ITEM_ALL',
  HAS_OBJECT_ANY: 'HAS_OBJECT_ANY',
  HAS_OBJECT_ALL: 'HAS_OBJECT_ALL',
  INCLUDE_ANY: 'INCLUDE_ANY',
  INCLUDE_ALL: 'INCLUDE_ALL',
  IS: 'IS',
  IS_NOT_ONE_OF: 'IS_NOT_ONE_OF',
  IS_ONE_OF: 'IS_ONE_OF',
  LESS_THAN: 'LESS_THAN',
  LESS_THAN_OR_EQUAL: 'LESS_THAN_OR_EQUAL',
  NOT_EQUAL_TO: 'NOT_EQUAL_TO',
  STARTS_WITH: 'STARTS_WITH',
});

export default ComparisonOperator;
