import { useEffect, useState } from 'react';
import { CancelButton } from '@/atoms/buttons';
import { Paragraph } from '@/atoms/typography';
import { PropertyIdSelect } from '@/molecules/filterElements';
import { SelectFilter } from '@/organisms/filters';
import { FilterEntity, FilterProperty } from '@/organisms/filters/enums';
import {
  EntityPropertyMap,
  getFilterType,
  getFilterPropertyType,
} from '@/organisms/filters/utils';
import { DefaultCondition } from '@/organisms/filters/utils';
import { convertEmptyToNull } from '@/shared/utils';
import Stack from '@mui/material/Stack';
import { ucfirst } from '@pkg/utils/strings';
import ConditionalOperator from './ConditionalOperator';
import FilterRule from './FilterRule';

const FilterCondition = ({
  conditionIndex,
  condition,
  canRemove,
  onChange,
  onRemoveCondition,
  readOnly = false,
}) => {
  const [propertyOptions, setPropertyOptions] = useState();
  const entity = condition?.get('entity');
  const property = condition?.get('property');

  // As the property type can be a first class selection, this helps inform us
  // what type of property has been selected anywhere in the condition.
  const propertyType = getFilterPropertyType(entity?.key, property?.key);
  // We need to identify the property filter so we can render the property
  // id select field.
  const isPropertyFilter = propertyType === FilterProperty.PROPERTY;

  const [entityOptions] = useState(
    Object.values(FilterEntity).map((entity) => ({
      key: entity,
      label: entity === FilterEntity.GROUP ? 'Team' : ucfirst(entity, true),
    }))
  );

  // Handles entity selection.
  const handleEntityChange = (key, value) => {
    if (entity?.key && value?.key === entity.key) {
      return;
    }

    // We want to reset all other values when an entity is changed.
    const updatedCondition = new Map([...DefaultCondition, ['entity', value]]);

    onChange?.(conditionIndex, updatedCondition);
  };

  // Handles comparator and value changes.
  const handleRuleChange = (key, value) => {
    const updatedCondition = new Map(condition);
    const changedValue = key === 'value' ? convertEmptyToNull(value) : value;
    updatedCondition.set(key, changedValue);
    onChange?.(conditionIndex, updatedCondition);
  };

  // Handles updates to the property and property type selection.
  const handlePropertyChange = (key, value) => {
    if (property && value?.key === property?.key) {
      return;
    }

    // We want to reset remaining values when a property is changed.
    const updatedCondition = new Map([
      ...DefaultCondition,
      ['entity', entity],
      ['property', value],
    ]);

    const propertyType = getFilterPropertyType(entity?.key, value?.key);
    const isPropertyFilter = propertyType === FilterProperty.PROPERTY;

    // We don't want to set the type if this is a property filter because we
    // need to wait for a property id to be selected.
    if (!isPropertyFilter) {
      const filterType = getFilterType(entity?.key, value?.key);
      updatedCondition.set('type', filterType);
    }

    onChange?.(conditionIndex, updatedCondition);
  };

  // Handles updating the property filter type.
  const handlePropertyIdChange = (key, value) => {
    const filterType = getFilterType(entity?.key, property?.key, value);

    const updatedCondition = new Map([
      ...DefaultCondition,
      ['entity', entity],
      ['property', property],
      ['propertyId', value],
      ['type', filterType],
    ]);

    onChange?.(conditionIndex, updatedCondition);
  };

  // Updates the property options based on the selected entity.
  useEffect(() => {
    const entity = condition?.get('entity');

    if (!entity) {
      return;
    }

    const propertyOptions = [
      ...EntityPropertyMap.get(entity?.key).entries(),
    ].map(([key, option]) => {
      return {
        key,
        ...option,
      };
    });

    setPropertyOptions(propertyOptions);
  }, [condition?.get('entity')]);

  return (
    condition && (
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.5}
        sx={{ pointerEvents: readOnly ? 'none' : 'auto' }}
      >
        {!readOnly && (
          <>
            {conditionIndex === 0 ? (
              <Paragraph overrideStyles={{ mb: 0, pr: 0.5 }}>
                Filter by
              </Paragraph>
            ) : (
              <ConditionalOperator />
            )}
          </>
        )}
        <SelectFilter
          id="entity"
          key={`${condition?.get('entity')}-${conditionIndex}`}
          value={condition.get?.('entity')}
          onChange={handleEntityChange}
          optionLabel="label"
          options={entityOptions}
          optionId="key"
          label="Select a filter type"
          emptyText="No filter types were found"
        />
        {propertyOptions && (
          <SelectFilter
            emptyText="No filter types were found"
            value={condition.get?.('property')}
            id="property"
            label="Select filter property"
            onChange={handlePropertyChange}
            optionLabel="label"
            optionId="key"
            options={propertyOptions}
            triggerOnOpen
          />
        )}
        {isPropertyFilter && (
          <PropertyIdSelect
            condition={condition}
            onChange={handlePropertyIdChange}
            triggerOnOpen
            value={condition?.get('propertyId')}
          />
        )}
        {condition.get('type') && (
          <FilterRule condition={condition} onChange={handleRuleChange} />
        )}
        {canRemove && !readOnly && (
          <CancelButton onClick={() => onRemoveCondition(conditionIndex)} />
        )}
      </Stack>
    )
  );
};

export default FilterCondition;
