import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';

export default function assignedManagerAction({
  comparisonScenario,
  originalRole,
  role,
}) {
  const action = {
    type: ActionType.ASSIGNED_MANAGERS,
    [EntityType.MANAGER]: new Map(),
    metrics: defaultMetrics({ comparisonScenario }),
  };

  const managerProperties = {};

  if (role?.user_uuid !== originalRole?.user_uuid) {
    managerProperties.assigned = {
      from: originalRole?.user_uuid,
      to: role?.user_uuid,
    };
  }

  action[EntityType.MANAGER].set(role.uuid, { props: managerProperties });

  action.metrics[1].assigned += 1;

  return action;
}
