import { EntityChip, StatusChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { EntityType } from '@/shared/enums';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const ActivityTitle = ({ activity, status }) => {
  return (
    <>
      <Stack direction="row" spacing={0.5} mt={0.5}>
        <Box flexShrink={0}>
          {status && (
            <StatusChip
              status={status}
              size={Size.XX_SMALL}
              sx={{ height: 20, px: 1, mr: 0.75 }}
            />
          )}
          <EntityChip
            size={Size.XX_SMALL}
            sx={{ height: 20, px: 1 }}
            type={EntityType.ACTIVITY}
          />
        </Box>
      </Stack>
      <Heading
        variant="h4"
        overrideStyles={{
          mt: 0.5,
        }}
      >
        {activity.__description}
      </Heading>
    </>
  );
};

export default ActivityTitle;
