import { DesignEntity, Visibility } from '@/lib/enums';
import defaultMetricGroup from '../utils/defaultMetricGroup';
import updateMetrics from './shared/updateMetrics';

/**
 * Calculates the metrics for role spans, and managers.
 *
 * @param {Object}
 *
 * @return {Object}
 */
function deriveRoleSpan({ role, snapshot }) {
  if (!role?.__metrics || !role?.is_manager) {
    return;
  }

  const roleMetrics = role.__metrics;

  const isVisible = role.__visibility === Visibility.FULL;
  const { total, visible } = role.__metrics.self;

  roleMetrics.managed.visible = {
    ...roleMetrics.managed.visible,
    activities: visible.activities,
    hours: visible.hours,
  };

  // iterate path and metrics
  [...role.__managed[DesignEntity.ROLE]].forEach((id) => {
    const managedRole = snapshot.entities.roles[id];
    const managedRoleMetrics = managedRole.__metrics;
    const visibleMetrics = managedRoleMetrics.self.visible;
    if (!managedRoleMetrics || managedRole.__visibility === Visibility.NONE) {
      return;
    }

    const isSpan = managedRole.__manager === role.uuid;

    roleMetrics.managed.visible = updateMetrics({
      target: { ...roleMetrics.managed.visible },
      source: {
        ...defaultMetricGroup(false),
        activities: visibleMetrics.activities,
        budget: visibleMetrics.budget,
        fte: visibleMetrics.fte,
        hours: visibleMetrics.hours,
        managers: managedRole.is_manager ? 1 : 0,
        roles: 1,
      },
    });

    if (isSpan) {
      roleMetrics.span.visible = updateMetrics({
        target: { ...roleMetrics.span.visible },
        source: {
          ...defaultMetricGroup(false),
          activities: visibleMetrics.activities,
          budget: visibleMetrics.budget,
          fte: visibleMetrics.fte,
          hours: visibleMetrics.hours,
          managers: managedRole.is_manager ? 1 : 0,
          roles: 1,
        },
      });
    }
  });

  [...role.__managed[DesignEntity.GROUP]].forEach((id) => {
    const managedGroup = snapshot.entities.groups[id];
    const managedGroupMetrics = managedGroup.__metrics;
    const visibleMetrics = managedGroupMetrics.self.visible;

    if (!managedGroupMetrics || managedGroup.__visibility === Visibility.NONE) {
      return;
    }

    roleMetrics.managed.visible.groups += 1;

    if (managedGroup.__manager === role.uuid) {
      roleMetrics.span.visible.groups += 1;
    }
  });
}

/**
 * Calculates the role based metrics for group spans and managers.
 *
 * @param {Object}
 *
 * @return {Object}
 */
function deriveGroupSpanFromRole({ role, snapshot }) {
  if (!role?.__metrics || role.__visibility === Visibility.NONE) {
    return;
  }

  const roleMetrics = role.__metrics;
  const { total, visible } = roleMetrics.self;

  [...role.__above[DesignEntity.GROUP]].forEach((id) => {
    const group = snapshot.entities.groups[id];
    const groupMetrics = group.__metrics;

    if (role.__visibility === Visibility.NONE) {
      return;
    }

    const isSpan = group.__span[DesignEntity.ROLE].has(role.uuid);

    groupMetrics.managed.visible = updateMetrics({
      target: { ...groupMetrics.managed.visible },
      source: {
        ...defaultMetricGroup(false),
        activities: visible.activities,
        budget: visible.budget,
        fte: visible.fte,
        hours: visible.hours,
        managers: role.is_manager ? 1 : 0,
        roles: 1,
      },
    });

    if (isSpan) {
      groupMetrics.span.visible = updateMetrics({
        target: { ...groupMetrics.span.visible },
        source: {
          ...defaultMetricGroup(false),
          activities: visible.activities,
          budget: visible.budget,
          fte: visible.fte,
          hours: visible.hours,
          managers: role.is_manager ? 1 : 0,
          roles: 1,
        },
      });
    }
  });
}

/**
 * Calculates the metrics for managed groups.
 *
 * @param {Object}
 *
 * @return {Object}
 */
function deriveGroupSpan({ group, snapshot }) {
  if (!group?.__metrics) {
    return;
  }

  const groupMetrics = group.__metrics;

  [...group.__managed[DesignEntity.GROUP]].forEach((id) => {
    const managedGroup = snapshot.entities.groups[id];

    if (managedGroup.__visibility === Visibility.NONE) {
      return;
    }

    const isSpan = group.__span[DesignEntity.GROUP].has(id);

    groupMetrics.managed.visible = updateMetrics({
      target: { ...groupMetrics.managed.visible },
      source: {
        ...defaultMetricGroup(false),
        groups: 1,
      },
    });

    groupMetrics.span.visible = updateMetrics({
      target: { ...groupMetrics.span.visible },
      source: {
        ...defaultMetricGroup(false),
        groups: 1,
      },
    });
  });
}

/**
 * Derives the span and manager metrics for roles and groups.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function deriveSpanMetrics(snapshot) {
  snapshot.__keys.roles.forEach((id) => {
    const role = snapshot.entities.roles[id];

    deriveRoleSpan({
      role,
      snapshot,
    });

    deriveGroupSpanFromRole({
      role,
      snapshot,
    });
  });

  // This derives the group level metrics for managed metrics and spans.
  snapshot.__keys.groups.forEach((id) => {
    const group = snapshot.entities.groups[id];

    deriveGroupSpan({
      group,
      snapshot,
    });
  });

  return snapshot;
}
