import {
  ComparisonOperator as Comparison,
  LogicalOperator as Logical,
} from '@/organisms/filters/enums';

const OperatorMap = new Map([
  [Comparison.AFTER, { label: 'At or after' }],
  [Comparison.BEFORE, { label: 'At or before' }],
  [Comparison.NOT_CONTAINS, { label: 'Does not contain' }],
  [Comparison.CONTAINS, { label: 'Contains' }],
  [Comparison.CONTAINS_ANY_OF, { label: 'Contains any of' }],
  [Comparison.EQUALS, { label: 'Equals' }],
  [Comparison.EXCLUDE_ANY, { label: 'Excludes any' }],
  [Comparison.EXCLUDE_ALL, { label: 'Excludes all' }],
  [Comparison.GREATER_THAN, { label: 'Greater than' }],
  [Comparison.GREATER_THAN_OR_EQUAL, { label: 'Greater than or equal' }],
  [Comparison.INCLUDE_ANY, { label: 'Includes any' }],
  [Comparison.INCLUDE_ALL, { label: 'Includes all' }],
  [Comparison.IS, { label: 'Is' }],
  [Comparison.IS_NOT, { label: 'Is not' }],
  [Comparison.IS_NOT_ONE_OF, { label: 'Is not one of' }],
  [Comparison.IS_ONE_OF, { label: 'Is one of' }],
  [Comparison.LESS_THAN, { label: 'Less than' }],
  [Comparison.LESS_THAN_OR_EQUAL, { label: 'Less than or equal' }],
  [Comparison.NOT_EQUAL_TO, { label: 'Does not equal' }],
  [Comparison.STARTS_WITH, { label: 'Starts with' }],
  [Logical.AND, { label: 'And' }],
  [Logical.OR, { label: 'Or' }],
]);

export default OperatorMap;
