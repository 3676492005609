import { Snapshots } from '@pkg/entities';
import { Collections } from '@pkg/utils';
import config from '@/lib/config';

export default function deriveProps(library, snapshot) {
  if (!snapshot) {
    return snapshot;
  }

  const derived = Snapshots.deriveProps({
    library,
    snapshot,
  });

  derived.entities.roles.forEach((role) => {
    role.__visibility = role.__enabled
      ? config.VISIBILITY.FULL
      : config.VISIBILITY.NONE;

    const roleActivities = Collections.where(
      derived.entities.activities,
      'owner_uuid',
      role.uuid
    );

    roleActivities.forEach((activity) => {
      activity.__visibility = role.__visibility;
    });
  });

  return derived;
}
