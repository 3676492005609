import { useRouter } from 'next/router';
import { useState, useEffect, useCallback, memo } from 'react';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import AddScenario from '@/organisms/forms/AddScenario/AddScenario';
import { ViewMode } from '@/shared/enums';
import { useFilterContext } from '@/shared/providers';
import { useViewModeContext } from '@/shared/providers';
import Tooltip from '@mui/material/Tooltip';
import { useAuthentication } from '@pkg/auth';
import { Activities } from '@pkg/entities/library';
import { Routes } from '@pkg/utils';
import shortuuid from '@pkg/uuid';
import { ActivityOwner } from '@/lib/enums';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';
import { useMessages } from '../context/MessagesContext';
import useCreatePrompt from '../hooks/useCreatePrompt';
import usePrompt from '../hooks/usePrompt';
import filtersPrompt from '../prompts/filters';
import intentPrompt from '../prompts/intent';
// import mutationsPrompt from '../prompts/mutations';
import navigatePrompt from '../prompts/navigate';
import reportsPrompt from '../prompts/reports';
import { getReducers } from '../tools/config';
import createFilters from '../tools/createFilters/createFilters';
// import createMutation from '../tools/createMutation/createMutation';
import generateReport from '../tools/generateReport/generateReport';
import navigateTo from '../tools/navigateTo/navigateTo';
import { restoreUUIDs } from '../utils/uuidUtils';

export const ActionTypes = {
  generateReport: {
    label: 'Generate report',
    prompt: reportsPrompt,
    tools: { generateReport },
    callback: async (response) => {
      if (response.generateReport) {
        await response.generateReport.execute(response.generateReport);
      }
    },
  },
  createMutation: {
    label: 'Apply changes',
    // prompt: mutationsPrompt,
    // tools: { createMutation },
    // callback: async (response) => {
    //   if (response.createMutation) {
    //     await response.createMutation.execute(response.createMutation);
    //   }
    // },
  },
  applyMutation: {
    label: 'Apply changes',
  },
  applyFilters: {
    label: 'Apply filters',
    hide: true,
  },
  navigateTo: {
    label: 'Navigate To',
    prompt: navigatePrompt,
    tools: { navigateTo },
    callback: async (response) => {
      if (response.navigateTo) {
        await response.navigateTo.execute(response.navigateTo);
      }
    },
  },
  createFilters: {
    label: 'Apply filters',
    prompt: filtersPrompt,
    tools: { createFilters },
    callback: async (response) => {
      if (response.createFilters) {
        await response.createFilters.execute(response.createFilters);
      }
    },
  },
};

const toolReducerMap = getReducers();

function convertNumbers(obj) {
  // Check if the current item is an object or an array
  if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'object') {
          // Recursively call the function on nested objects or arrays
          convertNumbers(obj[key]);
        } else if (typeof obj[key] === 'string' && !isNaN(obj[key])) {
          // Convert strings that are numeric into actual numbers
          obj[key] = Number(obj[key]);
        }
      }
    }
  }
  return obj;
}

// Remove memo to ensure component updates with local state changes
function Action({ action: initialAction }) {
  // Create a local copy of the action to prevent mutation issues
  const [action, setAction] = useState(() => {
    // Ensure action has an ID on initialization
    if (!initialAction.id) {
      console.error('Action missing ID:', initialAction);
      initialAction.id = `act-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    }
    return initialAction;
  });

  // Update local action when props change
  useEffect(() => {
    if (!initialAction.id) {
      console.error('Action missing ID:', initialAction);
      initialAction.id = `act-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    }
    setAction(initialAction);
  }, [initialAction]);
  const { user } = useAuthentication();
  const { accessToken } = user;
  const {
    addMessage,
    getMessages,
    messages,
    addStream,
    setStatus,
    clearStatus,
    updateMessageAction,
  } = useMessages();
  const { selectViewMode } = useViewModeContext();
  const router = useRouter();
  const snapshot = useDesignStore((state) => state.snapshot);
  const mutate = useDesignStore((state) => state.actions.mutate);
  const [isApplied, setIsApplied] = useState(action?.applied || false);
  const actionType = ActionTypes[action?.type];
  const label = actionType?.label || 'Unknown';
  const { changeFilter } = useFilterContext();

  const { error, isLoading, data, prompt, stream } = usePrompt({
    tools: actionType?.tools,
    accessToken,
  });

  const createPrompt = useCreatePrompt();

  const createLibraryActivities = Activities.mutations.useCreateMany();
  const getExisting = Activities.useStore((state) => state.getExisting);

  const addActivitiesToLibrary = useCallback(
    async (activities) => {
      if (!activities || !activities.length) return [];

      const createActivities = [];
      const mappedActivities = activities.map((activity) => {
        let library_uuid = getExisting(activity.description);
        if (!library_uuid) {
          library_uuid = shortuuid.generate();
          createActivities.push({
            uuid: library_uuid,
            description: activity.description,
          });
        }

        return {
          new_uuid: shortuuid.generate(),
          library_uuid,
          hours: activity.hours,
          owner_type: ActivityOwner.ROLE,
        };
      });

      if (createActivities.length) {
        await createLibraryActivities(createActivities);
      }

      return mappedActivities;
    },
    [createLibraryActivities, getExisting]
  );

  // Initialize action state based on stored messages
  useEffect(() => {
    if (!action?.messageId || !action?.id) {
      console.error('Missing required action properties:', { action });
      return;
    }

    const storedMessages = localStorage.getItem('messages');
    if (!storedMessages) {
      console.debug('No stored messages found');
      return;
    }

    try {
      const messages = JSON.parse(storedMessages);
      const storedAction = messages
        .flatMap((msg) => msg.actions || [])
        .find((act) => act?.id === action.id);

      console.debug('Action state:', {
        actionId: action.id,
        messageId: action.messageId,
        storedAction,
        currentApplied: action.applied,
        isApplied,
      });

      if (storedAction?.applied !== action.applied) {
        // Update local state to match stored state
        action.applied = Boolean(storedAction?.applied);
        setIsApplied(Boolean(storedAction?.applied));
      }
    } catch (error) {
      console.error('Error loading stored messages:', error);
    }
  }, [action?.messageId, action?.id]);

  // Helper function to ensure consistent state updates
  const updateAppliedState = useCallback(
    (newAppliedState) => {
      // Update both the action object and React state
      if (action?.messageId && action?.id) {
        console.debug('Updating action state:', {
          messageId: action.messageId,
          actionId: action.id,
          newState: newAppliedState,
          currentApplied: action.applied,
          currentState: isApplied,
        });

        // Update local state
        setIsApplied(newAppliedState);
        action.applied = newAppliedState;

        // Update in messages context
        updateMessageAction(action.messageId, action.id, {
          applied: newAppliedState,
        });

        // Update local state
        action.applied = newAppliedState;
        setIsApplied(newAppliedState);

        // Force immediate storage update
        const storedMessages = localStorage.getItem('messages');
        if (storedMessages) {
          const messages = JSON.parse(storedMessages);
          const updatedMessages = messages.map((msg) => {
            if (msg.id === action.messageId) {
              return {
                ...msg,
                actions:
                  msg.actions?.map((act) =>
                    act.id === action.id
                      ? { ...act, applied: newAppliedState }
                      : act
                  ) || [],
              };
            }
            return msg;
          });
          localStorage.setItem('messages', JSON.stringify(updatedMessages));

          // Verify the update
          const verifyMessages = JSON.parse(localStorage.getItem('messages'));
          const verifyAction = verifyMessages
            .flatMap((msg) => msg.actions || [])
            .find((act) => act && act.id === action.id);

          console.debug('Storage update verification:', {
            actionId: action.id,
            storedApplied: verifyAction?.applied,
            expectedApplied: newAppliedState,
          });
        }
      }
    },
    [action, updateMessageAction]
  );

  // Force UI update when applied state changes
  useEffect(() => {
    if (action?.applied) {
      console.debug('Action marked as applied, updating state:', {
        actionId: action.id,
        currentApplied: action.applied,
        isApplied,
      });
      setIsApplied(true);
    }
  }, [action]);

  const handleAction = useCallback(async () => {
    // Check if action is already applied or loading
    if (isApplied || isLoading) {
      console.debug('Action skipped:', {
        actionId: action?.id,
        messageId: action?.messageId,
        isApplied,
        isLoading,
      });
      return;
    }

    // Validate required properties
    if (!action?.type || !action?.id || !action?.messageId) {
      console.error('Invalid action configuration:', { action });
      return;
    }

    if (!actionType?.prompt) {
      setStatus('Applying changes...');
      let allToolsSucceeded = true;

      if (action.type === 'createMutation' && action.parameters?.toolResults) {
        const tools = action.parameters.toolResults;
        const { uuidMap } = action.parameters;

        // Restore original UUIDs in the tools results if mapping exists
        const processedTools = uuidMap ? restoreUUIDs(tools, uuidMap) : tools;

        // Store processedTools for UUID validation
        action.parameters.processedTools = processedTools;
        for (const tool of processedTools) {
          const toolName = tool.toolName;
          let payload = tool.result.payload;
          const total = tool.result.total;

          if (toolName === 'roleAdd') {
            try {
              const suggestedActivities = await addActivitiesToLibrary(
                tool.result.activities
              );
              if (!suggestedActivities || !suggestedActivities.length) {
                console.error('Failed to add activities to library:', {
                  tool,
                  suggestedActivities,
                });
                allToolsSucceeded = false;
                break;
              }
              payload.activities = suggestedActivities;
            } catch (error) {
              console.error('Failed to add activities:', {
                error,
                tool,
                action,
              });
              allToolsSucceeded = false;
              break;
            }
          }

          if (toolName === 'groupAssignLead') {
            // Fetch the role entity from the payload.leadId
            const role = snapshot.entities.roles.find(
              (role) => role.uuid === payload.leadId
            );

            if (!role) {
              console.error('Could not find role with UUID:', {
                leadId: payload.leadId,
                tool,
                action,
              });
              allToolsSucceeded = false;
              break;
            }

            payload.groupId = role.group_uuid ?? null;
            if (!payload.groupId) {
              console.error('Role has no group UUID:', { role, tool, action });
              allToolsSucceeded = false;
              break;
            }
          }

          if (toolName === 'activityArchive') {
            // the activity archive reducer doesnt take an object as its second parameter
            payload = payload.ids;
          }

          const reducer = toolReducerMap[toolName];

          const timesToApply = total || 1;
          try {
            for (let i = 0; i < timesToApply; i++) {
              const mutation = reducer(snapshot, payload);
              if (mutation) {
                mutate(mutation, 'ai');
              } else {
                console.error('No mutation generated:', { toolName, payload });
                allToolsSucceeded = false;
                break;
              }
            }
          } catch (error) {
            console.error('Mutation failed:', { error, toolName, payload });
            allToolsSucceeded = false;
          }
        }
      }
      if (allToolsSucceeded) {
        // Only mark as applied if all tools succeeded
        console.debug('All tools succeeded, marking as applied:', {
          actionId: action.id,
          messageId: action.messageId,
          currentApplied: action.applied,
          isApplied,
        });
        updateAppliedState(true);
        setStatus('Changes applied successfully');
      } else {
        // Log error and show failure message
        console.error('Tool execution failed:', {
          actionId: action.id,
          messageId: action.messageId,
          allToolsSucceeded,
        });
        updateAppliedState(false);
      }

      // Clear status after a delay
      setTimeout(() => {
        clearStatus();
      }, 3000);
      return;
    }

    try {
      console.debug('Starting intent analysis:', {
        actionId: action.id,
        messageId: action.messageId,
        type: action.type,
      });

      const intentOptions = await createPrompt({
        basePrompt: intentPrompt,
        userText: action.parameters.userQuestion,
      });

      setStatus('Analyzing intent...');

      const intentResult = await prompt(intentOptions);
      if (!intentResult?.toolResults?.[0]?.result) {
        throw new Error('Failed to determine intent');
      }

      const intent = intentResult.toolResults[0].result;
      console.debug('Intent determined:', {
        actionId: action.id,
        intent,
      });

      const promptOptions = await createPrompt({
        intent: intentResult,
        basePrompt: actionType.prompt,
        userText: action.parameters.userQuestion,
      });

      setStatus('Processing request...');

      const response = await prompt(promptOptions);
      if (!response) {
        throw new Error('No response received from prompt');
      }

      if (actionType.callback) {
        console.debug('Executing callback:', {
          actionId: action.id,
          type: action.type,
        });

        await actionType.callback(response);

        console.debug('Callback executed successfully:', {
          actionId: action.id,
          messageId: action.messageId,
        });

        updateAppliedState(true);
      }

      const data = response;

      if (!data) return;
      if (action.type === 'generateReport') {
        console.debug('Generating report:', {
          actionId: action.id,
          messageId: action.messageId,
        });
        addStream({ action, stream: response });
        clearStatus();
        return;
      }

      if (action.type === 'createFilters') {
        console.debug('Creating filters:', {
          actionId: action.id,
          messageId: action.messageId,
        });

        const filters = data?.toolResults?.[0]?.args?.filters?.conditions;
        if (!filters) {
          throw new Error('No filter conditions found in response');
        }

        const processedFilters = convertNumbers(filters);
        const newConditions = processedFilters.map(
          (condition) => new Map(Object.entries(condition))
        );

        try {
          changeFilter({ groups: [{ conditions: newConditions }] });
          console.debug('Filters applied successfully:', {
            actionId: action.id,
            filterCount: newConditions.length,
          });

          addMessage({
            text: 'Filters applied successfully',
            sender: 'AI',
            action: 'applyFilters',
            parameters: {
              uuid: shortuuid.generate(),
              toolResults: data.toolResults,
            },
          });
        } catch (error) {
          console.error('Failed to apply filters:', {
            actionId: action.id,
            error: error.message || error,
          });
          throw new Error('Failed to apply filters');
        }
      }

      if (action.type === 'navigateTo') {
        const url = data?.toolResults?.[0]?.args?.url;
        if (!url) {
          throw new Error('No navigation URL found in response');
        }

        console.debug('Navigating to:', {
          actionId: action.id,
          url,
        });
        window.location.href = url;
      }

      if (action.type === 'createMutation') {
        if (data?.toolResults?.length > 0) {
          console.debug('Creating mutation:', {
            actionId: action.id,
            toolCount: data.toolResults.length,
          });

          addMessage({
            text: 'Preparing to apply changes',
            action: 'applyMutation',
            parameters: {
              uuid: shortuuid.generate(),
              toolResults: data.toolResults,
            },
          });
        } else {
          console.warn('No tool results for mutation:', {
            actionId: action.id,
            data,
          });

          const message =
            data?.choices?.[0]?.message?.content || 'No changes to apply';
          addMessage({
            text: message,
            action: 'applyMutation',
            parameters: {},
          });
        }
      }
    } catch (err) {
      console.error('Error executing action:', {
        actionId: action?.id,
        messageId: action?.messageId,
        type: action?.type,
        error: err.message || err,
        err,
      });

      // Set error state
      updateAppliedState(false);

      // Add error message
      addMessage({
        text: `Error: ${err.message || 'Failed to execute action'}`,
        sender: 'AI',
        parameters: {
          uuid: shortuuid.generate(),
          error: err.message || err,
          actionId: action?.id,
          messageId: action?.messageId,
        },
        error: true,
      });
    } finally {
      // Clear status and error after delay
      setTimeout(() => {
        clearStatus();
      }, 3000);
    }
  }, [action, actionType, createPrompt, isLoading, prompt]);

  if (actionType?.hide) {
    return null;
  } else {
    return (
      <span style={{ color: 'green', marginLeft: '5px' }}>
        {action.type === 'createMutation' && (
          <>
            <Button
              color="secondary"
              label={isLoading ? 'Processing...' : label}
              onClick={handleAction}
              size={Size.SMALL}
              disabled={isLoading || isApplied || error}
            />
            <Tooltip title="Create a new scenario and automatically apply these changes to it">
              <span>
                <AddScenario
                  color="secondary"
                  variant="naked"
                  buttonSize={Size.SMALL}
                  boldButtonText={false}
                  disabled={isLoading || isApplied || error}
                  style={{ marginLeft: '8px' }}
                  onScenarioCreated={async (scenario) => {
                    if (scenario) {
                      const { scenarioId, scope, entity } = scenario;

                      // Mark this as a scenario creation action
                      const actionWithScenario = {
                        ...action,
                        parameters: {
                          ...action.parameters,
                          createScenario: true,
                          scenarioId,
                        },
                      };

                      // Update the state immediately for scenario creation
                      updateMessageAction(action.messageId, action.id, {
                        applied: true,
                        parameters: actionWithScenario.parameters,
                      });
                      // Use helper function for consistent updates
                      updateAppliedState(true);

                      // Build the navigation path to the new scenario
                      const path = Routes.build.designParts({
                        designId: scenarioId,
                        level: {
                          type: scope,
                          uuid: entity?.uuid ?? entity?.__uuid,
                        },
                        params: {
                          view: ViewMode.PLAN,
                        },
                      });

                      setStatus('Scenario created successfully');
                      // State handled by updateAppliedState

                      // Navigate to the scenario
                      if (path) {
                        await router.push(path);
                        handleAction();
                        selectViewMode(ViewMode.PLAN);
                      }
                    }
                  }}
                />
              </span>
            </Tooltip>
          </>
        )}

        {action.type === 'generateReport' && (
          <Button
            color="secondary"
            label={isLoading ? 'Processing...' : label}
            onClick={handleAction}
            size={Size.SMALL}
            disabled={isLoading || isApplied || error}
          />
        )}

        {error && (
          <span style={{ color: 'red', marginLeft: '5px', fontSize: '0.8em' }}>
            Error: {error.message}
          </span>
        )}
      </span>
    );
  }
}

Action.displayName = 'Action';

export default memo(Action, (prevProps, nextProps) => {
  // Only prevent re-renders when applied state hasn't changed
  if (prevProps.action?.applied !== nextProps.action?.applied) {
    return false; // Re-render when applied state changes
  }
  return prevProps.action === nextProps.action;
});
