import { Colors } from '@/atoms/icons';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Box from '@mui/material/Box';
import { styled } from '@mui/styles';
import excludeProps from '@/lib/theme/excludeProps';
import { core } from '@/lib/theme/tokens/palettes';

const StyledBox = styled(Box, {
  shouldForwardProp: excludeProps('color', 'isVisible'),
})(({ color, isVisible }) => ({
  ...Colors.icon[color].contained.normal,
  borderRadius: 4,
  color: core.white,
  height: 27,
  opacity: isVisible ? 1 : 0,
  textAlign: 'center',
  transition: 'opacity 100ms',
  width: '28px',
  zIndex: 0,
}));

const EditIcon = ({ color, isVisible }) => {
  return (
    <StyledBox color={color} isVisible={isVisible}>
      <EditRoundedIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />
    </StyledBox>
  );
};

export default EditIcon;
