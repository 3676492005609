import { percent, roundFloat } from '@pkg/utils/numbers';
import config from '@/lib/config';
import defaultMetricGroup from '../utils/defaultMetricGroup';
import updateRootMetrics from './shared/updateRootMetrics';

/**
 * Derives metrics for an individual activity.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function activityMetrics({ activity, role, group, metrics }) {
  // Skip disabled
  if (activity.disabled_at) {
    return { total: defaultMetricGroup(), visible: defaultMetricGroup() };
  }

  const activityPercentage = !activity.hours
    ? 0
    : percent(activity.hours, role?.__hours || activity.hours, 8) / 100;

  const isVisible = activity.__visibility === config.VISIBILITY.FULL;

  // Capture activity metrics that apply to all entities.
  const activityMetrics = {
    ...defaultMetricGroup(),
    activities: 1,
    hours: activity.hours,
  };

  // Total metrics are applied to the activity itself.
  const total = {
    ...activityMetrics,
    roles: role ? 1 : 0,
    groups: group ? 1 : 0,
    budget: role ? roundFloat(role.budget * activityPercentage) : 0,
    fte: role ? roundFloat(role.fte * activityPercentage) : 0,
  };

  activity.__budget = total.budget;
  activity.__fte = total.fte;
  activity.__percentage = roundFloat(activityPercentage);

  // Visible metrics are applied to the activity itself.
  const visible = isVisible ? { ...total } : defaultMetricGroup();

  // Updates the root values in the metrics object.
  updateRootMetrics({
    entityMetrics: activityMetrics,
    visibleMetrics: isVisible ? { ...activityMetrics } : null,
    metrics,
  });

  return { total, visible };
}
