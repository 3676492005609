import { useState } from 'react';
import {
  // Psychology,
  Assessment,
  GroupAdd,
  TipsAndUpdates,
  Lightbulb,
  BarChart,
} from '@mui/icons-material';

const quickActions = [
  {
    id: 'add-roles',
    title: 'Add Roles to Organization',
    description:
      'Let me help you define and add new roles to your organization structure.',
    icon: GroupAdd,
    prompt:
      'I need help adding some new roles to my organization. Can you help me define and structure them?',
    aiMessage:
      "I'd be happy to help you add new roles to your organization. Could you tell me more about the types of roles you're looking to add and their main responsibilities?",
  },
  {
    id: 'generate-automation-report',
    title: 'Generate Automation Report',
    description:
      'I can analyze your organization and identify processes that could benefit from automation and AI integration.',
    icon: Assessment,
    prompt:
      'Can you analyze our organization and generate a report on automation opportunities and how AI could make us more efficient?',
    aiMessage:
      "I'll help you identify automation opportunities and AI solutions to improve efficiency. To create a targeted report, which areas of your organization would you like me to focus on? For example: administrative tasks, data processing, customer interactions, or specific departments?",
  },
  {
    id: 'efficiency-study',
    title: 'Conduct Efficiency Study',
    description:
      "Let's examine your workflows and processes to identify optimization opportunities.",
    icon: BarChart,
    prompt:
      'Can you conduct an efficiency study of our current workflows and processes?',
    aiMessage:
      "I'll help you analyze your workflows and processes for efficiency improvements. To get started, which specific area or team's workflows would you like me to examine first?",
  },
];

const spotlights = [
  {
    id: 'automation-1',
    title: 'Automation Efficiency Found',
    description:
      'Multiple manual data entry tasks detected in the Sales team. Potential for 15 hours/week savings.',
    icon: TipsAndUpdates,
    severity: 'high',
  },
  {
    id: 'process-1',
    title: 'Process Improvement Opportunity',
    description:
      'Meeting frequency could be optimized in the Engineering team.',
    icon: Lightbulb,
    severity: 'medium',
  },
];

const CopilotIntro = ({ onActionClick }) => {
  const [selectedSpotlight, setSelectedSpotlight] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);

  const handleActionClick = (action) => {
    setSelectedAction(action);
    onActionClick(action.prompt, action.aiMessage);
  };

  const handleSpotlightClick = (spotlight) => {
    setSelectedSpotlight(
      spotlight.id === selectedSpotlight?.id ? null : spotlight
    );
  };

  const CardContainer = ({ children, isSelected, onClick, severity }) => (
    <div
      onClick={onClick}
      style={{
        padding: '12px',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        cursor: 'pointer',
        backgroundColor: isSelected ? '#f8f9fa' : '#fff',
        transition: 'all 0.2s',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '10px',
        boxShadow: isSelected ? '0 2px 8px rgba(0, 0, 0, 0.1)' : 'none',
        ':hover': {
          backgroundColor: '#f8f9fa',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      {children}
    </div>
  );

  return (
    <div style={{ padding: '0 16px', maxWidth: '600px', margin: '0' }}>
      <div style={{ marginBottom: '24px', textAlign: 'left' }}>
        <h2
          style={{
            fontSize: '20px',
            marginBottom: '8px',
            color: '#1a73e8',
          }}
        >
          Welcome to Copilot
        </h2>
        <p
          style={{
            fontSize: '14px',
            color: '#5f6368',
            lineHeight: '1.4',
            margin: 0,
          }}
        >
          I'm here to help you optimize your organization and improve team
          efficiency. What would you like to explore today?
        </p>
      </div>

      <div style={{ marginBottom: '24px' }}>
        <h3
          style={{
            fontSize: '16px',
            marginBottom: '12px',
            color: '#202124',
          }}
        >
          Quick Actions
        </h3>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {quickActions.map((action) => (
            <CardContainer
              key={action.id}
              isSelected={selectedAction?.id === action.id}
              onClick={() => handleActionClick(action)}
            >
              <action.icon
                style={{
                  color: '#1a73e8',
                  fontSize: '20px',
                  flexShrink: 0,
                  marginTop: '2px',
                }}
              />
              <div>
                <h4
                  style={{
                    margin: '0 0 4px 0',
                    fontSize: '14px',
                    color: '#202124',
                  }}
                >
                  {action.title}
                </h4>
                <p
                  style={{
                    margin: 0,
                    fontSize: '12px',
                    color: '#5f6368',
                  }}
                >
                  {action.description}
                </p>
              </div>
            </CardContainer>
          ))}
        </div>
      </div>
      {/* 
      <div>
        <h3 style={{ 
          fontSize: '16px', 
          marginBottom: '12px',
          color: '#202124'
        }}>
          Recent Spotlights
        </h3>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {spotlights.map((spotlight) => (
            <CardContainer
              key={spotlight.id}
              isSelected={selectedSpotlight?.id === spotlight.id}
              onClick={() => handleSpotlightClick(spotlight)}
              severity={spotlight.severity}
            >
              <spotlight.icon style={{ 
                color: spotlight.severity === 'high' ? '#d93025' : '#1a73e8',
                fontSize: '20px',
                flexShrink: 0,
                marginTop: '2px'
              }} />
              <div>
                <h4 style={{ 
                  margin: '0 0 4px 0',
                  fontSize: '14px',
                  color: '#202124'
                }}>
                  {spotlight.title}
                </h4>
                <p style={{ 
                  margin: 0,
                  fontSize: '12px',
                  color: '#5f6368'
                }}>
                  {spotlight.description}
                </p>
              </div>
            </CardContainer>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default CopilotIntro;
