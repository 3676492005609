import { Avatar } from '@/atoms/avatars';
import { StatusChip, EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { ChangeMetric } from '@/molecules/metrics';
import { OutlineCard } from '@/organisms/cards';
import { planEntityColors } from '@/organisms/plans';
import { ActionType, EntityType, Status } from '@/shared/enums';
import {
  useScenarioContext,
  useTagContext,
  useViewModeContext,
} from '@/shared/providers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
  determineChartType,
  determineEntityChange,
  determineMetric,
} from './utils';

const retrieveRole = ({ actions, comparisonScenario, id, scenario }) => {
  const role = scenario?.relationships?.get(EntityType.ROLE)?.get(id);

  if (role) {
    return role;
  }

  return comparisonScenario?.relationships?.get(EntityType.ROLE)?.get(id);
};

const PlanManagerCard = ({
  actions,
  actionType,
  id,
  isSelected,
  onSelect,
  roleProperties,
  metrics,
}) => {
  const { tagMap } = useTagContext();
  const { comparisonScenario, scenario } = useScenarioContext();
  const role = retrieveRole({
    comparisonScenario,
    id,
    scenario,
  });
  const { showBudget } = useViewModeContext();
  const cardMetric = determineMetric({
    actionType,
    entityType: EntityType.MANAGER,
    metrics,
    showBudget,
  });

  const cardProperties = {
    actionType,
    props: { ...roleProperties },
  };

  if (cardMetric.tagId) {
    cardProperties.props.id = cardMetric.tagId;
    cardProperties.actionType = ActionType.UPDATED_TAGS;
  }

  const chartType = determineChartType(cardMetric.metricType);
  const colors = planEntityColors({
    actionType: cardProperties.actionType,
    actionProperties: cardProperties.props,
    entityType: EntityType.MANAGER,
    metric: cardMetric.metricType,
    tagMap,
  });

  const entityChange = determineEntityChange({
    metrics: cardMetric.metrics,
    entityCount: 1,
    entityType: EntityType.ROLE,
  });

  const assignee = role?.__person?.__name || 'Unassigned';
  const isNewRole = actions?.has(ActionType.ADDED_ROLE);
  const isRemovedRole = actions?.has(ActionType.REMOVED_ROLE);

  const handleSelect = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onSelect({
      id,
      props: {
        ...roleProperties,
        title: role?.title,
        role,
      },
      metrics,
      actions,
    });
  };

  return (
    <Stack direction="row" alignItems="center" width="100%">
      <Box width="136px" mr={1} flexShrink={0}>
        <ChangeMetric
          chartType={chartType}
          colors={colors}
          entityType={EntityType.ROLE}
          metric={cardMetric.metricType}
          metrics={cardMetric.metrics}
        />
      </Box>
      <Box flexGrow={1} onClick={handleSelect}>
        <OutlineCard padding={0} margin={0}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            p={1.5}
            sx={{
              boxShadow: isSelected
                ? `inset 0 0 0 1px ${colors.primary}, 0 0 0 1px ${colors.primary}`
                : 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              transition: 'box-shadow 200ms',
              '&: hover': !isSelected && {
                boxShadow: `inset 0 0 0 1px ${colors.secondary}, 0 0 0 1px ${colors.secondary}`,
              },
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar
                name={assignee}
                src={role?.__person?.avatar}
                sizeProps={{
                  fontSize: '1rem',
                  width: '40px',
                  height: '40px',
                }}
              />
              <Box>
                <Stack direction="row" spacing={1} alignItems="center" pb={0.5}>
                  <EntityChip
                    size={Size.XX_SMALL}
                    sx={{ height: 20, px: 1 }}
                    type={
                      role.is_manager ? EntityType.MANAGER : EntityType.ROLE
                    }
                  />
                  <Heading variant="h4">
                    {role.title}
                    {(isRemovedRole || isNewRole) && (
                      <Box
                        sx={{
                          display: 'inline',
                          ml: 0.75,
                          verticalAlign: 'text-bottom',
                        }}
                      >
                        {isRemovedRole && (
                          <StatusChip
                            status={Status.REMOVED}
                            size={Size.XX_SMALL}
                          />
                        )}
                        {isNewRole && (
                          <StatusChip
                            status={Status.NEW}
                            size={Size.XX_SMALL}
                          />
                        )}
                      </Box>
                    )}
                  </Heading>
                </Stack>
                <Heading variant="h6">{assignee}</Heading>
              </Box>
            </Stack>
          </Stack>
        </OutlineCard>
      </Box>
    </Stack>
  );
};

export default PlanManagerCard;
