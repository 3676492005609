import { EntityType } from '@/shared/enums';
import SnapshotMetricGroup from '@pkg/entities/snapshots/enums/SnapshotMetricGroup';

export default function aggregateMetrics(entity) {
  if (!entity) {
    return { ...SnapshotMetricGroup };
  }

  const averageSpan =
    entity.__type === EntityType.ORGANISATION
      ? entity.__metrics.visible.span / entity.__metrics.visible.managers
      : entity.__metrics.span.visible.roles /
        entity.__metrics.self.visible.managers;

  if (entity.__type === EntityType.ORGANISATION) {
    return {
      ...entity.__metrics.visible,
      averageSpan,
    };
  }

  if (entity.__type === EntityType.ROLE && entity.is_manager) {
    const metrics = {};
    Object.entries(entity.__metrics.managed.visible).forEach(([key, value]) => {
      metrics[key] = value + (entity.__metrics.self.visible[key] || 0);
    });
    return {
      ...metrics,
      averageSpan,
    };
  }
  if (entity.__type === EntityType.ROLE) {
    return {
      ...entity.__metrics.self.visible,
    };
  }

  if (entity.__type === EntityType.GROUP) {
    // We use the self activities to include unallocated activities for the
    // scenario group.
    return {
      ...entity.__metrics.managed.visible,
      activities: entity.__metrics.self.visible.activities,
      averageSpan,
    };
  }
}
