import { useCallback } from 'react';
import shortuuid from '@pkg/uuid';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';
import { useMessages } from '../context/MessagesContext';
import useCreatePrompt from '../hooks/useCreatePrompt';
import usePrompt from '../hooks/usePrompt';
import intentPrompt from '../prompts/intent';
import mainPrompt from '../prompts/main';

const useAIResponse = ({ accessToken }) => {
  const { addMessage, addStream, setStatus, clearStatus } = useMessages();
  const snapshot = useDesignStore((state) => state.snapshot);
  const createPrompt = useCreatePrompt();
  const { prompt } = usePrompt({ accessToken });
  const sendToAI = useCallback(
    async (userText) => {
      let message = {};
      try {
        window.localStorage.getItem('accessToken');

        const intentOptions = await createPrompt({
          basePrompt: intentPrompt,
          userText: userText,
        });

        // Show status
        setStatus('Figuring out intent...');

        const intentResult = await prompt(intentOptions);
        console.log('Intent Result:', intentResult);

        setStatus('Generating response...');

        const promptOptions = await createPrompt({
          intent: intentResult,
          basePrompt: mainPrompt,
          userText: userText,
        });

        console.log('Prompt Options:', {
          promptOptions,
          mainPrompt,
          intentResult,
        });

        console.debug('Main Prompt Options:', promptOptions);
        const result = await prompt(promptOptions);

        // If we didn't get a result, provide a fallback message
        if (!result || Object.keys(result).length === 0) {
          message = {
            text: 'I was unable to understand your request. Could you please rephrase it?',
            type: 'ai',
            sender: 'AI',
            parameters: {
              uuid: shortuuid.generate(),
            },
          };
          addMessage(message);
        }

        clearStatus();

        if (result?.toolResults?.length) {
          // Check if any tool is a mutation tool
          const mutationTools = [
            'roleAdd',
            'roleUpdate',
            'roleMove',
            'activityUpdate',
            'groupUpdate',
            'groupAdd',
            'groupMove',
            'groupAssignLead',
            'activityTagging',
            'roleTagging',
            'groupArchive',
            'roleArchive',
            'activityArchive',
          ];

          const hasMutation = result.toolResults.some((tool) =>
            mutationTools.includes(tool.toolName)
          );

          if (hasMutation) {
            message = {
              text: 'The following changes will be applied',
              type: 'ai',
              sender: 'AI',
              action: 'createMutation',
              parameters: {
                uuid: shortuuid.generate(),
                userQuestion: userText,
                toolResults: result.toolResults,
                snapshot: structuredClone(snapshot),
                uuidMap: structuredClone(promptOptions.uuidMap),
              },
              show: true,
            };
          } else if (result.toolResults[0].toolName === 'generateReport') {
            const question = result.toolResults[0].result.question;
            const keywords = result.toolResults[0].result.keywords;

            message = {
              text: question,
              type: 'ai',
              sender: 'AI',
              action: 'generateReport',
              parameters: {
                uuid: shortuuid.generate(),
                userQuestion: userText,
                question,
                keywords,
              },
            };
          } else if (result.toolResults[0].toolName === 'createFilters') {
            const filterQuestion = result.toolResults[0].result.filter_question;
            message = {
              text: filterQuestion,
              type: 'ai',
              sender: 'AI',
              action: 'createFilters',
              parameters: {
                uuid: shortuuid.generate(),
                userQuestion: userText,
                filterQuestion,
              },
            };
          } else if (result.toolResults[0].toolName === 'navigateTo') {
            const url = result.toolResults[0].result.url;
            const explanation = result.toolResults[0].result.explanation;
            message = {
              text: explanation,
              type: 'ai',
              sender: 'AI',
              action: 'navigateTo',
              parameters: {
                url,
                userQuestion: explanation,
              },
            };
          }
        } else {
          message = {
            text: result.text,
            sender: 'AI',
            parameters: { uuid: shortuuid.generate() },
          };

          // Retrigger the request with streaming enabled
          const promptStreamOptions = await createPrompt({
            basePrompt: mainPrompt,
            intent: intentResult,
            userText: userText,
            stream: true,
          });

          setStatus('Streaming response...');

          const streamResult = await prompt({
            ...promptStreamOptions,
            stream: true,
          });

          clearStatus();

          return { message, stream: streamResult };
        }
      } catch (error) {
        console.error('Error fetching AI response:', error);
        message = {
          text: 'There was an error processing your request.',
          sender: 'AI',
          parameters: { uuid: shortuuid.generate() },
        };
      }

      // Clear status since we got a response
      clearStatus();

      return { message, stream: null };
    },
    [
      accessToken,
      addMessage,
      addStream,
      createPrompt,
      prompt,
      setStatus,
      clearStatus,
    ]
  );

  return { sendToAI };
};

export default useAIResponse;
