import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

/**
 * Adds newly appointed managers to the actions map.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function addedManagersAction({
  actionMap,
  comparisonScenario,
  originalRole,
  role,
  scenario,
}) {
  const action = actionMap?.get(ActionType.ADD_MANAGERS) ?? {
    entity: EntityType.MANAGER,
    metrics: defaultMetrics({ comparisonScenario }),
    type: ActionType.ADD_MANAGERS,
    [EntityType.MANAGER]: new Map(),
  };

  action.metrics[1].managers += 1;

  // Aggregate the individual manager action metrics.
  const actionManager = action[EntityType.MANAGER].get(role.uuid) ?? {
    metrics: defaultMetrics({ comparisonScenario, role }),
  };

  const managerProperties = {};

  // Add the parent manager.
  if (role.__manager) {
    managerProperties.moved = {
      manager: {
        to: role.__manager,
      },
    };
  }

  // Add the parent group.
  if (role.group_uuid) {
    managerProperties.moved = {
      ...managerProperties.moved,
      group: {
        to: role.group_uuid,
      },
    };
  }

  // Add the assignee.
  managerProperties.assigned = {
    to: role.user_uuid || null,
  };

  actionManager.props = { ...managerProperties };

  actionManager.metrics[1].managers += 1;

  action[EntityType.MANAGER].set(role.uuid, actionManager);

  const managerCount = action[EntityType.MANAGER].size;
  const managerLabel = entityLabel(EntityType.MANAGER, managerCount !== 1);
  const verb = managerCount === 1 ? 'was' : 'were';

  action.title = `${managerCount} ${managerLabel} ${verb} added`;

  return action;
}
