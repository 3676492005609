// import { useEffect, useState } from 'react';
import { PlanEntityTypeCard } from '@/organisms/cards';
import { EmptyPlanList, PlanListSkeleton } from '@/organisms/plans';
import { EntityName } from '@/shared/enums';
import { usePlanContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';
import useMappedEntityChanges from './hooks/useMappedEntityChanges';

const PlanEntityTypeList = ({ entityType, onSelect }) => {
  const { entities, isLoading } = useMappedEntityChanges({ entityType });
  const { selectedSummaryItem, selectSummaryItem } = usePlanContext();
  const entityName = EntityName[entityType];

  const handleSummarySelect = (event, selectedEntity) => {
    event.preventDefault();
    event.stopPropagation();

    selectSummaryItem({
      ...selectedEntity,
      entityType,
    });
  };

  const handleEntitySelect = (event, selectedEntity) => {
    event.preventDefault();
    event.stopPropagation();

    if (selectedSummaryItem?.id !== selectedEntity.id) {
      selectSummaryItem({
        ...selectedEntity,
        entityType,
      });
    }

    onSelect(selectedEntity);
  };

  return isLoading ? (
    <PlanListSkeleton />
  ) : (
    <>
      {entities.length > 0 ? (
        <Grid container spacing={2} p={2}>
          {entities.map((entity) => (
            <Grid
              item
              xs={12}
              key={entity.props.title}
              onClick={(event) => handleSummarySelect(event, entity)}
            >
              {entity[entityType] && (
                <PlanEntityTypeCard
                  entityType={entityType}
                  metrics={entity.metrics}
                  entityCount={entity[entityType]?.size || 0}
                  entityMap={entity[entityType]}
                  isSelected={entity.id === selectedSummaryItem?.id}
                  onEdit={(event) => handleEntitySelect(event, entity)}
                  title={entity.props.title}
                />
              )}
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyPlanList
          message={`No ${entityName.toLowerCase()} changes have been found in this scenario.`}
        />
      )}
    </>
  );
};

export default PlanEntityTypeList;
