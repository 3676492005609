import { Card as CardColors } from '@/atoms/colors';
import { ScenarioSummary } from '@/molecules/summaries';
import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Close from '@pkg/icons/Close';
import excludeProps from '@/lib/theme/excludeProps';
import Box from '@/components/Box';
import Typography from '@/components/Typography';

const Root = styled(Box, { shouldForwardProp: excludeProps('compareColor') })(
  ({ compareColor }) => ({
    minWidth: 260,
    padding: 4,
    backgroundColor: CardColors.outline.backgroundColor,
    borderLeft: `7px solid ${compareColor?.fill}`,
    borderRadius: 4,
    boxShadow: `inset 0 0 0 1px ${CardColors.outline.borderColor}, 0 1px 3px 1px ${CardColors.outline.borderColor}44`,
    overflow: 'hidden',
  })
);

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: 2,
  right: 2,
  padding: 1,
});

export default function ActiveComparisonCard({ scenario, onCancelComparison }) {
  const compareColor = scenario?.comparisonColor;
  return (
    <Root compareColor={compareColor}>
      <Box p={1} borderRadius={0.5} position="relative">
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          {scenario?.entity ? (
            <>
              <ScenarioSummary
                scenario={scenario}
                showPerson={false}
                showEntityChip={true}
                showScenarioChip={true}
                showHistoricalChip={true}
                isLarge={false}
              />
              <Typography variant="body2" fontSize="0.825rem">
                {scenario?.entity.objective}
              </Typography>
            </>
          ) : (
            <Typography variant="body2" fontSize="0.825rem" sx={{ mr: 3 }}>
              Current selection not found in comparison scenario
            </Typography>
          )}
        </Stack>
        <CloseButton size="small" onClick={onCancelComparison}>
          <Close />
        </CloseButton>
      </Box>
    </Root>
  );
}
