import { useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Base as BaseColors } from '@/atoms/colors';
import { TagProvider } from '@/shared/providers';
import {
  ChevronLeft,
  ChevronRight,
  ChatBubbleOutline,
  Minimize,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useAuthentication } from '@pkg/auth';
import CopilotIntro from './components/CopilotIntro';
import Messages from './components/Messages';
import { useCopilot } from './context/CopilotContext';
import { useMessages, MessagesProvider } from './context/MessagesContext';
import useAIResponse from './hooks/useAIResponse';

const COPILOT_EXPANDED_KEY = 'copilot_expanded';

const Copilot = () => {
  const { copilotOpen, toggleCopilot } = useCopilot();
  const { user } = useAuthentication();
  const { accessToken } = user;
  const { addMessage, addStream, messages, status } = useMessages();
  const [thinking, setThinking] = useState(false);

  const [isExpanded, setIsExpanded] = useState(() => {
    if (typeof window !== 'undefined') {
      const saved = localStorage.getItem(COPILOT_EXPANDED_KEY);
      return saved ? JSON.parse(saved) : false;
    }
    return false;
  });

  const { sendToAI } = useAIResponse({ accessToken });
  const [input, setInput] = useState('');

  const [streamingMessage] = useState(null);

  const handleSendMessage = async () => {
    if (input.trim()) {
      addMessage({ text: input, sender: 'User' });

      setThinking(true);
      const { message, stream } = await sendToAI(input);
      console.debug({ message, stream });

      if (stream) {
        addStream({ stream });
      } else {
        addMessage(message);
      }

      setThinking(false);

      setInput('');
    }
  };

  const handleQuickAction = async (prompt, aiMessage) => {
    // First add the AI's question
    addMessage({
      text: aiMessage,
      sender: 'Bot',
    });

    // Then add the user's response
    addMessage({
      text: prompt,
      sender: 'User',
    });

    // Now process the response
    setThinking(true);
    const { message, stream } = await sendToAI(prompt);

    if (stream) {
      addStream({ stream });
    } else {
      addMessage(message);
    }

    setThinking(false);
  };

  const toggleExpand = () => {
    const newExpandedState = !isExpanded;
    setIsExpanded(newExpandedState);
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        COPILOT_EXPANDED_KEY,
        JSON.stringify(newExpandedState)
      );
    }
  };

  return copilotOpen ? (
    <div
      style={{
        display: 'flex',
        position: 'fixed',
        height: '80vh',
        bottom: '2rem',
        right: '2rem',
        width: isExpanded ? '800px' : '400px',
        background: '#fff',
        color: BaseColors.font.tertiary,
        zIndex: 1000,
        border: '1px solid #ddd',
        borderRadius: '8px',
        flexDirection: 'column',
        overflow: 'hidden',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        transition: 'width 0.3s ease-in-out',
      }}
    >
      <div
        style={{
          padding: '8px 16px',
          borderBottom: '1px solid #ddd',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>Copilot</div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Tooltip title="Start new chat" placement="bottom" arrow>
            <span>
              <Button
                onClick={() => addMessage({ type: 'clear' })}
                style={{
                  minWidth: 'unset',
                  padding: '4px',
                }}
                color="white"
                size="small"
                startIcon={<ChatBubbleOutline />}
              />
            </span>
          </Tooltip>
          <Tooltip
            title={isExpanded ? 'Collapse' : 'Expand'}
            placement="bottom"
            arrow
          >
            <span>
              <Button
                onClick={toggleExpand}
                style={{
                  minWidth: 'unset',
                  padding: '4px',
                }}
                color="white"
                size="small"
                startIcon={isExpanded ? <ChevronRight /> : <ChevronLeft />}
              />
            </span>
          </Tooltip>
          <Tooltip title="Minimize Copilot" placement="bottom" arrow>
            <span>
              <Button
                onClick={toggleCopilot}
                style={{
                  minWidth: 'unset',
                  padding: '4px',
                }}
                color="white"
                size="small"
                startIcon={<Minimize />}
              />
            </span>
          </Tooltip>
        </div>
      </div>

      <div
        style={{
          flex: 1,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          borderBottom: '1px solid #ddd',
          height: 'calc(100% - 120px)',
        }}
      >
        {messages.length === 0 && !thinking && (
          <CopilotIntro
            onActionClick={(prompt, aiMessage) => {
              handleQuickAction(prompt, aiMessage);
            }}
          />
        )}
        <Messages
          status={status}
          thinking={thinking}
          messages={
            streamingMessage ? [...messages, streamingMessage] : messages
          }
        />
        <div style={{ height: '40px' }}></div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px',
          borderTop: '1px solid #ddd',
        }}
      >
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (!thinking) {
                handleSendMessage();
              }
            }
          }}
          style={{
            flex: 1,
            padding: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            marginRight: '8px',
          }}
          placeholder="Type a message..."
          disabled={thinking}
        />
        <Button
          color="secondary"
          label="Send"
          onClick={handleSendMessage}
          size="large"
          disabled={thinking}
        />
      </div>
    </div>
  ) : null;
};

const CopilotWithProviders = () => {
  return (
    <MessagesProvider>
      <TagProvider>
        <Copilot />
      </TagProvider>
    </MessagesProvider>
  );
};

export default CopilotWithProviders;
