const YAML = require('json-to-pretty-yaml');

const orgData = ({ tags, intentSnapshot, level }) => {
  const getLevelContext = () => {
    if (!level) return '';

    return `
Current Context Level: ${level.type}
Level UUID: ${level.uuid}

When creating new roles, follow these rules based on the current level:
- If level is "MANAGER": Set role.parent_uuid to ${level.uuid} (creating a role under this manager)
- If level is "GROUP": Set role.group_uuid to ${level.uuid} (creating a role in this group)
- If level is "ROLE": Set role.parent_uuid to ${level.uuid} (creating a sub-role)
- If level is "ORGANISATION": No specific UUID needs to be set (creating a top-level role)

Note: All UUIDs are 9-digit numbers (000000000-999999999) and must be used exactly as provided.
`;
  };

  return `
===== ORGANIZATION DATA START =====
  
  
  Here is the current organizational structure:

The following data outlines the current structure, roles, and activities within the organization. Use this data to analyze, answer questions, or generate insights tailored to the organization's context. When providing recommendations or insights, consider:
- The hierarchy and relationships between groups, roles, and activities.
- The metrics provided, ensuring data-driven responses.
- The organizational visibility constraints, ensuring contextually appropriate suggestions.

IMPORTANT: This section contains the ONLY REAL DATA about the organization. You MUST:
- ONLY use data provided in this section for all responses
- NEVER fabricate or invent organizational data not present here
- ALWAYS verify information against this data before responding
- NEVER reference fictional roles, groups, or activities not found in this data

${getLevelContext()}

Rules:
- The activity.owner_uuid matches to a role.uuid


### Tag Handling
Tags are referenced by UUIDs and support exact or fuzzy matching based on user queries.

**Fuzzy Matching Rules**:
- "meeting(s)" → Check "Internal meetings" AND "External meetings".
- "fun", "enjoy(able)", "love" → Match "Energising".
- "ai", "artificial intelligence", "automated", "automation" → Match "Automated By AI" AND "Augmented by AI".
- "important", "crucial", "critical", "strategic" → Match "Strategic importance" AND "Critical work".
- "admin(istrative)", "routine" → Match "Administrative".
- "efficiency", "improve", "enhancement", "optimize" → Match "Efficiency opportunity".
- "specialist", "expert", "advanced", "unique" → Match "Specialist capability".

**Rules**:
- Always use UUIDs from the tag list for matching.
- Check entity \`tags\` array for UUID presence.
- For multiple tags, verify ALL requested UUIDs are present unless specified otherwise.


  ${YAML.stringify(
    tags.map((tag) => ({
      uuid: tag.uuid,
      name: tag.name,
      description: tag.description,
    }))
  )}

  The organisational structure: 

  ${YAML.stringify(intentSnapshot)}

===== ORGANIZATION DATA END =====
`;
};

export default orgData;
