import toolsConfig from '../tools/config';

export const getToolsListing = () => {
  return `
    Available Tools:
    ${Object.entries(toolsConfig).reduce((acc, [key, config], index) => {
      return (
        acc +
        `
    Tool ${index + 1}:
      Name: ${config.title}
      Category: ${config.category}
      Description: ${config.description}
      Prompt: ${config.prompt}
      ---
   `
      );
    }, '')}
  `;
};

export default getToolsListing;
