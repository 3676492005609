import {
  FilterType,
  ComparisonOperator as Comparison,
} from '@/organisms/filters/enums';
import { OperatorMap } from '@/organisms/filters/utils';

export const BooleanComparator = new Map([
  [Comparison.IS, OperatorMap.get(Comparison.IS)],
]);

export const DateComparator = new Map([
  [Comparison.AFTER, OperatorMap.get(Comparison.AFTER)],
  [Comparison.BEFORE, OperatorMap.get(Comparison.BEFORE)],
]);

// Multi select comparison.
export const MultiSelectComparator = new Map([
  [Comparison.INCLUDE_ANY, OperatorMap.get(Comparison.INCLUDE_ANY)],
  [Comparison.INCLUDE_ALL, OperatorMap.get(Comparison.INCLUDE_ALL)],
  [Comparison.EXCLUDE_ANY, OperatorMap.get(Comparison.EXCLUDE_ANY)],
  [Comparison.EXCLUDE_ALL, OperatorMap.get(Comparison.EXCLUDE_ALL)],
]);

// Number comparisons.
export const NumberComparator = new Map([
  [Comparison.LESS_THAN, OperatorMap.get(Comparison.LESS_THAN)],
  [
    Comparison.LESS_THAN_OR_EQUAL,
    OperatorMap.get(Comparison.LESS_THAN_OR_EQUAL),
  ],
  [Comparison.EQUALS, OperatorMap.get(Comparison.EQUALS)],
  [
    Comparison.GREATER_THAN_OR_EQUAL,
    OperatorMap.get(Comparison.GREATER_THAN_OR_EQUAL),
  ],
  [Comparison.GREATER_THAN, OperatorMap.get(Comparison.GREATER_THAN)],
]);

// Single select comparison.
export const SelectComparator = new Map([
  [Comparison.INCLUDE_ALL, OperatorMap.get(Comparison.INCLUDE_ALL)],
]);

// Text comparisons.
export const TextComparator = new Map([
  [Comparison.STARTS_WITH, OperatorMap.get(Comparison.STARTS_WITH)],
  [Comparison.CONTAINS, OperatorMap.get(Comparison.CONTAINS)],
  [Comparison.NOT_CONTAINS, OperatorMap.get(Comparison.NOT_CONTAINS)],
  // [Comparison.CONTAINS_ANY_OF, OperatorMap.get(Comparison.CONTAINS_ANY_OF)],
]);

// Mapping allowable comparison types to their filter type.
const ComparatorMap = new Map([
  [FilterType.BOOLEAN, BooleanComparator],
  [FilterType.DATE, DateComparator],
  [FilterType.MULTI_SELECT, MultiSelectComparator],
  [FilterType.NUMBER, NumberComparator],
  [FilterType.SELECT, SelectComparator],
  [FilterType.TEXT, TextComparator],
]);

export default ComparatorMap;
