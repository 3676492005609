import { format } from '@pkg/utils/numbers';
import config from '@/lib/config';

// Calculated as 52.143 weeks in a year divided my 12 months.
const WEEKS_PER_MONTH = 4.345;
const HOURS_PER_DAY = config.FTE_HOURS_PER_WEEK / 5;

/**
 * Returns a dasy per week value based on the hours provided and per week.
 *
 * @param {Number} hours
 *
 * @return {Number}
 */
export default function hoursToDaysPerMonth(hours) {
  const daysPerMonth = (hours / HOURS_PER_DAY) * WEEKS_PER_MONTH;

  return format(daysPerMonth, {
    maximumFractionDigits: 1,
  });
}
