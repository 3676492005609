import { ActionType, EntityType } from '@/shared/enums';
import actionExists from './actionExists';
import getActionGroup from './getActionGroup';
import updatedTagAction from './updatedTagAction';

/**
 * Determines if the tag metrics have changed based on the tag type.
 *
 * @param {Object}
 *
 * @return {Boolean}
 */
function hasTagChanged({ metrics, originalMetrics, tagType }) {
  if (!metrics || !originalMetrics) {
    return true;
  }

  if (tagType === EntityType.ACTIVITY) {
    return metrics.hours !== originalMetrics.hours;
  }

  if (tagType === EntityType.ROLE) {
    return (
      metrics.fte !== originalMetrics.fte ||
      metrics?.budget !== originalMetrics?.budget
    );
  }
}

export default function addTagActions({
  actionMap,
  actionType,
  entity,
  comparisonScenario,
  originalEntity,
  scenario,
  tagType,
}) {
  const actions = [];
  const groupActionType = ActionType.UPDATED_TAGS;

  const entityTags = entity?.__metrics?.self?.visible?.tags?.[tagType];
  const originalTags =
    originalEntity?.__metrics?.self?.visible?.tags?.[tagType];

  const entityTagList = entityTags ? Object.keys(entityTags) : [];
  const originalTagList = originalTags ? Object.keys(originalTags) : [];

  const tagList = new Set([...entityTagList, ...originalTagList]);

  // Activity tag updated.
  [...tagList].forEach((tagId) => {
    const groupActionId = tagId;

    const entityTagMetrics =
      entity?.__metrics?.self?.visible?.tags?.[tagType]?.[tagId];

    const originalTagMetrics =
      originalEntity?.__metrics?.self?.visible?.tags?.[tagType]?.[tagId];

    const tagChanged = hasTagChanged({
      metrics: entityTagMetrics,
      originalMetrics: originalTagMetrics,
      tagType,
    });

    if (!tagChanged) {
      return;
    }

    const actionGroup = getActionGroup({
      actionMap,
      actionType,
      groupActionId: tagId,
      groupActionType,
      entityType: EntityType.TAG,
      props: {
        tagType,
      },
    });

    const hasTagChange =
      (entity || originalEntity) &&
      actionExists({
        actionMap: actionGroup,
        actionType,
        entityType: tagType,
        id: entity?.uuid || originalEntity?.uuid,
      });

    if (!hasTagChange) {
      actionMap
        .get(groupActionType)
        .actionMap?.get(tagId)
        ?.set(
          actionType,
          updatedTagAction({
            actionMap: actionGroup,
            actionType,
            entity,
            entityType: tagType,
            comparisonScenario,
            originalEntity,
            scenario,
            tag: { uuid: tagId },
          })
        );

      actions.push(actionMap.get(groupActionType));
    }
  });

  return actions;
}
