import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import MuiAvatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import excludeProps from '@/lib/theme/excludeProps';
import { color as tokenColors } from '@/lib/theme/tokens';
import Base from '../Base';

const Avatar = styled(MuiAvatar, {
  shouldForwardProp: excludeProps('color', 'hasClickEvent', 'size'),
})(({ color, hasClickEvent, size }) => ({
  boxSizing: 'border-box !important',
  cursor: hasClickEvent ? 'pointer' : 'inherit',
  height: size,
  width: size,
  background: color ? color : tokenColors.core.white,
  fontSize: size > 0 ? size / 2 : size,
}));

const Component = ({ user, size = 45, className, ...props }, ref) => (
  <Base
    user={user}
    component={
      <Avatar
        ref={ref}
        className={className}
        color={user?.__color}
        data-testid="static-avatar"
        hasClickEvent={Boolean(props.onClick)}
        size={size}
      />
    }
    {...props}
  />
);

const StaticAvatar = forwardRef(Component);

StaticAvatar.propTypes = {
  user: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default StaticAvatar;
