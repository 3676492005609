import { useMemo } from 'react';
import deriveHierarchy from './deriveHierarchy';

/**
 * @param {Object} snapshot
 * @param {Boolean} flatten
 * @returns {Object}
 */
export default function useHierarchy(snapshot, flatten = false) {
  return useMemo(() => {
    console.time('Snapshots.utils.useReduced');
    const children = deriveHierarchy({ snapshot, flatten });
    console.timeEnd('Snapshots.utils.useReduced');
    return children;
  }, [snapshot, flatten]);
}
