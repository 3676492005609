import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useEffect, useRef, memo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { PlanActionCard } from '@/organisms/cards';
import FilterGroup from '@/organisms/filters/FilterGroup';
import { mapAggregateActions } from '@/organisms/plans';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import * as Auth from '@pkg/auth';
import { Library } from '@pkg/entities';
import deriveSnapshot from '@pkg/entities/snapshots/derive/snapshot';
import localMutate from '@pkg/entities/snapshots/mutate';
import { getReducers } from '../tools/config';
import { restoreUUIDs } from '../utils/uuidUtils';
import Action from './Action';
import StatusPill from './StatusPill';

const toolReducerMap = getReducers();

const Actor = memo(function Actor({ children, type }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Avatar
        sx={{
          width: 32,
          height: 32,
          bgcolor: type === 'User' ? '#1976d2' : '#2e7d32',
        }}
      >
        {type === 'User' ? 'U' : 'B'}
      </Avatar>
      <span style={{ fontWeight: 'bold' }}>{children}</span>
    </div>
  );
});

const Message = memo(function Message({ msg }) {
  const [isHovered, setIsHovered] = useState(false);
  const library = Library.useLibrary();
  const messageRef = useRef(null);
  const { me, organisation } = Auth.useStore();

  const handleCopyMessage = () => {
    navigator.clipboard.writeText(msg.message);
  };

  const handleDownloadPDF = async () => {
    if (!messageRef.current) return;

    try {
      // Create a temporary container with proper styling
      const container = document.createElement('div');
      container.style.padding = '40px';
      container.style.background = 'white';
      container.style.width = '800px';
      container.style.fontSize = '12px'; // Reduce font size

      // Clone the message content
      const clone = messageRef.current.cloneNode(true);
      container.appendChild(clone);
      document.body.appendChild(container);

      const canvas = await html2canvas(container, {
        scale: 2,
        backgroundColor: '#ffffff',
        logging: false,
      });

      document.body.removeChild(container);

      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width / 2, canvas.height / 2],
      });

      // Add the main content
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width / 2, canvas.height / 2);

      // Add footer to each page
      const pageCount = pdf.getNumberOfPages();
      pdf.setFontSize(8);
      pdf.setTextColor(128, 128, 128); // Gray color

      for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        pdf.text(
          'Generated by Beamible',
          pdf.internal.pageSize.getWidth() / 2,
          pdf.internal.pageSize.getHeight() - 10,
          { align: 'center' }
        );
      }

      pdf.save('message.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Error generating PDF. Please try again.');
    }
  };

  // if msg.actions exist, we want to choose the first one check what type it is, if it is applyMutation, then we want to render another component

  let component = null;
  if (msg.actions) {
    const action = msg.actions[0];

    if (action?.type === 'createMutation') {
      try {
        const showBudget = true;
        let actions = null;
        let derivedUpdatedSnapshot = null;
        const snapshot = action.parameters.snapshot;
        const { uuidMap } = action.parameters;

        const tools = restoreUUIDs(action.parameters.toolResults, uuidMap);

        if (snapshot) {
          // calculate the bulk mutation locally
          const mutations = [];

          tools.forEach((tool) => {
            // skip tools that we don't want to render components for
            if (tool.toolName === 'activityTagging') {
              return;
            }

            const toolName = tool.toolName;
            const total = tool.result.total;
            const payload = tool.result.payload;
            const reducer = toolReducerMap[toolName];

            console.log({ toolReducerMap, toolName, total, payload, reducer });

            try {
              const timesToApply = total || 1;
              for (let i = 0; i < timesToApply; i++) {
                const mutation = reducer(snapshot, payload);
                if (mutation) {
                  mutations.push(mutation);
                }
              }
            } catch (error) {
              console.debug({ error });
            }
          });

          let updatedSnapshot = structuredClone(snapshot);

          mutations.forEach((mutation) => {
            updatedSnapshot = localMutate(updatedSnapshot, mutation);
          });

          derivedUpdatedSnapshot = deriveSnapshot({
            library,
            snapshot: updatedSnapshot,
          });

          // add snapshot entities to scenario maps
          const comparisonScenario = {
            relationships: new Map([
              [
                'ROLE',
                new Map(
                  snapshot.entities.roles.map((role) => [role.uuid, role]) || []
                ),
              ],
              [
                'ACTIVITY',
                new Map(
                  snapshot.entities.activities.map((activity) => [
                    activity.uuid,
                    activity,
                  ]) || []
                ),
              ],
              [
                'GROUP',
                new Map(
                  snapshot.entities.groups?.map((group) => [
                    group.uuid,
                    group,
                  ]) || []
                ),
              ],
              [
                'PERSON',
                new Map(
                  snapshot.entities.people?.map((person) => [
                    person.uuid,
                    person,
                  ]) || []
                ),
              ],
              [
                'MANAGER',
                new Map(
                  snapshot.entities.managers?.map((manager) => [
                    manager.uuid,
                    manager,
                  ]) || []
                ),
              ],
            ]),
          };

          const scenario = {
            relationships: new Map([
              [
                'ROLE',
                new Map(
                  derivedUpdatedSnapshot.entities.roles.map((role) => [
                    role.uuid,
                    role,
                  ]) || []
                ),
              ],
              [
                'ACTIVITY',
                new Map(
                  derivedUpdatedSnapshot.entities.activities?.map(
                    (activity) => [activity.uuid, activity]
                  ) || []
                ),
              ],
              [
                'GROUP',
                new Map(
                  derivedUpdatedSnapshot.entities.groups?.map((group) => [
                    group.uuid,
                    group,
                  ]) || []
                ),
              ],
              [
                'PERSON',
                new Map(
                  derivedUpdatedSnapshot.entities.people?.map((person) => [
                    person.uuid,
                    person,
                  ]) || []
                ),
              ],
              [
                'MANAGER',
                new Map(
                  derivedUpdatedSnapshot.entities.managers?.map((manager) => [
                    manager.uuid,
                    manager,
                  ]) || []
                ),
              ],
            ]),
          };

          actions = mapAggregateActions({
            comparisonScenario,
            scenario,
            showBudget,
          });
        }

        component = (
          <div
            style={{
              transform: 'scale(0.8)',
            }}
          >
            {actions &&
              Array.from(actions).map(([_, planAction]) => {
                return (
                  <div key={planAction.type}>
                    <PlanActionCard
                      title={planAction.title}
                      entityType={planAction.entity}
                      actionType={planAction.type}
                      metrics={planAction.metrics}
                    />
                    <br />
                  </div>
                );
              })}
          </div>
        );
      } catch (e) {
        console.log(e);
      }
    }

    if (action?.type === 'applyFilters') {
      const conditions =
        action.parameters.toolResults[0].result.filters.conditions;

      const conditionsMap = conditions.map(
        (condition, index) =>
          new Map([
            ['entity', condition.entity],
            ['property', condition.property],
            ['type', condition.type],
            ['propertyId', condition.propertyId || null],
            ['comparator', condition.comparator],
            ['value', condition.value],
          ])
      );

      component = (
        <div
          style={{
            transform: 'scale(0.8)',
          }}
        >
          <FilterGroup
            groupIndex={0}
            initialConditions={conditionsMap}
            onChange={null}
            readOnly
          />
        </div>
      );
    }
  }

  return (
    <div className={`message ${msg.sender}`} style={{ marginBottom: '0.5rem' }}>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'flex-start',
          marginBottom: '4px',
        }}
      >
        <Actor type={msg.sender}>
          {msg.sender === 'User' ? 'You: ' : 'Beamible: '}
        </Actor>
      </div>
      <div
        ref={messageRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          backgroundColor: '#ffffff',
          padding: '0 12px',
          borderRadius: '4px',
          marginLeft: '40px',
          position: 'relative',
          '& pre': {
            background: '#f6f8fa',
            padding: '0.75rem',
            borderRadius: '4px',
          },
          '& code': {
            background: '#f6f8fa',
            padding: '0.2rem 0.4rem',
            borderRadius: '3px',
          },
          '& p': { margin: '0.25rem 0' },
        }}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{msg.message}</ReactMarkdown>
        {isHovered && (
          <div
            style={{
              position: 'absolute',
              bottom: '-28px',
              right: '12px',
              display: 'flex',
              gap: '4px',
              background: 'white',
              padding: '4px',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              zIndex: 1,
            }}
          >
            <Tooltip title="Copy message">
              <IconButton size="small" onClick={handleCopyMessage}>
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download as PDF">
              <IconButton size="small" onClick={handleDownloadPDF}>
                <PictureAsPdfIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {msg.actions &&
          msg.actions.length > 0 &&
          (msg.actions[0].type === 'createMutation' ||
            msg.actions[0].type === 'applyFilters') &&
          msg.actions[0].parameters.toolResults && (
            <ul
              style={{
                marginTop: '8px',
                marginBottom: '8px',
                fontSize: '0.9em',
                opacity: 0.8,
              }}
            >
              {msg.actions[0].parameters.toolResults.map(
                (toolResult, index) => (
                  <li key={index}>
                    <Tooltip title={toolResult.toolName} placement="left">
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {toolResult.result.explanation}
                      </ReactMarkdown>{' '}
                    </Tooltip>
                  </li>
                )
              )}
            </ul>
          )}{' '}
      </div>

      {component}

      {msg.actions && msg.actions.length > 0 && (
        <div
          style={{
            marginLeft: '46px',
            marginTop: '4px',
            marginBottom: '30px',
          }}
        >
          {msg.actions.map((action, index) => (
            <span key={index}>
              <Action
                action={{
                  id:
                    action.id ||
                    `act-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
                  ...action,
                  messageId: msg.id, // Pass message ID to action for state management
                }}
              />
            </span>
          ))}
        </div>
      )}
    </div>
  );
});

const ChatMessages = memo(function ChatMessages({
  messages,
  thinking,
  status,
}) {
  const messagesEndRef = useRef(null);
  const lastMessageRef = useRef(null);
  const lastMessageLengthRef = useRef(0);
  const lastMessageCountRef = useRef(messages.length);

  useEffect(() => {
    const shouldScrollToBottom =
      messages.length !== lastMessageCountRef.current || // New message added
      (messages.length > 0 &&
        messages[messages.length - 1].message?.length !==
          lastMessageLengthRef.current); // Last message content changed

    if (shouldScrollToBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      if (messages.length > 0) {
        lastMessageLengthRef.current =
          messages[messages.length - 1].message?.length || 0;
      }
      lastMessageCountRef.current = messages.length;
    }
  }, [messages, status]);

  return (
    <div
      className="chat-messages"
      style={{
        height: '100%',
        width: '100%',
        paddingBottom: '40px',
        marginBottom: '40px',
      }}
    >
      {messages.map((msg, index) => (
        <Message
          key={index}
          msg={msg}
          ref={index === messages.length - 1 ? lastMessageRef : null}
        />
      ))}
      {status && (
        <div className="message AI" style={{ marginBottom: '0.5rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <div style={{ marginLeft: '40px' }}>
              <StatusPill message={status} />
            </div>
          </div>
        </div>
      )}
      <div ref={messagesEndRef} />
      {/* Invisible spacer for hover actions */}
      <div style={{ height: '40px', width: '100%' }} />
    </div>
  );
});

ChatMessages.displayName = 'ChatMessages';
Message.displayName = 'Message';
Actor.displayName = 'Actor';

export default ChatMessages;
