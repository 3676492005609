import config from '@/lib/config';
import { DesignEntity } from '@/lib/enums';
import defaultSnapshotEntities from './utils/defaultSnapshotEntities';

const ENTITY_DEFAULTS = {
  [DesignEntity.GROUP]: config.DEFAULT_GROUP,
  [DesignEntity.ROLE]: config.DEFAULT_ROLE,
  [DesignEntity.ACTIVITY]: config.DEFAULT_ACTIVITY,
};

export default function make(input = {}) {
  const clonedInput = structuredClone(input);
  const snapshot = {
    ...config.DEFAULT_SNAPSHOT,
    ...clonedInput,
    entities: defaultSnapshotEntities(),
  };

  const entities = clonedInput.entities ?? {};
  Object.keys(clonedInput.entities ?? {}).forEach((plural) => {
    const type = DesignEntity.fromPlural(plural);
    const defaults = ENTITY_DEFAULTS[type];

    // Handle flattened and unflattened entities.
    const entityArray = Array.isArray(entities[plural])
      ? entities[plural]
      : Object.values(entities[plural]);

    entityArray.forEach((entity) => {
      snapshot.entities[plural].push({
        ...defaults,
        ...entity,
      });
    });
  });

  return snapshot;
}
