import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';

export default function addedRoleAction({ role, comparisonScenario }) {
  const metrics = role.__metrics.self.total;

  const roleProperties = {};

  // Add the parent manager.
  if (role.__manager) {
    roleProperties.moved = {
      manager: {
        to: role.__manager,
      },
    };
  }

  // Add the parent group.
  if (role.group_uuid) {
    roleProperties.moved = {
      ...roleProperties.moved,
      group: {
        to: role.group_uuid,
      },
    };
  }

  // Add the assignee.
  roleProperties.assigned = {
    to: role.user_uuid || null,
  };

  const action = {
    type: ActionType.ADDED_ROLE,
    [EntityType.ROLE]: new Map([
      [
        role.uuid,
        {
          metrics,
          props: { ...roleProperties },
        },
      ],
    ]),
    metrics: defaultMetrics({ comparisonScenario }),
  };

  action.metrics[1].budget += metrics.budget;
  action.metrics[1].fte += metrics.fte;
  action.metrics[1].roles += 1;

  return action;
}
