import { useState, useEffect } from 'react';

export default function StatusPill({ message }) {
  const [displayMessage, setDisplayMessage] = useState(message);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (message !== displayMessage) {
      setIsTransitioning(true);
      // Fade out current message
      setTimeout(() => {
        setDisplayMessage(message);
        // Fade in new message
        setIsTransitioning(false);
      }, 150); // Half of the transition duration
    }
  }, [message]);

  return (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: '8px',
        padding: '4px 12px',
        borderRadius: '16px',
        background: 'rgba(0, 0, 0, 0.05)',
        fontSize: '14px',
        transition: 'opacity 0.3s ease',
        opacity: isTransitioning ? 0 : 1,
      }}
    >
      <span>{displayMessage}</span>
    </div>
  );
}
