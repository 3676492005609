import { cumulateMetrics } from '@/organisms/plans/utils';
import { EntityType } from '@/shared/enums';
import defaultMetricGroup from '@pkg/entities/snapshots/utils/defaultMetricGroup';

const DEFAULT_TAG_TYPE_LIST = [
  EntityType.ACTIVITY,
  EntityType.ROLE,
  EntityType.GROUP,
];

const defaultTagTypes = () => {
  return {
    [EntityType.ACTIVITY]: {},
    [EntityType.ROLE]: {},
    [EntityType.GROUP]: {},
  };
};

export default function cumulateTagMetrics({
  aggregateOriginalMetrics,
  entityTags,
  tagTypes = DEFAULT_TAG_TYPE_LIST,
  originalEntityTags,
  existingMetrics,
}) {
  let tagsAdded = false;
  const tags = [defaultTagTypes(), defaultTagTypes()];

  if (!entityTags && !originalEntityTags) {
    return;
  }

  tagTypes.forEach((tagType) => {
    const entityTagList = entityTags?.[tagType]
      ? Object.keys(entityTags[tagType])
      : [];
    const originalTagList = originalEntityTags?.[tagType]
      ? Object.keys(originalEntityTags[tagType])
      : [];

    const tagList = new Set([...entityTagList, ...originalTagList]);

    [...tagList].forEach((tagId) => {
      const entityMetrics = entityTags?.[tagType]?.[tagId];
      const originalEntityMetrics = originalEntityTags?.[tagType]?.[tagId];

      // We need the aggregated original entity metrics to be used as the
      // comparison value when we have mutliple entities of the same type.
      const originalMetrics = existingMetrics[0]?.tags?.[tagType]?.[tagId]
        ? {
            ...defaultMetricGroup(false),
            ...existingMetrics[0]?.tags?.[tagType]?.[tagId],
          }
        : {
            ...defaultMetricGroup(false),
            ...originalEntityMetrics,
            moved: 0,
          };

      // The cumulative metrics are the aggregated differences between the
      // original and updated metrics. If we don't have existing metrics we
      // begin with the original scenario metrics.
      const cumulativeMetrics = existingMetrics[1]?.tags?.[tagType]?.[tagId]
        ? {
            ...existingMetrics[1]?.tags?.[tagType]?.[tagId],
          }
        : {
            ...defaultMetricGroup(false),
            ...originalEntityMetrics,
            moved: 0,
          };

      // The metrics array is used to determine the difference between the
      // original entity metrics and the updated scenario metrics.
      const metrics = [
        {
          ...defaultMetricGroup(false),
          moved: 0,
          ...originalEntityMetrics,
        },
        {
          ...defaultMetricGroup(false),
          moved: 0,
          ...entityMetrics,
        },
      ];

      // Set the comparison metrics to the original comparison metrics if we're
      // relying on the pre-calculated values or increment the original
      // comparison metrics if we're calculating them dynamically.
      const comparisonMetrics = aggregateOriginalMetrics
        ? cumulateMetrics(
            {
              metrics: [
                defaultMetricGroup(false),
                { ...originalEntityMetrics },
              ],
            },
            originalMetrics
          )
        : { ...originalMetrics };

      // Calculate the cumulated metrics based on the difference between the
      // entities in the metrics array.
      const cumulatedMetrics = cumulateMetrics(
        { metrics },
        cumulativeMetrics,
        true
      );

      // We only want to include tags that have been changed.
      if (!cumulatedMetrics) {
        return;
      }

      tags[1][tagType][tagId] = { ...cumulatedMetrics };
      tags[0][tagType][tagId] = { ...comparisonMetrics };

      tagsAdded = true;
    });
  });

  return tagsAdded ? tags : null;
}
