import format from './format';

const DefaultOptions = Object.freeze({
  notation: 'compact',
  style: 'decimal',
});

/**
 * @param {number} value
 * @param {object} options // Intl.NumberFormat options
 * @return {string}
 */
export default function currency(value, options = DefaultOptions) {
  if (isNaN(value) || value === null || value === '') {
    return null;
  }

  const formattedValue = format(value, {
    ...DefaultOptions,
    ...options,
  });

  return `$${formattedValue}`;
}
