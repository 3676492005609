import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { ActivityHeading, SurveyHeading } from '@/molecules/headings';
import { CategorisedActivityList } from '@/organisms/activities';
import { ActivityCardList } from '@/organisms/cards';
import { RoleActivityHoursChart } from '@/organisms/charts';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ActivityHours = ({
  allowedTagSet,
  coreActivitySet,
  categoryTagMap,
  categoryTags,
  showDaysPerMonth,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const settings = {
    isEditable: true,
    isTagsEditable: false,
    showComparison: false,
    showDaysPerMonth: showDaysPerMonth,
    showDragDrop: false,
    showHours: true,
    showMenu: false,
    showPercentage: true,
    showRemove: true,
    showTags: false,
    showTitle: !categoryTagMap?.size,
    showTotalActivityHours: true,
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <Paragraph size={isLargeScreen ? Size.LARGE : Size.MEDIUM}>
            Enter hours for each activity to reflect{' '}
            <span style={{ textDecoration: 'underline' }}>approximately</span>{' '}
            how much time you spend on (on average) per week.
          </Paragraph>
          <Paragraph
            size={isLargeScreen ? Size.MEDIUM : Size.SMALL}
            overrideStyles={{ fontStyle: 'italic', fontWeight: 600 }}
          >
            Tips:
          </Paragraph>
          <Paragraph size={isLargeScreen ? Size.MEDIUM : Size.SMALL}>
            &#8226;{' '}
            <span style={{ fontStyle: 'italic' }}>
              {' '}
              Check your total hours add up to your typical work week
            </span>
          </Paragraph>
          <Paragraph size={isLargeScreen ? Size.MEDIUM : Size.SMALL}>
            &#8226;{' '}
            <span style={{ fontStyle: 'italic' }}>
              {' '}
              You can break your time down to a 1/4 hour using 0.25 if needed
            </span>
          </Paragraph>
        </Grid>
        <Grid item xs={12} md={9} sx={{ mt: 1 }}>
          {categoryTags?.length ? (
            <CategorisedActivityList
              categoryTags={categoryTags}
              categoryTagMap={categoryTagMap}
              Component={
                <ActivityCardList
                  allowedTagSet={allowedTagSet}
                  coreActivitySet={coreActivitySet}
                />
              }
              settings={settings}
            />
          ) : (
            <>
              <SurveyHeading settings={settings}>
                <ActivityHeading
                  showDragDrop={settings.showDragDrop}
                  showHours={settings.showHours}
                />
              </SurveyHeading>
              <ActivityCardList
                allowedTagSet={allowedTagSet}
                coreActivitySet={coreActivitySet}
                settings={settings}
                showHeading={false}
              />
            </>
          )}
        </Grid>
      </Grid>
      {isLargeScreen && (
        <Box
          sx={{ position: 'fixed', right: 'calc(8.5vw)', top: 'calc(37vh)' }}
        >
          <RoleActivityHoursChart />
        </Box>
      )}
    </>
  );
};

export default ActivityHours;
