import { useLibrary } from '@pkg/entities/library';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';
import { useMessages } from '../context/MessagesContext';

function reduceIntentSnapshot(snapshot, dataPaths) {
  const result = {
    entities: {
      roles: [],
      activities: [],
      groups: [],
      people: [],
    },
  };

  dataPaths.forEach((path) => {
    const pathParts = path.replace(/^snapshot\./, '').split('.');
    let currentData = snapshot;

    // Check if we're dealing with an entity array
    if (
      pathParts[0] === 'entities' &&
      ['roles', 'activities', 'groups', 'people'].includes(pathParts[1])
    ) {
      const entityType = pathParts[1];
      currentData = currentData[pathParts[0]][entityType];

      // Process each entity
      currentData.forEach((entity, index) => {
        // Initialize entity object if not exists
        if (!result.entities[entityType][index]) {
          result.entities[entityType][index] = {};
        }

        // Handle different path depths
        if (pathParts.length === 3) {
          // Simple property like 'title'
          result.entities[entityType][index][pathParts[2]] =
            entity[pathParts[2]];
        } else if (pathParts.length > 3) {
          // Nested property like '__total_metrics.hours'
          const nestedProp = pathParts[2];
          const finalProp = pathParts[3];

          // Ensure nested object exists
          if (!result.entities[entityType][index][nestedProp]) {
            result.entities[entityType][index][nestedProp] = {};
          }

          // Set the final property value
          result.entities[entityType][index][nestedProp][finalProp] = entity[
            nestedProp
          ]
            ? entity[nestedProp][finalProp]
            : null;
        }
      });
    }
  });

  return result;
}

const useCreatePrompt = () => {
  const snapshot = useDesignStore((state) => state.snapshot);
  const level = useDesignStore((state) => state.level);
  const designStore = useDesignStore((state) => state);

  const {
    tags,
    activities: activityLibrary,
    flowTemplates,
    properties,
    skills,
  } = useLibrary();
  const { messages, addMessage } = useMessages();
  const lastMessages = messages.slice(-8);

  const createPrompt = async ({
    basePrompt,
    intent,
    userText,
    stream = false,
  }) => {
    let intentSnapshot = null;

    if (intent) {
      const dataPaths = intent.toolCalls[0].args.dataPaths;
      if (dataPaths) {
        intentSnapshot = reduceIntentSnapshot(snapshot, dataPaths);
      }
    }

    const {
      roles = [],
      activities = [],
      groups = [],
      people = [],
    } = snapshot.entities || {};

    console.log('createPrompt params:', { intent, userText, stream });

    function mapShortUUID(shortUUID) {
      function hashString(str) {
        return str.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
      }

      function normalizeToRange(value, min, max) {
        return min + (value % (max - min + 1));
      }

      const hash1 = normalizeToRange(hashString(shortUUID.slice(0, 7)), 0, 999);
      const hash2 = normalizeToRange(
        hashString(shortUUID.slice(7, 14)),
        0,
        999
      );
      const hash3 = normalizeToRange(
        hashString(shortUUID.slice(14, 21)),
        0,
        999
      );

      return `${hash1}${String(hash2).padStart(3, '0')}${String(hash3).padStart(3, '0')}`;
    }

    function traverseAndProcessUUIDs(obj) {
      console.time('traverseAndProcessUUIDs');
      const processed = {};
      let replacedCount = 0;
      // Map to store original UUID to replacement mapping
      const uuidMap = {};

      function traverse(current, path = '') {
        if (current === null || current === undefined) {
          return current;
        }

        // Handle string values - check if it's a 21-character string
        if (
          typeof current === 'string' &&
          (current.length === 21 || current.length === 22) &&
          !current.includes(' ')
        ) {
          replacedCount++;
          const replacement = mapShortUUID(current);
          // Store in our mapping
          uuidMap[replacement] = current;
          return replacement;
        }

        // Handle arrays
        if (Array.isArray(current)) {
          return current.map((item, index) =>
            traverse(item, `${path}[${index}]`)
          );
        }

        // Handle objects
        if (typeof current === 'object') {
          const result = {};
          for (const key in current) {
            if (Object.prototype.hasOwnProperty.call(current, key)) {
              result[key] = traverse(
                current[key],
                path ? `${path}.${key}` : key
              );
            }
          }
          return result;
        }

        // Return primitive values as is
        return current;
      }

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          processed[key] = traverse(obj[key], key);
        }
      }

      console.timeEnd('traverseAndProcessUUIDs');
      console.log(`Replaced ${replacedCount} UUIDs`);
      console.log('UUID mapping:', uuidMap);

      return { processed, uuidMap };
    }

    let selection = window?.diagram?.selection?.toArray() ?? [];

    const designMap = {
      DESIGN_ROLE: 'role',
      DESIGN_GROUP: 'group',
      DESIGN_ACTIVITY: 'activity',
    };

    selection = selection.map((node) => {
      console.log('selection', { node });
      return { uuid: node.key, entityType: designMap[node.data.entity] };
    });

    console.log({ selection });

    const mapped = { tags: tags.tags.list, intentSnapshot, selection };

    const { processed, uuidMap } = traverseAndProcessUUIDs(mapped);

    console.log('Processed data with replaced UUIDs:', { processed });
    console.log('UUID mapping for lookups:', { uuidMap });

    const data = {
      stream,
      userText,
      snapshot,
      tags: processed.tags,
      intent,
      selection: processed.selection,
      intentSnapshot: processed.intentSnapshot,
      activityLibrary,
      flowTemplates,
      lastMessages,
      properties,
      roles,
      skills,
      groups,
      people,
      level,
    };

    // @TODO - IMPLEMENT THIS AS A UI FEATURE
    // const groupCount = processed.intentSnapshot?.entities?.groups?.length || 0;
    // const roleCount = processed.intentSnapshot?.entities?.roles?.length || 0;
    // const activityCount =
    //   processed.intentSnapshot?.entities?.activities?.length || 0;

    // const message = {
    //   text: `@TODO - This should be available in a tooltip to show what entities are available.

    //   Group count: ${groupCount}
    //   Role count: ${roleCount}
    //   Activity count: ${activityCount}
    //     `,
    //   type: 'ai',
    //   sender: 'AI',
    //   parameters: {},
    // };
    // addMessage(message);

    // Continue with the original data to keep the normal flow
    const createdPrompt = await basePrompt(data);
    createdPrompt.uuidMap = uuidMap;
    return createdPrompt;
  };

  return createPrompt;
};

export default useCreatePrompt;
