import { isTagActionType } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';
import { EntityMetric } from '@/lib/enums';

const DEFAULT_METRIC_ORDER = [
  EntityMetric.BUDGET,
  EntityMetric.FTE,
  EntityMetric.HOURS,
  EntityMetric.ACTIVITIES,
  EntityMetric.MOVED,
  EntityMetric.ASSIGNED,
  EntityMetric.GROUPS,
];

export default function getMetricOrder(entityType, actionType) {
  if (isTagActionType(actionType)) {
    switch (entityType) {
      case EntityType.ACTIVITY:
        return [EntityMetric.HOURS, EntityMetric.ACTIVITIES];
      case EntityType.MANAGER:
        return [EntityMetric.MANAGERS, EntityMetric.FTE, EntityMetric.SPAN];
      case EntityType.ROLE:
        return [EntityMetric.ROLES, EntityMetric.FTE];
      case EntityType.GROUP:
        return [EntityMetric.GROUPS, EntityMetric.FTE];
      default:
        return [EntityMetric.HOURS, EntityMetric.ACTIVITIES];
    }
  }

  if (actionType === ActionType.UPDATED_SPANS) {
    return [EntityMetric.AVERAGE_SPAN, EntityMetric.SPAN];
  }

  switch (entityType) {
    case EntityType.MANAGER:
      return [
        EntityMetric.MANAGERS,
        EntityMetric.FTE,
        EntityMetric.SPAN,
        EntityMetric.ASSIGNED,
      ];
    default:
      return DEFAULT_METRIC_ORDER;
  }
}
