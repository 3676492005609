import { percent } from '@pkg/utils/numbers';
import config from '@/lib/config';
import defaultMetricGroup from '../utils/defaultMetricGroup';
import updateMetrics from './shared/updateMetrics';
import updateRootMetrics from './shared/updateRootMetrics';

/**
 * Returns the incremental metrics to be added to the visible metrics.
 *
 * @param {Object}
 *
 * @return {Object}
 */
function getVisibleIncrementMetrics({
  activity,
  isVisibleActivity,
  isVisibleRole,
  role,
  roleMetrics,
}) {
  const visibleBudget = activity
    ? isVisibleActivity
      ? roleMetrics.budget
      : 0
    : isVisibleRole
      ? roleMetrics.budget
      : 0;

  const visibleFTE = activity
    ? isVisibleActivity
      ? roleMetrics.fte
      : 0
    : isVisibleRole
      ? roleMetrics.fte
      : 0;

  const visibleMetrics = role.__metrics.self.visible;

  const visible = {
    ...defaultMetricGroup(),
    budget: visibleBudget,
    fte: visibleFTE,
    activities: isVisibleActivity ? roleMetrics.activities : 0,
    hours: isVisibleActivity ? roleMetrics.hours : 0,
  };

  if ((activity && isVisibleActivity) || isVisibleRole) {
    visible.roles = visibleMetrics.roles === 0 ? 1 : 0;

    visible.managers = role.is_manager && visibleMetrics.managers === 0 ? 1 : 0;

    visible.span = role?.__manager && visibleMetrics.span === 0 ? 1 : 0;
  }

  return visible;
}

/**
 * Derives metrics for the role.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function roleMetrics({
  activity,
  isFiltered,
  role,
  roles,
  group,
  metrics,
}) {
  const isNewRole = roles.has(role.uuid);
  const isVisibleRole = role.__visibility === config.VISIBILITY.FULL;
  const isActivityDisabled = activity?.disabled_at;
  const isVisibleActivity = activity?.__visibility === config.VISIBILITY.FULL;

  const activityPercentage = activity
    ? !activity.hours || (isVisibleRole && !role.__hours)
      ? 0
      : percent(activity.hours, role.__hours, 8) / 100
    : 1;

  // Capture the metrics for this role.
  const roleMetrics = {
    ...defaultMetricGroup(false),
    // If filter conditions haven't been added, we use the raw role metrics.
    budget: !isFiltered
      ? isNewRole
        ? role.budget
        : 0
      : role.budget * activityPercentage,
    fte: !isFiltered
      ? isNewRole
        ? role.fte
        : 0
      : role.fte * activityPercentage,
    roles: isNewRole ? 1 : 0,
    managers: isNewRole && role.is_manager ? 1 : 0,
    groups: isNewRole && group ? 1 : 0,
    activities: !activity || isActivityDisabled ? 0 : 1,
    hours: !activity || isActivityDisabled ? 0 : activity.hours,
  };

  // Aggregate this roles's metrics with the total metrics.
  const total = updateMetrics({
    target: isNewRole ? defaultMetricGroup() : { ...role.__metrics.self.total },
    source: {
      ...roleMetrics,
    },
  });

  const visibleIncrementMetrics = getVisibleIncrementMetrics({
    activity,
    isVisibleActivity,
    isVisibleRole,
    role,
    roleMetrics,
  });

  // Aggregate this roles's metrics with the visible metrics.
  const visible = isVisibleRole
    ? updateMetrics({
        target: isNewRole
          ? defaultMetricGroup()
          : { ...role.__metrics.self.visible },
        source: {
          ...visibleIncrementMetrics,
        },
      })
    : isNewRole
      ? { ...defaultMetricGroup() }
      : { ...metrics.get(role.uuid)?.self?.visible };

  // If this is a new role, aggregate the root metrics with this role data.
  updateRootMetrics({
    entityMetrics: {
      ...roleMetrics,
      span: isNewRole && role?.__manager ? 1 : 0,
      groups: 0,
      activities: 0,
      hours: 0,
    },
    visibleMetrics: {
      ...visibleIncrementMetrics,
      groups: 0,
      activities: 0,
      hours: 0,
    },
    metrics,
  });

  role.__metrics.self.total = total;
  role.__metrics.self.visible = visible;

  return { total, visible };
}
