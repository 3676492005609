import { memo } from 'react';
import { Avatar } from '@/atoms/avatars';
import { EntityChip, StatusChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { ChangeMetric } from '@/molecules/metrics';
import { OutlineCard } from '@/organisms/cards';
import { planEntityColors } from '@/organisms/plans';
import { ActionType, EntityType, Status } from '@/shared/enums';
import {
  useScenarioContext,
  useTagContext,
  useViewModeContext,
} from '@/shared/providers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
  determineChartType,
  determineEntityChange,
  determineMetric,
} from './utils';

const retrieveActivity = ({ comparisonScenario, id, scenario }) => {
  const activity = scenario?.relationships?.get(EntityType.ACTIVITY)?.get(id);

  if (activity) {
    return activity;
  }

  return comparisonScenario?.relationships?.get(EntityType.ACTIVITY)?.get(id);
};

const retrieveRole = ({ comparisonScenario, id, scenario }) => {
  const role = scenario?.relationships?.get(EntityType.ROLE)?.get(id);

  if (role) {
    return role;
  }

  return comparisonScenario?.relationships?.get(EntityType.ROLE)?.get(id);
};

const retrieveStatus = (actions) => {
  if (!actions) {
    return;
  }

  const isNew = actions?.has(ActionType.ADDED_ACTIVITY);
  const isRemoved = actions?.has(ActionType.REMOVED_ACTIVITY);

  if (isNew && isRemoved) {
    return;
  }

  if (isNew) {
    return Status.NEW;
  }
  if (isRemoved) {
    return Status.REMOVED;
  }
};

const PlanActivityCard = ({
  actions,
  actionType,
  activityProperties,
  id,
  isSelected,
  onSelect,
  metrics,
}) => {
  const { tagMap } = useTagContext();
  const { comparisonScenario, scenario } = useScenarioContext();
  const activity = retrieveActivity({
    comparisonScenario,
    id,
    scenario,
  });
  const role =
    activity?.owner_type === EntityType.ROLE
      ? retrieveRole({
          comparisonScenario,
          id: activity?.owner_uuid,
          scenario,
        })
      : null;
  const { showBudget } = useViewModeContext();

  const cardProperties = {
    actionType,
    props: { ...activityProperties },
  };

  const cardMetric = determineMetric({
    actionType,
    entityType: EntityType.ACTIVITY,
    metrics,
    showBudget,
  });

  if (cardMetric.tagId) {
    cardProperties.props.id = cardMetric.tagId;
    cardProperties.actionType = ActionType.UPDATED_TAGS;
  }

  const chartType = determineChartType(cardMetric.metricType);
  const colors = planEntityColors({
    actionType: cardProperties.actionType,
    actionProperties: cardProperties.props,
    entityType: EntityType.ACTIVITY,
    tagMap,
  });
  const entityChange = determineEntityChange({
    metrics: cardMetric.metricType,
    entityCount: 1,
    entityType: EntityType.ACTIVITY,
  });
  const roleTitle = role ? role.title : 'Unallocated';
  const assignee = role?.__person?.__name || 'Unassigned';
  const activityStatus = retrieveStatus(actions);

  const handleSelect = (event) => {
    event.stopPropagation();

    onSelect({
      id,
      entityType: EntityType.ACTIVITY,
      props: {
        ...activityProperties,
        title: activity?.__description,
        activity,
        role,
      },
      metrics,
      actions,
    });
  };

  return (
    <Stack direction="row" alignItems="center" width="100%">
      <Box width="136px" mr={1} flexShrink={0}>
        <ChangeMetric
          chartType={chartType}
          colors={colors}
          entityType={EntityType.ACTIVITY}
          metric={cardMetric.metricType}
          metrics={cardMetric.metrics}
        />
      </Box>
      <Box flexGrow={1}>
        <OutlineCard padding={0} margin={0}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            p={1.5}
            sx={{
              boxShadow: isSelected
                ? `inset 0 0 0 1px ${colors.primary}, 0 0 0 1px ${colors.primary}`
                : 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              transition: 'box-shadow 200ms',
              '&: hover': !isSelected && {
                boxShadow: `inset 0 0 0 1px ${colors.secondary}, 0 0 0 1px ${colors.secondary}`,
              },
            }}
            onClick={handleSelect}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar
                name={assignee}
                src={role?.__person?.avatar}
                sizeProps={{
                  fontSize: '1rem',
                  width: '40px',
                  height: '40px',
                }}
              />
              <Box>
                <Stack direction="row" spacing={1} alignItems="center">
                  <EntityChip
                    size={Size.XX_SMALL}
                    sx={{ height: 20, px: 1 }}
                    type={EntityType.ROLE}
                  />
                  <Heading variant="h6">{roleTitle}</Heading>
                </Stack>
                <Stack direction="row" spacing={0.5} mt={0.5}>
                  <Box flexShrink={0}>
                    {activityStatus && (
                      <StatusChip
                        status={activityStatus}
                        size={Size.XX_SMALL}
                        sx={{ height: 20, px: 1, mr: 0.75 }}
                      />
                    )}
                    <EntityChip
                      size={Size.XX_SMALL}
                      sx={{ height: 20, px: 1 }}
                      type={EntityType.ACTIVITY}
                    />
                  </Box>
                  <Heading variant="h4">{activity.__description}</Heading>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </OutlineCard>
      </Box>
    </Stack>
  );
};

export default memo(PlanActivityCard, (previous, next) => {
  return previous?.isSelected === next?.isSelected;
});
