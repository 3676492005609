import { Chip, EntityChip, TagChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { isTagActionType, planEntityColors } from '@/organisms/plans';
import { useTagContext } from '@/shared/providers';
import { core } from '@/lib/theme/tokens/palettes';

const PlanChip = ({ actionType, entityType, properties, plural }) => {
  const { tagMap } = useTagContext();
  const isTagAction = isTagActionType(actionType);
  const tag = isTagAction && properties ? tagMap?.get(properties?.id) : null;

  const entityColor = planEntityColors({
    actionProperties: properties,
    actionType,
    entityType,
    tagMap,
  });

  if (!entityColor) {
    return <div>No entity color found</div>;
  }

  return (
    <>
      {actionType && !tag && (
        <Chip
          id={actionType}
          name={actionType.toUpperCase()}
          colorProps={{
            normal: {
              backgroundColor: entityColor.primary,
              color: core.white,
            },
          }}
          size={Size.XX_SMALL}
          fontWeight={600}
          sx={{ height: 20, px: 1 }}
        />
      )}
      {actionType && tag && (
        <TagChip
          color={tag.color}
          expanded
          name={tag.name}
          size={Size.X_SMALL}
          sx={{
            height: 20,
            px: 1,
            fontWeight: 500,
          }}
        />
      )}
      {!actionType && (
        <EntityChip
          size={Size.XX_SMALL}
          sx={{ height: 20, px: 1 }}
          type={entityType}
          plural={plural}
        />
      )}
    </>
  );
};

export default PlanChip;
