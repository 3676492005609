import { useMemo } from 'react';
import serialize from '@pkg/utils/strings/serialize';
import deriveCompleteness from './deriveCompleteness';

/**
 * @param {Object} snapshot
 * @return {Object}
 */
export default function useDerivedCompleteness(snapshot) {
  return useMemo(() => {
    const scenario = deriveCompleteness(snapshot);

    if (!scenario) {
      return null;
    }

    return Object.freeze(scenario);
  }, [serialize(snapshot)]);
}
