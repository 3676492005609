import { createContext, useContext, useState } from 'react';

const CopilotContext = createContext();

export const CopilotProvider = ({ children }) => {
  const [copilotOpen, setCopilotOpen] = useState(false);

  const toggleCopilot = () => setCopilotOpen(!copilotOpen);

  return (
    <CopilotContext.Provider
      value={{ copilotOpen, setCopilotOpen, toggleCopilot }}
    >
      {children}
    </CopilotContext.Provider>
  );
};

export const useCopilot = () => {
  const context = useContext(CopilotContext);
  if (!context) {
    throw new Error('useCopilot must be used within a CopilotProvider');
  }
  return context;
};
