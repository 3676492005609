import { SkillLevel } from '@/lib/enums';
import { palettes } from '@/lib/theme/tokens';

export const LevelColors = {
  [SkillLevel.BASIC]: {
    active: {
      base: {
        fill: palettes.brand.blue.tints[5],
        outline: palettes.brand.blue.tints[5],
        text: palettes.core.white,
      },
      focus: {
        fill: palettes.brand.blue.tints[5],
        outline: palettes.brand.blue.tints[5],
        text: palettes.core.white,
      },
      hover: {
        fill: palettes.brand.blue.tints[5],
        outline: palettes.brand.blue.tints[5],
        text: palettes.core.white,
      },
    },
    inactive: {
      base: {
        fill: palettes.core.white,
        outline: palettes.brand.blue.tints[5],
        text: palettes.brand.blue.tints[5],
      },
      focus: {
        fill: palettes.core.white,
        outline: palettes.brand.blue.tints[5],
        text: palettes.brand.blue.tints[5],
      },
      hover: {
        fill: palettes.core.white,
        outline: palettes.brand.blue.tints[5],
        text: palettes.brand.blue.tints[5],
      },
    },
  },
  [SkillLevel.INTERMEDIATE]: {
    active: {
      base: {
        fill: palettes.brand.blue.shades[2],
        outline: palettes.brand.blue.shades[2],
        text: palettes.core.white,
      },
      focus: {
        fill: palettes.brand.blue.shades[2],
        outline: palettes.brand.blue.shades[2],
        text: palettes.core.white,
      },
      hover: {
        fill: palettes.brand.blue.shades[2],
        outline: palettes.brand.blue.shades[2],
        text: palettes.core.white,
      },
    },
    inactive: {
      base: {
        fill: palettes.core.white,
        outline: palettes.brand.blue.shades[2],
        text: palettes.brand.blue.shades[2],
      },
      focus: {
        fill: palettes.core.white,
        outline: palettes.brand.blue.shades[2],
        text: palettes.brand.blue.shades[2],
      },
      hover: {
        fill: palettes.core.white,
        outline: palettes.brand.blue.shades[2],
        text: palettes.brand.blue.shades[2],
      },
    },
  },
  [SkillLevel.ADVANCED]: {
    active: {
      base: {
        fill: palettes.brand.blue.shades[4],
        outline: palettes.brand.blue.shades[4],
        text: palettes.core.white,
      },
      focus: {
        fill: palettes.brand.blue.shades[4],
        outline: palettes.brand.blue.shades[4],
        text: palettes.core.white,
      },
      hover: {
        fill: palettes.brand.blue.shades[4],
        outline: palettes.brand.blue.shades[4],
        text: palettes.core.white,
      },
    },
  },
  inactive: {
    base: {
      fill: palettes.core.white,
      outline: palettes.brand.blue.shades[4],
      text: palettes.brand.blue.shades[4],
    },
    focus: {
      fill: palettes.core.white,
      outline: palettes.brand.blue.shades[4],
      text: palettes.brand.blue.shades[4],
    },
    hover: {
      fill: palettes.core.white,
      outline: palettes.brand.blue.shades[4],
      text: palettes.brand.blue.shades[4],
    },
  },
};

export default function skill(level = SkillLevel.BASIC) {
  return LevelColors[level];
}
