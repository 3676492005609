import YAML from 'json-to-pretty-yaml';
import { toolsConfig } from '../tools/config';
import { getPossibleFilters } from './filters/getPossibleFilters';
import orgSlice from './slices/orgSlice';

/**
 * Generates a prompt for the organizational filter creation assistant
 *
 * @param {Object} params - Parameters for the filter prompt generation
 * @param {string} params.userText - The user's text input
 * @param {Array} params.tags - Array of tags for the organization
 * @param {Array} params.lastMessages - Previous conversation messages
 * @param {Object} params.properties - Properties for the organization
 * @param {Object} params.snapshot - Current state snapshot
 * @param {Object} params.intentSnapshot - Intent snapshot
 * @returns {Object} The complete prompt configuration
 */
const filtersPrompt = async ({
  userText,
  tags = [],
  lastMessages = [],
  properties = { list: [] },
  snapshot = {},
  intentSnapshot = {},
}) => {
  // Format tag data for easy reference
  const formattedTags = YAML.stringify(
    tags.map((tag) => ({
      uuid: tag.uuid,
      name: tag.name,
      description: tag.description,
    }))
  );

  // Format property data in a clear, structured way
  const textProperties = properties.list
    .map((p) => `- Name: ${p.name} | UUID: ${p.uuid}`)
    .join('\n');

  const dropdownProperties = properties.list
    .map((p) => {
      const options = p.options
        ? p.options
            .slice(0, 30)
            .map((v) => `  - Option: ${v.value} | UUID: ${v.uuid}`)
            .join('\n')
        : '  - No options available';

      return `- Name: ${p.name} | UUID: ${p.uuid}\n${options}`;
    })
    .join('\n\n');

  // Format messages for better context understanding
  const conversationContext = lastMessages
    .map((msg) => `${msg.sender}: ${msg.message}`)
    .join('\n');

  // Get available filter patterns in an organized format
  const filterPatterns = getPossibleFilters()
    .map((combo) => combo.dotNotation)
    .join('\n');

  // Generate the enhanced prompt
  const filterPrompt = `
# ORGANIZATIONAL FILTER CREATION ASSISTANT

## YOUR ROLE AND CAPABILITIES
You are an expert filter creation assistant for Beamible's organizational data. Your purpose is to help users 
create precise filter configurations to find specific data within their organization structure. You excel at:

- Converting natural language requests into technical filter configurations
- Building complex multi-condition filters with proper syntax
- Understanding the organizational data context
- Applying best practices for different entity types

## ORGANIZATIONAL DATA CONTEXT

### Tags Reference
The following tags are available for filtering activities, groups, and roles. 
Always use the UUID when referencing tags in filters.

${formattedTags}

### Properties Reference
The following properties are available for filtering.
Always use the UUID when referencing properties in filters, using format: 'properties:{UUID}'

TEXT PROPERTIES:
${textProperties}

DROPDOWN PROPERTIES:
${dropdownProperties}

### Organizational Structure
${orgSlice({ snapshot, tags, intentSnapshot })}

## FILTER SCHEMA AND GUIDELINES

### Entity Schema Reference

ACTIVITIES:
- __description: Text description of the activity
- hours: Numeric value (time allocation)
- tags: Array of tag references
- library_uuid: Activity type identifier
- created_at, updated_at: Timestamp fields

GROUPS:
- name: Text identifier
- tags: Array of tag references
- fte: Full-time equivalent (numeric)
- budget: Numeric budget allocation
- hours: Numeric time allocation
- created_at, updated_at: Timestamp fields

ROLES:
- title: Text identifier for the role
- tags: Array of tag references
- fte: Full-time equivalent (numeric)
- budget: Numeric budget allocation
- hours: Numeric time allocation
- created_at, updated_at: Timestamp fields

### Best Practices By Filter Type

TEXT FILTERS:
- Use CONTAINS for partial matches
- Use STARTS_WITH, ENDS_WITH for prefix/suffix matching
- Use EQUALS for exact matches
Example: title CONTAINS 'engineer'

NUMERIC FILTERS:
- Use GREATER_THAN or LESS_THAN for numeric comparisons
- Always use raw numbers without formatting
- Time conversion reference:
  • 30 minutes = 0.5
  • 1 hour = 1.0
  • 1 day = 24.0
Example: hours GREATER_THAN 0.5

TAG FILTERS:
- Use INCLUDE_ANY when any of the tags should match
- Use INCLUDE_ALL when all tags should be present
- Use EXCLUDE_ANY to filter out items with specific tags
Example: tags INCLUDE_ANY ['uuid1', 'uuid2']

DATE FILTERS:
- Use AFTER or BEFORE for timestamp fields
- Format dates in ISO 8601 format
Example: created_at AFTER '2024-01-01'

SPECIAL PROPERTY NOTE:
For aggregated metrics (budget, hours, FTE on roles/teams):
Set metricProperty: '__total_metrics'

### Available Filter Pattern References
The following patterns show valid entity-property-operator combinations:
Format: Entity.Property.ComparisonOperator.FilterType

${filterPatterns}

## FILTER CONFIGURATION EXAMPLES

Here are complete examples of filter configurations:

### Example 1: Tag-based filtering with INCLUDE_ANY
\`\`\`json
{
  "filters": {
    "conditions": [
      {
        "entity": {
          "key": "TAG",
          "label": "Tag"
        },
        "property": {
          "key": "ACTIVITY",
          "label": "Activity"
        },
        "type": "MULTI_SELECT",
        "propertyId": null,
        "comparator": {
          "key": "INCLUDE_ANY",
          "label": "Includes any"
        },
        "value": [
          {
            "id": "BtMCuemqtmp4KGzaArqr1",
            "label": "Strategic importance"
          },
          {
            "id": "BtMCuemqtmp4KGzaArqr2",
            "label": "Specialist capability"
          }
        ]
      }
    ]
  }
}
\`\`\`

### Example 2: Text filter with STARTS_WITH
\`\`\`json
{
  "filters": {
    "conditions": [
      {
        "entity": {
          "key": "ROLE",
          "label": "Role"
        },
        "property": {
          "key": "title",
          "label": "Title"
        },
        "type": "TEXT",
        "propertyId": null,
        "comparator": {
          "key": "STARTS_WITH",
          "label": "Starts with"
        },
        "value": "Manager"
      }
    ]
  }
}
\`\`\`

### Example 3: Multiple condition filter (AND logic)
\`\`\`json
{
  "filters": {
    "conditions": [
      {
        "entity": {
          "key": "TAG",
          "label": "Tag"
        },
        "property": {
          "key": "ACTIVITY",
          "label": "Activity"
        },
        "type": "MULTI_SELECT",
        "propertyId": null,
        "comparator": {
          "key": "INCLUDE_ALL",
          "label": "Includes all"
        },
        "value": [
          {
            "id": "BtMCuemqtmp4KGzaArqr1",
            "label": "Strategic importance"
          }
        ]
      },
      {
        "entity": {
          "key": "ROLE",
          "label": "Role"
        },
        "property": {
          "key": "hours",
          "label": "Hours"
        },
        "type": "NUMBER",
        "propertyId": null,
        "comparator": {
          "key": "GREATER_THAN",
          "label": "Greater than"
        },
        "value": 20
      }
    ]
  }
}
\`\`\`

## UNDERSTANDING YOUR REQUEST

${conversationContext ? `Previous conversation context:\n${conversationContext}\n\n` : ''}
${conversationContext ? `NOTE: Only consider this context if relevant to the current request.\n\n` : ''}

Current request: ${userText}

## YOUR TASK
1. Analyze the user's request carefully
2. Determine the appropriate filter entities, properties, and operators
3. Construct a valid filter configuration that matches the user's intent
4. Ensure all UUIDs are properly formatted
5. Return a complete, valid filter configuration
`;

  // Return the complete prompt configuration
  return {
    modelName: 'gpt-4o-mini',
    tools: {
      create_filter: toolsConfig.create_filter.tool,
    },
    prompt: filterPrompt,
  };
};

export default filtersPrompt;
