import { defaultMetrics } from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

export default function updatedTagAction({
  actionMap,
  actionType,
  entity,
  entityType,
  comparisonScenario,
  originalEntity,
  scenario,
  tag,
}) {
  const isTagRemoved = originalEntity && !entity;
  const primaryEntity = isTagRemoved ? originalEntity : entity;
  const primaryScenario = isTagRemoved ? comparisonScenario : scenario;

  let role;
  let group;

  if (entityType === EntityType.ACTIVITY) {
    role =
      primaryEntity.owner_type === EntityType.ROLE
        ? primaryScenario.relationships
            .get(EntityType.ROLE)
            ?.get(primaryEntity.owner_uuid)
        : null;
    group = role
      ? primaryScenario?.relationships
          .get(EntityType.GROUP)
          ?.get(role.group_uuid)
      : primaryScenario?.relationships
          .get(EntityType.GROUP)
          ?.get(primaryEntity.owner_uuid);
  }

  if (entityType === EntityType.ROLE) {
    group = primaryScenario?.relationships
      .get(EntityType.GROUP)
      ?.get(primaryEntity.group_uuid);
  }

  const metrics = entity?.__metrics.self.visible.tags?.[entityType]?.[tag.uuid];
  const originalMetrics =
    originalEntity?.__metrics?.self?.visible.tags?.[entityType]?.[tag.uuid];

  const existingAction = actionMap?.get(actionType);

  const action = existingAction ?? {
    actionType,
    entity: entityType,
    metrics: defaultMetrics({
      comparisonScenario,
      tag: {
        type: entityType,
        id: tag.uuid,
      },
    }),
    id: `${actionType}:${tag.uuid}`,
    type: actionType,
    props: {
      id: tag.uuid,
    },
    [EntityType.ROLE]: entityType === EntityType.ROLE ? new Map() : new Set(),
    [EntityType.GROUP]: entityType === EntityType.GROUP ? new Map() : new Set(),
    [EntityType.ACTIVITY]:
      entityType === EntityType.ACTIVITY ? new Map() : new Set(),
  };

  if (role) {
    action[EntityType.ROLE].add(role.uuid);
  }
  if (group) {
    action[EntityType.GROUP].add(group.uuid);
  }

  const actionEntity = action[entityType].get(primaryEntity.uuid) ?? {
    actionType,
    entity: entityType,
    metrics: defaultMetrics({
      action,
      activity: entityType === EntityType.ACTIVITY ? primaryEntity : null,
      role: entityType === EntityType.ROLE ? primaryEntity : null,
      group: entityType === EntityType.GROUP ? primaryEntity : null,
      comparisonScenario,
      tag: {
        type: entityType,
        id: tag.uuid,
      },
    }),
    id: `${actionType}:${tag.uuid}:${primaryEntity.uuid}`,
    props: {
      id: tag.uuid,
    },
  };

  if (!originalMetrics && metrics) {
    if (entityType === EntityType.ACTIVITY) {
      action.metrics[1].activities += 1;
      actionEntity.metrics[1].activities += 1;
      action.metrics[1].hours += metrics.hours;
      actionEntity.metrics[1].hours += metrics.hours;
    }
    if (entityType === EntityType.ROLE) {
      action.metrics[1].roles += 1;
      actionEntity.metrics[1].roles += 1;
    }
    if (entityType === EntityType.GROUP) {
      action.metrics[1].groups += 1;
      actionEntity.metrics[1].groups += 1;
    }
    if (entityType !== EntityType.ACTIVITY) {
      action.metrics[1].budget += metrics.budget;
      actionEntity.metrics[1].budget += metrics.budget;
      action.metrics[1].fte += metrics.fte;
      actionEntity.metrics[1].fte += metrics.fte;
    }
  }
  if (!metrics && originalMetrics) {
    if (entityType === EntityType.ACTIVITY) {
      action.metrics[1].activities -= 1;
      action.metrics[1].hours -= originalMetrics.hours;
      actionEntity.metrics[1].activities -= 1;
      actionEntity.metrics[1].hours -= originalMetrics.hours;
    }
    if (entityType === EntityType.ROLE) {
      action.metrics[1].roles -= 1;
      actionEntity.metrics[1].roles -= 1;
    }
    if (entityType === EntityType.GROUP) {
      action.metrics[1].groups -= 1;
      actionEntity.metrics[1].groups -= 1;
    }
    if (entityType !== EntityType.ACTIVITY) {
      action.metrics[1].budget -= originalMetrics.budget;
      actionEntity.metrics[1].budget -= originalMetrics.budget;
      action.metrics[1].fte -= originalMetrics.fte;
      actionEntity.metrics[1].fte -= originalMetrics.fte;
    }
  }
  if (metrics && originalMetrics) {
    if (entityType === EntityType.ACTIVITY) {
      const hoursChanged = metrics.hours - originalMetrics.hours;
      action.metrics[1].hours += hoursChanged;
      actionEntity.metrics[1].hours += hoursChanged;
    }
    if (entityType !== EntityType.ACTIVITY) {
      const budgetChanged = originalMetrics.budget - metrics.budget;
      const fteChanged = originalMetrics.fte - metrics.fte;
      action.metrics[1].budget += budgetChanged;
      actionEntity.metrics[1].hours += budgetChanged;
      action.metrics[1].fte += fteChanged;
      actionEntity.metrics[1].fte += fteChanged;
    }
  }

  action[entityType].set(primaryEntity.uuid, actionEntity);

  const count = action[entityType].size;
  const label = entityLabel(entityType, count !== 1);
  const verb = count === 1 ? 'was' : 'were';

  const roleCount = action[EntityType.ROLE].size;
  const roleLabel = entityLabel(EntityType.ROLE, roleCount !== 1);

  const groupCount = action[EntityType.GROUP].size;
  const groupLabel = entityLabel(EntityType.GROUP, groupCount !== 1);

  action.title = `${count} ${label} ${verb} updated`;

  if (entityType === EntityType.ACTIVITY) {
    action.title = `${action.title} affecting ${roleCount} ${roleLabel}`;
  }

  if (entityType === EntityType.ROLE && groupCount > 0) {
    action.title = `${action.title} impacting ${groupCount} ${groupLabel}`;
  }

  return action;
}
