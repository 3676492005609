import { DesignEntity } from '@/lib/enums';
import defaultEntityMetrics from './defaultEntityMetrics';
import getFlattened from './getFlattened';

/**
 * Default properties for stepping through the hierarchy tree.
 *
 * @return {Object}
 */
function defaultTreePathStep() {
  return {
    uuid: null,
    type: null,
    order: Infinity,
  };
}

/**
 * Default properties for entity maps.
 *
 * @return {Object}
 */
function defaultEntityMap() {
  return {
    [DesignEntity.GROUP]: new Set(),
    [DesignEntity.MANAGER]: new Set(),
    [DesignEntity.ROLE]: new Set(),
    [DesignEntity.ACTIVITY]: new Set(),
  };
}

/**
 * Extends an entity with additional hierarchy properties.
 *
 * @param {Object} entity
 *
 * @return {Object}
 */
function extendEntityProperties(entity) {
  /** The activities that belong to this entity */
  entity.__activities = new Set();
  /** The direct children of this node in the tree hierarchy */
  entity.__children = new Map();
  /** The closest manager above this node in the tree hierarchy */
  entity.__manager = null;
  /** The direct parent of this node in the tree hierarchy */
  entity.__parent = null;
  /** The path of tree steps to this node from its root */
  entity.__path = new Map();
  /** Splits out the path by types */
  entity.__above = defaultEntityMap();
  /** Groups: All entities within this group, inclusive of nested */
  /** Roles: All entities that branch from this role, inclusive of sub-managers */
  entity.__managed = defaultEntityMap();
  /** Groups: Splits out children by type */
  /** Roles: Splits out children by type, including nested groups lead and roles parented */
  entity.__direct = defaultEntityMap();
  // entity.__layers = null;
  /** Groups: All entities within this group, exclusive of nested */
  /** Roles: All entities in the branches between this and inclusive of the next manager */
  entity.__span = defaultEntityMap();
  /** The managed, self and span entity metrics */
  entity.__metrics = defaultEntityMetrics();

  return entity;
}

/**
 * Extends an activity with additional hierarchy properties.
 *
 * @param {Object} activity
 *
 * @return {Object}
 */
function extendActivityPropeerties(activity) {
  /** The group of the owner of this activity */
  activity.__group = null;
  /** The manager of the owner of this activity */
  activity.__manager = null;
  /** The path of tree steps to this node from its root */
  activity.__path = null;

  return activity;
}

/**
 * Iterate through the hierarchy to set manager and path
 * @param {Object} keyed
 * @param {String} id
 * @param {DesignEntity} type
 * @param {String} [managerId]
 * @param {Map} [path]
 * @returns {void}
 */
function iterateHierarchy(keyed, id, type, managerId = null, path = new Map()) {
  const plural = DesignEntity.toPlural(type);
  const entity = keyed.entities[plural][id];

  // Set the entity type.
  entity.__type = type;

  // set entity hierarchy
  entity.__manager = managerId;
  entity.__path = new Map(path);

  // prepare next
  const nextManagerId = entity.is_manager ? id : managerId;
  const nextPath = new Map(path);
  const nextStep = defaultTreePathStep();
  nextStep.uuid = id;
  nextStep.type = type;
  nextStep.order = nextPath.size;

  nextPath.set(id, nextStep);

  // iterate children
  const entityChildren = Array.from(entity.__children.entries());

  entityChildren.forEach(([id, type]) => {
    iterateHierarchy(keyed, id, type, nextManagerId, nextPath);
  });
}

/**
 * Map hierarchy to a snapshot, optionally flattening it
 * @param {Object} snapshot
 * @param {Boolean} [flatten]
 * @returns {?Object}
 */
export default function deriveHierarchy({ flatten, snapshot }) {
  if (!snapshot) {
    return null;
  }

  // setup hierarchy objects
  snapshot.__roots = new Map();

  // Set the default entity hierarchy properties on the group.
  snapshot.__keys.groups.forEach((id) => {
    snapshot.entities.groups[id] = extendEntityProperties(
      snapshot.entities.groups[id]
    );
  });

  // Set the default entity hierarchy properties on the role.
  snapshot.__keys.roles.forEach((id) => {
    snapshot.entities.roles[id] = extendEntityProperties(
      snapshot.entities.roles[id]
    );
  });

  // Set the default hierarchy properties on the activity.
  snapshot.__keys.activities.forEach((id) => {
    snapshot.entities.activities[id] = extendActivityPropeerties(
      snapshot.entities.activities[id]
    );
  });

  // loop through groups for parents and children
  snapshot.__keys.groups.forEach((id) => {
    const group = snapshot.entities.groups[id];
    const outer = snapshot.entities.groups[group.group_uuid];
    const lead = snapshot.entities.roles[group.lead_uuid];

    const leadId = group.lead_uuid ?? null;
    const leadGroupId = lead?.group_uuid ?? null;
    const outerId = group.group_uuid ?? null;

    const hasLead = Boolean(lead);
    const hasOuter = Boolean(outer);
    const isLeadInGroup = hasLead && leadGroupId === outerId;

    // set hierarchy
    switch (true) {
      case isLeadInGroup:
        group.__parent = { uuid: leadId, type: DesignEntity.ROLE };
        lead.__children.set(id, DesignEntity.GROUP);
        return;

      case hasOuter:
        group.__parent = { uuid: outerId, type: DesignEntity.GROUP };
        outer.__children.set(id, DesignEntity.GROUP);
        return;

      default:
        return snapshot.__roots.set(id, DesignEntity.GROUP);
    }
  });

  // loop through roles for parents and children
  snapshot.__keys.roles.forEach((id) => {
    const role = snapshot.entities.roles[id];
    role.__layers = null;
    const group = snapshot.entities.groups[role.group_uuid];
    const parent = snapshot.entities.roles[role.parent_uuid];

    const groupId = role.group_uuid ?? null;
    const parentId = role.parent_uuid ?? null;
    const parentGroupId = parent?.group_uuid ?? null;

    const hasGroup = Boolean(group);
    const hasParent = Boolean(parent);
    const isParentInGroup = hasParent && parentGroupId === groupId;

    // set hierarchy
    switch (true) {
      case isParentInGroup:
        role.__parent = { uuid: parentId, type: DesignEntity.ROLE };
        parent.__children.set(id, DesignEntity.ROLE);
        return;

      case hasGroup:
        role.__parent = { uuid: groupId, type: DesignEntity.GROUP };
        group.__children.set(id, DesignEntity.ROLE);
        return;

      default:
        return snapshot.__roots.set(id, DesignEntity.ROLE);
    }
  });

  // iterate through hierarchy for path and manager
  try {
    const roots = Array.from(snapshot.__roots.entries());
    roots.forEach(([id, type]) => {
      iterateHierarchy(snapshot, id, type);
    });
  } catch (e) {
    console.debug('Error iterating __roots map in getHierarchy ', snapshot);
    console.error(e);
  }

  // loop through groups for above, managed, direct, and spans
  snapshot.__keys.groups.forEach((id) => {
    const group = snapshot.entities.groups[id];
    const lead = snapshot.entities.roles[group.lead_uuid] ?? null;
    const outer = snapshot.entities.groups[group.group_uuid] ?? null;
    const manager = snapshot.entities.roles[group.__manager] ?? null;

    const managerId = group.__manager ?? null;
    const isManagerInGroup = manager?.group_uuid === group.group_uuid;

    lead?.__direct[DesignEntity.GROUP].add(group.uuid);

    if (outer) {
      outer.__span[DesignEntity.GROUP].add(group.uuid);
      outer.__metrics.span.total.groups += 1;
    }

    if (manager) {
      manager.__span[DesignEntity.GROUP].add(group.uuid);
      manager.__metrics.span.total.groups += 1;
    }

    const childGroups = Array.from(group.__children.entries());
    childGroups.forEach(([childId, childType]) => {
      group.__direct[childType].add(childId);
    });

    const groupPath = Array.from(group.__path.values());
    groupPath.forEach(({ uuid, type }) => {
      const plural = DesignEntity.toPlural(type);
      const entity = snapshot.entities[plural][uuid];
      group.__above[type].add(uuid);

      if (entity.__managed[DesignEntity.GROUP].has(group.uuid)) {
        return;
      }

      entity.__managed[DesignEntity.GROUP].add(group.uuid);
      entity.__metrics.managed.total.groups += 1;
    });
  });

  // loop through roles for above, managed, direct, and spans
  snapshot.__keys.roles.forEach((id) => {
    const role = snapshot.entities.roles[id];
    const budget = +role.budget;
    const fte = +role.fte;
    const parent = snapshot.entities.roles[role.parent_uuid] ?? null;
    const group = snapshot.entities.groups[role.group_uuid] ?? null;
    const manager = snapshot.entities.roles[role.__manager] ?? null;

    const groupId = role.group_uuid ?? null;
    const managerId = role.__manager ?? null;
    const managerGroupId = manager?.group_uuid ?? null;
    const hasManager = Boolean(manager);
    const isManagerInGroup = hasManager && managerGroupId === groupId;

    parent?.__direct[DesignEntity.ROLE].add(role.uuid);
    if (group) {
      group.__span[DesignEntity.ROLE].add(role.uuid);
      group.__metrics.span.total.roles += 1;
      group.__metrics.span.total.fte += fte;
      group.__metrics.span.total.budget += budget;

      if (role.is_manager) {
        group.__span[DesignEntity.MANAGER].add(role.uuid);
        group.__metrics.span.total.managers += 1;
      }
    }

    if (manager) {
      manager.__span[DesignEntity.ROLE].add(role.uuid);
      manager.__metrics.span.total.roles += 1;
      manager.__metrics.span.total.fte += fte;
      manager.__metrics.span.total.budget += budget;

      if (role.is_manager) {
        manager.__span[DesignEntity.MANAGER].add(role.uuid);
        manager.__metrics.span.total.managers += 1;
      }
    }

    const roleChildren = Array.from(role.__children.entries());

    roleChildren.forEach(([childId, childType]) => {
      role.__direct[childType].add(childId);

      const plural = DesignEntity.toPlural(childType);
      const child = snapshot.entities[plural][childId];
      if (child.is_manager) {
        role.__direct[DesignEntity.MANAGER].add(childId);
      }
    });

    const rolePath = Array.from(role.__path.values());

    rolePath.forEach(({ uuid, type }) => {
      const plural = DesignEntity.toPlural(type);
      const above = snapshot.entities[plural][uuid];

      if (
        type === DesignEntity.ROLE &&
        !above.__managed[DesignEntity.ROLE].has(role.uuid)
      ) {
        above.__managed[DesignEntity.ROLE].add(role.uuid);
        above.__metrics.managed.total.roles += 1;
        above.__metrics.managed.total.budget += budget;
        above.__metrics.managed.total.fte += fte;

        const addLayer = above.__layer > 1 ? 1 : 0;

        const layers = role.__layer - above.__layer;
        above.__layers = Math.max(above.__layers || 0, layers);
      }

      if (above.is_manager) {
        role.__above[DesignEntity.MANAGER].add(uuid);
      }

      if (
        type === DesignEntity.GROUP &&
        !above.__managed[DesignEntity.ROLE].has(role.uuid)
      ) {
        above.__metrics.managed.total.roles += 1;
        above.__metrics.managed.total.budget += budget;
        above.__metrics.managed.total.fte += fte;
      }

      role.__above[type].add(uuid);
      if (
        role.is_manager &&
        !above.__managed[DesignEntity.MANAGER].has(role.uuid)
      ) {
        above.__managed[DesignEntity.MANAGER].add(role.uuid);
        above.__metrics.managed.total.managers += 1;
        role.__layers = above.__layer - role.__layer + 1;
      }
    });
  });

  // loop through activities to set paths
  const activityPaths = new Map();
  snapshot.__keys.activities.forEach((id) => {
    const activity = snapshot.entities.activities[id];
    const isActivityDisabled = Boolean(activity.disabled_at);

    const ownerPlural = DesignEntity.toPlural(activity.owner_type);
    const owner = snapshot.entities[ownerPlural][activity.owner_uuid];

    if (owner) {
      owner.__activities.add(id);
      owner.__metrics.self.total.hours += activity.hours;
      owner.__hours = owner.__hours
        ? (owner.__hours += activity.hours)
        : activity.hours;
    }

    const manager = snapshot.entities.roles[owner?.__manager] ?? null;

    if (manager) {
      manager.__span[DesignEntity.ACTIVITY].add(id);
      if (!isActivityDisabled) {
        manager.__metrics.span.total.activities += 1;
        manager.__metrics.span.total.hours += activity.hours;
      }
    }

    const parent = snapshot.entities.roles[owner?.__parent?.uuid] ?? null;
    parent?.__direct[DesignEntity.ACTIVITY].add(id);

    const group = owner?.group_uuid
      ? snapshot.entities.groups[owner.group_uuid]
      : null;

    if (group && !isActivityDisabled) {
      group.__hours = group.__hours
        ? (group.__hours += activity.hours)
        : activity.hours;
      group.__metrics.self.total.hours += activity.hours;
      group.__metrics.span.total.activities += 1;
      group.__metrics.span.total.hours += activity.hours;
    }

    activity.__group = owner?.group_uuid ?? null;
    activity.__manager = owner?.__manager ?? null;

    // set path
    switch (true) {
      case !owner:
        activity.__path = new Map();
        break;

      case activityPaths.has(owner.uuid):
        activity.__path = new Map(activityPaths.get(owner.uuid));
        break;

      default:
        const ownerStep = defaultTreePathStep();
        ownerStep.uuid = activity.owner_uuid;
        ownerStep.type = activity.owner_type;
        ownerStep.order = owner.__path.size;

        activity.__path = new Map(owner.__path);
        activity.__path.set(owner.uuid, ownerStep);
        activityPaths.set(owner.uuid, new Map(activity.__path));
    }

    // iterate path
    const activityPath = Array.from(activity.__path.values());

    activityPath.forEach(({ uuid, type }) => {
      const plural = DesignEntity.toPlural(type);
      const entity = snapshot.entities[plural][uuid];
      if (entity.__managed[DesignEntity.ACTIVITY].has(id)) {
        return;
      }
      entity.__managed[DesignEntity.ACTIVITY].add(id);
      if (!isActivityDisabled) {
        entity.__metrics.managed.total.activities += 1;
        entity.__metrics.managed.total.hours += activity.hours;
      }
    });
  });

  // optionally flatten, then freeze and return
  const frozen = flatten ? getFlattened(snapshot) : snapshot;
  return frozen;
}
