import { FilterEntity, FilterProperty } from '@/organisms/filters/enums';
import { getFilterType } from '@/organisms/filters/utils';
import ComparatorMap from '@/organisms/filters/utils/comparatorMap';

export const getPossibleFilters = () => {
  const combinations = [];
  Object.values(FilterEntity).forEach((entity) => {
    Object.values(FilterProperty).forEach((property) => {
      const realFilterType = getFilterType(entity, property, null);
      const validOperators = ComparatorMap.get(realFilterType) || [];
      validOperators.forEach((_, key) => {
        combinations.push({
          dotNotation: `${entity}.${property}.${key}.${realFilterType}`,
          entity,
          property,
          operator: key,
          realFilterType,
        });
      });
    });
  });

  return combinations;
};

export default getPossibleFilters;
