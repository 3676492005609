import { MetricProperty } from '@/atoms/enums';
import { Size } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import { BarChart } from '@/molecules/chartElements';
import { BinaryDonutChart } from '@/organisms/charts';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { format } from '@pkg/utils/numbers';

const formatChartData = ({ chartType, colors, isPositive, values }) => {
  const previous = values[0];
  const next = values[1];

  switch (chartType) {
    case 'donut':
      return {
        values: isPositive
          ? [next - previous, previous]
          : [previous - next, next],
        colors: isPositive
          ? [colors.primary, colors.secondary]
          : [colors.secondary, colors.primary],
      };
    default:
      return {
        values,
        colors: isPositive
          ? [colors.secondary, colors.primary]
          : [colors.primary, colors.secondary],
      };
  }
};

const BeforeAfterMetric = ({
  colors,
  metric,
  metrics,
  chartType = 'donut',
}) => {
  const previous = metrics?.[0]?.[metric];
  const next = metrics?.[1]?.[metric];
  const differenceValue = next - previous;
  const values = [previous, next];
  const isPositive = differenceValue > 0;
  const chartData = formatChartData({
    chartType,
    colors,
    values,
    isPositive,
  });
  const previousValue = format(previous, {
    ...MetricProperty[metric].formatOptions,
    notation: 'compact',
  });
  const nextValue = format(next, {
    ...MetricProperty[metric].formatOptions,
    notation: 'compact',
  });

  const color = isPositive
    ? [colors.secondary, colors.primary]
    : [colors.primary, colors.secondary];

  return (
    <Stack direction="row" spacing={1}>
      <Box
        textAlign="right"
        alignSelf={isPositive ? 'end' : 'start'}
        width={66}
      >
        <Heading
          variant="h4"
          overrideStyles={{
            fontSize: '1.125rem',
            fontWeight: 700,
          }}
        >
          {previousValue}
        </Heading>
        <Paragraph
          size={Size.XX_SMALL}
          overrideStyles={{
            mb: 0,
            textTransform: 'uppercase',
          }}
        >
          Before
        </Paragraph>
      </Box>
      <Stack
        alignItems="center"
        direction="row"
        height={56}
        justifyContent="center"
        width={56}
      >
        {chartType === 'bar' && (
          <BarChart
            values={chartData.values}
            colors={color}
            minHeight={6}
            height={54}
            barWidth={20}
          />
        )}
        {chartType === 'donut' && (
          <BinaryDonutChart
            colors={chartData.colors}
            height={54}
            id={`${metric}-donut-chart`}
            data={chartData.values}
            width={54}
          />
        )}
      </Stack>
      <Box textAlign="left" alignSelf={isPositive ? 'start' : 'end'} width={66}>
        <Heading
          variant="h4"
          overrideStyles={{
            fontSize: '1.125rem',
            fontWeight: 700,
          }}
        >
          {nextValue}
        </Heading>
        <Paragraph
          size={Size.XX_SMALL}
          overrideStyles={{
            mb: 0,
            textTransform: 'uppercase',
          }}
        >
          After
        </Paragraph>
      </Box>
    </Stack>
  );
};

export default BeforeAfterMetric;
