import { Avatar } from '@/atoms/avatars';
import { EntityChip, StatusChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { EntityType } from '@/shared/enums';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const RoleTitle = ({ role, status }) => {
  const personName = role?.__person ? role.__person.__name : 'Unassigned';

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Avatar
        src={role.__person?.avatar}
        name={personName}
        sizeProps={{
          fontSize: '1rem',
          width: '42px',
          height: '42px',
        }}
      />
      <Box>
        <Heading variant="h5">
          <EntityChip
            size={Size.XX_SMALL}
            sx={{ height: 18, px: 1, mr: 0.5 }}
            type={role.is_manager ? EntityType.MANAGER : EntityType.ROLE}
          />
          {status && (
            <StatusChip
              status={status}
              size={Size.XX_SMALL}
              sx={{ height: 18, px: 1, mr: 0.5 }}
            />
          )}
          {role.title}
        </Heading>
        <Heading variant="h6" overrideStyles={{ mt: 0.75 }}>
          {personName}
        </Heading>
      </Box>
    </Stack>
  );
};

export default RoleTitle;
