import { Size } from '@/atoms/enums';
import { Heading, Label } from '@/atoms/typography';
import Stack from '@mui/material/Stack';
import { format } from '@pkg/utils/numbers';
import RelativeSize from './utils/RelativeSize';
import TextMetricSize from './utils/TextMetricSize';

const TextMetric = ({
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
  boldValue = true,
  boldText,
  direction = 'column',
  formatOptions,
  label,
  size = Size.MEDIUM,
  value,
  comparisonValue,
  comparisonColor,
}) => {
  const sizeProps = TextMetricSize[size];
  const formattedValue = format(value, {
    ...formatOptions,
    notation: 'compact',
  });
  const formattedComparisonValue = format(comparisonValue, {
    ...formatOptions,
    notation: 'compact',
  });

  const compareSize = RelativeSize.smaller(size);
  const isActiveComparison = typeof comparisonValue === 'number';

  return (
    <Stack
      direction={direction}
      alignItems={alignItems}
      justifyContent={justifyContent}
      spacing={direction === 'column' ? 0 : 0.5}
    >
      {direction === 'column' && (
        <>
          <Stack direction="row" alignItems="flex-start" spacing={1}>
            {isActiveComparison && (
              <Heading
                overrideStyles={{
                  ...compareSize,
                  fontWeight: boldValue ? 700 : sizeProps.value.fontWeight,
                  color: comparisonColor?.text,
                }}
                variant="h3"
              >
                {formattedComparisonValue}
              </Heading>
            )}
            <Heading
              overrideStyles={{
                ...sizeProps.value,
                fontWeight: boldText ? 700 : sizeProps.value.fontWeight,
              }}
              variant="h3"
            >
              {formattedValue}
            </Heading>
          </Stack>
          <Heading
            overrideStyles={{
              ...sizeProps.label,
              textTransform: 'uppercase',
            }}
            variant="h4"
          >
            {label}
          </Heading>
        </>
      )}
      {direction === 'row' && (
        <>
          {isActiveComparison && (
            <Label
              size={compareSize}
              overrideStyles={{
                fontWeight: 500,
                color: comparisonColor?.text,
              }}
            >
              {formattedComparisonValue}
            </Label>
          )}
          <Label size={size} overrideStyles={{ fontWeight: 500 }}>
            {formattedValue}
          </Label>
          <Label
            size={size}
            overrideStyles={{ textTransform: 'uppercase', fontWeight: 500 }}
          >
            {label}
          </Label>
        </>
      )}
    </Stack>
  );
};

export default TextMetric;
