import { Snapshots } from '@pkg/entities';
import { Obj, Str } from '@pkg/utils';
import config from '@/lib/config';
import { ClientError, DesignEntity, DesignLevel } from '@/lib/enums';

const REDUCABLE = Object.freeze(
  new Set([
    DesignLevel.GROUP,
    DesignLevel.MANAGER,
    DesignLevel.PERSON,
    DesignLevel.ROLE,
  ])
);

/**
 * @param {Object} snapshot
 * @param {Object} level
 * @returns {Object|ClientError}
 */
export default function reduce(snapshot, level) {
  if (!snapshot) {
    return null;
  }

  if (!REDUCABLE.has(level?.type)) {
    return snapshot;
  }

  const keyed = Snapshots.deriveProps({
    snapshot,
    includeHierarchy: !Object.hasOwn(snapshot, '__hierarchy'),
    includeMetrics: false,
    flatten: false,
  });

  switch (level.type) {
    case DesignLevel.GROUP:
      return reduceGroup(snapshot, keyed, level.uuid);
    case DesignLevel.MANAGER:
      return reduceManager(snapshot, keyed, level.uuid);
    case DesignLevel.PERSON:
      return reducePerson(snapshot, keyed, level.uuid);
    case DesignLevel.ROLE:
      return reduceRole(snapshot, keyed, level.uuid);
  }
}

/**
 * Reduce to a single group
 * @returns {ClientError|Snapshot}
 */
function reduceGroup(snapshot, keyed, groupId) {
  const group = keyed.entities.groups[groupId];
  if (!group) {
    return ClientError.NOT_FOUND;
  }

  // setup
  const reduced = structuredClone(config.DEFAULT_SNAPSHOT);
  reduced.created_at = group.updated_at;
  reduced.name = group.name;
  reduced.objective = group.objective;
  reduced.entities.people = snapshot.entities.people;

  const leadIds = new Set();
  const ownerIds = new Set();

  // surrounding group
  const surround = keyed.entities.groups[group.group_uuid] ?? null;
  if (surround) {
    reduced.entities.groups.push(surround);
    leadIds.add(surround.lead_uuid);
  }

  // groups
  const iterateGroups = (groupId) => {
    const group = keyed.entities.groups[groupId];
    if (!group) {
      return;
    }

    reduced.entities.groups.push(group);
    leadIds.add(group.lead_uuid);
    ownerIds.add(groupId);

    group.__span[DesignEntity.GROUP].forEach((innerId) => {
      iterateGroups(innerId);
    });
  };

  iterateGroups(groupId);

  // roles
  snapshot.entities.roles.forEach((role) => {
    if (ownerIds.has(role.group_uuid)) {
      reduced.entities.roles.push(role);
      ownerIds.add(role.uuid);
    }

    if (leadIds.has(role.uuid)) {
      reduced.entities.roles.push(role);
    }
  });

  // activities
  snapshot.entities.activities.forEach((candidate) => {
    if (ownerIds.has(candidate.owner_uuid)) {
      reduced.entities.activities.push(candidate);
    }
  });

  reduced.__hash = Str.hash(Obj.omitDerived(reduced));
  return reduced;
}

/**
 * Reduce to a manager hierarchy
 * @returns {ClientError|Snapshot}
 */
function reduceManager(snapshot, keyed, managerId) {
  const manager = keyed.entities.roles[managerId];
  if (!manager) {
    return ClientError.NOT_FOUND;
  }

  // setup
  const reduced = structuredClone(config.DEFAULT_SNAPSHOT);
  reduced.created_at = manager.updated_at;
  reduced.name = manager.title;
  reduced.objective = '';

  const ownerIds = new Set();
  const peopleIds = new Set();

  // groups
  snapshot.entities.groups.forEach((candidate) => {
    if (manager.__managed[DesignEntity.GROUP].has(candidate.uuid)) {
      reduced.entities.groups.push(candidate);
      ownerIds.add(candidate.uuid);
    }
  });

  // roles
  snapshot.entities.roles.forEach((candidate) => {
    const isManaged = manager.__managed[DesignEntity.ROLE].has(candidate.uuid);
    const isManager = candidate.uuid === manager.uuid;

    if (isManaged || isManager) {
      reduced.entities.roles.push(candidate);
      ownerIds.add(candidate.uuid);
      peopleIds.add(candidate.user_uuid);
    }
  });

  // activities
  snapshot.entities.activities.forEach((candidate) => {
    if (ownerIds.has(candidate.owner_uuid)) {
      reduced.entities.activities.push(candidate);
    }
  });

  // people
  snapshot.entities.people.forEach((candidate) => {
    if (peopleIds.has(candidate.uuid)) {
      reduced.entities.people.push(candidate);
    }
  });

  reduced.__hash = Str.hash(Obj.omitDerived(reduced));
  return reduced;
}

/**
 * Reduce to a single role
 * @returns {ClientError|Snapshot}
 */
function reduceRole(snapshot, keyed, roleId) {
  const role = keyed.entities.roles[roleId];
  if (!role) {
    return ClientError.NOT_FOUND;
  }

  // setup
  const reduced = structuredClone(config.DEFAULT_SNAPSHOT);
  reduced.entities.people = snapshot.entities.people;
  reduced.entities.roles.push(role);
  reduced.created_at = role.updated_at;

  reduced.name = role.title;
  reduced.objective = '';

  // group
  const group = keyed.entities.groups[role.group_uuid];
  if (group) {
    reduced.entities.groups.push(group);
    reduced.created_at = keepNewest(reduced.created_at, role.updated_at);
  }

  // activities
  snapshot.entities.activities.forEach((candidate) => {
    if (candidate.owner_uuid === roleId) {
      reduced.entities.activities.push(candidate);
    }
  });

  reduced.__hash = Str.hash(Obj.omitDerived(reduced));
  return reduced;
}

/**
 * Reduce to a persons roles and groups
 * @returns {ClientError|Snapshot}
 */
function reducePerson(snapshot, keyed, personId) {
  const person = keyed.entities.people[personId];
  if (!person) {
    return ClientError.NOT_FOUND;
  }

  // setup
  const reduced = structuredClone(config.DEFAULT_SNAPSHOT);
  reduced.entities.people.push(person);
  reduced.created_at = 0;

  reduced.name = `${person.first_name} ${person.last_name}`;
  reduced.objective = '';

  const peopleIds = new Set([personId]);
  const groupIds = new Set();
  const roleIds = new Set();

  // roles
  snapshot.entities.roles.forEach((candidate) => {
    if (candidate.user_uuid === personId) {
      reduced.created_at = keepNewest(reduced.created_at, candidate.updated_at);
      reduced.entities.roles.push(candidate);
      groupIds.add(candidate.group_uuid);
      roleIds.add(candidate.uuid);
    }
  });

  // groups
  snapshot.entities.groups.forEach((candidate) => {
    if (candidate.lead_uuid === personId || groupIds.has(candidate.uuid)) {
      reduced.created_at = keepNewest(reduced.created_at, candidate.updated_at);
      reduced.entities.groups.push(candidate);
      peopleIds.add(candidate.lead_uuid);
    }
  });

  // activities
  snapshot.entities.activities.forEach((candidate) => {
    if (roleIds.has(candidate.owner_uuid)) {
      reduced.entities.activities.push(candidate);
    }
  });

  // people
  snapshot.entities.people.forEach((candidate) => {
    if (peopleIds.has(candidate.uuid)) {
      reduced.entities.people.push(candidate);
    }
  });

  reduced.__hash = Str.hash(Obj.omitDerived(reduced));
  return reduced;
}

/**
 * Keep newest timestamp
 */
function keepNewest(a, b) {
  return a > b ? a : b;
}
