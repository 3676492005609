import { EntityType } from '@/shared/enums';
import { EntityMetric } from '@/lib/enums';

export default function tagMetricChange({
  scenarioMetrics,
  comparisonMetrics,
  tag,
  tagType,
}) {
  const tagMetric = {
    name: tag.name,
    color: tag.color,
    id: tag.uuid,
    metrics: [{ ...comparisonMetrics }, { ...scenarioMetrics }],
  };

  const isActivityTag =
    tagType === EntityType.ACTIVITY &&
    tag.__taggables_set.has(EntityType.ACTIVITY);
  const isRoleTag =
    tagType === EntityType.ROLE && tag.__taggables_set.has(EntityType.ROLE);
  const isGroupTag =
    tagType === EntityType.GROUP && tag.__taggables_set.has(EntityType.GROUP);

  if (isActivityTag) {
    const hours = comparisonMetrics?.hours !== scenarioMetrics?.hours;
    const activities =
      comparisonMetrics?.activities !== scenarioMetrics?.activities;

    if (hours || activities) {
      tagMetric.metric = hours ? EntityMetric.HOURS : EntityMetric.ACTIVITIES;
      tagMetric.entityType = EntityType.ACTIVITY;

      return tagMetric;
    }
  }
  if (isRoleTag) {
    const roles = comparisonMetrics?.roles !== scenarioMetrics?.roles;
    const fte = comparisonMetrics?.fte !== scenarioMetrics?.fte;
    if (roles || fte) {
      tagMetric.metric = roles ? EntityMetric.ROLES : EntityMetric.FTE;
      tagMetric.entityType = EntityType.ROLE;
      return tagMetric;
    }
  }
  if (isGroupTag) {
    const groups = comparisonMetrics?.groups !== scenarioMetrics?.groups;
    const fte = comparisonMetrics?.fte !== scenarioMetrics?.fte;
    if (groups || fte) {
      tagMetric.metric = groups ? EntityMetric.GROUPS : EntityMetric.FTE;
      tagMetric.entityType = EntityType.GROUP;
      return tagMetric;
    }
  }

  return;
}
