/**
 * Helper function to check if an action exists in the action map map.
 */
export default function actionExists({
  actionMap,
  actionType,
  entityType,
  id,
}) {
  return actionMap.get(actionType)?.[entityType]?.get(id);
}
