import addedManagerAction from './addedManagerAction';
import assignedManagerAction from './assignedManagerAction';
import removedManagerAction from './removedManagerAction';
import updatedSpansAction from './updatedSpansAction';

export default function addActionByManager({
  comparisonScenario,
  role,
  originalRole,
  scenario,
  showBudget,
}) {
  const actions = [];

  const isNewManager = role && role.is_manager && !originalRole?.is_manager;
  const isRemovedManager = originalRole?.is_manager && !role?.is_manager;

  if (isNewManager) {
    actions.push(addedManagerAction({ role, comparisonScenario }));
  }

  if (isRemovedManager) {
    actions.push(
      removedManagerAction({
        role: role || originalRole,
        comparisonScenario,
      })
    );
  }

  if (!role?.is_manger && !originalRole?.is_manager) {
    return actions;
  }

  // Changed manager assignee.
  if (role && role?.user_uuid !== originalRole?.user_uuid) {
    actions.push(
      assignedManagerAction({
        comparisonScenario,
        originalRole,
        role,
      })
    );
  }

  if (
    role?.__metrics?.span.visible.roles !==
    originalRole?.__metrics?.span.visible.roles
  ) {
    actions.push(
      updatedSpansAction({
        role,
        originalRole,
        comparisonScenario,
      })
    );
  }

  return actions;
}
