/**
 * Returns an action group map and creates a new one if it doesn't exist.
 *
 * @param {Object}
 *
 * @return {Map}
 */
export default function getActionGroup({
  actionMap,
  actionType,
  entityType,
  groupActionType,
  groupActionId,
  props,
}) {
  // Group maps contain a nested actionMap property using the groupActionId
  // provided as the map identifier.
  if (!actionMap?.get(groupActionType)) {
    actionMap.set(groupActionType, {
      entity: entityType,
      props,
      type: groupActionType,
      actionMap: new Map([[groupActionId, new Map()]]),
    });
  }
  if (!actionMap.get(groupActionType).actionMap?.get(groupActionId)) {
    actionMap.get(groupActionType).actionMap.set(groupActionId, new Map());
  }

  return actionMap.get(groupActionType).actionMap.get(groupActionId);
}
