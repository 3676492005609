import { memo } from 'react';
import { Button } from '@/atoms/buttons';
import { Chip, EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { TextMetric } from '@/atoms/metrics';
import { Heading } from '@/atoms/typography';
import { ChangeMetric } from '@/molecules/metrics';
import { OutlineCard } from '@/organisms/cards';
import { planEntityColors } from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { ActionType } from '@/shared/enums';
import { useTagContext, useViewModeContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { core } from '@/lib/theme/tokens/palettes';
import {
  calculateEntityChanges,
  determineChartType,
  determineEntityChange,
  determineMetric,
} from './utils';

const PlanEntityTypeCard = ({
  actionType,
  entityType = EntityType.ROLE,
  isSelected,
  metrics,
  onEdit,
  onRemove,
  entityMap,
  entityCount,
  title,
}) => {
  const { tagMap } = useTagContext();
  const { showBudget } = useViewModeContext();

  const cardMetric = determineMetric({
    actionType,
    entityType,
    metrics,
    showBudget,
  });

  const cardProperties = {};

  if (cardMetric.tagId) {
    cardProperties.props = {
      id: cardMetric.tagId,
    };
    cardProperties.actionType = ActionType.UPDATED_TAGS;
  }

  const chartType = determineChartType(cardMetric.metricType);

  const colors = planEntityColors({
    actionProperties: { ...cardProperties.props },
    actionType: cardProperties.actionType,
    entityType,
    metric: cardMetric.metricType,
    tagMap,
  });

  const entityChange = determineEntityChange({
    metrics: cardMetric.metrics,
    entityCount,
    entityType,
  });

  const changes = calculateEntityChanges(entityMap, entityType);

  return (
    <Stack direction="row" alignItems="center" width="100%">
      <Box width="136px" mr={1} flexShrink={0}>
        <ChangeMetric
          chartType={chartType}
          colors={colors}
          entityType={entityType}
          metric={cardMetric.metricType}
          metrics={cardMetric.metrics}
        />
      </Box>
      <Box flexGrow={1}>
        <OutlineCard padding={0} margin={0}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            p={1.5}
            spacing={1}
            sx={{
              boxShadow: isSelected
                ? `inset 0 0 0 1px ${colors.primary}, 0 0 0 1px ${colors.primary}`
                : 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              transition: 'box-shadow 200ms',
              '&: hover': !isSelected && {
                boxShadow: `inset 0 0 0 1px ${colors.secondary}, 0 0 0 1px ${colors.secondary}`,
              },
            }}
          >
            <Box>
              {actionType && (
                <Chip
                  id={actionType}
                  name={actionType.toUpperCase()}
                  colorProps={{
                    normal: {
                      backgroundColor: colors.primary,
                      color: core.white,
                    },
                  }}
                  size={Size.XX_SMALL}
                  fontWeight={600}
                  sx={{ height: 20, px: 1 }}
                />
              )}
              {!actionType && (
                <EntityChip
                  size={Size.XX_SMALL}
                  sx={{ height: 20, px: 1 }}
                  type={entityType}
                />
              )}
              <Box>
                <Heading
                  variant="h4"
                  overrideStyles={{
                    fontSize: '1.025rem',
                    mb: 0,
                    mt: 0.5,
                  }}
                >
                  {title}
                </Heading>
              </Box>
            </Box>
            <Stack alignItems="center" direction="row" spacing={2}>
              {changes.added > 0 && (
                <TextMetric
                  alignItems="center"
                  label="Added"
                  size={Size.X_SMALL}
                  value={changes.added}
                />
              )}
              {changes.removed > 0 && (
                <TextMetric
                  alignItems="center"
                  label="Removed"
                  size={Size.X_SMALL}
                  value={changes.removed}
                />
              )}
              {changes.moved > 0 && (
                <TextMetric
                  alignItems="center"
                  label="Moved"
                  size={Size.X_SMALL}
                  value={changes.moved}
                />
              )}
              {changes.changed > 0 && (
                <TextMetric
                  alignItems="center"
                  label="Changed"
                  size={Size.X_SMALL}
                  value={changes.changed}
                />
              )}
              <Button
                label="View"
                color="light-blue"
                size={Size.SMALL}
                onClick={onEdit}
              />
            </Stack>
          </Stack>
        </OutlineCard>
      </Box>
    </Stack>
  );
};

export default memo(PlanEntityTypeCard, (previous, next) => {
  return previous?.isSelected === next?.isSelected;
});
