import { ActionType, EntityType } from '@/shared/enums';
import actionExists from './actionExists';
import addTagActions from './addTagActions';
import addedActivitiesAction from './addedActivitiesAction';
import addedGroupsAction from './addedGroupsAction';
import addedManagersAction from './addedManagersAction';
import addedRolesAction from './addedRolesAction';
import assignedManagersAction from './assignedManagersAction';
import assignedRolesAction from './assignedRolesAction';
import movedActivities from './movedActivities';
import movedGroupsAction from './movedGroupsAction';
import movedRolesAction from './movedRolesAction';
import removedActivitiesAction from './removedActivitiesAction';
import removedGroupsAction from './removedGroupsAction';
import removedManagersAction from './removedManagersAction';
import removedRolesAction from './removedRolesAction';
import updatedBudgetAction from './updatedBudgetAction';
import updatedFTEAction from './updatedFTEAction';
import updatedHoursAction from './updatedHoursAction';
import updatedSpansAction from './updatedSpansAction';

export default function addActionByActionType({
  activity,
  actionMap,
  comparisonScenario,
  group,
  originalActivity,
  originalGroup,
  originalRole,
  role,
  scenario,
  showBudget,
}) {
  const actions = [];
  const isNewRole = role && !originalRole;
  const roleIsManager = role && role.is_manager;
  const originalRoleIsManager = originalRole && originalRole.is_manager;
  const isNewManager = roleIsManager && !originalRoleIsManager;
  const isRemovedManager = originalRoleIsManager && !roleIsManager;
  const roleSpan = role?.__metrics?.span?.visible.roles || 0;
  const originalRoleSpan = originalRole?.__metrics?.span?.visible.roles || 0;
  const isSpansUpdated = roleSpan !== originalRoleSpan;
  const primaryRole = role || originalRole;

  // New activity actions.
  const hasNewActivity =
    activity &&
    actionExists({
      actionMap,
      actionType: ActionType.ADD_ACTIVITIES,
      entityType: EntityType.ACTIVITY,
      id: activity.uuid,
    });
  if (activity && !hasNewActivity && !originalActivity) {
    actions.push(
      addedActivitiesAction({
        activity,
        actionMap,
        comparisonScenario,
        scenario,
      })
    );
  }

  // Activity hours change actions.
  const hasHoursChange =
    activity &&
    actionExists({
      actionMap,
      actionType: ActionType.UPDATE_HOURS,
      entityType: EntityType.ACTIVITY,
      id: activity.uuid,
    });
  if (
    !hasHoursChange &&
    originalActivity &&
    activity &&
    activity.hours !== originalActivity.hours
  ) {
    actions.push(
      updatedHoursAction({
        actionMap,
        activity,
        comparisonScenario,
        scenario,
      })
    );
  }

  const activityTags =
    activity?.__metrics?.self?.visible?.tags?.[EntityType.ACTIVITY] ||
    originalActivity?.__metrics?.self?.visible?.tags?.[EntityType.ACTIVITY];

  if (activityTags) {
    actions.push(
      ...addTagActions({
        actionMap,
        actionType: ActionType.UPDATED_ACTIVITY_TAGS,
        entity: activity,
        entityTags: activityTags,
        comparisonScenario,
        originalEntity: originalActivity,
        scenario,
        tagType: EntityType.ACTIVITY,
      })
    );
  }

  // New role added.
  const hasNewRole =
    role &&
    actionExists({
      actionMap,
      actionType: ActionType.ADD_ROLES,
      entityType: EntityType.ROLE,
      id: role.uuid,
    });
  if (!hasNewRole && isNewRole) {
    actions.push(
      addedRolesAction({
        actionMap,
        comparisonScenario,
        role,
        scenario,
      })
    );
  }

  const hasNewManager =
    role &&
    actionExists({
      actionMap,
      actionType: ActionType.ADD_MANAGERS,
      entityType: EntityType.MANAGER,
      id: role.uuid,
    });
  if (!hasNewManager && isNewManager) {
    actions.push(
      addedManagersAction({
        actionMap,
        comparisonScenario,
        originalRole,
        role,
        scenario,
      })
    );
  }

  const hasRemovedManager =
    originalRole &&
    actionExists({
      actionMap,
      actionType: ActionType.REMOVE_MANAGERS,
      entityType: EntityType.MANAGER,
      id: originalRole.uuid,
    });
  if (!hasRemovedManager && isRemovedManager) {
    actions.push(
      removedManagersAction({
        actionMap,
        comparisonScenario,
        originalRole,
        role,
        scenario,
      })
    );
  }

  const hasSpanChange =
    primaryRole &&
    actionExists({
      actionMap,
      actionType: ActionType.UPDATED_SPANS,
      entityType: EntityType.MANAGER,
      id: primaryRole.uuid,
    });

  if (!hasSpanChange && isSpansUpdated) {
    actions.push(
      updatedSpansAction({
        actionMap,
        comparisonScenario,
        originalRole,
        role,
        scenario,
      })
    );
  }

  if (role && originalRole) {
    // FTE Changes.
    const hasFTEChange = actionExists({
      actionMap,
      actionType: ActionType.UPDATE_FTE,
      entityType: EntityType.ROLE,
      id: role.uuid,
    });
    if (
      !hasFTEChange &&
      role.__metrics.self.visible.fte !==
        originalRole.__metrics.self.visible.fte
    ) {
      actions.push(
        updatedFTEAction({
          actionMap,
          comparisonScenario,
          role,
        })
      );
    }

    // Budget Changes.
    const hasBudgetChange = actionExists({
      actionMap,
      actionType: ActionType.UPDATE_BUDGETS,
      entityType: EntityType.ROLE,
      id: role.uuid,
    });
    if (
      showBudget &&
      !hasBudgetChange &&
      role &&
      originalRole &&
      role.__metrics.self.visible.budget !==
        originalRole.__metrics.self.visible.budget
    ) {
      actions.push(
        updatedBudgetAction({
          actionMap,
          comparisonScenario,
          role,
          scenario,
        })
      );
    }

    // Assignee changed.
    const isNewlyAssigned = role?.user_uuid !== originalRole?.user_uuid;
    const hasAssignedRole =
      isNewlyAssigned &&
      actionExists({
        actionMap,
        actionType: roleIsManager
          ? ActionType.ASSIGNED_MANAGERS
          : ActionType.ASSIGNED_ROLES,
        entityType: roleIsManager ? EntityType.MANAGER : EntityType.ROLE,
        id: role.uuid,
      });

    if (!hasAssignedRole && isNewlyAssigned) {
      const assignFunction = roleIsManager
        ? assignedManagersAction
        : assignedRolesAction;

      actions.push(
        assignFunction({
          actionMap,
          comparisonScenario,
          role,
          originalRole,
          scenario,
        })
      );
    }

    // Moving roles between groups and managers.
    const changedManagers = role.__manager !== originalRole.__manager;
    const changedGroups = role.group_uuid !== originalRole.group_uuid;

    const hasMovedRole =
      (changedManagers || changedGroups) &&
      actionExists({
        actionMap,
        actionType: ActionType.MOVE_ROLES,
        entityType: EntityType.ROLE,
        id: role.uuid,
      });
    if (!hasMovedRole && (changedManagers || changedGroups)) {
      actions.push(
        movedRolesAction({
          actionMap,
          comparisonScenario,
          role,
          originalRole,
          scenario,
        })
      );
    }
  }

  const roleTags =
    role?.__metrics?.self?.visible?.tags?.[EntityType.ROLE] ||
    originalActivity?.__metrics?.self?.visible?.tags?.[EntityType.ROLE];

  if (roleTags) {
    actions.push(
      ...addTagActions({
        actionMap,
        actionType: ActionType.UPDATED_ROLE_TAGS,
        entity: role,
        entityTags: roleTags,
        comparisonScenario,
        originalEntity: originalRole,
        scenario,
        tagType: EntityType.ROLE,
      })
    );
  }

  // Adding a new group.
  const isNewGroup =
    group &&
    !comparisonScenario?.relationships?.get(EntityType.GROUP)?.has(group.uuid);
  const hasGroupChange =
    isNewGroup &&
    actionExists({
      actionMap,
      actionType: ActionType.ADD_GROUPS,
      entityType: EntityType.GROUP,
      id: group.uuid,
    });
  if (!hasGroupChange && isNewGroup) {
    actions.push(
      addedGroupsAction({
        actionMap,
        comparisonScenario,
        group,
        scenario,
      })
    );
  }

  const groupTags =
    group?.__metrics?.self?.visible?.tags?.[EntityType.GROUP] ||
    originalGroup?.__metrics?.self?.visible?.tags?.[EntityType.GROUP];

  if (groupTags) {
    actions.push(
      ...addTagActions({
        actionMap,
        actionType: ActionType.UPDATED_GROUP_TAGS,
        entity: group,
        entityTags: groupTags,
        comparisonScenario,
        originalEntity: originalGroup,
        scenario,
        tagType: EntityType.GROUP,
      })
    );
  }

  const hasRemovedActivity =
    originalActivity &&
    actionExists({
      actionMap,
      actionType: ActionType.REMOVE_ACTIVITIES,
      entityType: EntityType.ACTIVITY,
      id: originalActivity.uuid,
    });
  if (!hasRemovedActivity && !activity && originalActivity) {
    actions.push(
      removedActivitiesAction({
        actionMap,
        activity: originalActivity,
        comparisonScenario,
      })
    );
  }

  const hasRemovedRole =
    originalRole &&
    actionExists({
      actionMap,
      actionType: ActionType.REMOVE_ROLES,
      entityType: EntityType.ROLE,
      id: originalRole.uuid,
    });
  if (!hasRemovedRole && !role && originalRole) {
    actions.push(
      removedRolesAction({
        actionMap,
        comparisonScenario,
        group: originalGroup,
        role: originalRole,
      })
    );
  }

  const hasRemovedGroup =
    originalGroup &&
    actionExists({
      actionMap,
      actionType: ActionType.REMOVE_GROUPS,
      entityType: EntityType.GROUP,
      id: originalGroup.uuid,
    });
  if (!hasRemovedGroup && !group && originalGroup) {
    actions.push(
      removedGroupsAction({
        actionMap,
        comparisonScenario,
        group: originalGroup,
      })
    );
  }

  // Moving activities between roles and unallocated.
  const changedOwners =
    activity &&
    originalActivity &&
    activity.owner_uuid !== originalActivity.owner_uuid;
  const hasChangedOwners =
    changedOwners &&
    actionExists({
      actionMap,
      actionType: ActionType.MOVE_ACTIVITIES,
      entityType: EntityType.ACTIVITY,
      comparisonScenario,
      originalActivity,
      scenario,
    });
  if (!hasChangedOwners && changedOwners) {
    actions.push(
      movedActivities({
        actionMap,
        activity,
        comparisonScenario,
        originalActivity,
        scenario,
      })
    );
  }

  // Moving groups.
  const comparisonGroup = comparisonScenario?.relationships
    ?.get(EntityType.GROUP)
    ?.get(group?.uuid);
  const changedGroupManager =
    group && comparisonGroup && group.__manager !== comparisonGroup.__manager;
  const changedParent =
    group &&
    comparisonGroup &&
    group.__parent?.uuid !== comparisonGroup.__parent?.uuid;
  const hasMovedGroup =
    (changedGroupManager || changedParent) &&
    actionExists({
      actionMap,
      actionType: ActionType.MOVE_GROUPS,
      entityType: EntityType.GROUP,
      id: group.uuid,
    });

  if (!hasMovedGroup && (changedGroupManager || changedParent)) {
    actions.push(
      movedGroupsAction({
        actionMap,
        comparisonScenario,
        group,
        originalGroup: comparisonGroup,
        scenario,
      })
    );
  }

  return actions;
}
