import { defaultMetrics } from '@/organisms/plans/utils';
import { ActionType, EntityType } from '@/shared/enums';

export default function movedActivity({
  activity,
  comparisonScenario,
  originalActivity,
}) {
  const action = {
    type: ActionType.MOVED_ACTIVITY,
    [EntityType.ACTIVITY]: new Map(),
    metrics: defaultMetrics({ comparisonScenario }),
  };

  const activityProperties = {
    metrics: {
      hours: activity.hours,
    },
  };

  activityProperties.moved = {
    parent: {
      to: {
        uuid: activity.owner_uuid,
        type: activity.owner_type,
      },
      from: {
        uuid: originalActivity.owner_uuid,
        type: originalActivity.owner_type,
      },
    },
  };

  action[EntityType.ACTIVITY].set(activity.uuid, { props: activityProperties });

  action.metrics[1].moved += 1;

  return action;
}
