import { Heading } from '@/atoms/typography';
import { PlanChip } from '@/organisms/plans';
import Box from '@mui/material/Box';

const ActionSummaryOverview = ({ action }) => {
  return (
    <>
      <Box>
        <PlanChip
          actionType={action.actionType}
          entityType={action.entity}
          properties={action.props}
        />
      </Box>
      <Heading variant="h4" overrideStyles={{ mt: 1 }}>
        {action.title}
      </Heading>
    </>
  );
};

export default ActionSummaryOverview;
