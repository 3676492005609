import { useEffect, useState } from 'react';
import { EntityType } from '@/shared/enums';
import { useScenarioContext, useViewModeContext } from '@/shared/providers';
import { useTagContext } from '@/shared/providers';
import {
  aggregateMetrics,
  summaryMetricList,
  summaryTagMetricList,
} from '../utils';

const useSummaryMetrics = () => {
  const { comparisonScenario, scenario } = useScenarioContext();
  const { showBudget } = useViewModeContext();
  const { tagMap } = useTagContext();

  const [selected, setSelected] = useState();
  const [comparisonMetrics, setComparisonMetrics] = useState();
  const [metricList, setMetricList] = useState();
  const [tagMetricList, setTagMetricList] = useState();
  const [scenarioMetrics, setScenarioMetrics] = useState();

  const selectSummaryItem = (summaryItem) => {
    if (!summaryItem || summaryItem.id === selected?.id) {
      setSelected(null);
      return;
    }
    setSelected(summaryItem);
  };

  useEffect(() => {
    if (!comparisonScenario || !scenario || !tagMap?.size) {
      return;
    }

    if (!selected) {
      const comparisonMetrics = comparisonScenario
        ? aggregateMetrics(comparisonScenario.entity)
        : null;
      const scenarioMetrics = scenario
        ? aggregateMetrics(scenario.entity)
        : null;

      const metricList = summaryMetricList({
        comparisonMetrics,
        scenarioMetrics,
        showBudget,
      });
      const tagMetricList = summaryTagMetricList({
        comparisonMetrics,
        scenarioMetrics,
        showBudget,
        tagMap,
      });

      setComparisonMetrics(comparisonMetrics);
      setScenarioMetrics(scenarioMetrics);
      setMetricList(metricList);
      setTagMetricList(tagMetricList);

      return;
    }

    const showSpan =
      selected.entityType !== EntityType.MANAGER ||
      (selected.entityType === EntityType.MANAGER && selected.actions);

    const { metrics } = selected;

    const comparisonMetrics = metrics[0];
    const scenarioMetrics = metrics[1];

    const metricList = summaryMetricList({
      comparisonMetrics,
      scenarioMetrics,
      showBudget,
      showSpan,
    });
    const tagMetricList = summaryTagMetricList({
      comparisonMetrics,
      scenarioMetrics,
      showBudget,
      tagMap,
    });

    setComparisonMetrics(comparisonMetrics);
    setScenarioMetrics(scenarioMetrics);
    setMetricList(metricList);
    setTagMetricList(tagMetricList);
  }, [
    JSON.stringify(scenario?.entity?.__metrics?.visible),
    JSON.stringify(comparisonScenario?.entity?.__metrics?.visible),
    selected,
    showBudget,
    scenario?.details?.hash,
    comparisonScenario?.details?.hash,
    tagMap?.size,
  ]);

  return {
    comparisonMetrics,
    metricList,
    tagMetricList,
    scenarioMetrics,
    selectedSummaryItem: selected,
    selectSummaryItem,
  };
};

export default useSummaryMetrics;
