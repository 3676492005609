const ActionType = Object.freeze({
  // Activity
  ADDED_ACTIVITY: 'Added activity',
  REMOVED_ACTIVITY: 'Removed activity',
  MOVED_ACTIVITY: 'Moved activity',
  // Activities
  ADD_ACTIVITIES: 'Added activities',
  REMOVE_ACTIVITIES: 'Removed activities',
  MOVE_ACTIVITIES: 'Moved activities',
  UPDATED_HOURS: 'Updated hours',
  UPDATED_ACTIVITY_TAGS: 'Categorised activities',
  // Managers.
  ADD_MANAGERS: 'Added managers',
  ASSIGNED_MANAGERS: 'Assigned managers',
  REMOVE_MANAGERS: 'Removed managers',
  // Role
  ADDED_ROLE: 'New role',
  ASSIGNED_ROLES: 'Assigned roles',
  UPDATED_MANAGER: 'Updated manager',
  REMOVED_ROLE: 'Removed role',
  UPDATED_FTE: 'Updated fte',
  UPDATED_BUDGET: 'Updated budget',
  // Roles
  ADD_ROLES: 'Added roles',
  REMOVE_ROLES: 'Removed roles',
  MOVE_ROLES: 'Moved roles',
  UPDATED_ROLE_TAGS: 'Categorised roles',
  // Tags
  ADD_TAGS: 'Added tags',
  REMOVE_TAGS: 'Removed tags',
  // Teams
  ADD_GROUPS: 'Added teams',
  REMOVE_GROUPS: 'Removed teams',
  MOVE_GROUPS: 'Moved teams',
  UPDATED_GROUP_TAGS: 'Categorised teams',
  // UPDATES
  UPDATE_BUDGETS: 'Updated budgets',
  UPDATE_FTE: 'Updated FTE',
  UPDATE_HOURS: 'Updated Hours',
  UPDATED_TAGS: 'Updated categories',
  UPDATED_SPANS: 'Updated spans',
});

export default ActionType;
