import { DesignEntity } from '@/lib/enums';
import defaultEntityMetrics from '../utils/defaultEntityMetrics';

/**
 * Normalizes default person properties.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function normalizePerson({ people, person, role, snapshot }) {
  if (!person) {
    return;
  }

  const isNewPerson = !people || people.has(person.uuid);

  if (isNewPerson) {
    person.__name = `${person.first_name ?? ''} ${person.last_name ?? ''}`;
    person.__name = person.__name.trim();
    person.__pathname = `/u/${person.uuid}`;
    person.__type = DesignEntity.PERSON;
    person.__assigned = {
      [DesignEntity.MANAGER]: new Set(),
      [DesignEntity.ROLE]: new Set(),
    };
    person.__metrics = defaultEntityMetrics();
  }

  if (!role) {
    return person;
  }

  const addRole = !person.__assigned[DesignEntity.ROLE].has(role.uuid);
  if (addRole) {
    person.__assigned[DesignEntity.ROLE].add(role.uuid);
  }

  const addManager =
    role.is_manager && !person.__assigned[DesignEntity.MANAGER].has(role.uuid);
  if (addManager) {
    person.__assigned[DesignEntity.MANAGER].add(role.uuid);
  }

  return person;
}
