import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDesignsContext } from '@/shared/providers';
import * as Auth from '@pkg/auth';
import { Snapshots } from '@pkg/entities';
import { DesignEntity } from '@/lib/enums';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';
import iterateHierarchy from './iterateHierarchy';

export const EntityHierarchyContext = createContext();
export const useEntityHierarchyContext = () =>
  useContext(EntityHierarchyContext);

/**
 * Maps a design to a flat list hierarchy for use with navigation.
 */
const EntityHierarchyProvider = ({ children }) => {
  // We're using the designs here as a short term solution to make the entity
  // selector available app wide when a design hasn't been loaded in the store.
  // We should introduce a better solution when we can.
  const designs = useDesignsContext();
  const storedDesign = useDesignStore((state) => state.design);
  const storedSnapshot = useDesignStore((state) => state.revision?.snapshot);
  const { organisation } = Auth.useStore();

  // We're prioritising the loaded design in the store but there are times when
  // it's not available so we're loading the hydrated main design in this
  // instance.
  const design = storedDesign
    ? storedDesign
    : designs?.designs?.get(organisation?.design.uuid)?.design;
  const snapshot = storedSnapshot
    ? storedSnapshot
    : designs?.designs?.get(organisation?.design.uuid)?.snapshot;
  const [hierarchy, setHierarchy] = useState();
  const [entityMap, setEntityMap] = useState();

  // This effect is responsible for converting the entities in the snapshot into
  // a flat list.
  useEffect(() => {
    if (!design || !snapshot) {
      return;
    }

    const keyed = Snapshots.deriveProps({
      snapshot,
      includeMetrics: false,
      flatten: false,
    });

    if (!keyed) {
      return;
    }

    let results = [];
    const entityMap = new Map([
      [DesignEntity.ORGANISATION, new Map()],
      [DesignEntity.ACTIVITY, new Map()],
      [DesignEntity.ROLE, new Map()],
      [DesignEntity.GROUP, new Map()],
      [DesignEntity.PERSON, new Map()],
    ]);

    // Beginning with the root entities, traverse the organisation and create
    // a flat list of the organisation.
    keyed.__roots?.forEach((type, id) => {
      const item = iterateHierarchy({
        keyed,
        id,
        map: entityMap,
        type,
      });

      if (!item) {
        return;
      }

      results.push(item);
    });

    // We need to create an organisation entity at the top of the hierarchy so
    // it's possible to represent the root of a design.
    if (design.scope === DesignEntity.ORGANISATION) {
      entityMap.get(DesignEntity.ORGANISATION).set(design.owner?.uuid, {
        ...design,
        name: design?.name ?? organisation?.name,
        __type: DesignEntity.ORGANISATION,
      });

      const childItems = [...results];

      results = [
        {
          uuid: design.owner?.uuid,
          type: DesignEntity.ORGANISATION,
          children: childItems,
        },
      ];
    }

    // Add each person to the entity map so we can access them for the
    // hierarchy.
    Object.values(keyed.entities?.people).forEach((person) =>
      entityMap.get(DesignEntity.PERSON).set(person.uuid, person)
    );

    setHierarchy(results);
    setEntityMap(entityMap);
  }, [design?.uuid, snapshot?.__hash]);

  const context = useMemo(() => {
    return {
      hierarchy,
      entityMap,
    };
  }, [hierarchy, entityMap]);

  return (
    <EntityHierarchyContext.Provider value={context}>
      {children}
    </EntityHierarchyContext.Provider>
  );
};

export default EntityHierarchyProvider;
