import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { ActivityHeading, SurveyHeading } from '@/molecules/headings';
import { CategorisedActivityList } from '@/organisms/activities';
import { ActivityCardList } from '@/organisms/cards';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ActivityDescriptions = ({
  allowedTagSet,
  coreActivitySet,
  categoryTagMap,
  categoryTags,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const settings = {
    isEditable: true,
    isTagsEditable: false,
    showComparison: false,
    showDragDrop: false,
    showHours: false,
    showMenu: false,
    showRemove: true,
    showTags: false,
    showTitle: !categoryTagMap?.size,
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Paragraph size={isLargeScreen ? Size.LARGE : Size.MEDIUM}>
          Does this list describe the bundles of work (or work activities) that
          make up your typical working week?
        </Paragraph>
        <Paragraph
          size={isLargeScreen ? Size.MEDIUM : Size.SMALL}
          overrideStyles={{ fontStyle: 'italic', fontWeight: 600 }}
        >
          Tips:
        </Paragraph>
        <Paragraph size={isLargeScreen ? Size.MEDIUM : Size.SMALL}>
          &#8226;{' '}
          <span style={{ fontStyle: 'italic' }}>
            {' '}
            If you do work which is not listed, please add new activities at the
            bottom
          </span>
        </Paragraph>
        <Paragraph size={isLargeScreen ? Size.MEDIUM : Size.SMALL}>
          &#8226;{' '}
          <span style={{ fontStyle: 'italic' }}>
            {' '}
            If there are activities here which you don't do, you'll be able to
            mark as zero hours on the next page
          </span>
        </Paragraph>
      </Grid>
      {categoryTags?.length ? (
        <Grid item xs={12} md={8} sx={{ mt: 1 }}>
          <CategorisedActivityList
            categoryTags={categoryTags}
            categoryTagMap={categoryTagMap}
            Component={
              <ActivityCardList
                allowedTagSet={allowedTagSet}
                coreActivitySet={coreActivitySet}
              />
            }
            settings={settings}
          />
        </Grid>
      ) : (
        <Grid item xs={12} md={8} sx={{ mt: 1 }}>
          <SurveyHeading settings={settings}>
            <ActivityHeading
              showDragDrop={settings.showDragDrop}
              showHours={settings.showHours}
            />
          </SurveyHeading>
          <ActivityCardList
            allowedTagSet={allowedTagSet}
            coreActivitySet={coreActivitySet}
            settings={settings}
            showHeading={false}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ActivityDescriptions;
