import { useMemo } from 'react';
import serialize from '@pkg/utils/strings/serialize';
import filters from './filters';

/**
 * @param {Object} snapshot
 * @param {Object} [options]
 * @return {Object}
 */
export default function useMetrics(snapshot, options = {}) {
  return useMemo(() => {
    return filters.metrics(snapshot, options);
  }, [serialize(snapshot), serialize(options)]);
}
