const ActivityConfig = Object.freeze({
  canAddNew: true,
  isCoreActivity: true,
  isEditable: false,
  isTagsEditable: true,
  showComparison: true,
  showDaysPerMonth: false,
  showDragDrop: false,
  showHours: true,
  showMenu: true,
  showRemove: false,
  showPercentage: true,
  showTags: true,
  showTitle: true,
  title: 'Activities',
  showTagsExpanded: false,
});

export default ActivityConfig;
