import { cumulateTagMetrics, defaultMetrics } from '@/organisms/plans/utils';
import { ActionType } from '@/shared/enums';

export default function updateEntityTagsAction({
  entity,
  tagTypes,
  originalEntity,
  comparisonScenario,
}) {
  const primaryEntity = entity || originalEntity;

  const entityType = primaryEntity?.__type;

  const entityTags = entity?.__metrics?.self?.visible?.tags || {};
  const originalEntityTags =
    originalEntity?.__metrics?.self?.visible?.tags || {};
  const comparisonTags = comparisonScenario?.entity?.__metrics?.self
    ? comparisonScenario?.entity?.__metrics?.self?.visible?.tags
    : comparisonScenario?.entity?.__metrics?.visible?.tags;

  const comparisonMetrics = comparisonScenario?.entity?.__metrics?.self
    ? comparisonScenario?.entity?.__metrics?.self?.visible
    : comparisonScenario?.entity?.__metrics?.visible;

  const existingMetrics = defaultMetrics({ comparisonScenario });

  const cumulatedTagMetrics = cumulateTagMetrics({
    entityTags: entityTags,
    tagTypes,
    originalEntityTags: originalEntityTags,
    existingMetrics,
  });

  if (!cumulatedTagMetrics) {
    return;
  }

  const action = {
    type: ActionType.UPDATED_TAGS,
    [entityType]: new Map([
      [
        primaryEntity.uuid,
        {
          metrics: primaryEntity?.__metrics?.self?.visible,
        },
      ],
    ]),
    metrics: defaultMetrics({ comparisonScenario }),
  };

  action.metrics[0].tags = cumulatedTagMetrics[0];
  action.metrics[1].tags = cumulatedTagMetrics[1];

  return action;
}
