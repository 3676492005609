import { useEffect, useState } from 'react';
import {
  mapAggregateActivities,
  mapAggregateRoles,
  mapAggregateManagers,
} from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { useScenarioContext, useViewModeContext } from '@/shared/providers';
import { Sort } from '@pkg/utils';

const getMappedChangesFn = (entityType) => {
  switch (entityType) {
    case EntityType.ACTIVITY:
      return mapAggregateActivities;
    case EntityType.ROLE:
      return mapAggregateRoles;
    case EntityType.MANAGER:
      return mapAggregateManagers;
    default:
      return mapAggregateRoles;
  }
};

const useMappedEntityChanges = ({ entityType }) => {
  const { comparisonScenario, scenario } = useScenarioContext();
  const { showBudget } = useViewModeContext();
  const [isLoading, setIsLoading] = useState(true);
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    const mappedChangesFn = getMappedChangesFn(entityType);

    const entities = mappedChangesFn({
      comparisonScenario,
      scenario,
      showBudget,
    });

    if (!entities) {
      return;
    }

    setEntities(
      [...entities.entries()]
        .map(([id, entity]) => {
          return {
            ...entity,
            id,
          };
        })
        .sort(Sort.alpha('props.title'))
    );
    setIsLoading(false);
  }, [
    JSON.stringify(scenario?.entity?.__metrics?.visible),
    JSON.stringify(comparisonScenario?.entity?.__metrics?.visible),
    scenario?.details?.hash,
    showBudget,
    comparisonScenario?.details?.hash,
    entityType,
  ]);

  return {
    entities,
    isLoading,
  };
};

export default useMappedEntityChanges;
