import { generateText, streamText } from 'ai';
import { useState, useCallback } from 'react';
import client from '../client';

const DEFAULT_MODEL = 'gpt-4o-mini';

const usePrompt = ({ tools = {}, accessToken }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [streamData, setStreamData] = useState(null);

  const prompt = useCallback(
    async (promptOptions) => {
      const openai = client.createOpenAI({
        accessToken,
      });
      const shouldStream = promptOptions?.stream;

      if (!promptOptions) {
        return;
      }

      setIsLoading(true);
      setError(null);
      setData(null);
      setStreamData(null);

      console.debug('Sending prompt', promptOptions.prompt);

      try {
        if (shouldStream) {
          // Handle streaming response
          const streamResponse = streamText({
            maxRetries: 0,
            model: openai.responses(promptOptions.modelName || DEFAULT_MODEL),
            ...promptOptions,
            headers: {
              'x-prompt-layer': promptOptions?.promptLayer,
            },
          });

          setStreamData(streamResponse);
          return streamResponse;
        } else {
          const response = await generateText({
            maxRetries: 0,
            model: openai.responses(promptOptions.modelName || DEFAULT_MODEL),
            ...promptOptions,
            headers: {
              'x-prompt-layer': promptOptions?.promptLayer,
            },
          });
          setData(response);
          return response;
        }
      } catch (err) {
        setError(err);
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [tools]
  );

  return {
    error,
    isLoading,
    data,
    stream: streamData,
    prompt,
  };
};

export default usePrompt;
