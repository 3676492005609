import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

export default function assignedRolesAction({
  actionMap,
  comparisonScenario,
  originalRole,
  role,
  scenario,
}) {
  const metrics = {
    ...role.__metrics.self.visible,
  };

  const existingAction = actionMap?.get(ActionType.ASSIGNED_MANAGERS);

  const action = existingAction ?? {
    entity: EntityType.MANAGER,
    metrics: defaultMetrics({ comparisonScenario }),
    type: ActionType.ASSIGNED_MANAGERS,
    [EntityType.MANAGER]: new Map(),
    [EntityType.PERSON]: new Set(),
  };

  const roleProperties = {};

  if (role?.user_uuid !== originalRole?.user_uuid) {
    if (role?.user_uuid) {
      action[EntityType.PERSON].add(role.user_uuid);
    }
    if (originalRole?.user_uuid) {
      action[EntityType.PERSON].add(originalRole.user_uuid);
    }

    roleProperties.assigned = {
      from: originalRole?.user_uuid,
      to: role?.user_uuid,
    };
  }

  action.metrics[1].assigned += 1;

  // Aggregate the individual manager action metrics.
  const actionManager = action[EntityType.MANAGER].get(role.uuid) ?? {
    metrics: defaultMetrics({ comparisonScenario, role }),
  };
  actionManager.metrics[1].assigned += 1;
  actionManager.props = { ...roleProperties };
  action[EntityType.MANAGER].set(role.uuid, actionManager);

  const managerCount = action[EntityType.MANAGER].size;
  const peopleCount = action[EntityType.PERSON].size;

  const managerLabel = entityLabel(EntityType.MANAGER, managerCount !== 1);
  const peopleLabel = entityLabel(EntityType.PERSON, peopleCount !== 1);
  const verb = managerCount === 1 ? 'was' : 'were';

  action.title = `${managerCount} ${managerLabel} ${verb} reassigned, affecting ${peopleCount} ${peopleLabel}`;

  return action;
}
