import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';
import shortuuid from '@pkg/uuid';
import useStore from '../useStore';

const CREATE_FLOW_TEMPLATE = /* GraphQL */ `
  mutation CreateFlowTemplate($input: CreateFlowTemplateInput!) {
    createFlowTemplate(input: $input) {
      uuid
      name
      is_onboarding
      description
      disclaimer
      settings {
        show_days_per_month
        show_tags
        tags
      }
      type
    }
  }
`;

/**
 * @return {Function}
 */
export default function useCreate() {
  const { mutateAsync } = useMutation({
    mutation: CREATE_FLOW_TEMPLATE,
  });

  const database = useDatabase();
  const { add } = useStore();

  /**
   * Optimistically add a flow in storage
   * @todo handle failure
   */
  async function optimisticCreate(input) {
    add(input);
    await database.flowTemplates.put(input);
  }

  /**
   * @param {String} organisationId
   * @param {Object} input
   * @param {String} [input.uuid]
   * @param {String} input.name
   * @returns {Promise}
   */
  async function createFlowTemplate(input) {
    if (!input.uuid) {
      input.uuid = shortuuid.generate();
    }

    await optimisticCreate(input);
    return mutateAsync({ ...input });
  }

  return useCallback(createFlowTemplate, [mutateAsync]);
}
