import { useEffect, useState } from 'react';
import { Size } from '@/atoms/enums';
import { CheckboxOptions } from '@/atoms/inputs';
import { Paragraph } from '@/atoms/typography';
import { FilterSearch } from '@/molecules/filterElements';
import { usePropertyContext } from '@/shared/providers';
import Box from '@mui/material/Box';

const sortPropertyOptions = (list, selectedValues) => {
  if (!list.length) {
    return;
  }

  const selected = new Set(
    selectedValues?.length > 0 ? selectedValues?.map(({ id }) => id) : []
  );

  const options = list.map(({ name, uuid, value }) => ({
    id: uuid,
    name: name ?? value,
  }));

  if (selectedValues?.length) {
    options.sort((a, b) => {
      const aSelected = selected.has(a.id);
      const bSelected = selected.has(b.id);
      return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
    });
  }

  return options;
};

const PropertySelect = ({ propertyId, value, onChange }) => {
  const { getProperty, searchPropertyValues } = usePropertyContext();
  const property = getProperty(propertyId);
  const propertyOptions = property?.list || [];
  const [options, setOptions] = useState(null);

  const handleChange = (event, ids) => {
    const values = ids.map((id) => ({
      id,
      label: property?.__options?.get(id)?.name,
    }));

    onChange(event, values);
  };

  const handleSearch = (event, term) => {
    if (!term) {
      setOptions(sortPropertyOptions(propertyOptions, value));
      return;
    }

    const results = searchPropertyValues(propertyId, term);

    if (!results?.length) {
      setOptions([]);
      return;
    }

    setOptions(sortPropertyOptions(results, value));
  };

  // This effect sets up the default list of options sorted with checked properties
  // at the start of the list.
  useEffect(() => {
    if (!propertyOptions || options) {
      return;
    }

    setOptions(sortPropertyOptions(propertyOptions, value));
  }, [propertyOptions, value]);

  return options ? (
    <>
      <FilterSearch
        onSearch={handleSearch}
        placeholder="Search for properties"
      />
      <Box sx={{ maxHeight: 340, overflowY: 'auto' }}>
        {options?.length > 0 ? (
          <CheckboxOptions
            onChange={handleChange}
            options={options}
            optionLabel="name"
            initialSelectedIds={value?.map((item) => item.id)}
          />
        ) : (
          <Box p={2}>
            <Paragraph
              size={Size.SMALL}
              overrideStyles={{ textAlign: 'center' }}
            >
              No properties were found matching that search term
            </Paragraph>
          </Box>
        )}
      </Box>
    </>
  ) : (
    <Box p={2}>
      <Paragraph size={Size.SMALL} overrideStyles={{ textAlign: 'center' }}>
        No properties have been added.
      </Paragraph>
    </Box>
  );
};

export default PropertySelect;
