import { useRef } from 'react';
import { Entity as EntityColors } from '@/atoms/colors';
import {
  PlanActivityCard,
  PlanManagerCard,
  PlanRoleCard,
} from '@/organisms/cards';
import { ActionType, EntityType } from '@/shared/enums';
import { useOutsideClick } from '@/shared/hooks';
import { usePlanContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';

const getActionEntity = ({ actions, actionType, entityType, id }) => {
  if (!actions?.size) {
    return;
  }

  const action = actions.get(actionType);

  return action?.[entityType]?.get(id);
};

const getMovedEntity = ({ actions, entityType, id }) => {
  let actionType = null;

  if (entityType === EntityType.ROLE) {
    if (actions.has(ActionType.UPDATED_MANAGER)) {
      actionType = ActionType.UPDATED_MANAGER;
    }
    if (actions.has(ActionType.ADDED_ROLE)) {
      actionType = ActionType.ADDED_ROLE;
    }
    if (actions.has(ActionType.REMOVED_ROLE)) {
      actionType = ActionType.REMOVED_ROLE;
    }
  }

  if (entityType === EntityType.MANAGER) {
    if (actions.has(ActionType.ADD_MANAGERS)) {
      actionType = ActionType.ADD_MANAGERS;
    }
    if (actions.has(ActionType.REMOVE_MANAGERS)) {
      actionType = ActionType.REMOVE_MANAGERS;
    }
  }

  if (entityType === EntityType.ACTIVITY) {
    actionType = ActionType.MOVED_ACTIVITY;
  }

  if (actionType) {
    return getActionEntity({
      actions,
      actionType,
      entityType,
      id,
    });
  }
};

const getAssignedEntity = ({ actions, entityType, id }) => {
  let actionType = null;

  if (entityType === EntityType.ROLE) {
    actionType = ActionType.ASSIGNED_ROLES;
  }
  if (entityType === EntityType.MANAGER) {
    actionType = ActionType.ASSIGNED_MANAGERS;
  }

  if (actionType) {
    return getActionEntity({
      actions,
      actionType,
      entityType,
      id,
    });
  }
};

const PlanEntityType = ({ onSelect, entity, entityType }) => {
  const entityColor = EntityColors[entityType];
  const { selectedSummaryItem, selectSummaryItem } = usePlanContext();
  const ref = useRef();

  /**
   * Handles the default selection of the role type screen.
   */
  const handleSelectDefault = (event) => {
    event?.preventDefault();
    event?.stopPropagation();

    // Don't trigger an update if we're already on the default view.
    if (selectedSummaryItem?.id === entity.id) {
      return;
    }

    selectSummaryItem({
      ...entity,
      entityType,
    });
  };

  /**
   * Handles the selection of role cards.
   */
  const handleSelect = (selectedEntity) => {
    if (!selectedEntity || selectedEntity.id === selectedSummaryItem?.id) {
      handleSelectDefault();
      return;
    }

    // Add the moved properties on select.
    const movedEntity = getMovedEntity({
      actions: selectedEntity?.actions,
      entityType,
      id: selectedEntity.id,
    });

    if (movedEntity) {
      selectedEntity.props = {
        ...movedEntity.props,
        ...selectedEntity.props,
      };
    }

    // Add the assigned properties on select.
    const assignedEntity = getAssignedEntity({
      actions: selectedEntity?.actions,
      entityType,
      id: selectedEntity.id,
    });

    if (assignedEntity) {
      selectedEntity.props = {
        ...assignedEntity.props,
        ...selectedEntity.props,
      };
    }

    selectSummaryItem({
      ...selectedEntity,
      entityType,
    });
  };

  // Handles the click events triggered outside of the role type cards which
  // should default the view back to the role type screen.
  useOutsideClick({
    ref,
    onClick: handleSelectDefault,
    updateProperty: selectedSummaryItem?.id,
  });

  return (
    <Grid container spacing={2} p={2} onClick={handleSelectDefault} ref={ref}>
      {[...entity[entityType].entries()].map(([id, entity]) => (
        <Grid item xs={12} key={id}>
          {entityType === EntityType.ACTIVITY && (
            <PlanActivityCard
              actions={entity.actions}
              activityProperties={entity.properties}
              id={id}
              isSelected={id === selectedSummaryItem?.id}
              metrics={entity.metrics}
              onSelect={handleSelect}
            />
          )}
          {entityType === EntityType.ROLE && (
            <PlanRoleCard
              actions={entity.actions}
              id={id}
              isSelected={id === selectedSummaryItem?.id}
              metrics={entity.metrics}
              onSelect={handleSelect}
              roleProperties={entity.properties}
            />
          )}
          {entityType === EntityType.MANAGER && (
            <PlanManagerCard
              actions={entity.actions}
              id={id}
              isSelected={id === selectedSummaryItem?.id}
              metrics={entity.metrics}
              onSelect={handleSelect}
              managerProperties={entity.properties}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default PlanEntityType;
