import { tool } from 'ai';
import { z } from 'zod';
import { DesignEntity } from '@/lib/enums';

const groupAdd = tool({
  description: 'Add a new group to the organizational design',
  parameters: z.object({
    payload: z
      .object({
        name: z.string().describe('The name of the group'),
        objective: z.string().describe('The objective of the group'),
        group_uuid: z
          .string()
          .nullable()
          .describe(
            'The UUID of the outer group this group belongs to, or null for top-level groups. This can be set to null.'
          ),
        lead_uuid: z
          .string()
          .nullable()
          .describe(
            'The UUID of the role that leads this group, or null if no direct leader.  This can be set to null.'
          ),
        ids: z
          .array(
            z.object({
              id: z.string(),
              type: z.enum([DesignEntity.ROLE, DesignEntity.GROUP]),
            })
          )
          .describe(
            'Array of entity IDs and types to move into this new group'
          ),
        properties: z
          .array(z.string())
          .describe('Array of properties associated with this group'),
        tags: z
          .array(z.string())
          .describe('Array of tag UUIDs associated with this group'),
      })
      .describe('The creation payload for the group'),
    explanation: z
      .string()
      .describe(
        'An explanation of what is about to be created. This step will be asked to be confirmed. Phrase it accordingly.'
      ),
  }),
  execute: async ({ payload, explanation }) => {
    return {
      payload,
      explanation,
    };
  },
});

export default groupAdd;
