import { percent } from '@pkg/utils/numbers';
import { DesignEntity } from '@/lib/enums';
import defaultEntityMetrics from '../utils/defaultEntityMetrics';
import activityMetrics from './activityMetrics';
import deriveActivityTags from './deriveActivityTags';
import deriveGroupTags from './deriveGroupTags';
import deriveRoleTags from './deriveRoleTags';
import groupMetrics from './groupMetrics';
import normalizeActivity from './normalizeActivity';
import normalizeGroup from './normalizeGroup';
import normalizePerson from './normalizePerson';
import normalizeRole from './normalizeRole';
import personMetrics from './personMetrics';
import roleMetrics from './roleMetrics';

const filterConditionsExist = (filterConditions) => {
  if (filterConditions?.activities?.length) {
    return true;
  }
  if (filterConditions?.roles?.length) {
    return true;
  }
  if (filterConditions?.groups?.length) {
    return true;
  }
};

/**
 * Derives default properties and metrics for every entity in the snapshot.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function deriveEntities({
  includeTagMetrics,
  isFiltered,
  metrics,
  library,
  snapshot,
}) {
  // These sets are used to identity which entities have been missed when
  // looping through the activities first.
  const roles = new Set(Object.keys(snapshot.entities.roles));
  const groups = new Set(Object.keys(snapshot.entities.groups));
  const people = new Set(Object.keys(snapshot.entities.people));

  // The tag map is used to track entities that have been added for each tag
  // type. This assists with identifying unique entities at each level.
  const tagMap = new Map([
    [DesignEntity.ACTIVITY, new Map()],
    [DesignEntity.ROLE, new Map()],
    [DesignEntity.GROUP, new Map()],
  ]);

  // Loop through each activity and update every activity, role and group
  // meta data associated with it.
  snapshot.__keys.activities.forEach((id) => {
    // Add default properties and normalize the activity data.
    const activity = normalizeActivity({
      activity: snapshot.entities.activities[id],
      library,
      snapshot,
    });

    const unNormalizedRole = snapshot.entities.roles[activity?.owner_uuid];

    // Add default properties and normalize the person data.
    const person = unNormalizedRole
      ? normalizePerson({
          person: snapshot.entities.people[unNormalizedRole.user_uuid],
          people,
          role: unNormalizedRole,
          snapshot,
        })
      : null;

    // Add default properties and normalize the role data.
    const role =
      activity.owner_type === DesignEntity.ROLE
        ? metrics.has(activity.owner_uuid)
          ? snapshot.entities.roles[activity.owner_uuid]
          : normalizeRole({
              library,
              person,
              role: snapshot.entities.roles[activity.owner_uuid],
              snapshot,
            })
        : null;

    // Add default properties and normalize the group data.
    const group = role?.group_uuid
      ? metrics.has(role.group_uuid)
        ? snapshot.entities.groups[role.group_uuid]
        : normalizeGroup({
            group: snapshot.entities.groups[role.group_uuid],
            library,
            snapshot,
          })
      : null;

    // Apply the activity metrics directly to the activity.
    activity.__metrics = {
      self: activityMetrics({
        activity,
        role,
        group,
        metrics,
      }),
    };

    // Calculate the role metrics.
    const derivedRoleMetrics = role
      ? roleMetrics({
          activity,
          isFiltered,
          role,
          roles,
          group,
          metrics,
        })
      : null;

    // Calculate the person metrics.
    const derivedPersonMetrics =
      person && role
        ? personMetrics({
            activity,
            people,
            person,
            role,
            roles,
            group,
            metrics,
          })
        : null;

    // Calculate the group metrics.
    const derivedGroupMetrics = group
      ? groupMetrics({
          activity,
          role,
          roles,
          group,
          groups,
          metrics,
        })
      : null;

    // Apply the role metrics to the role object in the metrics map.
    if (derivedRoleMetrics) {
      const roleMetrics = metrics.get(role.uuid) || defaultEntityMetrics();
      roleMetrics.self = derivedRoleMetrics;
      role.__percentage = percent(
        derivedRoleMetrics.visible.hours,
        derivedRoleMetrics.total.hours
      );
      role.__total_metrics = derivedRoleMetrics.total;
      role.__visible_metrics = derivedRoleMetrics.visible;
      metrics.set(role.uuid, roleMetrics);
      roles.delete(role.uuid);
    }

    // Apply the group metrics to the group object in the metrics map.
    if (derivedGroupMetrics) {
      const groupMetrics = metrics.get(group.uuid) || defaultEntityMetrics();
      groupMetrics.self = derivedGroupMetrics;
      group.__percentage = percent(
        derivedGroupMetrics.visible.hours,
        derivedGroupMetrics.total.hours
      );
      group.__total_metrics = derivedGroupMetrics.total;
      group.__visible_metrics = derivedGroupMetrics.visible;

      metrics.set(group.uuid, groupMetrics);
      groups.delete(group.uuid);
    }

    // Apply the person metrics to the person object in the metrics map.
    if (derivedPersonMetrics) {
      const personMetrics = metrics.get(person.uuid) || defaultEntityMetrics();
      personMetrics.self = derivedPersonMetrics;
      personMetrics.__total_metrics = derivedPersonMetrics.total;
      personMetrics.__visible_metrics = derivedPersonMetrics.visible;
      metrics.set(person.uuid, personMetrics);
      people.delete(person.uuid);
    }

    // Exit if we're not calculating tag metrics.
    if (!includeTagMetrics) {
      return;
    }

    // Update the activity tag metrics.
    if (activity.tags.length > 0) {
      deriveActivityTags({
        activity,
        role,
        group,
        metrics,
        tagMap,
      });
    }

    // Update the role tag metrics.
    if (role?.tags?.length) {
      deriveRoleTags({
        activity,
        role,
        group,
        metrics,
        tagMap,
      });
    }

    // Update the group tag metrics.
    if (group?.tags?.length) {
      deriveGroupTags({
        activity,
        role,
        group,
        metrics,
        tagMap,
      });
    }
  });

  // Here derive roles that didn't have any activities.
  [...roles].forEach((roleId) => {
    const unNormalizedRole = snapshot.entities.roles[roleId];

    // Add default properties and normalize the person data.
    const person = unNormalizedRole
      ? normalizePerson({
          person: snapshot.entities.people[unNormalizedRole.user_uuid],
          people,
          role: unNormalizedRole,
          snapshot,
        })
      : null;

    // Add default properties and normalize the role data.
    const role = normalizeRole({
      library,
      person,
      role: snapshot.entities.roles[roleId],
      snapshot,
    });

    // Add default properties and normalize the group data.
    const group = role?.group_uuid
      ? metrics.has(role.group_uuid)
        ? snapshot.entities.groups[role.group_uuid]
        : normalizeGroup({
            group: snapshot.entities.groups[role.group_uuid],
            library,
            snapshot,
          })
      : null;

    // Calculate the role metrics.
    const derivedRoleMetrics = role
      ? roleMetrics({
          isFiltered,
          role,
          roles,
          group,
          metrics,
        })
      : null;

    // Calculate the person metrics.
    const derivedPersonMetrics =
      person && role
        ? personMetrics({
            people,
            person,
            role,
            roles,
            group,
            metrics,
          })
        : null;

    // Calculate the group metrics.
    const derivedGroupMetrics = group
      ? groupMetrics({
          role,
          roles,
          group,
          groups,
          metrics,
        })
      : null;

    // Apply the role metrics to the role object in the metrics map.
    if (derivedRoleMetrics) {
      // Ideally we can get rid of the need for this object in the future.
      const roleMetrics = metrics.get(role.uuid) || defaultEntityMetrics();
      roleMetrics.self = derivedRoleMetrics;
      role.__total_metrics = derivedRoleMetrics.total;
      role.__visible_metrics = derivedRoleMetrics.visible;
      metrics.set(role.uuid, roleMetrics);
      roles.delete(role.uuid);
    }

    // Apply the group metrics to the group object in the metrics map.
    if (derivedGroupMetrics) {
      const groupMetrics = metrics.get(group.uuid) || defaultEntityMetrics();
      groupMetrics.self = derivedGroupMetrics;
      group.__total_metrics = derivedGroupMetrics.total;
      group.__visible_metrics = derivedGroupMetrics.visible;
      metrics.set(group.uuid, groupMetrics);
      groups.delete(group.uuid);
    }

    // Apply the person metrics to the person object in the metrics map.
    if (derivedPersonMetrics) {
      const personMetrics = metrics.get(person.uuid) || defaultEntityMetrics();
      personMetrics.self = derivedPersonMetrics;
      personMetrics.__total_metrics = derivedPersonMetrics.total;
      personMetrics.__visible_metrics = derivedPersonMetrics.visible;
      metrics.set(person.uuid, personMetrics);
      people.delete(person.uuid);
    }

    // Exit if we're not calculating tag metrics.
    if (!includeTagMetrics) {
      return;
    }

    // Update the role tag metrics.
    if (role?.tags?.length) {
      deriveRoleTags({
        role,
        group,
        metrics,
        tagMap,
      });
    }

    // Update the group tag metrics.
    if (group?.tags?.length) {
      deriveGroupTags({
        role,
        group,
        metrics,
        tagMap,
      });
    }
  });

  // Here we derive groupsthat didn't have any roles.
  [...groups].forEach((groupId) => {
    const group = normalizeGroup({
      group: snapshot.entities.groups[groupId],
      library,
      snapshot,
    });

    // Calculate the group metrics..
    const derivedGroupMetrics = group
      ? groupMetrics({
          group,
          groups,
          metrics,
        })
      : null;

    if (derivedGroupMetrics) {
      const groupMetrics = metrics.get(group.uuid) || defaultEntityMetrics();
      groupMetrics.self = derivedGroupMetrics;
      group.__total_metrics = derivedGroupMetrics.total;
      group.__visible_metrics = derivedGroupMetrics.visible;
      metrics.set(group.uuid, groupMetrics);
      groups.delete(group.uuid);
    }

    // Exit if we're not calculating tag metrics.
    if (!includeTagMetrics) {
      return;
    }

    // Calculate the group tags.
    if (group?.tags?.length) {
      deriveGroupTags({
        group,
        metrics,
        tagMap,
      });
    }
  });

  // Here derive people that haven't been assigned to any roles.
  [...people].forEach((personId) => {
    // Add default properties and normalize the person data.
    const person = normalizePerson({
      person: snapshot.entities.people[personId],
      people,
      snapshot,
    });
  });

  return snapshot;
}
