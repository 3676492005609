import updateMetrics from './updateMetrics';

/**
 * Updates the root metrics.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function updateRootMetrics({
  metrics,
  entityMetrics,
  visibleMetrics,
}) {
  const rootMetrics = metrics.get('*');

  if (!rootMetrics) {
    return;
  }

  const total = updateMetrics({
    target: rootMetrics.total,
    source: { ...entityMetrics },
  });

  const visible = visibleMetrics
    ? updateMetrics({
        target: rootMetrics.visible,
        source: { ...visibleMetrics },
      })
    : rootMetrics.visible;

  metrics.set('*', { total, visible });

  return { total, visible };
}
