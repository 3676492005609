import { defaultMetrics } from '@/organisms/plans';
import { ActionType, EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

/**
 * Adds span changes to the actions map.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function upatedSpansAction({
  actionMap,
  comparisonScenario,
  originalRole,
  role,
  scenario,
}) {
  const existingAction = actionMap?.get(ActionType.UPDATED_SPANS);
  const primaryRole = role || originalRole;

  const action = existingAction ?? {
    entity: EntityType.MANAGER,
    metrics: defaultMetrics({ comparisonScenario }),
    type: ActionType.UPDATED_SPANS,
    [EntityType.MANAGER]: new Map(),
  };

  if (!existingAction) {
    const scenarioType = scenario?.entity?.__type;
    const scenarioMetrics = scenario?.entity?.__metrics;
    const comparisonMetrics = comparisonScenario?.entity?.__metrics;

    if (scenarioType === EntityType.ORGANISATION) {
      const scenarioSpans = scenarioMetrics?.visible?.span || 0;
      const scenarioManagers = scenarioMetrics.visible.managers || 0;

      action.metrics[0].averageSpan =
        action.metrics[0].span / action.metrics[0].managers;

      action.metrics[1].averageSpan = scenarioSpans / scenarioManagers;
      action.metrics[1].span = scenarioSpans;
    }

    if (scenarioType !== EntityType.ORGANISATION) {
      const comparisonSpans = comparisonMetrics?.span?.visible?.roles || 0;
      const comparisonManagers =
        comparisonMetrics?.self?.visible?.managers || 0;
      const scenarioSpans = scenarioMetrics?.span?.visible?.roles || 0;
      const scenarioManagers = scenarioMetrics?.self?.visible?.managers || 0;

      action.metrics[0].averageSpan = comparisonSpans / comparisonManagers;
      action.metrics[1].averageSpan = scenarioSpans / scenarioManagers;
    }
  }

  // Aggregate the individual manager action metrics.
  const actionManager = action[EntityType.MANAGER].get(primaryRole.uuid) ?? {
    metrics: defaultMetrics({
      comparisonScenario,
      includeSpans: true,
      role: primaryRole,
    }),
  };

  const originalRoleSpan = originalRole?.__metrics?.span?.visible.roles || 0;
  const roleSpans = role?.__metrics?.span?.visible.roles || 0;
  const spanChange = role ? roleSpans - originalRoleSpan : -originalRoleSpan;

  actionManager.metrics[1].span += spanChange;
  action[EntityType.MANAGER].set(primaryRole.uuid, actionManager);

  const managerCount = action[EntityType.MANAGER].size;
  const managerLabel = entityLabel(EntityType.MANAGER, managerCount !== 1);

  action.title = `Spans were updated for ${managerCount} ${managerLabel}`;

  return action;
}
