import { Draggable } from '@hello-pangea/dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { styled } from '@mui/material/styles';
import excludeProps from '@/lib/theme/excludeProps';

const DraggableContainer = styled('div', {
  shouldForwardProp: excludeProps('zIndex'),
})(({ zIndex }) => ({
  alignItems: 'center',
  display: 'flex',
  paddingBottom: '8px',
  left: 'auto !important',
  flexDirection: 'row',
  top: 'auto !important',
  position: 'relative',
  zIndex,
}));

const DraggableHandle = styled('div')({
  height: '2rem',
  lineHeight: '2rem',
  padding: '4px',
  textAlign: 'center',
});

const DraggedContainer = styled('div', {
  shouldForwardProp: excludeProps('isDragDisabled'),
})(({ isDragDisabled }) => ({
  flexGrow: 1,
  maxWidth: isDragDisabled ? '100%' : 'calc(100% - 32px)',
}));

const DraggableLayout = ({
  children,
  draggableId,
  index,
  isDragDisabled,
  zIndex,
}) => {
  return (
    <Draggable
      draggableId={draggableId}
      index={index}
      isDragDisabled={isDragDisabled}
      styles={{ backgroundColor: '#000' }}
    >
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <DraggableContainer {...draggableProps} ref={innerRef} zIndex={zIndex}>
          {!isDragDisabled && (
            <DraggableHandle {...dragHandleProps} width="80px" height="80px">
              <DragIndicatorIcon />
            </DraggableHandle>
          )}
          <DraggedContainer isDragDisabled={isDragDisabled}>
            {children}
          </DraggedContainer>
        </DraggableContainer>
      )}
    </Draggable>
  );
};
export default DraggableLayout;
