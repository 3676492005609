import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { Paragraph } from '@/atoms/typography';
import { isTagActionType, PlanSummaryChange } from '@/organisms/plans';
import { ActionType } from '@/shared/enums';
import { usePlanContext, useTagContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ActionSummaryOverview from './ActionSummaryOverview';
import EntityMovement from './EntityMovement';
import EntitySummaryOverview from './EntitySummaryOverview';

const hasEntityProps = (summary) => {
  if (!summary || !summary.props) {
    return false;
  }

  const { props } = summary;

  const entityProps = Boolean(props?.activity || props?.role || props?.group);

  if (entityProps) {
    return true;
  }

  return !summary.actionType;
};

const PlanSummary = () => {
  const {
    comparisonMetrics,
    metricList,
    scenarioMetrics,
    selectedSummaryItem,
    tagMetricList,
  } = usePlanContext();
  const { tagMap } = useTagContext();
  const showMetricChanges = Boolean(comparisonMetrics && scenarioMetrics);
  const metrics = [comparisonMetrics, scenarioMetrics];
  const isEntitySummary = hasEntityProps(selectedSummaryItem);

  const tag = isTagActionType(selectedSummaryItem?.actionType)
    ? tagMap.get(selectedSummaryItem?.props?.id)
    : null;
  const tagProperties = tag ? { id: tag.uuid, name: tag.name } : null;

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {showMetricChanges ? (
        <Grid container spacing={2} p={2}>
          {selectedSummaryItem && !isEntitySummary && (
            <Grid item xs={12}>
              <ActionSummaryOverview action={selectedSummaryItem} />
            </Grid>
          )}
          {isEntitySummary && (
            <Grid item xs={12}>
              <EntitySummaryOverview entity={selectedSummaryItem} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Heading
              variant="h6"
              overrideStyles={{ fontWeight: 600, textTransform: 'uppercase' }}
            >
              Scenario changes
            </Heading>
          </Grid>
          {selectedSummaryItem?.props?.assigned && (
            <Grid item xs={12}>
              <EntityMovement
                entityType={selectedSummaryItem?.entityType}
                movementType="assigned"
                properties={selectedSummaryItem.props}
              />
            </Grid>
          )}
          {selectedSummaryItem?.props?.moved && (
            <Grid item xs={12}>
              <EntityMovement
                entityType={selectedSummaryItem?.entityType}
                movementType="moved"
                properties={selectedSummaryItem.props}
              />
            </Grid>
          )}
          {metricList?.map((metric) => (
            <Grid item xs={12} key={metric}>
              <PlanSummaryChange
                actionType={selectedSummaryItem?.actionType}
                actionProperties={tagProperties || selectedSummaryItem?.props}
                metric={metric}
                metrics={metrics}
              />
            </Grid>
          ))}
          {tagMetricList?.map((metric, index) => (
            <Grid item xs={12} key={`${metric.id}-${index}`}>
              <PlanSummaryChange
                actionType={ActionType.UPDATED_TAGS}
                actionProperties={{
                  id: metric.id,
                  name: metric.name,
                }}
                metric={metric.metric}
                metrics={metric.metrics}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 200px)"
        >
          <Paragraph size={Size.MEDIUM}>
            No scenario changes have been made.
          </Paragraph>
        </Stack>
      )}
    </Box>
  );
};

export default PlanSummary;
