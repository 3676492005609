import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';

const MERGE = /* GraphQL */ `
  mutation MergeLibraryActivity($input: MergeLibraryActivityInput!) {
    mergeLibraryActivity(input: $input)
  }
`;

/**
 * @return {Function}
 */
export default function useMerge() {
  const database = useDatabase();
  const { mutateAsync } = useMutation({ mutation: MERGE });
  const { enqueueSnackbar } = useSnackbar();

  /**
   * @param {String} source_uuid
   * @param {String} target_uuid
   * @returns {Promise}
   */
  async function mergeActivity(source_uuid, target_uuid) {
    enqueueSnackbar('Your activities are being merged.');
    database.activities.delete(source_uuid); // @todo rollback

    // Replace library_uuid in activities in client stored revisions
    const revisions = await database.designRevisions.toArray();

    for (const revision of revisions) {
      let modified = false;

      if (revision.snapshot?.entities?.activities) {
        for (const activity of revision.snapshot.entities.activities) {
          if (activity.library_uuid === source_uuid) {
            activity.library_uuid = target_uuid;
            modified = true;
          }
        }
      }

      if (modified) {
        database.designRevisions.update(revision.uuid, revision);
      }
    }

    return mutateAsync({ source_uuid, target_uuid });
  }

  return useCallback(mergeActivity, [mutateAsync]);
}
