import { ActionType, EntityType } from '@/shared/enums';
import { EntityMetric } from '@/lib/enums';
import determineMetricOrder from './determineMetricOrder';

const fallbackMetric = (actionType) => {
  switch (actionType) {
    case ActionType.UPDATE_FTE:
    case ActionType.UPDATED_FTE:
      return EntityMetric.FTE;
    case ActionType.UPDATED_SPANS:
      return EntityMetric.AVERAGE_SPAN;
    default:
      return EntityMetric.HOURS;
  }
};

const getChangedTagMetric = ({ entityType, metrics }) => {
  if (!metrics[1]?.tags?.[entityType]) {
    return;
  }

  const tagMetricList = Object.keys(metrics[1].tags[entityType]);

  for (let i = 0; i < tagMetricList.length; i++) {
    const tagId = tagMetricList[i];

    const comparisonMetrics = metrics[0]?.tags?.[entityType]?.[tagId];
    const entityMetrics = metrics[1]?.tags?.[entityType]?.[tagId];

    if (entityType === EntityType.ACTIVITY) {
      const hours = comparisonMetrics.hours !== entityMetrics.hours;
      const activities =
        comparisonMetrics.activities !== entityMetrics.activities;

      if (!hours && !activities) {
        continue;
      }

      return {
        metricType: hours ? EntityMetric.HOURS : EntityMetric.ACTIVITIES,
        metrics: [comparisonMetrics, entityMetrics],
        tagId,
      };
    }

    if (entityType === EntityType.ROLE) {
      const roles = comparisonMetrics.roles !== entityMetrics.roles;
      const fte = comparisonMetrics.fte !== entityMetrics.fte;

      if (!roles && !fte) {
        continue;
      }

      return {
        metricType: roles ? EntityMetric.ROLES : EntityMetric.FTE,
        metrics: [comparisonMetrics, entityMetrics],
        tagId,
      };
    }

    if (entityType === EntityType.GROUP) {
      if (comparisonMetrics.groups === entityMetrics.groups) {
        continue;
      }

      return {
        metricType: EntityMetric.GROUPS,
        metrics: [comparisonMetrics, entityMetrics],
        tagId,
      };
    }
  }
};

export default function determineMetric({
  actionType,
  entityType,
  metrics,
  showBudget,
}) {
  if (!metrics) {
    return {
      metricType: fallbackMetric(actionType),
      metrics,
    };
  }

  const order = determineMetricOrder(entityType, actionType);

  for (let i = 0; i < order.length; i++) {
    const metric = order[i];

    if (!showBudget && metric === EntityMetric.BUDGET) {
      continue;
    }

    const metricA = metrics[0]?.[metric] || 0;
    const metricB = metrics[1]?.[metric] || 0;

    if (metricA !== metricB) {
      return {
        metricType: metric,
        metrics,
      };
    }
  }

  const changedTagMetric = getChangedTagMetric({
    metrics,
    entityType,
  });

  if (changedTagMetric) {
    return {
      ...changedTagMetric,
    };
  }

  return {
    metricType: fallbackMetric(actionType),
    metrics,
  };
}
