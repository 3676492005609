import merge from 'lodash/merge';
import set from 'lodash/set';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useViewModeContext } from '@/shared/providers';
import { DiagramEntity, DiagramLayout, DiagramSlot } from '@/lib/enums';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';

const STORAGE_PREFIX = 'diagram.settings.';
export const DEFAULT_SETTINGS = Object.freeze({
  __key: STORAGE_PREFIX,
  layout: DiagramLayout.TREE,
  structure: {
    [DiagramSlot.GROUPS]: DiagramEntity.DESIGN_GROUP,
    [DiagramSlot.CARDS]: DiagramEntity.DESIGN_ROLE,
    [DiagramSlot.ITEMS]: DiagramEntity.DESIGN_ACTIVITY,
    [DiagramSlot.SUBS]: null,
  },
  budgets: {
    visible: false,
  },
  properties: {
    expanded: false,
    visible: new Set(),
  },
  tags: {
    expanded: false,
  },
  roles: {
    metrics: {
      visible: false,
    },
  },
});

export default function useDisplaySettings() {
  const key = useDesignStore((state) => state.key);
  const settings = useDesignStore((state) => state.settings);
  const setSettings = useDesignStore((state) => state.setSettings);
  const ref = useRef(settings);

  /**
   * Load settings from localStorage
   * @returns {void}
   */
  const handleLoad = () => {
    if (!key) {
      return;
    }

    const stored = localStorage.getItem(`${STORAGE_PREFIX}${key}`);
    const parsed = stored ? JSON.parse(stored) : null;

    const isKey = parsed?.__key === key;
    const loaded = structuredClone(isKey ? parsed : DEFAULT_SETTINGS);
    const settings = merge({}, DEFAULT_SETTINGS, loaded);

    settings.__key = key;
    settings.properties.visible = new Set(settings.properties.visible ?? []);
    console.debug('Auth.useDisplaySettings.handleLoad', {
      key,
      stored,
      parsed,
      settings,
    });

    setSettings(Object.freeze(settings));
  };

  /**
   * Store settings in localStorage
   * @param {Object} input
   * @param {String?} path - optional path to update
   * @returns {void}
   */
  function handleStore(input, path = null) {
    if (!key) {
      return;
    }

    const updated = structuredClone(ref.current);
    if (path) {
      set(updated, path, input);
    } else {
      merge(updated, input);
    }

    const storing = structuredClone(updated);
    storing.__key = key;
    storing.properties.visible = Array.from(storing.properties.visible);

    console.debug('Auth.useDisplaySettings.handleStore', {
      key,
      path,
      input,
      storing,
      updated,
    });

    localStorage.setItem(`${STORAGE_PREFIX}${key}`, JSON.stringify(storing));
    setSettings(Object.freeze(updated));
  }

  /**
   * Update settings when storage key changes
   */
  useEffect(() => {
    if (ref.current?.__key !== key) {
      handleLoad();
    }
  }, [key]);

  /**
   * Keep ref up to date
   */
  useEffect(() => {
    ref.current = settings;
  }, [settings]);

  /**
   * @todo refactor budget visibility
   */
  const viewMode = useViewModeContext();
  const showBudget = viewMode?.showBudget ?? false;
  useEffect(() => {
    handleStore(showBudget, 'budgets.visible');
  }, [showBudget]);

  /**
   * Finalise and return
   */
  const loadSettings = useCallback(handleLoad, [key]);
  const storeSettings = useCallback(handleStore, [key]);
  const result = { loadSettings, storeSettings };
  return useMemo(() => result, [loadSettings, storeSettings]);
}
