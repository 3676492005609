import { useState, memo } from 'react';
import * as Colors from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { PercentageBar } from '@/atoms/metrics';
import { TextMetric } from '@/atoms/metrics';
import { Label } from '@/atoms/typography';
import {
  ActivityField,
  InlineNumberField,
  InlineTagField,
} from '@/molecules/formElements';
import { ConfirmationButton } from '@/molecules/formElements';
import { ActivityConfig } from '@/organisms/activities';
import { OutlineCard } from '@/organisms/cards';
import { DispatchProvider } from '@/shared/providers';
import { hoursToDaysPerMonth } from '@/shared/utils/calculations';
import metricColor from '@/shared/utils/colors/metricColor';
import roundHours from '@/shared/utils/roundHours';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import Stack from '@mui/material/Stack';
import { percent } from '@pkg/utils/numbers';
import { Property } from '@/lib/enums';
import Box from '@/components/Box';
import ActivityMenu from './ActivityMenu';
import DifferenceCard from './DifferenceCard';

const setCardWidth = ({
  showComparison,
  showDaysPerMonth,
  isEditable,
  showMenu,
  showRemove,
}) => {
  let percentage = 100;
  let pixels = 0;

  if (isEditable && showComparison) {
    percentage -= 20;
    pixels += 8;
  }

  if (showDaysPerMonth) {
    pixels += 156;
  }

  if (showMenu) {
    pixels += 32;
  }

  if (showRemove) {
    pixels += 28;
  }

  return `calc(${percentage}% - ${pixels}px)`;
};

const ActivityCard = ({
  allowedTagSet = null,
  id,
  cardRef,
  comparisonHours,
  disabled,
  description,
  entityType,
  hours,
  isCoreActivity,
  isNew,
  metrics,
  roleId,
  settings = ActivityConfig,
  tags,
}) => {
  const {
    showComparison,
    showDaysPerMonth,
    showHours,
    showMenu,
    showPercentage,
    showRemove,
    showTags,
    showTagsExpanded,
    isEditable,
    isTagsEditable,
  } = settings;

  const colors = metricColor(entityType);
  const percentage =
    metrics.totalHours > 0 ? percent(hours, metrics.totalHours) : 0;
  const [tagList] = useState(
    allowedTagSet ? tags.filter((tag) => allowedTagSet.has(tag)) : tags
  );

  const daysPerMonth = showDaysPerMonth ? hoursToDaysPerMonth(hours) : null;

  const cardWidth = setCardWidth({
    showDaysPerMonth,
    showComparison,
    showMenu,
    showRemove: showRemove || (isEditable && !isCoreActivity),
    isEditable,
  });

  return (
    <Stack
      direction="row"
      alignItems="stretch"
      justifyContent="flex-start"
      spacing={1}
    >
      <div
        ref={cardRef}
        style={{
          width: cardWidth,
          minWidth: cardWidth,
        }}
      >
        <OutlineCard margin={0} padding={0} width="100%">
          <Stack
            direction="row"
            width="100%"
            position="relative"
            sx={{ opacity: disabled ? 0.5 : 1 }}
          >
            <Stack
              sx={{
                flexGrow: 1,
                width: showHours ? 'calc(100% - 70px)' : '100%',
              }}
              zIndex={0}
              alignItems="space-between"
            >
              <Stack
                direction="row"
                alignItems="baseline"
                justifyContent="space-between"
              >
                <Box
                  width={
                    showHours && showPercentage ? 'calc(100% - 38px)' : '100%'
                  }
                  px={0.5}
                  pt={0.5}
                  pb={showHours ? 0 : 0.5}
                >
                  <DispatchProvider
                    uuid={id}
                    action="activities.update"
                    isEditable={isEditable && !isCoreActivity && !disabled}
                    name={Property.DESCRIPTION}
                  >
                    <ActivityField
                      autoFocus={id === 'new'}
                      name="library_uuid"
                      placeholder="Activity name"
                      initialValue={{ uuid: id, description }}
                      size={Size.LARGE}
                      id={id}
                    />
                  </DispatchProvider>
                </Box>
                {showHours && showPercentage && percentage > 0 && (
                  <Box mr={1.5}>
                    <Label overrideStyles={{ fontWeight: 600 }}>
                      {percentage}%
                    </Label>
                  </Box>
                )}
              </Stack>
              {showHours && (
                <Box px={1.5} pt={showTags ? 0 : 1}>
                  <PercentageBar
                    colors={colors}
                    numerator={hours}
                    denominator={metrics.totalHours}
                  />
                </Box>
              )}
              {showTags && (
                <Box py={tagList?.length === 0 ? 0.5 : 0.75} px={1.5}>
                  <DispatchProvider
                    uuid={id}
                    action="activities.update"
                    isEditable={isEditable && !disabled && isTagsEditable}
                    name={Property.TAGS}
                  >
                    <InlineTagField
                      key={id}
                      id={id}
                      expanded={showTagsExpanded}
                      initialTagIds={tagList}
                      name={Property.TAGS}
                      allowedTagSet={allowedTagSet}
                    />
                  </DispatchProvider>
                </Box>
              )}
            </Stack>
            {showHours && (
              <Stack
                sx={{
                  boxShadow: `-1px 0 0 ${Colors.Card.outline.borderColor}`,
                }}
                width="64px"
                zIndex={1}
                justifyContent="flex-start"
              >
                <Box width="100%" px={0.5} pt={0.5}>
                  <DispatchProvider
                    uuid={id}
                    action="activities.update"
                    isEditable={isEditable && !disabled}
                    name={Property.HOURS}
                  >
                    <InlineNumberField
                      uuid={id}
                      valueFormatter={roundHours}
                      initialValue={hours}
                      id={id}
                      precision={2}
                      inputProps={{
                        min: 0,
                      }}
                      name={Property.HOURS}
                      placeholder="0"
                      textAlign="center"
                      size={Size.X_LARGE}
                    />
                  </DispatchProvider>
                </Box>
                <Box width="100%" pb={0.5}>
                  <Label
                    overrideStyles={{ textAlign: 'center', fontWeight: 500 }}
                  >
                    HOURS
                  </Label>
                </Box>
              </Stack>
            )}
          </Stack>
        </OutlineCard>
      </div>
      {isEditable && showComparison && (
        <div style={{ width: '20%' }}>
          <DifferenceCard
            label={{ singular: 'hour', plural: 'hours' }}
            isNew={isNew}
            value={hours}
            comparisonValue={comparisonHours}
          >
            Changed
          </DifferenceCard>
        </div>
      )}
      {showDaysPerMonth && (
        <Stack
          direction="row"
          width={138}
          justifyContent="stretch"
          alignItems="center"
          pl={1}
        >
          <TextMetric
            size={Size.XX_SMALL}
            value={daysPerMonth}
            boldValue={false}
            label="Days per month"
          />
        </Stack>
      )}
      {isEditable && showMenu && (
        <div style={{ alignSelf: 'flex-start', marginLeft: '4px' }}>
          <ActivityMenu
            disabled={disabled}
            id={id}
            isEditable={isEditable}
            isCoreActivity={isCoreActivity}
            roleId={roleId}
          />
        </div>
      )}
      {isEditable && showRemove && !isCoreActivity && (
        <div style={{ alignSelf: 'center' }}>
          <DispatchProvider>
            <ConfirmationButton
              action="activities.archive"
              properties={[id]}
              iconButton={<DeleteIcon />}
              label="Remove"
              variant="text"
              color="secondary"
              confirmation={{
                title: 'Remove activity',
                content: 'Are you sure you wish to remove this activity?',
              }}
            />
          </DispatchProvider>
        </div>
      )}
    </Stack>
  );
};

export default memo(ActivityCard, (previous, next) => {
  return (
    previous.index === next.index &&
    previous.updatedAt === next.updatedAt &&
    previous.id === next.id &&
    previous.description === next.description &&
    previous.isCoreActivity === next.isCoreActivity &&
    previous.isNew === next.isNew &&
    previous.metrics?.totalHours === next.metrics?.totalHours &&
    previous.comparisonHours === next.comparisonHours &&
    previous.settings?.isEditable === next.settings?.isEditable
  );
});
