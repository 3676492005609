/**
 * Returns the first tag id that exists in the list of activities.
 *
 * @param {Object}
 *
 * @return {String|null}
 */
export default function retrieveFirstTagId({ activityList, tags }) {
  for (let i = 0; i < tags.length; i++) {
    const tagId = tags[i];
    const activity = activityList.find((activity) =>
      activity.tags.includes(tagId)
    );

    if (activity) {
      return tagId;
    }
  }
}
