import { forwardRef } from 'react';
import { Button } from '@/atoms/buttons';
import { Entity as EntityColors } from '@/atoms/colors';
import { Heading } from '@/atoms/typography';
import { isTagActionType, PlanChip } from '@/organisms/plans';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { core } from '@/lib/theme/tokens/palettes';

const PlanListHeading = forwardRef(function PlanListHeading(
  { actionType, entityType, name, onClick, properties, title },
  ref
) {
  const entityColor = EntityColors[entityType];
  const isTagPlan = isTagActionType(actionType);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      py={1}
      pl={4}
      pr={2}
      ref={ref}
      sx={{ backgroundColor: entityColor.primary }}
    >
      <Stack alignItems="center" direction="row" spacing={1}>
        {' '}
        {isTagPlan && (
          <PlanChip
            actionType={actionType}
            entityType={entityType}
            properties={properties}
          />
        )}
        {!isTagPlan && <PlanChip entityType={entityType} plural={true} />}
        <Heading
          variant="h4"
          overrideStyles={{
            color: core.white,
            fontWeight: 500,
            textShadow: `0 1px 1px rgba(0,0,0,0.2)`,
            mt: 0.25,
          }}
        >
          {title}
        </Heading>
      </Stack>
      <Box flexShrink={0}>
        <Button
          color="white"
          label={`Back to ${name.toLowerCase()}`}
          onClick={onClick}
          startIcon={<ArrowBackIcon />}
          variant="naked"
        />
      </Box>
    </Stack>
  );
});

export default PlanListHeading;
